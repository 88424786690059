import { TimeSelectionType } from '../assetConstants';
import { AssetTypeDefinition } from './assetTypes';

export const ASSETTYPE_MEETINGROOM = 'MeetingRoom';

export const DEFAULTS_MEETINGROOM: AssetTypeDefinition = {
    alwaysUseDays: false,
    assetTypeTag: ASSETTYPE_MEETINGROOM,
    backdrop: '/images/defaults/backdrop/RB_gemensamma-resurser_lokal.png',
    canChangeToDaily: true,
    canChangeToHourly: true,
    canChangeToInterval: false,
    endTimeNextDay: true,
    endTimeRestOfDay: false,
    image: '/images/defaults/preview/RB_gemensamma-resurser_lokal.png',
    isSingleton: true,
    name: 'Lokal',
    nameDefinitiveForm: 'lokalen',
    namePlural: 'lokaler',
    nameSingular: 'lokal',
    options: [
        'Wifi',
        'Toalett',
        'Dusch',
        'Kök/pentry',
        'Kylskåp',
        'Frys',
        'Diskmaskin',
        'Mikrovågsugn',
    ],
    prefixName: true,
    serviceInformationText: 'Ex. Gemensamhetslokal med sittplatser för ca 30 personer. Kök och WC i anslutning till lokalen. Två parkeringsplatser kan hyras vid behov.',
    serviceInformationTitle: 'Plats för gemenskap',
    thumbnail: '/images/defaults/thumbnails/RB_gemensamma-resurser_lokal.png',
    timeSelectionType: TimeSelectionType.Day,
}