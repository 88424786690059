import { Fragment, FunctionComponent, useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { ConfigContext } from '../../configuration/ConfigContext';
import Button from '../../forms/Button';
import { requiredValidator } from '../../forms/validators';
import OverlayAlertModal from '../../modal/OverlayAlertModal';
import OverlayConfirmModal from '../../modal/OverlayConfirmModal';
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext';
import { removeAssetFromAssetGroup } from '../assetGroupService';
import { displayBookingCancellationTerms, displayBookingType, displayLateCancellationFee, displayMaxBookings, displayPrice } from '../assetHelpers';
import { AssetTypeDefinition } from '../assetTypes/assetTypes';
import AssetFile from '../components/AssetFile';
import AssetGroupList from '../components/AssetGroupList';
import AssetImage from '../components/AssetImage';
import Availability from '../components/Availability';
import EntranceMethod from '../components/EntranceMethod';
import ConditionsModal from '../modals/ConditionsModal';
import ConnectionModal from '../modals/ConnectionModal';
import DescriptionModal from '../modals/DescriptionModal';
import EntranceMethodModal from '../modals/EntranceMethodModal';
import KeywordsModal from '../modals/KeywordsModal';
import PriceModal from '../modals/PriceModal';
import TitleModal from '../modals/TitleModal';
import { Asset } from '../models/Asset';

type Props = {
    defaults: AssetTypeDefinition;
    gotoNext: () => void;
    renderCancelButton: () => any;
    asset: Asset;
    updateValues: () => void;
}

const validationSchema = Yup.object().shape({
    name: requiredValidator,
    serviceInformation: Yup.object().shape({
        serviceInformationTitle: requiredValidator,
        serviceInformationText: requiredValidator,
    }),
    entranceMethods: Yup.array().min(1, 'Åtkomst måste väljas'),
    bookingType: Yup.number().min(1, 'Hur resursen ska bokas måste väljas'),
    bookingMaxDaysInAdvance: Yup.number().min(1, 'Tillgänglighet måste väljas'),
})

const AssetPreview: FunctionComponent<Props> = ({defaults, gotoNext, renderCancelButton, asset, updateValues}) => {
    const { apiBaseUrl } = useContext(ConfigContext);
    const { currentPropertyAssociation } = useContext(PropertyAssociationContext);
    const [ showConditionsModal, setShowConditionsModal ] = useState(false);
    const [ showDescriptionModal, setShowDescriptionModal ] = useState(false);
    const [ descriptionError, setdescriptionError] = useState('');
    const [ showEntranceMethodModal, setShowEntranceMethodModal ] = useState(false);
    const [ entranceError, setEntranceError] = useState('');
    const [ showErrorModal, setShowErrorModal ] = useState(false);
    const [ showKeywordsModal, setShowKeywordsModal ] = useState(false);
    const [ showPriceModal, setShowPriceModal ] = useState(false);
    const [ showTitleModal, setShowTitleModal ] = useState(false);
    const [ titleError, setTitleError] = useState('');
    const [ isSubmitted, setIsSubmitted ] = useState(false);
    const [ connectionToDelete, setConnectionToDelete ] = useState<undefined | string>();
    const [ errorMessage, setErrorMessage ] = useState<string | undefined>();
    const [ showConnectionModal, setShowConnectionModal ] = useState(false);

    const handleNext = () => {
        setIsSubmitted(true)
        setdescriptionError(!asset?.serviceInformation?.serviceInformationText ? 'Beskrivning måste anges' : '')
        setTitleError(!asset?.serviceInformation?.serviceInformationTitle ? 'Rubrik måste anges' : '')
        setEntranceError(!asset?.entranceMethod ? 'Åtkomst måste anges' : '')
        validationSchema
            .isValid(asset)
            .then(isValid => {
                if(!isValid) {
                    setShowErrorModal(true);
                    return;
                }

                gotoNext();
            })
    }

    const handleRemoveConnection = () => {
        if(!currentPropertyAssociation?.propertyAssociationId || !connectionToDelete || !asset.assetGroupId) {
            return;
        }

        removeAssetFromAssetGroup(
            apiBaseUrl,
            currentPropertyAssociation?.propertyAssociationId,
            asset.assetGroupId,
            { assetIds: [connectionToDelete] },
            () => {
                setConnectionToDelete(undefined);
                updateValues();
            },
            () => setErrorMessage('Kunde inte ta bort koppling')
        );
    }

    useEffect(() => {
        setShowConditionsModal(false);
        setShowDescriptionModal(false);
        setShowEntranceMethodModal(false);
        setShowKeywordsModal(false);
        setShowPriceModal(false);
        setShowTitleModal(false);
        setdescriptionError('');
        setTitleError('');
        setEntranceError('')
        setIsSubmitted(false);
    }, [])

    useEffect(() => {
      if (!isSubmitted) {
        return
      }
      setdescriptionError(!asset?.serviceInformation?.serviceInformationText ? 'Beskrivning måste anges' : '')
      setTitleError(!asset?.serviceInformation?.serviceInformationTitle ? 'Rubrik måste anges' : '')
      setEntranceError(!asset?.entranceMethod ? 'Åtkomst måste anges' : '')
    }, [asset])

    return (
        <Fragment>
            <div className="paper__main">
                <div className="paper__preamble">
                    <strong>Så gör du</strong>
                    <p>Att skapa en gemensam, bokningsbar resurs är enkelt! Klicka på pennan intill de olika fälten nedan och fyll i information om resursen samt ange pris, åtkomstinformation och bokningsvillkor. Här kan du även ladda upp bild samt pdf-dokument med ytterligare information.</p>
                </div>
                <div className="preview">
                    <AssetImage asset={asset} defaults={defaults} updateValues={updateValues} />
                    <button className="preview__block  preview__heading" onClick={() => { setShowTitleModal(true) }}>
                        {asset.serviceInformation?.serviceInformationTitle ? (
                            asset.serviceInformation.serviceInformationTitle
                        ):(
                            <i className="preview__default">{defaults.serviceInformationTitle}</i>
                        )}
                    </button>
                    {!titleError && !asset.serviceInformation?.serviceInformationTitle && <div className="preview__required">* Obligatoriskt fält</div>}
                    {titleError && <div className="px-4 m-0 field-error text-danger fw-normal">{titleError}</div>}

                    <button className="preview__block" onClick={() => { setShowDescriptionModal(true) }}>
                        {asset.serviceInformation?.serviceInformationText ? (
                            asset.serviceInformation.serviceInformationText
                        ):(
                            <i className="preview__default">{defaults.serviceInformationText}</i>
                        )}
                    </button>
                    {!descriptionError && !asset.serviceInformation?.serviceInformationText && <div className="preview__required">* Obligatoriskt fält</div>}
                    {descriptionError && <div className="px-4 m-0 field-error text-danger fw-normal">{descriptionError}</div>}

                    <button className="preview__block" onClick={() => { setShowKeywordsModal(true) }}>
                        {
                            asset.serviceInformation?.serviceInformationKeywords?.length > 0 ? (
                                <ul className="preview__list">
                                    { asset.serviceInformation?.serviceInformationKeywords?.map(x => <li key={x}>{x}</li>) }
                                </ul>
                            ):(
                                <i className="preview__default">Ange bekvämligheter</i>
                            )
                        }
                    </button>

                    <AssetFile asset={asset} updateValues={updateValues} />

                    <button className="preview__block  preview__block--price" onClick={() => { setShowPriceModal(true) }}>
                        <div className="preview__block-heading">Pris</div>
                        { displayPrice(asset) }<br />
                        { asset.price === 0 && displayBookingType(asset) }
                    </button>

                    <button className="preview__block  preview__block--key" onClick={() => { setShowEntranceMethodModal(true) }}>
                        <div className="preview__block-heading">Åtkomst </div>
                        <EntranceMethod asset={asset} />
                    </button>
                    {!entranceError && !asset.entranceMethod && <div className="preview__required preview__required--indent">* Obligatoriskt fält</div>}
                    {entranceError && <div className="px-4 m-0 field-error text-danger fw-normal">{entranceError}</div>}

                    <button className="preview__block  preview__block--calendar" onClick={() => setShowConditionsModal(true) }>
                        <div className="preview__block-heading">Bokningsvillkor</div>
                        {displayBookingCancellationTerms(asset)}<br />
                        {displayLateCancellationFee(asset)}
                        <div>{displayMaxBookings(asset)}</div>
                        {asset.requireVerifiedPhoneNumber && <div>Telefonnummer krävs vid bokning.</div>}
                    </button>
                </div>
                <Availability initialValues={asset} updateValues={updateValues} />
                <div className="alert alert-warning mb-0 rounded-0">
                    <div className="paper__content  paper__content--preview py-0">
                        { asset.assetGroupId ? (
                            <>
                                <strong>Kopplad till andra resurser</strong>
                                <p className="mb-2">Tillgängliga tider vid bokning påverkas även av bokningar för följande resurser:</p>
                                <AssetGroupList assetGroupId={asset.assetGroupId} asset={asset} onRemove={setConnectionToDelete} />
                                <Button text="Koppla till ytterligare resurs" onClick={() => setShowConnectionModal(true)} />
                            </>
                        ) : (
                            <>
                                <strong>Koppla till andra resurser</strong>
                                <p className="mb-3">Vill du koppla resursen till andra resurser så att deras tillgängliga tider påverkar varandra?</p>
                                <Button text="Koppla till resurs" onClick={() => setShowConnectionModal(true)} />
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className="paper__buttons mt-0">
                { renderCancelButton() }
                <div className="paper__primary-buttons">
                    <div></div>
                    <button className="btn btn-lg btn-primary btn-next" onClick={handleNext}>Nästa</button>
                </div>
            </div>

            <ConditionsModal initialValues={asset} updateValues={updateValues} visible={showConditionsModal} onClose={() => setShowConditionsModal(false) } />
            <DescriptionModal initialValues={asset} defaults={defaults} updateValues={updateValues} visible={showDescriptionModal} onClose={() => setShowDescriptionModal(false) } />
            <EntranceMethodModal initialValues={asset} updateValues={updateValues} visible={showEntranceMethodModal} onClose={() => setShowEntranceMethodModal(false) } />
            <KeywordsModal initialValues={asset} defaults={defaults} updateValues={updateValues} visible={showKeywordsModal} onClose={() => setShowKeywordsModal(false) } />
            <OverlayAlertModal heading="Det saknas information" text="Det verkar som att viss obligatorisk information om resursen saknas. När du fyllt i all information kommer du kunna gå vidare till nästa steg." visible={showErrorModal} onClick={() => setShowErrorModal(false)} />
            <PriceModal initialValues={asset} defaults={defaults} updateValues={updateValues} visible={showPriceModal} onClose={() => setShowPriceModal(false) } />
            <TitleModal initialValues={asset} defaults={defaults} updateValues={updateValues} visible={showTitleModal} onClose={() => setShowTitleModal(false) } />
            <OverlayConfirmModal heading="Är du säker?" text="Detta kommer att ta bort kopplingen mellan de båda resurserna och de kommer inte längre att påverka varandras tillgängliga tider." okButtonText="Ta bort koppling" visible={!!connectionToDelete} onSubmit={handleRemoveConnection} onCancel={() => setConnectionToDelete(undefined)} />
            <ConnectionModal assetId={asset.assetId} assetGroupId={asset.assetGroupId} updateValues={updateValues} visible={showConnectionModal} onClose={() => setShowConnectionModal(false)} />
            { errorMessage && <OverlayAlertModal heading="Ett fel har uppstått" text={errorMessage} visible={true} onClick={() => setErrorMessage('')} /> }
        </Fragment>
    )
}

export default AssetPreview