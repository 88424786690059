import { FunctionComponent } from 'react';
import { tryFormatDateTimeRange } from '../../../common/utils/dateUtils';
import { Booking } from '../models/Booking';
import { BookingStatus } from '../models/BookingStatus';
import { InvoiceStatus } from '../models/InvoiceStatus';

type Props = {
    booking: Booking;
    isArchived: boolean;
    showDetails: (bookingId: string) => void;
};

const BookingItem: FunctionComponent<Props> = ({ booking, isArchived, showDetails }) => (
    <tr className="bookings__row" onClick={() => showDetails(booking.bookingId)}>
        {isArchived && <td>{booking.bookingReference}</td>}
        <td>{booking.assetName}</td>
        <td>{tryFormatDateTimeRange(booking.startTime, booking.endTime)}</td>
        <td className="d-none d-md-table-cell">{booking.userName || '-'}</td>
        <td className="d-none d-md-table-cell">
            {isArchived && (
                <div className="d-flex">
                    <div className="d-flex justify-content-end">{renderStatus(booking)}</div>
                    <div className="d-flex justify-content-end">{renderInvoiceStatus(booking)}</div>
                </div>
            )}
        </td>
    </tr>
)

const renderStatus = (booking: Booking) => {
    if(booking.status === BookingStatus.Cancelled) {
         return <div className="bookings__status badge rounded-pill fw-light bg-danger">Avbokad</div>
    }

    return <div className="bookings__status badge rounded-pill fw-light bg-success">Genomförd</div>
}

const renderInvoiceStatus = (booking: Booking) => {
    switch(booking.generalInvoiceStatus) {
        case InvoiceStatus.Cancelled:
            return <div className="bookings__status badge rounded-pill fw-light bg-danger">Annulerad</div>
        case InvoiceStatus.HandledExternally:
            return <div className="bookings__status badge rounded-pill fw-light bg-success">Externt</div>
        case InvoiceStatus.Paid:
            return <div className="bookings__status badge rounded-pill fw-light bg-success">Betald</div>
        case InvoiceStatus.Pending:
            return <div className="bookings__status badge rounded-pill fw-light bg-warning">Väntande</div>
        case InvoiceStatus.Created:
            return <div className="bookings__status badge rounded-pill fw-light bg-info">Skapad</div>        
    }

    if(booking.status === BookingStatus.Cancelled && booking.hasFreeCancellation) {
        return <div className="bookings__status badge rounded-pill fw-light bg-danger">Fri avbokning</div>
    }

    return <div className="bookings__status badge rounded-pill fw-light bg-light">&nbsp;</div>
}

export default BookingItem;