import { FunctionComponent, useContext } from 'react'
import { tryFormatDateTime } from '../../../common/utils/dateUtils'
import { ConfigContext } from '../../configuration/ConfigContext'
import { invalidateInvitation } from '../../invitation/invitationService'
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext'
import ListItem from '../../shared/ListItem'
import { ToastContext } from '../../toaster/ToastContext'
import { ToastMessageType } from '../../toaster/models/ToastMessageType'
import { GuestInvitation } from '../models/GuestInvitation'

type Props = {
    invitations: GuestInvitation[],
    reload: () => void,
}

const ActiveInvitationList: FunctionComponent<Props> = ({invitations, reload}) => {
    const { apiBaseUrl } = useContext(ConfigContext);
    const { currentPropertyAssociationId } = useContext(PropertyAssociationContext);
    const { addToast } = useContext(ToastContext);

    const revokeInvitation = (id?: string) => {
        if(!id) {
            return;
        }

        invalidateInvitation(apiBaseUrl, currentPropertyAssociationId, id, reload, () => addToast('Kunde inte återta inbjudan', '', ToastMessageType.Error));
    }

    const menuOptions = [
        {
            title: 'Återta',
            action: revokeInvitation,
        },
    ]

    return (
        <div className="pb-5">
            {invitations?.map(({id, email, accessLevel, validUntil}) => <ListItem key={id} id={id} fields={[`${email} (${accessLevel})`, `Giltig till ${tryFormatDateTime(validUntil)}`]} menuOptions={menuOptions} />)}
        </div>
    )
}

export default ActiveInvitationList