import { FunctionComponent, useContext, useEffect, useState } from 'react'
import { PropertyAssociationContext } from '../propertyAssociation/PropertyAssociationContext';
import styles from './SubscriptionBanner.module.scss'
import cx from 'classnames'

const Banner: FunctionComponent = () => {
    const { currentPropertyAssociation } = useContext(PropertyAssociationContext);
    const [showBanner, setShowBanner] = useState(false);
    const [bannerText, setBannerText] = useState<string | undefined>();

    useEffect(() => {
        if (!currentPropertyAssociation) {
            setShowBanner(false);
            return;
        }

        const { endDate, isTrialSubscription } = currentPropertyAssociation;

        if(!isTrialSubscription && endDate) {
            setBannerText(`Ni har valt att avsluta er prenumeration av Mitt Boende, tillgången till uthyrning och bokning av gemensamma resurser och digitala anslagstavlan för er och era medlemmar kommer att upphöra ${new Date(endDate).toLocaleDateString('sv-SE')}.`);
            setShowBanner(true);
            return;
        }

        if(isTrialSubscription) {
            setBannerText(`Ni har just nu en provperiod av Mitt Boende, som löper ut ${new Date(endDate).toLocaleDateString('sv-SE')}. För att starta en prenumeration gå in i <a href="https://mitt.riksbyggen.se/styrelsearbete/foreningen/prenumerationer/">prenumerationer på Mitt Riksbyggen</a>.`);
            setShowBanner(true);
            return;
        }
    }, [currentPropertyAssociation])

    if (!bannerText) {
        return null;
    }

    return (
        <div className={cx([styles.container], {[styles.show]: showBanner})}>
            <div className={styles.content} dangerouslySetInnerHTML={{__html: bannerText}}>
            </div>
            <div>
                <button className={styles['close-button']} onClick={() => setShowBanner(false)}>✖ Dölj</button>
            </div>
        </div>
    )
}

export default Banner