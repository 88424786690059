import cx from 'classnames';
import { Fragment, FunctionComponent, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { ConfigContext } from '../../configuration/ConfigContext';
import Button from '../../forms/Button';
import OverlayAlertModal from '../../modal/OverlayAlertModal';
import OverlayConfirmModal from '../../modal/OverlayConfirmModal';
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext';
import { deleteImage, uploadImage } from '../assetService';
import { AssetTypeDefinition } from '../assetTypes/assetTypes';
import ImageSortModal from '../modals/ImageSortModal';
import { Asset } from '../models/Asset';
import SmallImage from './SmallImage';

type Props = {
    asset: Asset;
    defaults: AssetTypeDefinition;
    updateValues: (values) => void;
};

const AssetImage: FunctionComponent<Props> = ({ asset, defaults, updateValues }) => {
    const node = useRef<HTMLDivElement>(null);
    const { apiBaseUrl } = useContext(ConfigContext);
    const { currentPropertyAssociationId } = useContext(PropertyAssociationContext);
    const [ error, setError ] = useState<string | undefined>()
    const [ isExpanded, setIsExpanded ] = useState(false);
    const [ isWorking, setIsWorking ] = useState(false);
    const [ showReorderModal, setShowReorderModal ] = useState(false);
    const [ toDelete, setToDelete ] = useState<string | undefined>(undefined);
    const [ preview, setPreview ] = useState<string>();

    const handleDrop = useCallback((acceptedFiles: File[]) => {
        if(acceptedFiles.length === 0) {
            return;
        }

        const file = acceptedFiles[0];
        if(file.size > 5000000) {
            setError('Filen överskrider tillåten maxstorlek')
            return;
        }

        setIsWorking(true);

        if(asset.images?.length === 0)
        {
            setPreview(URL.createObjectURL(file))
        }
        
        uploadImage(apiBaseUrl, currentPropertyAssociationId, asset.assetId, file, 
            (images) => {
                updateValues(Object.assign(asset, { images }));
                setPreview(undefined);
                setIsWorking(false);
            },
            () => {
                setPreview(undefined);
                setIsWorking(false);
                setError('Det gick tyvärr inte att ladda upp bilden.');
            }
        );
    }, []);

    const handleDelete = (originalPath: string) => {
        setIsExpanded(false);
        setToDelete(originalPath);
    }

    const performDelete = () => {
        if(!toDelete) {
            return;
        }

        setIsWorking(true);
    
        deleteImage(apiBaseUrl, currentPropertyAssociationId, asset.assetId, toDelete,
            () => {
                updateValues(Object.assign(asset, { images: asset.images.filter(image => image.originalPath !== toDelete) }));
                setPreview(undefined);
                setIsWorking(false);
            },
            () => {
                setIsWorking(false);
                setError('Det gick tyvärr inte att ta bort bilden.');
            }
        );

        setToDelete(undefined);
    }

    const handleClickOutSide = (e: any) => {
		if (node.current && !node.current.contains(e.target)) {
			setIsExpanded(false)
		}
	};

    const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({ 
        accept: 'image/jpeg, image/png',
        multiple: false,
        onDrop: handleDrop,
    });

    useEffect(() => {
		document.addEventListener('mousedown', handleClickOutSide);
		return () => {
			document.removeEventListener('mousedown', handleClickOutSide);
		};
	}, []);

    useEffect(() => {
        if(preview) {
            URL.revokeObjectURL(preview);
        }
    }, [preview])

    const errorModal = error && <OverlayAlertModal heading="Ooops!" text={error} visible={true} onClick={() => setError(undefined)} />

    if (preview) {
        // NOTE: Show preview of first image being uploaded
        return (
            <div className="preview__image-block">
                <div className="preview__image-wrapper">
                    <img src={preview} alt="" className="preview__image" />
                </div>
                { errorModal }
            </div>
        );
    }

    if (asset.images && asset.images.length > 0) {
        return (
            <Fragment>
                <div className="d-flex flex-wrap p-relative">
                    {asset.images.map((image, index) => {
                        if (index === 0) {
                            return (
                                <div className="preview__image-block  preview__image-block--with-hover w-100" ref={node} key={image.originalPath}>
                                    <button className="preview__image-wrapper" onClick={() => setIsExpanded(!isExpanded)}>
                                        <img src={image.path} alt="" className="preview__image" />
                                    </button>
                                    <div className={cx(['dropdown-menu preview__dropdown-menu', { 'show': isExpanded }])}>
                                        <button className="dropdown-item" onClick={() => handleDelete(image.originalPath)}>Ta bort</button>
                                    </div>
                                </div>
                            )
                        }

                        return <SmallImage imagePath={image.path} originalPath={image.originalPath} key={image.originalPath} onDelete={handleDelete} />
                    })}

                    <div className={cx(['w-100 mb-3 mx-5', {'preview__dropzone--accept': isDragAccept}])} {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Button text="Ladda upp fler bilder" isPrimary={false} additionalClassName="w-100 btn-lg btn-secondary btn-icon-upload" />
                    </div>
                    {asset.images.length > 1 && <Button text="Ändra ordning på bilderna" isPrimary={false} additionalClassName="w-100 mb-3 mx-5 btn-link" onClick={() => setShowReorderModal(true)} />}
                    {isWorking && <div className="preview__blocker"></div>}
                </div>
                { toDelete && <OverlayConfirmModal heading="Ta bort bild?" text="Vill du ta bort bilden?" okButtonText="Ta bort" cancelButtonText="Avbryt" onSubmit={performDelete} onCancel={() => setToDelete(undefined)} visible={true} /> }
                { showReorderModal && <ImageSortModal asset={asset} onClose={() => setShowReorderModal(false)} onSubmitted={() => {updateValues(asset); setShowReorderModal(false);}} /> }
                { errorModal }
            </Fragment>
        );
    }

    return (
        <Fragment>
            <div className="preview__image-block  preview__image-block--empty">
                <div className="preview__image-wrapper" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <img src={defaults.backdrop} alt="" className="preview__image" />
                    <div className={cx(['preview__no-image', {'preview__no-image--accept': isDragAccept}, {'preview__no-image--reject': isDragReject} ])}>
                        <div className="preview__image-heading">Bild på resurs (valfritt)</div>
                        <div className="preview__image-text">
                            <p>Giltiga format är JPG och PNG (maximal filstorlek 5 MB)</p>
                            <button className="btn btn-lg btn-secondary btn-icon-upload">Ladda upp bild</button>
                        </div>
                    </div>
                </div>
            </div>
            { errorModal }
        </Fragment>
    );
};

export default AssetImage;
