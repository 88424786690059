export function intersect(array1: Array<any>, array2: Array<any>): boolean {
    if(!array1 || !array2) {
        return false;
    }

    return array1.filter(x => array2.includes(x)).length > 0;
}

export function keyBy(array: Array<any>, func: (item) => string): any {
    return array.reduce(
        (collection, item) => {
            const key = func(item);
            collection[key] = item;
            return collection;
        },
        {}
    );
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function toggleItemInArray(array: any[], item: any): any[] {
    if (array.indexOf(item) < 0) {
        return [...array, item];
    }
    return array.filter(x => x !== item);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function updateArrayItem<T>(array: T[], item: T, compare: (a: T, b: T) => boolean): T[] {
    if(!array) {
        return array;
    }

    return array.map(a => {
        if(compare(a, item)) {
            return item;
        }
        return a;
    })
}