import { TimeSelectionType } from '../assetConstants';
import { DEFAULTS_APARTMENT, ASSETTYPE_APARTMENT } from './apartment';
import { DEFAULTS_LAUNDRYROOM, ASSETTYPE_LAUNDRYROOM } from './laundryRoom';
import { DEFAULTS_MEETINGROOM, ASSETTYPE_MEETINGROOM } from './meetingRoom';
import { DEFAULTS_OTHER, ASSETTYPE_OTHER } from './other';
import { DEFAULTS_PARKING, ASSETTYPE_PARKING } from './parking';
import { DEFAULTS_SAUNA, ASSETTYPE_SAUNA } from './sauna';

export type AssetTypeDefinition = {
    alwaysUseDays: boolean;                 // Använd dagar för bokningsbara tider
    assetTypeTag: string | null;            // Används för att hitta rätt Defaults för en asset
    backdrop: string;                       // Dimmad variant som visas i bildväljaren
    canChangeToDaily: boolean;              // Tillåter att användaren kan byta hur resursen bokas till  dygn
    canChangeToHourly: boolean;             // Tillåter att användaren kan byta hur resursen bokas till per timme
    canChangeToInterval: boolean;           // Tillåter att användaren kan byta hur resursen bokas till  intervall
    endTimeNextDay: boolean;                // Används av bokningsbara tider
    endTimeRestOfDay: boolean;              // Används av bokningsbara tider
    image: string;                          // Fallback-bild som visas i preview om ingen bild valts
    isSingleton: boolean;                   // Flagga om det är 1:1 mellan asset och objekt eller om den kan ha flera objekt
    name: string;                           // Namn används bl a som bas när nya resurser skapas
    nameDefinitiveForm: string;             // Namn i bestämd form ("lägenheten")
    namePlural: string;                     // Namn i pluralform ("lägenheter")
    nameSingular: string;                   // Namn i singular ("lägenhet")
    options: string[];                      // Lista över alternativ som visas under "Bekvämligheter"
    prefixName: boolean;                    // Prefixa alltid namnet som en användare kan ange för resursen med namnet från resurstypen
    serviceInformationText: string | null;  // Föreslagen text för beskrivning
    serviceInformationTitle: string | null; // Föreslagen text för rubrik
    thumbnail: string;                      // Miniatyrbild som visas vid val av resurstyp
    timeSelectionType: TimeSelectionType;   // Tidsvalstyp
}

// NOTE: Used as fallback if not specified asset type is found
export const DEFAULTS_EMPTY: AssetTypeDefinition = {
    alwaysUseDays: false,
    assetTypeTag: null,
    backdrop: '/images/defaults/backdrop/RB_gemensamma-resurser_bild-saknas.png',
    canChangeToDaily: false,
    canChangeToHourly: false,
    canChangeToInterval: false,
    endTimeNextDay: false,
    endTimeRestOfDay: false,
    image: '/images/defaults/preview/RB_gemensamma-resurser_bild-saknas.png',
    isSingleton: true,
    name: 'Resurs',
    nameDefinitiveForm: 'resursen',
    namePlural: 'resurser',
    nameSingular: 'resurs',
    options: [],
    prefixName: false,
    serviceInformationText: null,
    serviceInformationTitle: null,
    thumbnail: '/images/defaults/thumbnails/RB_gemensamma-resurser_bild-saknas.png',
    timeSelectionType: TimeSelectionType.Startstop,
}

export const DefaultsLookup = {
    [ASSETTYPE_SAUNA]: DEFAULTS_SAUNA,
    [ASSETTYPE_APARTMENT]: DEFAULTS_APARTMENT,
    [ASSETTYPE_PARKING]: DEFAULTS_PARKING,
    [ASSETTYPE_MEETINGROOM]: DEFAULTS_MEETINGROOM,
    [ASSETTYPE_LAUNDRYROOM]: DEFAULTS_LAUNDRYROOM,
    [ASSETTYPE_OTHER]: DEFAULTS_OTHER,
}
