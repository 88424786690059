import { FunctionComponent, useEffect, useState } from 'react'
import Button from '../../forms/Button'
import ApartmentModal from '../modals/ApartmentModal'
import CreateApartmentModal from '../modals/CreateApartmentModal'
import { ApartmentResponse, LocationResponse } from '../models/GetApartmentsResponse'

type Props = {
    location: LocationResponse,
    refreshApartments: () => void,
    setLocation: (location: LocationResponse | undefined) => void,
}

const ApartmentList: FunctionComponent<Props> = ({location, refreshApartments, setLocation}) => {
    const [apartment, setApartment] = useState<ApartmentResponse | undefined>(undefined);
    const [showCreateModal, setShowCreateModal] = useState(false);

    useEffect(() => {
        if(location && apartment) {
            const refreshedApartment = location.apartments.find(a => a.apartmentId === apartment.apartmentId);
            setApartment(refreshedApartment);
        }
    }, [location]);

    return (
        <div className="mb-5">
            <h2>Lägenheter på {location.name}</h2>
            {location.apartments ? (
                <table className="custom-table" style={{tableLayout: 'fixed'}}>
                    <thead className="custom-table__head">
                        <tr>
                            <th className="custom-table__header">Lägenhetsnr</th>
                            <th className="custom-table__header text-center">Yta</th>
                            <th className="custom-table__header text-center">Antal rum</th>
                            <th className="custom-table__header text-end">Antal boende</th>
                        </tr>
                    </thead>
                    <tbody>
                        {location.apartments.map(apartment => (
                            <tr key={apartment.apartmentId} className="custom-table__row" role="button" onClick={() => setApartment(apartment)}>
                                <td>{apartment.name}</td>
                                <td className="text-center">{formatNumber(apartment.area)}</td>
                                <td className="text-center">{formatNumber(apartment.numberOfRooms)}</td>
                                <td className="text-end">{formatNumber(apartment.count)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>Inga lägenheter på vald adress.</p>
            )}
            <div className="paper__buttons">
                <Button text="Tillbaka" isPrimary={false} additionalClassName="btn-secondary btn-lg btn-prev" onClick={() => setLocation(undefined)} />
                <Button text="Lägg till lägenhet" isPrimary={true} additionalClassName="btn-primary btn-lg btn-plus" onClick={() => setShowCreateModal(true)} />
            </div>
            {apartment && <ApartmentModal apartment={apartment} onClose={() => setApartment(undefined)} refreshApartments={refreshApartments} />}
            {showCreateModal && <CreateApartmentModal location={location} onClose={() => setShowCreateModal(false)} refreshApartments={refreshApartments} />}
        </div>
    )
}

function formatNumber(number: number) {
    return number === 0 ? '-' : number;
}

export default ApartmentList