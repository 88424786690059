import { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react'
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart'
import { generateColourFromName } from '../../../common/utils/colourUtils'
import { ConfigContext } from '../../configuration/ConfigContext'
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext'
import { GetAssetRevenueResponse } from '../models/GetAssetRevenueResponse'
import { getAssetRevenue } from '../statisticsService'

type Props = {
    activeYear: string,
    activeMonth: string | undefined,
    assetFilter: string[],
    setActiveMonth?: (month: string) => void,
}

type BarProps = {
    dataKey: string,
    name: string,
}

const monthNames = ['jan', 'feb', 'mars', 'april', 'maj', 'juni', 'juli', 'aug', 'sep', 'okt', 'nov', 'dec'];

const AssetRevenueChart: FunctionComponent<Props> = ({ activeYear, activeMonth, assetFilter, setActiveMonth }) => {
    const { apiBaseUrl } = useContext(ConfigContext);
    const { currentPropertyAssociationId } = useContext(PropertyAssociationContext);
    const [data, setData] = useState<any[]>([]);
    const [bars, setBars] = useState<BarProps[]>([]);

    const handleClick = useCallback(({activeLabel}: CategoricalChartState) => {
        if(activeMonth || !activeLabel) {
            return;
        }

        const month = monthNames.indexOf(activeLabel);
        if(month === -1) {
            return;
        }

        setActiveMonth && setActiveMonth(`${month + 1}`);
    }, [activeMonth, setActiveMonth])

    const onSuccess = useCallback((data: GetAssetRevenueResponse) => {
        const { newBars, newData } = buildGraphData(data, activeMonth);
        setBars(newBars);
        setData(newData);
    }, [activeMonth])

    const onError = useCallback(() => {
        console.log('error')
    }, [])

    useEffect(() => {
        getAssetRevenue(
            apiBaseUrl,
            currentPropertyAssociationId,
            parseInt(activeYear),
            activeMonth ? parseInt(activeMonth) : undefined,
            assetFilter,
            onSuccess,
            onError
        );
    }, [activeYear, activeMonth, assetFilter]);

    if(!data || !bars) {
        return null;
    }

    return (
        <ResponsiveContainer width="100%" height="100%" minHeight={400}>
            <BarChart
                data={data}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
                onClick={handleClick}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                {bars.map(x => <Bar key={x.dataKey} dataKey={x.dataKey} name={x.name} unit="kr" stackId="!" fill={generateColourFromName(x.dataKey)} /> )}
            </BarChart>
        </ResponsiveContainer>
    )
}

function buildGraphData(data: GetAssetRevenueResponse, activeMonth: string | undefined): { newBars: BarProps[]; newData: any[] } {
    const newData: any[] = []
    const newBars: BarProps[] = []

    data.assetRevenueGroups.map(x => {
        const temp = {
            name: activeMonth ? x.group : monthNames[x.group - 1]
        }
        x.assetRevenues.map(y => {
            if (!newBars.find(z => z.dataKey === y.assetId)) {
                newBars.push({
                    dataKey: y.assetId,
                    name: y.assetName
                })
            }

            temp[y.assetId] = (temp[y.assetId] ?? 0) + y.revenue
        })
        newData.push(temp)
    })
    return { newBars, newData }
}

export default AssetRevenueChart