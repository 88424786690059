// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function replaceNullMembersWithEmptyStrings(data: any): any {
    if(!data) {
        return data;
    }
    
    return JSON.parse(JSON.stringify(data, (k, v) => (v === null ? '' : v)));
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function objectAsArray(object: any): any[] {
    if(!object) {
        return [];
    }

    const keys = Object.keys(object);
    return keys.map(x => object[x]);
}