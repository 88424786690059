import { FunctionComponent, ReactNode } from 'react';

type Props = {
    children: ReactNode | ReactNode[] | string;
}

const PreviewHeading: FunctionComponent<Props> = ({children}) => (
    <div className="preview__block  preview__block--read-only  preview__heading">{children}</div>
)

export default PreviewHeading