import { Fragment, FunctionComponent, useContext } from 'react';
import { useHistory } from 'react-router';
import { PropertyAssociationContext } from '../PropertyAssociationContext';
import { PropertyAssociationInfo } from '../models/PropertyAssociationInfo';
import PropertyAssociationItem from './PropertyAssociationItem';

const PickPropertyAssociation: FunctionComponent = () => {
    const history = useHistory();
    const { allPropertyAssociations, setCurrentPropertyAssociation } = useContext(PropertyAssociationContext);

    const setCurrentPropertyAssociationAndRedirect = (propertyAssociation: PropertyAssociationInfo) => {
        setCurrentPropertyAssociation(propertyAssociation);
        history.push('/');
    }
    
    return (
        <Fragment>
            <div className="text-center">
                <h1 className="large-heading">Välj engagemang</h1>
                <p className="preamble">
                    Det här är alternativen du kan välja mellan.
                </p>
            </div>
            <div className="boxlist">
                { allPropertyAssociations?.map(x => <PropertyAssociationItem key={x.propertyAssociationId} value={x} setCurrentPropertyAssociation={setCurrentPropertyAssociationAndRedirect} />)}
            </div>
        </Fragment>
    )
}

export default PickPropertyAssociation