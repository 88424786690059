import React, { FunctionComponent } from 'react'

type Props = {
    width?: number,
}

const FormField: FunctionComponent<Props> = ({ children, width = 100 }) => (
    <div className={`column width-${width} mb-3`}>
        {children}
    </div>
)

export default FormField