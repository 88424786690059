const STORAGE_NAME = 'seenVMAs';

export const getSeenAlerts = (): string[] => {
    return JSON.parse(sessionStorage.getItem(STORAGE_NAME) || '[]');
}

export const setAlertAsSeen = (alertMessageId: string): void => {
    const seenAlerts = getSeenAlerts();
    seenAlerts.push(alertMessageId);
    sessionStorage.setItem(STORAGE_NAME, JSON.stringify(seenAlerts));
}