import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

const NotFound : FunctionComponent = () => (
    <div className="app__inner app__inner--center">
        <article className="text-center">
            <img src="/images/not-found.png" alt="" width="300" height="230" className="mb-4" />
            <div className="large-heading">Ooops!</div>
            <p className="preamble">
                Vi kan inte hitta sidan du söker. Den kan ha tagits bort, eller så kan du kan ha skrivit fel i adressfältet.
            </p>
            <Link to="/" className="btn btn-lg btn-primary">Gå till startsidan</Link>
        </article>
    </div>
)

export default NotFound
