import { buildQueryString, getFromApi } from '../../common/utils/apiHelper';
import { Booking } from './models/Booking';
import { BookingDetails } from './models/BookingDetails';

export const getActiveBookings = (apiBaseUrl: string, propertyAssociationId: string, assetId: string | undefined, areaId: string | undefined, onSuccess: (data: Booking[]) => void, onFail: () => void): void => {
    getFromApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/bookings/active${buildQueryString({ areaId, assetId })}`, onSuccess, onFail);
};

export const getArchivedBookings = (apiBaseUrl: string, propertyAssociationId: string, assetId: string | undefined, areaId: string | undefined, onSuccess: (data: Booking[]) => void, onFail: () => void): void => {
    getFromApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/bookings/inactive${buildQueryString({ areaId, assetId })}`, onSuccess, onFail);
};

export const getBooking = (apiBaseUrl: string, propertyAssociationId: string, bookingId: string, onSuccess: (data: BookingDetails) => void, onFail: () => void): void => {
    getFromApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/bookings/${bookingId}`, onSuccess, onFail);
};

export const cancelBooking = (apiBaseUrl: string, propertyAssociationId: string, bookingsId: string, applyCancellationFee: boolean, onSucces: () => void, onFail: () => void): void => {
    getFromApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/bookings/${bookingsId}/cancel?applyCancellationFee=${applyCancellationFee}`, onSucces, onFail);
};
   