import { FunctionComponent, useState, useEffect } from 'react';
import styles from './ImagePreview.module.scss';

type Props = {
    image: File | undefined;
    removeImage: () => void;
};

const ImagePreview: FunctionComponent<Props> = ({ image, removeImage }) => {
    const [previewUrl, setPreviewUrl] = useState<string | undefined>();

    useEffect(() => {
        if (image) {
            const reader = new FileReader();
            reader.onload = () => {
                setPreviewUrl(reader.result as string);
            };
            reader.readAsDataURL(image);
        }
    }, [image]);

    if(!previewUrl) {
        return null;
    }

    return (
        <div className={styles.container}>
            <button type="button" className={styles.remove} onClick={removeImage} title='Ta bort bild'>✖</button>
            {image?.type.startsWith('image') ? (
                <img src={previewUrl} alt="Preview" className={styles.image} />
            ): (
                <div>{image?.name}</div>
            )}
        </div>
    )
};

export default ImagePreview;