import { Asset } from './models/Asset';
import { Option } from '../forms/models/Option';
import { AssetStatus, BookingCancellationTerms, TimeSelectionType } from './assetConstants';

export const isDraft = (assetStatus: AssetStatus): boolean => {
    
    switch (assetStatus) {
        case AssetStatus.Created:
            return true;
        default:
            return false;
    }
}

export const isUnpublished = (assetStatus: AssetStatus): boolean => {
    return assetStatus === AssetStatus.UnPublished;
}

export const isPublished = (assetStatus: AssetStatus): boolean => {
    return assetStatus === AssetStatus.Published;
}

export const isBookingTypePerDay = (asset: Asset): boolean => {
    return asset.timeSelectionType === TimeSelectionType.Day;
}

export const isBookingTypePerHour = (asset: Asset): boolean => {
    return (
        (asset.timeSelectionType === TimeSelectionType.Slot || asset.timeSelectionType === TimeSelectionType.Startstop) &&
        asset.requiredMinutes === 60
    );
}

export const isBookingTypePerInterval = (asset: Asset): boolean => {
    return (
        (asset.timeSelectionType === TimeSelectionType.Slot || asset.timeSelectionType === TimeSelectionType.Startstop) &&
        asset.requiredMinutes !== 60
    );
}

export const displayBookingType = (asset: Asset): string => {
    if (isBookingTypePerDay(asset)) {
        return 'Resursen bokas per dygn.';
    }

    if (isBookingTypePerHour(asset)) {
        return 'Resursen bokas per timme.';
    }

    if (isBookingTypePerInterval(asset)) {
        return 'Resursen bokas per tidsintervall.';
    }

    return '';
}

export const displayPrice = (asset: Asset): string => {
    if (asset.price === 0) {
        return 'Resursen är gratis.';
    }
    if (!asset.price) { return 'Inget pris angivet.' }

    if (isBookingTypePerHour(asset)) {
        return `${asset.price}kr/tim (betalning sker efter avslutad hyrning)`;
    }

    if (isBookingTypePerInterval(asset)) {
        return `${asset.price}kr/tidsintervall (betalning sker efter avslutad hyrning)`;
    }

    return `${asset.price}kr/dygn (betalning sker efter avslutad hyrning)`;
}

export const getIntervalOptions = (start: number, end: number, step: number): Option[] => {
    const options: Option[] = [];

    for (let i = start; i <= end; i += step) {
        options.push({ text: getTimeString(i), value: i.toString() })
    }

    return options;
}

export const getTimeString = (minutes: number): string => {
    if (minutes === 1) {
        return '1 minut';
    }
    if (minutes < 60) {
        return `${minutes} minuter`;
    }
    if (minutes === 60) {
        return '1 timme';
    }
    if (minutes < 1440) {
        return `${minutes / 60} timmar`;
    }
    return `${(minutes / 1440)} dygn`;
}

export const displayMaxBookings = (asset: Asset): string => {
    if (asset.bookingMaxActive === 1) {
        return 'Max en aktiv bokning per boende.';
    }

    if (asset.bookingMaxActive > 0) {
        return `Max ${asset.bookingMaxActive} aktiva bokningar per boende.`;
    }

    if (asset.bookingMaxActiveBlocks === 1) {
        if(isBookingTypePerHour(asset)) {
            return 'Max en aktiv bokad timme per boende.';
        }

        return 'Max ett aktivt bokat dygn per boende.';
    }

    if (asset.bookingMaxActiveBlocks > 0) {
        if (isBookingTypePerDay(asset)) {
            return `Max ${asset.bookingMaxActiveBlocks} aktiva bokade dygn per boende.`;
        }
        
        return `Max ${asset.bookingMaxActiveBlocks} aktiva bokade timmar per boende.`;
    }

    if (asset.bookingMaxOccasionsPerInterval === 1) {
        return `Max en bokning per ${getIntervalName(asset.maxPerMonthsInterval)} och boende.`
    }

    if (asset.bookingMaxOccasionsPerInterval > 0) {
        return `Max ${asset.bookingMaxOccasionsPerInterval} bokningar per ${getIntervalName(asset.maxPerMonthsInterval)} och boende.`
    }

    if (asset.bookingMaxBlocksPerInterval > 0) {
        if (isBookingTypePerDay(asset)) {
            return `Max ${asset.bookingMaxBlocksPerInterval} dygn per ${getIntervalName(asset.maxPerMonthsInterval)} och boende.`
        }
        
        return `Max ${asset.bookingMaxBlocksPerInterval} timmar per ${getIntervalName(asset.maxPerMonthsInterval)} och boende.`
    }
    
    return 'Obegränsat antal bokningar per boende.';
}

export const displayBookingCancellationTerms = (asset: Asset): string => {
    if (asset.bookingCancellationTerms === BookingCancellationTerms.Free) {
        return 'Fri avbokning.';
    }

    if (asset.bookingCancellationTerms === BookingCancellationTerms.Free1w) {
        return 'Fri avbokning fram till 1 vecka före bokad tid.';
    }

    return `Fri avbokning fram till ${asset.bookingCancellationTerms * 24} timmar före bokad tid.`;
}

export const displayLateCancellationFee = (asset: Asset): string => {
    if (asset.lateCancellationFeePercentage === 100) {
        return 'Vid sen avbokning debiteras hela priset. ';
    }

    if (asset.lateCancellationFeePercentage === 50) {
        return 'Vid sen avbokning debiteras halva priset. ';
    }

    return '';
}

const getIntervalName = (months: number) => {
    if (months === 1) {
        return 'månad';
    }
    if (months === 3) {
        return 'kvartal';
    }
    if (months === 12) {
        return 'år';
    }

    return `${months} månader`;
}