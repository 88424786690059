import React, { FunctionComponent, useContext } from 'react';
import RedirectLoader from './RedirectLoader';
import { AuthContext } from './AuthContext';

const AuthSignOutCallback: FunctionComponent = () => {
	const { signoutRedirectCallback } = useContext(AuthContext);

	signoutRedirectCallback();

	return <RedirectLoader />;
};

export default AuthSignOutCallback;