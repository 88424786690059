import { FunctionComponent } from 'react';
import { useHistory } from 'react-router';
import { BookingTypes } from '../BookingTypes';

type Props = {
    setBookingType: (bookingType: BookingTypes) => void;
}

const SelectBookingType: FunctionComponent<Props> = ({setBookingType}) => {
    const { push } = useHistory();
    
    return (
        <div className="app__inner app__inner--center">
            <div className="text-center">
                <h1 className="large-heading">Vilken typ av bokning vill du göra?</h1>
                <p className="preamble">I nästa steg väljer du tid och datum för bokning.</p>
            </div>
            <div>
                <div className="boxlist">
                    <button className="boxlist__item boxlist__item--no-image" onClick={() => setBookingType(BookingTypes.Internal)}>Intern bokning</button>
                    <button className="boxlist__item boxlist__item--no-image" onClick={() => setBookingType(BookingTypes.Intermediate)}>Förmedlingsbokning</button>
                </div>
                <div className="mt-5 text-center">
                    <button className="btn btn-lg btn-secondary btn-next" onClick={() => push('/bookings/')}>Avbryt</button>
                </div>
            </div>
        </div>
    )
}

export default SelectBookingType