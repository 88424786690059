import { FunctionComponent, useContext, useEffect } from 'react';
import Calendar from 'react-calendar';
import { isSameDate, tryFormatDate } from '../../../common/utils/dateUtils';
import { ExtendedDate, mapDate } from '../models/ExtendedDate';
import { ConfigContext } from '../../configuration/ConfigContext';
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext';
import { getAvailableStartTimes } from '../internalBookingService';

type Props = {
    assetId: string;
    onMonthChange?: (date: Date) => void;
    selectedEndTime: ExtendedDate | null;
    selectedStartTime: ExtendedDate | null;
    setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    setHasRestrictions?: (hasRestrictions: boolean) => void;
    setStartTimes: (startTimes: ExtendedDate[]) => void;
    startTimes: ExtendedDate[];
    userId?: string;
}

const DatePicker: FunctionComponent<Props> = ({assetId, onMonthChange, selectedStartTime, selectedEndTime, setFieldValue, setHasRestrictions, setStartTimes, startTimes, userId }) => {
    const { apiBaseUrl } = useContext(ConfigContext);
    const { currentPropertyAssociationId } = useContext(PropertyAssociationContext);

    useEffect(() => {
        getStartTimesForMonth(new Date(), apiBaseUrl, currentPropertyAssociationId, assetId, setStartTimes, userId, setHasRestrictions);
    }, [])
    
    const handleChange = (date: Date) => {
        setStartTime(date, startTimes, selectedStartTime, setFieldValue);
    }

    const handleMonthChange = ({activeStartDate}) => {
        getStartTimesForMonth(activeStartDate, apiBaseUrl, currentPropertyAssociationId, assetId, setStartTimes, userId, setHasRestrictions);
        if(onMonthChange) {
            onMonthChange(activeStartDate);
        }
    }

    const getDisabledTiles = ({date}): boolean => {
        if(selectedStartTime && isSameDate(selectedStartTime, date)) {
            return false;
        }

        return !startTimes?.some(x => isSameDate(x, date));
    }

    const startTime = selectedStartTime?.date ?? null;
    const endTime = selectedEndTime?.date ?? null;

    return (
        <Calendar
            calendarType="ISO 8601"
            onChange={handleChange}
            onActiveStartDateChange={handleMonthChange}
            showNeighboringMonth={false}
            value={(startTime && endTime) ? [startTime, endTime] : [null, null]}
            tileDisabled={getDisabledTiles}
        />
    )
}

export default DatePicker

function setStartTime(date: Date, startTimes: ExtendedDate[], selectedStartTime: ExtendedDate | null, setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void) {
    const startTime = startTimes.find(x => isSameDate(x, date));
    if(!startTime || startTime.value === selectedStartTime?.value) {
        return;
    }

    if(!setFieldValue) {
        return;
    }

    setFieldValue('endTime', '');
    setFieldValue('startTime', startTime.value);
}

function getStartTimesForMonth(date: Date, apiBaseUrl: string, currentPropertyAssociationId: string, assetId: string, setStartTimes: (startTimes: ExtendedDate[]) => void, userId?: string, setHasRestrictions?: (hasRestrictions: boolean) => void) {
    const fromDate = new Date(date.getFullYear(), date.getMonth(), 1);
    const toDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    const intervalStart = tryFormatDate(fromDate);
    const intervalEnd = tryFormatDate(toDate);

    getAvailableStartTimes(
        apiBaseUrl,
        currentPropertyAssociationId,
        assetId,
        userId,
        { intervalStart, intervalEnd },
        (data) => {
            const times = data.availableStartTimes.map((d) => (mapDate(d.time)));
            setStartTimes(times);
            if(setHasRestrictions) {
                setHasRestrictions(data.restrictionsPrevent);
            }
        },
        () => {
            console.log('Failed to get start times', { currentPropertyAssociationId, assetId, intervalStart, intervalEnd });
        }
    )
}