import { buildQueryString, deleteToApi, getFromApi, postToApi, putToApi } from '../../common/utils/apiHelper';
import { CreatePinboardMessageRequest } from './models/CreatePinboardMessageRequest';
import { GetPinboardMessageResponse } from './models/GetPinboardMessageResponse';
import { GetPinboardMessageStatisticsResponse } from './models/GetPinboardMessageStatisticsResponse';
import { GetPinboardMessagesResponse } from './models/GetPinboardMessagesResponse';
import { UpdatePinboardMessageRequest } from './models/UpdatePinboardMessageRequest';

export const getPinboardMessage = (apiBaseUrl: string, propertyAssociationId: string, pinboardMessageId: string, onSuccess: (data: GetPinboardMessageResponse) => void, onFail: () => void): void => {
    getFromApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/pinboardmessages/${pinboardMessageId}`, onSuccess, onFail);
}

export const getPinboardMessages = (apiBaseUrl: string, propertyAssociationId: string, areaId: string | undefined, onSuccess: (data: GetPinboardMessagesResponse) => void, onFail: () => void): void => {
    getFromApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/pinboardmessages${buildQueryString({ areaId })}`, onSuccess, onFail);
}

export const getStatistics = (apiBaseUrl: string, propertyAssociationId: string, pinboardMessageId: string, onSuccess: (data: GetPinboardMessageStatisticsResponse ) => void, onFail: () => void): void => {
    getFromApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/pinboardmessages/${pinboardMessageId}/statistics`, onSuccess, onFail);
}

export const setSettings = (apiBaseUrl: string, propertyAssociationId: string, pinboardMessageId: string, usersCanRespond: boolean, onSuccess: () => void, onFail: () => void): void => {
    const settings = { usersCanRespond };
    postToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/pinboardmessages/${pinboardMessageId}/settings`, settings, onSuccess, onFail);
}

export const createPinboardMessage = (apiBaseUrl: string, propertyAssociationId: string, data: CreatePinboardMessageRequest, onSuccess: (id: string) => void, onFail: () => void): void => {
    const formData = new FormData();
    formData.append('title', data.title);
    formData.append('text', data.text);
    formData.append('isPinned', JSON.stringify(data.isPinned));
    formData.append('usersCanRespond', JSON.stringify(data.usersCanRespond));
    formData.append('propertyAssociationAreaId', data.propertyAssociationAreaId || '');

    if(data.files) {
        for(let i = 0; i < data.files.length; i++) {
            formData.append('files', data.files[i]);
        }
    }

    postToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/pinboardmessages`, formData, onSuccess, onFail);
}

export const updatePinboardMessage = (apiBaseUrl: string, propertyAssociationId: string, pinboardMessageId: string, data: UpdatePinboardMessageRequest, onSuccess: () => void, onFail: () => void): void => {
    const formData = new FormData();
    formData.append('title', data.title);
    formData.append('text', data.text);
    formData.append('isPinned', JSON.stringify(data.isPinned));
    formData.append('usersCanRespond', JSON.stringify(data.usersCanRespond));
    
    if(data.filePaths) {
        for(let i = 0; i < data.filePaths.length; i++) {
            formData.append('filePaths', data.filePaths[i]);
        }
    }

    if(data.files) {
        for(let i = 0; i < data.files.length; i++) {
            formData.append('files', data.files[i]);
        }
    }

    putToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/pinboardmessages/${pinboardMessageId}`, formData, onSuccess, onFail);
}

export const deletePinboardMessage = (apiBaseUrl: string, propertyAssociationId: string, pinboardMessageId: string, onSuccess: () => void, onFail: () => void): void => {
    deleteToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/pinboardmessages/${pinboardMessageId}`, onSuccess, onFail);
}
