import { buildQueryString, deleteToApi, getFromApi, postToApi, putToApi } from '../../common/utils/apiHelper'
import { AddApartmentRequest } from './models/AddApartmentRequest'
import { GetApartmentsResponse } from './models/GetApartmentsResponse'
import { UpdateApartmentFeaturesRequest } from './models/UpdateApartmentFeaturesRequest'

export const getApartments = (
    apiBaseUrl: string,
    propertyAssociationId: string,
    areaId: string | undefined,
    onSuccess: (response: GetApartmentsResponse) => void,
    onFail: () => void
): void => {
    getFromApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/apartments${buildQueryString({ areaId })}`, onSuccess, onFail)
}

export const addApartment = (
    apiBaseUrl: string,
    propertyAssociationId: string,
    request: AddApartmentRequest,
    onSuccess: () => void,
    onFail: () => void
): void => {
    postToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/apartments`, request, onSuccess, onFail)
}

export const deleteApartment = (
    apiBaseUrl: string,
    propertyAssociationId: string,
    apartmentId: string,
    onSuccess: () => void,
    onFail: () => void
): void => {
    deleteToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/apartments/${apartmentId}`, onSuccess, onFail)
}

export const updateApartment = (
    apiBaseUrl: string,
    propertyAssociationId: string,
    apartmentId: string,
    request: UpdateApartmentFeaturesRequest,
    onSuccess: () => void,
    onFail: () => void
): void => {
    putToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/apartments/${apartmentId}`, request, onSuccess, onFail);
}