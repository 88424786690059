export type ExtendedDate = {
    date: Date;
    day: number;
    month: number;
    price?: number;
    value: string;
    year: number;
}

export const mapDate = (value: string, price?: number): ExtendedDate => {
    const date = new Date(value);

    return { 
        date,
        day: date.getDate(),
        month: date.getMonth(),
        price,
        value,
        year: date.getFullYear(),
    }
}