import axios, { AxiosResponse } from 'axios';

export type ErrorResult = {
    errors: any;
    status: number;
    title: string;
    traceId: string;
    type: string;
}

const silentAxiosInstance = axios.create();

export function isAccessDenied(response: AxiosResponse): boolean {
    if (response && (response.status === 401 || response.status === 403)) {
        console.log('access is denied')
        document.location.href = '/access-denied/'
        return true;
    }
    return false;
}

const trackException = (error: Error, url: string, type: string) => {
    if (window.appInsights) {
        window.appInsights?.trackException({
            exception: new Error(JSON.stringify(error)),
            properties: {
                requestUrl: url,
                type: type,
                location: JSON.stringify(window.location)
            },
        })
    }
}

export function getFromApi(url: string, onSuccess: { (data: any): void }, onFail: { (): void }): void {
    axios.get(url)
        .then(response => onSuccess && onSuccess(response.data))
        .catch(error => {
            trackException(error, url, 'GET')
            if (isAccessDenied(error.response)) {
                return;
            }
            onFail && onFail();
        });
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function postToApi(url: string, data: any, onSuccess: { (data: any): void }, onFail: { (result: ErrorResult): void }, always?: { (): void }): void {
    axios.post(url, data)
        .then(response => {
            onSuccess && onSuccess(response.data);
        })
        .catch(error => {
            console.log('post failed', error);
            trackException(error, url, 'POST')
            if (isAccessDenied(error.response)) {
                return;
            }
            const result = error.response ? error.response.data : null;
            onFail && onFail(result);
        })
        .finally(() => {
            always && always();
        });
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function postStringToApi(url: string, data: string, onSuccess: { (data: any): void }, onFail: { (result: ErrorResult): void }, always?: { (): void }): void {
    axios.post(url, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            onSuccess && onSuccess(response.data);
        })
        .catch(error => {
            trackException(error, url, 'POST')
            if (isAccessDenied(error.response)) {
                return;
            }
            const result = error.response ? error.response.data : null;
            onFail && onFail(result);
        })
        .finally(() => {
            always && always();
        });
}

export function putStringToApi(url: string, data: string, onSuccess: { (data: any): void }, onFail: { (result: ErrorResult): void }, always?: { (): void }): void {
    axios.put(url, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            },
        })
        .then(response => {
            onSuccess && onSuccess(response.data);
        })
        .catch(error => {
            trackException(error, url, 'POST')
            if (isAccessDenied(error.response)) {
                return;
            }
            const result = error.response ? error.response.data : null;
            onFail && onFail(result);
        })
        .finally(() => {
            always && always();
        });
}

//eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function putToApi(url: string, data: any, onSuccess: { (data: any): void }, onFail: { (result: ErrorResult): void }, always?: { (): void }): void {
    axios.put(url, data)
        .then(response => {
            onSuccess && onSuccess(response.data);
        })
        .catch(error => {
            console.log('put failed', error);
            trackException(error, url, 'PUT')
            if (isAccessDenied(error.response)) {
                return;
            }
            const result = error.response ? error.response.data : null;
            onFail && onFail(result);
        })
        .finally(() => {
            always && always();
        });
}

export function deleteToApi(url: string, onSuccess: { (): void }, onFail: { (result: ErrorResult): void }, always?: { (): void }): void {
    axios.delete(url)
        .then(() => {
            onSuccess && onSuccess();
            always && always();
        })
        .catch(error => {
            console.log('delete failed', error);
            trackException(error, url, 'DELETE')
            if (isAccessDenied(error.response)) {
                return;
            }
            const result = error.response ? error.response.data : null;
            onFail && onFail(result);
        })
        .finally(() => {
            always && always();
        });
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function deleteWithBodyToApi(url: string, data: any, onSuccess: { (): void }, onFail: { (result: ErrorResult): void }, always?: { (): void }): void {
    axios.delete(url, { data: data })
        .then(() => {
            onSuccess && onSuccess();
        })
        .catch(error => {
            console.log('delete failed', error);
            trackException(error, url, 'DELETE')
            if (isAccessDenied(error.response)) {
                return;
            }
            const result = error.response ? error.response.data : null;
            onFail && onFail(result);
        })
        .finally(() => {
            always && always();
        });
}

// Get data from API without triggering loading indicator
export function getSilentFromApi(url: string, onSuccess: { (data: any): void }, onFail: { (): void }): void {
    ensureUpdatedToken();
    silentAxiosInstance.get(url)
        .then(response => onSuccess && onSuccess(response.data))
        .catch(error => {
            trackException(error, url, 'GET')
            if (isAccessDenied(error.response)) {
                return;
            }
            onFail && onFail();
        });
}

export function buildQueryString(parameters: object): string {
    if (!parameters) {
        return '';
    }

    const keysWithValues = Object.keys(parameters).filter(key => parameters[key] !== undefined && parameters[key] !== null);
    const query = keysWithValues.map(key => `${key}=${parameters[key]}`).join('&');
    return query ? `?${query}` : '';
}

function ensureUpdatedToken() {
    silentAxiosInstance.defaults.headers.common['Authorization'] = axios.defaults.headers.common['Authorization'];
}

export const axiosFetcher = (url: string): Promise<any> => axios.get(url).then(res => res.data);
