/* eslint-disable react/display-name */
import { useContext } from 'react';
import { PropertyAssociationContext } from './PropertyAssociationContext';

// NOTE: Use this for any sub component that requires a current property association (for top level use withPropertyAssociation)
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const withWaitForPropertyAssociation = (Component: any, allowGuests = true) => (props: any) => {
    const { currentArea, currentPropertyAssociation, isAdmin, isGuest, requireAreaSelection } = useContext(PropertyAssociationContext);

    if(!isAdmin && !allowGuests && isGuest) {
        return null;
    }

    if(!currentPropertyAssociation) {
        return null;
    }

    if(requireAreaSelection && !currentArea) {
        return null;
    }

	return <Component {...props} />;
}