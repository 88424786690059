import { Fragment, FunctionComponent, useContext, useState } from 'react';
import * as Yup from 'yup';
import { BookableAsset } from '../../assets/models/Asset';
import { ConfigContext } from '../../configuration/ConfigContext';
import TextArea from '../../forms/TextArea';
import { requiredValidator, stringConditionalValidator } from '../../forms/validators';
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext';
import { BookingTypes } from '../BookingTypes';
import BookingForm from '../components/BookingForm';
import DatePicker from '../components/DatePicker';
import NewBookingLayout from '../components/NewBookingLayout';
import ResourceSelector from '../components/ResourceSelector';
import SelectedTime from '../components/SelectedTime';
import TimeSelector from '../components/TimeSelector';
import { bookInternally } from '../internalBookingService';
import { useInternalBooking } from '../useInternalBooking';

const validationSchema = Yup.object().shape({
    startTime: requiredValidator,
    endTime: requiredValidator,
    resourceId: stringConditionalValidator('automaticallyAssignResource', false, requiredValidator, null),
})

type Props = {
    asset?: BookableAsset,
    label: string,
    setBookingType: (bookingType: BookingTypes | undefined) => void,
    setBookingId: (bookingId: string) => void
}

type FormData = {
    automaticallyAssignResource: boolean,
    endTime: string,
    internalBookingComment: string,
    resourceId: string,
    startTime: string,
}

const InternalBookingForm: FunctionComponent<Props> = ({asset, label, setBookingType, setBookingId}) => {
    const assetId = asset?.assetId ?? '';
    const { apiBaseUrl } = useContext(ConfigContext);
    const { currentPropertyAssociationId } = useContext(PropertyAssociationContext);
    const { endOptions, endTimes, error, formikRef, selectedEndTime, selectedStartTime, setError, setSelectedEndTime, setSelectedStartTime, setStartTimes, startOptions, startTimes, } = useInternalBooking(assetId);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = (values: FormData) => {
        const { startTime, endTime, resourceId, internalBookingComment } = values;
        const data = { assetId, assetObjectId: resourceId, endTime, internalBookingComment, startTime };
        
        setIsSubmitting(true);
        bookInternally(
            apiBaseUrl,
            currentPropertyAssociationId,
            data,
            (bookingId) => {
                setBookingId(bookingId);
                setIsSubmitting(false);
            },
            () => {
                setError('Bokningen kunde inte genomföras.');
                setIsSubmitting(false);
            }
        );
    }

    if(!asset) {
        return null;
    }

    return (
        <NewBookingLayout heading={asset.name} label={label}>
            <BookingForm
                startTimes={startTimes}
                endTimes={endTimes}
                selectedStartTime={selectedStartTime}
                error={error}
                
                formikRef={formikRef}
                initialValues={buildInitialValues}
                validationSchema={validationSchema}

                isSubmitting={isSubmitting}
                onSubmit={handleSubmit}
                setBookingType={setBookingType}
                setSelectedEndTime={setSelectedEndTime}
                setSelectedStartTime={setSelectedStartTime}
            >
                {(formProps) => (
                    <Fragment>
                        <h2>Bokningsdetaljer</h2>
                        <p>Välj datum och tid för bokningen</p>
                        
                        <div className="paper__fields">
                            <DatePicker assetId={assetId} selectedStartTime={selectedStartTime} selectedEndTime={selectedEndTime} startTimes={startTimes} setFieldValue={formikRef.current?.setFieldValue} setStartTimes={setStartTimes} />

                            <TimeSelector selectedStartTime={selectedStartTime} selectedEndTime={selectedEndTime} startOptions={startOptions} endOptions={endOptions} formProps={formProps} />

                            {!asset.automaticallyAssignResource && <ResourceSelector assetId={asset.assetId} formikRef={formikRef} selectedStartTime={selectedStartTime} selectedEndTime={selectedEndTime} setError={setError} formProps={formProps} />}

                            <SelectedTime selectedStartTime={selectedStartTime} selectedEndTime={selectedEndTime} />

                            <div className="mt-5">
                                    <TextArea name="internalBookingComment" label="Kommentar" {...formProps} />
                            </div>
                        </div>
                    </Fragment>
                )}
            </BookingForm>
        </NewBookingLayout>
    )
}

export default InternalBookingForm;

const buildInitialValues = (asset: BookableAsset) => ({
    automaticallyAssignResource: asset.automaticallyAssignResource,
    endTime: '',
    internalBookingComment: '',
    resourceId: '',
    startTime: '',
})