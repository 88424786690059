import React, { FunctionComponent, ReactNode } from 'react'

type Props = {
    children: ReactNode | ReactNode[],
    icon?: 'hash' | 'person' | 'phone' | 'exclamation' | 'calendar' | 'price' | 'comment',
    title?: string,
}

const PreviewBlock: FunctionComponent<Props> = ({ children, icon, title }) => {
    const iconClassName = icon ? `preview__block--${icon}` : '';

    return (
        <div className={`preview__block preview__block--read-only ${iconClassName}`}>
            {title && <div className="preview__block-heading">{title}</div>}
            {children}
        </div>
    )
}

export default PreviewBlock