import cx from 'classnames';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import RadioButton from './RadioButton';
import { Option } from './models/Option';

type Props = {
    label: string;
    name: string;
    options: Option[];
    values: any;
    fixed?: boolean;
}

const RadioBoxDropDown: FunctionComponent<Props> = ({fixed = false, label, name, options, values}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [text, setText] = useState(label);
    const node = useRef<HTMLDivElement>(null);

    const handleClickOutSide = (e: any) => {
		if (node.current && !node.current.contains(e.target)) {
			setIsExpanded(false)
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutSide);
		return () => {
			document.removeEventListener('mousedown', handleClickOutSide);
		};
	}, []);

    useEffect(() => {
        const selectedValue = values[name]?.toString();
        if(selectedValue !== undefined) {
            const option = options.filter(x => x.value === selectedValue);
            const newText = (option && option.length > 0) ? option[0].text : label;
            setText(newText);
            setIsExpanded(false);
        }
        else {
            setText(label);
        }
    }, [options, values])

    return (
        <div className="checkdown" ref={node}>
            <button className={cx(['checkdown__button', {'checkdown__button--expanded': isExpanded} ])} type="button" onClick={() => setIsExpanded(!isExpanded)}>
                {text}
            </button>
            { isExpanded && (
                <ul className={cx(['checkdown__list checkdown__list--floating', {'checkdown__list--fixed': fixed}])}>
                    {options.map(x => <li key={x.value} className="checkdown__item"><RadioButton name={name} label={x.text} value={x.value} /></li>)}
                </ul>
            )}
        </div>
    )
}

export default RadioBoxDropDown