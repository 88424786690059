import cx from 'classnames';
import { Field, getIn } from 'formik';
import React from 'react';
import MaskedInput from 'react-text-mask';
import FormField from './FormField';
import Label from './Label';

type Props = {
    disabled?: boolean;
    errors: any;
    extraClass?: string;
    label: string;
    mask: (string | RegExp)[];
    maxLength?: number;
    name: string;
    placeholder?: string;
    readonly?: boolean;
    touched: any;
    type?: string;
    values: any;
    width?: number;
}

const MaskedTextField = ({ disabled, extraClass, label, mask, maxLength, name, placeholder = undefined, type = 'text', width = 100, values, errors, touched }: Props): JSX.Element => {
    const value = getIn(values, name);
    const error = getIn(errors, name);
    const isTouched = getIn(touched, name);

    return (
        <FormField width={width}>
            {label && <Label name={name} text={label} />}
            <Field name={name} value={value}>
                {({ field, form: { handleChange, handleBlur } }) => (
                    <MaskedInput
                        {...field}
                        className={cx('form-control', { 'is-invalid': error && isTouched },  { 'is-valid': !error && isTouched }, extraClass)}
                        disabled={disabled}
                        guide={false}
                        id={name}
                        mask={mask}
                        maxLength={maxLength}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder={placeholder}
                        showMask={true}
                        type={type}
                    />
                )}
            </Field>
            {error && isTouched && <div className="field-error text-danger">{error}</div>}
        </FormField>
    )
}

export default MaskedTextField;
