import { Fragment, FunctionComponent, useState } from 'react';
import { Asset } from '../models/Asset';
import AdministerObjects from '../components/AdministerObjects';
import FullDaySchedule from '../components/FullDaySchedule';
import MaxLength from '../components/MaxLength';
import WeekSchedule from '../components/WeekSchedule';
import PriceModal from '../modals/PriceModal';
import { TimeSelectionType } from '../assetConstants';
import { displayBookingType } from '../assetHelpers';
import { AssetTypeDefinition } from '../assetTypes/assetTypes';

type Props = {
    defaults: AssetTypeDefinition;
    gotoNext: () => void;
    gotoPrevious: () => void;
    renderCancelButton: () => any;
    asset: Asset;
    updateValues: () => void;
}

const AssetSchedule: FunctionComponent<Props> = ({defaults, gotoNext, gotoPrevious, renderCancelButton, asset, updateValues}) => {
    const [showPriceModal, setShowPriceModal] = useState(false);

    return (
        <Fragment>
            <div className="paper__content  paper__main">
                { (defaults.canChangeToDaily || defaults.canChangeToHourly || defaults.canChangeToInterval) && (
                    <div className="mb-5">
                        <strong>Hur ska {defaults.nameDefinitiveForm} bokas?</strong>
                        <p>{displayBookingType(asset)} <button type="button" className="recipient__change" onClick={() => setShowPriceModal(true)}>Ändra</button></p>
                    </div>
                )}

                <MaxLength initialValues={asset} updateValues={updateValues} />

                { (asset.timeSelectionType === TimeSelectionType.Day || defaults.alwaysUseDays) && <FullDaySchedule initialValues={asset} defaults={defaults} updateValues={updateValues} /> }

                { (asset.timeSelectionType !== TimeSelectionType.Day && !defaults.alwaysUseDays) && <WeekSchedule initialValues={asset} updateValues={updateValues} />}

                { !defaults.isSingleton && <AdministerObjects defaults={defaults} asset={asset} updateValues={updateValues} />}
            </div>
            <div className="paper__buttons">
                { renderCancelButton() }
                <div className="paper__primary-buttons">
                    <button className="btn btn-lg btn-secondary btn-prev" onClick={gotoPrevious}>Tillbaka</button>
                    <button className="btn btn-lg btn-primary btn-next" onClick={gotoNext}>Nästa</button>
                </div>
            </div>

            { showPriceModal && <PriceModal initialValues={asset} defaults={defaults} updateValues={updateValues} visible={true} onClose={() => setShowPriceModal(false)} /> }
        </Fragment>
    )
}

export default AssetSchedule