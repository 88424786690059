import { FunctionComponent } from 'react'
import { AssetTypeDefinition } from '../assetTypes/assetTypes';

type Props = {
    defaults: AssetTypeDefinition;
    onClick: (defaults: AssetTypeDefinition) => void;
}

const AssetType: FunctionComponent<Props> = ({defaults, onClick}) => (
    <button className="boxlist__item" onClick={() => onClick(defaults)}>
        <img src={defaults.thumbnail} alt="" className="boxlist__image" />
        {defaults.name}
    </button>
)

export default AssetType