import { Form, Formik } from 'formik'
import { Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from 'react'
import { Option } from '../../forms/models/Option'
import FormikChangeSubscriber from '../../forms/FormikChangeSubscriber'
import RadioBoxDropDown from '../../forms/RadioBoxDropDown'
import { noValidationSchema } from '../../forms/validators'
import { isDraft } from '../../assets/assetHelpers'
import { useAssets } from '../../assets/assetService'
import NewBookingButton from './NewBookingButton'

type Props = {
    exportBookings: () => void;
    setAssetId: Dispatch<SetStateAction<string | undefined>>;
}

type FormData = {
    assetId: string | undefined;
}

const AssetFilter: FunctionComponent<Props> = ({exportBookings, setAssetId}) => {
    const { assets } = useAssets();
    const [ formData, setFormData ] = useState<FormData>({ assetId: undefined });
    const [ options, setOptions ] = useState<Option[]>([]);

    const handleChange = (values: FormData) => {
        const { assetId } = values;
        setAssetId(assetId);
        setFormData({ assetId });
    }

    useEffect(() => {
        const newOptions: Option[] = assets?.filter(x => !isDraft(x.status)).map(x => ({ text: x.name, value: x.assetId  })) ?? [];
        newOptions.unshift({ text: 'Alla resurser', value: '' });
        setOptions(newOptions);
    }, [assets])

    return (
        <Formik initialValues={formData} enableReinitialize={true} validationSchema={noValidationSchema} validateOnMount={false} onSubmit={() => { /* Hanterat av event */ }}>
            {({values}) => (
                <Form>
                    <FormikChangeSubscriber onChange={handleChange} />
                    <div className="d-flex flex-wrap justify-content-between align-items-center mb-4">
                        <div className="d-flex flex-wrap align-items-center bookings__filter">
                            <div className="me-4">Visa</div>
                            <div className="d-block d-md-none w-100 my-1"></div>
                            <RadioBoxDropDown label="Alla resurser" name="assetId" values={values} options={options} />
                        </div>
                        <div className="d-flex">
                            <button onClick={exportBookings} className="btn btn-secondary">Exportera CSV</button>
                            <NewBookingButton />
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default AssetFilter