/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line no-unused-vars
import { useEffect, useLayoutEffect } from 'react'
import { useLocation } from 'react-router-dom'
import {
  useAppInsightsContext,
} from '@microsoft/applicationinsights-react-js'
import  useDebounce  from '../utils/useDebounce'

const TrackPageViews = (): null => {
  const location = useLocation()
  const appInsights = useAppInsightsContext()
  let start = 0
  let end = 0

  useEffect(() => {
    end = Date.now()
  })
  useLayoutEffect(() => {
    start = Date.now()
  })
  useDebounce(() => {
    const duration = end - start
    appInsights?.trackPageView({
      uri: location.pathname,
      properties: { duration },
    })
  }, [location], 500)
  return null
}

export default TrackPageViews
