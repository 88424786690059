import { TimeSelectionType } from '../assetConstants';
import { AssetTypeDefinition } from './assetTypes';

export const ASSETTYPE_SAUNA = 'Sauna';

export const DEFAULTS_SAUNA: AssetTypeDefinition = {
    alwaysUseDays: false,
    assetTypeTag: ASSETTYPE_SAUNA,
    backdrop: '/images/defaults/backdrop/RB_gemensamma-resurser_bastu.png',
    canChangeToDaily: false,
    canChangeToHourly: true,
    canChangeToInterval: true,
    endTimeNextDay: false,
    endTimeRestOfDay: false,
    image: '/images/defaults/preview/RB_gemensamma-resurser_bastu.png',
    isSingleton: true,
    name: 'Bastu',
    nameDefinitiveForm: 'bastun',
    namePlural: 'bastur',
    nameSingular: 'bastu',
    options: [
        'Wifi',
        'Toalett',
        'Dusch',
        'Relaxstolar',
        'Kök/pentry',
    ],
    prefixName: true,
    serviceInformationText: 'Ex. Gemensam bastu med plats för 8 personer. Tillhörande loungeavdelning med relaxstolar och dusch i anslutning.',
    serviceInformationTitle: 'Välkommen in i värmen!',
    thumbnail: '/images/defaults/thumbnails/RB_gemensamma-resurser_bastu.png',
    timeSelectionType: TimeSelectionType.Startstop,
}