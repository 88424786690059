import { FunctionComponent, useState } from 'react';
import Button from '../forms/Button';
import PaperLayout from '../layout/PaperLayout';
import { withPropertyAssociation } from '../propertyAssociation/withPropertyAssociation';
import Conversation from './components/Conversation';
import ConversationsList from './components/ConversationsList';
import NoConversations from './components/NoConversations';
import { useConversations } from './hooks/useConversations';
import NewGroupModal from './modals/NewGroupModal';
import { useHistory } from 'react-router';

const GroupChats: FunctionComponent = () => {
    const { appendConversations, conversation, conversations, resetConversations, id, isLoading, totalCount } = useConversations(true);
    const { push } = useHistory();
    const [showNewGroupModal, setShowNewGroupModal] = useState(false);

    const onGroupCreated = (conversationId: string) => {
        setShowNewGroupModal(false);
        resetConversations();
        push(`/messages/groups/${conversationId}`);
    }

    return (
        <PaperLayout heading="Hantera chattgrupper" noBottomMargin>
            {conversations.length === 0 && !isLoading ? (
                <>
                    <NoConversations heading="Här visas aktiva grupper" text="Just nu finns inga grupper att visa." buttonText="Skapa den första gruppen" onButtonClick={() => setShowNewGroupModal(true)} />
                </>
            ) : (
                <div className='conversations'>
                    <ConversationsList
                        activeConversationId={id}
                        conversations={conversations} 
                        hasMore={totalCount > conversations.length}
                        onLoadMore={appendConversations}
                        showGroups={true}>
                        <Button text="Skapa ny grupp" additionalClassName='conversations__new-group' onClick={() => setShowNewGroupModal(true)} />
                    </ConversationsList>

                    <Conversation conversation={conversation} showGroups={true} fetchConversations={resetConversations} />
                </div>
            )}

            {showNewGroupModal && <NewGroupModal onClose={() => setShowNewGroupModal(false)} onGroupCreated={onGroupCreated} />}
        </PaperLayout>
    )
}

export default withPropertyAssociation(GroupChats, false)