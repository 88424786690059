import cx from 'classnames';
import { Field, getIn } from 'formik';
import React, { FunctionComponent } from 'react';

type Props = {
    disabled?: boolean;
    errors: any;
    label?: string;
    name: string;
    placeholder?: string;
    touched: any;
    values: any;
}

const TimeField: FunctionComponent<Props> = ({disabled, label, name, placeholder, values, errors, touched}) => {
    const value = getIn(values, name);
	const error = getIn(errors, name);
    const isTouched = getIn(touched, name);

    return (
        <div className="timefield">
            {label && <label htmlFor={name} className="form-label  timefield__label">{label}</label>}
            <Field
                autoComplete="off"
                className={cx('form-control timefield__control', { 'is-invalid': error && isTouched }, { 'is-valid': !error && isTouched })}
                disabled={disabled}
                id={name}
                name={name}
                placeholder={placeholder}
                type="Time"
                value={value}
            />
            {error && isTouched && <div className="field-error text-danger">{error}</div>}
        </div>
    )
}

export default TimeField