import React, { FunctionComponent, useContext } from 'react'
import { Link } from 'react-router-dom'
import { AuthContext } from '../authentication/AuthContext'
import MenuItem from './MenuItem'
import MenuIcon from './MenuIcon'
import { ConversationContext } from '../messages/ConversationContext'
import { PropertyAssociationContext } from '../propertyAssociation/PropertyAssociationContext'

const Navigation: FunctionComponent = () => {
    const { isAuthenticated, signinRedirect } = useContext(AuthContext);
    const { isAdmin, isGuest } = useContext(PropertyAssociationContext);
    const { unreadConversationIds } = useContext(ConversationContext);

    const signIn = (event) => {
        event.preventDefault();
        signinRedirect();
    }

    return (
        <header className="app__header">
            <div className="menu">
                <Link to="/" title="Mitt Boende Portal">
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="35.578" className="menu__logo"><path d="m39.648 16.814-4.067-3.561V3.332a1.112 1.112 0 0 0-1.112-1.112h-6.672a1.112 1.112 0 0 0-1.112 1.112v2.137L20.746.274a1.112 1.112 0 0 0-1.464 0L.38 16.814a1.112 1.112 0 0 0 1.464 1.674l2.6-2.281v16.035a3.339 3.339 0 0 0 3.336 3.336h24.465a3.339 3.339 0 0 0 3.336-3.336V16.207l2.6 2.279a1.112 1.112 0 0 0 1.464-1.672Zm-6.291 15.428a1.112 1.112 0 0 1-1.112 1.111H7.783a1.112 1.112 0 0 1-1.112-1.112v-17.98L20.014 2.586l13.343 11.675Zm0-20.935-4.448-3.892V4.444h4.448Zm-18.9 3.886v7.413a1.859 1.859 0 0 0 1.853 1.853h7.411a1.86 1.86 0 0 0 1.856-1.853v-7.413a1.859 1.859 0 0 0-1.856-1.853h-7.414a1.858 1.858 0 0 0-1.853 1.853Zm2.224.37h6.671v6.671h-6.674Z" fill="#fff"/></svg>
                </Link>
                <nav>
                    {
                        isAuthenticated ? (
                            <>
                                <MenuItem path="/members/" available={isAdmin || !isGuest}>
                                    <MenuIcon path="/members/" />
                                    Åtkomst
                                </MenuItem>
                                <MenuItem path="/messages/conversations" match="/messages/" notificationCount={unreadConversationIds.length}>
                                    <MenuIcon path="/messages/" />
                                    Meddelanden
                                </MenuItem>
                                <MenuItem path="/bookings/">
                                    <MenuIcon path="/bookings/" />
                                    Bokningar
                                </MenuItem>
                                <MenuItem path="/assets/">
                                    <MenuIcon path="/assets/" />
                                    Resurser
                                </MenuItem>
                                <MenuItem path="/adverts/user-adverts/" match="/adverts/" available={isAdmin || !isGuest}>
                                    <MenuIcon path="/adverts/" />
                                    Anslagstavlan
                                </MenuItem>
                                <MenuItem path="/settings/admins/" match="/settings/" available={isAdmin || !isGuest}>
                                    <MenuIcon path="/settings" />
                                    Inställningar
                                </MenuItem>
                                <MenuItem path="/release-notes/">
                                    <MenuIcon path="/release-notes/" />
                                    Vad är nytt?
                                </MenuItem>
                                <MenuItem path="/signout-oidc/">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="23" height="23"><path fill="#fff" d="M160 217.1c0-8.8 7.2-16 16-16h144v-93.9c0-7.1 8.6-10.7 13.6-5.7l141.6 143.1c6.3 6.3 6.3 16.4 0 22.7L333.6 410.4c-5 5-13.6 1.5-13.6-5.7v-93.9H176c-8.8 0-16-7.2-16-16v-77.7m-32 0v77.7c0 26.5 21.5 48 48 48h112v61.9c0 35.5 43 53.5 68.2 28.3l141.7-143c18.8-18.8 18.8-49.2 0-68L356.2 78.9c-25.1-25.1-68.2-7.3-68.2 28.3v61.9H176c-26.5 0-48 21.6-48 48zM0 112v288c0 26.5 21.5 48 48 48h132c6.6 0 12-5.4 12-12v-8c0-6.6-5.4-12-12-12H48c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16h132c6.6 0 12-5.4 12-12v-8c0-6.6-5.4-12-12-12H48C21.5 64 0 85.5 0 112z"/></svg>
                                    Logga ut
                                </MenuItem>
                            </>
                        ): (
                            <a href="/" className="menu__item" onClick={signIn}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="23" height="23"><path fill="#fff" d="M32 217.1c0-8.8 7.2-16 16-16h144v-93.9c0-7.1 8.6-10.7 13.6-5.7l141.6 143.1c6.3 6.3 6.3 16.4 0 22.7L205.6 410.4c-5 5-13.6 1.5-13.6-5.7v-93.9H48c-8.8 0-16-7.2-16-16v-77.7m-32 0v77.7c0 26.5 21.5 48 48 48h112v61.9c0 35.5 43 53.5 68.2 28.3l141.7-143c18.8-18.8 18.8-49.2 0-68L228.2 78.9c-25.1-25.1-68.2-7.3-68.2 28.3v61.9H48c-26.5 0-48 21.6-48 48zM512 400V112c0-26.5-21.5-48-48-48H332c-6.6 0-12 5.4-12 12v8c0 6.6 5.4 12 12 12h132c8.8 0 16 7.2 16 16v288c0 8.8-7.2 16-16 16H332c-6.6 0-12 5.4-12 12v8c0 6.6 5.4 12 12 12h132c26.5 0 48-21.5 48-48z"/></svg>
                                Logga in
                            </a>
                        )
                    }
                </nav>
            </div>
        </header>
    )
}

export default Navigation