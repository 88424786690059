import { getFromApi } from '../../common/utils/apiHelper';
import { GetPropertyAssociationConfigResponse } from './models/GetPropertyAssociationConfigResponse';
import { PropertyAssociationInfo } from './models/PropertyAssociationInfo';
import { PropertyAssociationList } from './models/PropertyAssociationList';
import { PropertyAssociationSearchResult } from './models/PropertyAssociationSearchResult';

export const getPropertyAssociation = (apiBaseUrl: string, id: string, onSuccess: (data: PropertyAssociationInfo) => void, onFail: () => void): void => {
    getFromApi(`${apiBaseUrl}/propertyassociations/${id}`, onSuccess, onFail);
}

export const getPropertyAssociations = (apiBaseUrl: string, onSuccess: (data: PropertyAssociationList) => void, onFail: () => void): void => {
    getFromApi(`${apiBaseUrl}/propertyassociations`, onSuccess, onFail);
}

export const getPropertyAssociationConfig = (apiBaseUrl: string, id: string, onSuccess: (data: GetPropertyAssociationConfigResponse) => void, onFail: () => void): void => {
    getFromApi(`${apiBaseUrl}/propertyassociations/${id}/config`, onSuccess, onFail);
}

export const searchPropertyAssociation = (apiBaseUrl: string, searchTerm: string, offset: number, onSuccess: (data: PropertyAssociationSearchResult) => void, onFail: () => void): void => {
    const pageSize = 10;
    const page = Math.floor(offset / pageSize);
    getFromApi(`${apiBaseUrl}/propertyassociations/page?search=${searchTerm}&page=${page}&pageSize=${pageSize}`, onSuccess, onFail);
}