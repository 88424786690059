import { FunctionComponent, useContext, useEffect, useState } from 'react'
import { ConfigContext } from '../configuration/ConfigContext'
import { withPropertyAssociation } from '../propertyAssociation/withPropertyAssociation'
import { ReleaseNote } from './models/GetReleaseNotesResponse'
import styles from './ReleaseNotes.module.scss'
import { getReleaseNotes } from './releaseNoteService'

const ReleaseNotes: FunctionComponent = () => {
    const { apiBaseUrl } = useContext(ConfigContext);
    const [releaseNotes, setReleaseNotes] = useState<ReleaseNote[]>();

    useEffect(() => {
        getReleaseNotes(
            apiBaseUrl,
            ({releaseNotes}) => setReleaseNotes(releaseNotes),
            () => console.error('Failed to get release notes')
        );
    }, [])

    if(!releaseNotes) {
        return null
    }

    return (
        <div className={styles.container}>
            <h1 className={styles.heading}>Versionshistorik</h1>

            {releaseNotes.map((releaseNote, index) => (
                <div key={index}>
                    <h2 className={styles.subheading}>{releaseNote.title}</h2>
                    <div dangerouslySetInnerHTML={{__html: releaseNote.text}} />
                </div>
            ))}
        </div>
    )
}

export default withPropertyAssociation(ReleaseNotes)