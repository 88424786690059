import { HttpTransportType, HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { FunctionComponent, createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { AuthContext } from '../authentication/AuthContext';
import { ConfigContext } from '../configuration/ConfigContext';
import { PropertyAssociationContext } from '../propertyAssociation/PropertyAssociationContext';
import { ToastContext } from '../toaster/ToastContext';
import { getUnreadConversations } from './conversationService';
import { ConversationType } from './models/ConversationType';

type Context = {
    lastUpdatedConversationId: string | null;
    lastMessageId: string | null;
    unreadConversationIds: string[];
}

const EMPTY_CONTEXT: Context = {
    lastUpdatedConversationId: null,
    lastMessageId: null,
    unreadConversationIds: []
}

export const ConversationContext = createContext<Context>(EMPTY_CONTEXT);

export const ConversationProvider: FunctionComponent = ({ children }) => {
    const { currentPropertyAssociationId, isAdmin, isGuest } = useContext(PropertyAssociationContext);
    const { accessToken } = useContext(AuthContext);
    const { apiBaseUrl, messageHubUrl } = useContext(ConfigContext);
    const { addToast } = useContext(ToastContext);
    const location = useLocation();
    const [ connection, setConnection ] = useState<HubConnection | null>(null);
    const [ value, setValue ] = useState<Context>(EMPTY_CONTEXT);
    const valueRef = useRef<Context>();
    const accessTokenRef = useRef<string | null>(accessToken);

    valueRef.current = value;

    useEffect(() => {
        accessTokenRef.current = accessToken;
    }, [accessToken]);

    const accessTokenFactory = useCallback(() => (accessTokenRef.current ?? ''), []);

    const onNewMessage = useCallback((conversationId: string, messageId: string, unread: boolean, conversationType: ConversationType) => {
        if (!isAdmin && isGuest && conversationType !== ConversationType.Booking) {
            return;
        }

        let { unreadConversationIds } = valueRef.current ?? EMPTY_CONTEXT;
        if(unread && !unreadConversationIds.includes(conversationId)) {
            unreadConversationIds = [...unreadConversationIds, conversationId];
        }
        setValue({ lastUpdatedConversationId: conversationId, lastMessageId: messageId, unreadConversationIds });
        
        const conversationUrl = `/messages/conversations/${conversationId}`;
        if(unread && location.pathname !== conversationUrl) {
            addToast('Nytt meddelande', <Link to={conversationUrl}>Klicka för att komma till meddelandet</Link>);
        }
    }, [isAdmin, isGuest]);

    const onConversationRead = useCallback((conversationId: string, conversationType: ConversationType) => {
        if (!isAdmin && isGuest && conversationType !== ConversationType.Booking) {
            return;
        }
        
        const { lastMessageId, lastUpdatedConversationId, unreadConversationIds } = valueRef.current ?? EMPTY_CONTEXT;
        const newUnreadConversationIds = unreadConversationIds.filter(x => x !== conversationId);
        setValue({ lastUpdatedConversationId, lastMessageId , unreadConversationIds: newUnreadConversationIds });
    }, [isAdmin, isGuest]);

    const onConversationUnread = useCallback((conversationId: string, conversationType: ConversationType) => {
        if (!isAdmin && isGuest && conversationType !== ConversationType.Booking) {
            return;
        }
        
        const { lastMessageId, lastUpdatedConversationId, unreadConversationIds } = valueRef.current ?? EMPTY_CONTEXT;
        const newUnreadConversationIds = unreadConversationIds.filter(x => x !== conversationId);
        newUnreadConversationIds.push(conversationId);
        setValue({ lastUpdatedConversationId, lastMessageId , unreadConversationIds: newUnreadConversationIds });
    }, [isAdmin, isGuest]);

    useEffect(() => {
        if(!currentPropertyAssociationId) {
            return;
        }

        getUnreadConversations(
            apiBaseUrl,
            currentPropertyAssociationId, 
            ({ unreadConversationIds }) => {
                const { lastMessageId, lastUpdatedConversationId } = value;
                setValue({ lastUpdatedConversationId, lastMessageId, unreadConversationIds });
            },
            () => { /* Non critical error */ }
        );

        const createConnection = async () => {
            const connection = new HubConnectionBuilder()
                .withUrl(`${messageHubUrl}?propertyAssociationId=${currentPropertyAssociationId}`, { accessTokenFactory, transport: HttpTransportType.LongPolling, withCredentials: false })
                .withAutomaticReconnect()
                // .configureLogging(LogLevel.Trace)
                .build();
            connection.on('addmessageinternalluser', x => onNewMessage(x.conversationId, x.messageId, false, x.conversationType));
            connection.on('addmessageexternaluser', x => onNewMessage(x.conversationId, x.messageId, true, x.conversationType));
            connection.on('readconversation', x => onConversationRead(x.conversationId, x.conversationType));
            connection.on('unreadconversation', x => onConversationUnread(x.conversationId, x.conversationType));
            setConnection(connection);
        }
        createConnection();
    }, [currentPropertyAssociationId]);

    useEffect(() => {
        if (connection) {
            connection.start()
                .then(() => console.log('Connection started!'))
                .catch(err => console.log('Error while establishing connection :' + err));
        }
    }, [connection]);

    return (
        <ConversationContext.Provider value={value}>
            {children}
        </ConversationContext.Provider>
    )
}