import { Fragment, FunctionComponent, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { BookableAsset } from '../assets/models/Asset';
import { ConfigContext } from '../configuration/ConfigContext';
import { PropertyAssociationContext } from '../propertyAssociation/PropertyAssociationContext';
import { withPropertyAssociation } from '../propertyAssociation/withPropertyAssociation';
import { BookingTypes } from './BookingTypes';
import { getBookableAsset } from './internalBookingService';
import BookingConfirmation from './steps/BookingConfirmation';
import IntermediateBookingForm from './steps/IntermediateBookingForm';
import InternalBookingForm from './steps/InternalBookingForm';
import SelectBookingType from './steps/SelectBookingType';

const InternalBooking: FunctionComponent = () => {
    const { id } = useParams() as any;
    const { apiBaseUrl } = useContext(ConfigContext);
    const { currentPropertyAssociationId } = useContext(PropertyAssociationContext);
    const [asset, setAsset] = useState<BookableAsset | undefined>();
    const [bookingId, setBookingId] = useState('');
    const [bookingType, setBookingType] = useState<BookingTypes>();
    const [activeStep, setActiveStep] = useState(0);

    useEffect(() => {
        if (bookingId) {
            setActiveStep(2);
        }
        else if (bookingType) {
            setActiveStep(1);
        }
        else {
            setActiveStep(0);
        }
    }, [bookingId, bookingType])

    useEffect(() => {
        if (!id) {
            setAsset(undefined);
            return;
        }

        getBookableAsset(
            apiBaseUrl,
            currentPropertyAssociationId,
            id,
            (data) => setAsset(data),
            () => setAsset(undefined)
        )
    }, [id])

    if(!asset) {
        return null;
    }

    const label = bookingType ?? '';

    return (
        <Fragment>
            {activeStep === 0 && <SelectBookingType setBookingType={setBookingType} />}
            {activeStep === 1 && bookingType === BookingTypes.Internal && <InternalBookingForm asset={asset} label={label} setBookingType={setBookingType} setBookingId={setBookingId} />}
            {activeStep === 1 && bookingType === BookingTypes.Intermediate && <IntermediateBookingForm asset={asset} label={label} setBookingType={setBookingType} setBookingId={setBookingId} />}
            {activeStep === 2 && <BookingConfirmation bookingId={bookingId} label={label} />}
        </Fragment>
    )
}

export default withPropertyAssociation(InternalBooking);