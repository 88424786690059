import { FunctionComponent, ReactNode } from 'react';
import styles from './Week.module.scss';

type Props = {
    children: ReactNode | ReactNode[];
}

const Week: FunctionComponent<Props> = ({children}) => (
    <div className={styles.container}>
        {children}
    </div>
)

export default Week