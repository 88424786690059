import { Field } from 'formik';
import React, { FunctionComponent } from 'react';

type Props = {
    disabled?: boolean;
    label: string;
    name: string;
    onClick?: () => void;
}

const BooleanCheckBox: FunctionComponent<Props> = ({ disabled = false, label, name, onClick }) => (
    <div className="mb-3">
        <div className="form-check">
            <Field className="form-check-input form-check-input--checkmark" name={name} id={name} disabled={disabled} type="checkbox" onClick={onClick} />
            <label className="form-check-label" htmlFor={name}>{label}</label>
        </div>
    </div>
)

export default BooleanCheckBox;
