/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { getFromApi, putToApi } from '../../common/utils/apiHelper';
import { PropertyAssociationSettings } from './models/PropertyAssociationSettings';
import { UpdatePropertyAssociationSettingsRequest } from './models/UpdatePropertyAssociationSettingsRequest';

export const getPropertyAssociationSettings = (
    apiBaseUrl: string,
    propertyAssociationId: string,
    onSuccess: (data: PropertyAssociationSettings) => void,
    onFail: () => void
): void => {
    getFromApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/settings`, onSuccess, onFail)
}

export const updatePropertyAssociationSettings = (
    apiBaseUrl: string,
    propertyAssociationId: string,
    data: UpdatePropertyAssociationSettingsRequest,
    onSuccess: () => void,
    onFail: () => void
): void => {
    putToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/settings`, data, onSuccess, onFail)
}