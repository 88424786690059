import cx from 'classnames';
import { Fragment, FunctionComponent, useContext, useEffect, useState } from 'react';
import { ConfigContext } from '../../configuration/ConfigContext';
import Button from '../../forms/Button';
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext';
import { ToastContext } from '../../toaster/ToastContext';
import { ToastMessageType } from '../../toaster/models/ToastMessageType';
import { WeekDay } from '../assetConstants';
import { updateTimeslots } from '../assetService';
import CopyDayModal from '../modals/CopyDayModal';
import EasyPlannerModal from '../modals/EasyPlannerModal';
import { Asset } from '../models/Asset';
import { AssetTimeSlot } from '../models/AssetTimeSlot';

type Props = {
    initialValues: Asset;
    updateValues: () => void;
}

const week = [
    { day: WeekDay.Monday, text: 'Mån' },
    { day: WeekDay.Tuesday, text: 'Tis' },
    { day: WeekDay.Wednesday, text: 'Ons' },
    { day: WeekDay.Thursday, text: 'Tors' },
    { day: WeekDay.Friday, text: 'Fre' },
    { day: WeekDay.Saturday, text: 'Lör' },
    { day: WeekDay.Sunday, text: 'Sön' },
]

const WeekSchedule: FunctionComponent<Props> = ({initialValues, updateValues}) => {
    const { addToast } = useContext(ToastContext);
    const { apiBaseUrl } = useContext(ConfigContext);
    const { currentPropertyAssociationId } = useContext(PropertyAssociationContext);
    const [schedule, setSchedule] = useState<boolean[][]>();
    const [showCopyDay, setShowCopyDay] = useState<boolean>(false);
    const [showEasyPlanner, setShowEasyPlanner] = useState<boolean>(false);
        
    const toggleBlock = (i,j,value) => {
        if(!schedule) {
            return;
        }

        const values = schedule.map(x => ([...x]))
        values[i][j] = value;
        setSchedule(values);

        const data = mapFromSchedule(values);
        updateTimeslots(apiBaseUrl, currentPropertyAssociationId, initialValues.assetId, { timeslots: data }, 
            () => {
                updateValues();
            },
            () => {
                addToast('Tiderna kunde inte sparas', 'De har återställts till senast sparade versionen.', ToastMessageType.Error);
                setSchedule(mapToSchedule(initialValues.timeslots));
            }
        );
    }
    
    const setTimeslots = (data: AssetTimeSlot[], onSuccess: () => void, onFailure: () => void) => {
        updateTimeslots(apiBaseUrl, currentPropertyAssociationId, initialValues.assetId, { timeslots: data },
            () => {
                updateValues();
                const updatedValues = Object.assign({}, initialValues, { timeslots: data });
                setSchedule(mapToSchedule(updatedValues.timeslots))
                onSuccess();
            },
            () => {
                onFailure();
            }
        );
    }

    useEffect(() => {
        setSchedule(mapToSchedule(initialValues.timeslots));
    }, [])

    if(!schedule) {
        return null;
    }

    return (
        <Fragment>
            <strong>Hantera tider</strong>
            <div className="d-flex justify-content-between">
                <p className="mb-3">Lägg in bokningsbara tider per veckodag.</p>
                <div>
                    <Button text="Kopiera dag" isPrimary={false} additionalClassName="btn-secondary" onClick={() => setShowCopyDay(true)} />
                    <Button text="Snabbskapa schema" isPrimary={false} additionalClassName="btn-secondary" onClick={() => setShowEasyPlanner(true)} />
                </div>
            </div>

            <div className="schedule">
                <div className="schedule__header">
                    { week.map(x => <div key={x.day} className="schedule__tab">{x.text}<span>dag</span></div>)}
                </div>
                <div className="schedule__content">
                    { schedule.map((day, i) => (
                        <div key={i} className="schedule__day">
                            { day.map((x, j) => <button key={j} type="button" className={cx(['schedule__block', {'schedule__block--available': x}])} onClick={() => toggleBlock(i, j, !x)}>{j}:00</button>)}
                        </div>
                    ))}
                </div>
            </div>

            {showCopyDay && <CopyDayModal onClose={() => setShowCopyDay(false)} setTimeslots={setTimeslots} timeslots={initialValues.timeslots} />}
            {showEasyPlanner && <EasyPlannerModal onClose={() => setShowEasyPlanner(false)} setTimeslots={setTimeslots} timeslots={initialValues.timeslots} />}
        </Fragment>
    )
}

export default WeekSchedule

export function mapToSchedule(timeslots: AssetTimeSlot[]): boolean[][] {
    const schedule: boolean[][] = [];
    for(let i = 0; i < 7; i++) {
        schedule.push([
            false, false, false, false, false, false, false, false, 
            false, false, false, false, false, false, false, false, 
            false, false, false, false, false, false, false, false
        ]);
    }

    timeslots.map(x => {
        const day = x.weekDay;
        const startHour = fromTime(x.startTime);
        let endHour = fromTime(x.endTime);
        if(endHour === 0) {
            endHour = 24;
        }

        for(let hour = startHour; hour < endHour; hour++) {
            schedule[day][hour] = true;
        }
    });

    return schedule;
}

export function mapFromSchedule(schedule: boolean[][]): AssetTimeSlot[] {
    const timeslots: AssetTimeSlot[] = [];

    for(let day = 0; day < 7; day++) {
        const weekDay = week[day].day;
        for(let hour = 0; hour < 24; hour++) {
            if(!schedule[day][hour]) {
                continue;
            }

            const startTime = toTime(hour);
            const endTime = toTime(hour + 1);
            timeslots.push({ weekDay, startTime, endTime, endTimeNextDay: false, endTimeRestOfDay: false })
        }
    }

    return timeslots;
}

function toTime(hour: number): string {
    if(hour === 24) {
        return '0001-01-02T00:00:00';
    }

    if(hour < 10) {
        return `0001-01-01T0${hour}:00:00`;
    }

    return `0001-01-01T${hour}:00:00`;
}

function fromTime(time: string | undefined): number {
    if(!time || time.length < 19) {
        return 0;
    }

    if(time === '0001-01-02T00:00:00') {
        return 24;
    } 

    return parseInt(time.substr(11,2));
}