import * as yup from 'yup'
import TextField from '../../forms/TextField'
import FormModal from '../../modal/FormModal';
import { FunctionComponent, useContext, useState } from 'react';
import { ConfigContext } from '../../configuration/ConfigContext';
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext';
import { createAssetGroup } from '../assetGroupService';

type Props = {
    assetIds: string[];
    onClose: () => void;
    onSubmit: () => void;
}

type FormData = {
    name: string;
    description: string;
}

const DEFAULT_VALUES: FormData = {
    name: '',
    description: ''
}

const validationSchema = yup.object().shape({
    name: yup.string().required('Namn är obligatoriskt'),
    description: yup.string().required('Beskrivning är obligatoriskt')
})

const AddAssetGroupModal: FunctionComponent<Props> = ({ assetIds, onClose, onSubmit }) => {
    const { apiBaseUrl } = useContext(ConfigContext);
    const { currentPropertyAssociation } = useContext(PropertyAssociationContext);
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [error, setError] = useState<string | undefined>();

    const handleSubmit = ({name, description}: FormData) => {
        setIsSubmitting(true);

        createAssetGroup(
            apiBaseUrl,
            currentPropertyAssociation?.propertyAssociationId ?? '',
            { name, description, assetIds },
            () => {
                setIsSubmitting(false);
                onSubmit();
            },
            () => {
                setIsSubmitting(false);
                setError('Kunde inte skapa resursgrupp');
            }
        );
    }

    return (
        <FormModal initialValues={DEFAULT_VALUES} title="Skapa ny resursgrupp" submitText="Skapa" validationSchema={validationSchema} visible={true} isSubmitting={isSubmitting} onClose={onClose} onSubmit={handleSubmit}>
            {(values, errors, touched) => (
                <>
                    <p>De valda resurserna kommer att kopplas ihop i en ny resursgrupp. Ange namn och beskrivning för resursgruppen.</p>
                    <TextField label="Namn" name="name" values={values} errors={errors} touched={touched} />
                    <TextField label="Beskrivning" name="description" values={values} errors={errors} touched={touched} />
                    {error && <div className="alert alert-danger">{error}</div>}
                </>
            )}
        </FormModal>
    );
}

export default AddAssetGroupModal