import cx from 'classnames';
import { FunctionComponent, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import OverlayAlertModal from '../../modal/OverlayAlertModal';
import { getFileName } from '../pinboardMessageHelpers';

type Props = {
    oldFiles: string[] | undefined;
    newFiles: File[] | undefined;
    onAddFiles: (values) => void;
    onDeleteOldFile: (fileName: string) => void;
    onDeleteNewFile: (index: number) => void;
};

const MAX_SIZE = 20 * 1024 * 1024;

const PinboardMessageFileInput: FunctionComponent<Props> = ({ newFiles, oldFiles, onAddFiles, onDeleteOldFile, onDeleteNewFile }) => {
    const [error, setError] = useState<string | undefined>();

    const onDrop = useCallback((acceptedFiles: File[]) => {
        if (acceptedFiles.length === 0) {
            return;
        }

        const file = acceptedFiles[0];
        if (file.size > MAX_SIZE) {
            setError('Filen överskrider tillåten maxstorlek');
            return;
        }

        onAddFiles(file);
    }, []);

    const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
        accept: [ 'application/pdf', 'image/jpeg', 'image/png' ],
        multiple: false,
        onDrop,
    });

    const errorModal = error && <OverlayAlertModal heading='Ooops!' text={error} visible={true} onClick={() => setError(undefined)} />;

    return (
        <div className="mb-5">
            <div className='preview__file-block px-0'>
                <p className='mb-0'>Bifoga filer (valfritt)</p>
                
                {oldFiles && oldFiles.length > 0 && oldFiles.map((file, index) => renderFile(file, index, () => onDeleteOldFile(file)))}

                {newFiles && newFiles.length > 0 && newFiles.map((file, index) => renderFile(file.name, index, () => onDeleteNewFile(index)))}

                <div className="mb-5">
                    <div className={cx(['preview__file-block', 'px-0', { 'preview__file-block--accept': isDragAccept }, { 'preview__file-block--reject': isDragReject }])} {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p className='meta mt-1 mb-3'>Giltiga format är PDF, JPG och PNG (maximal storlek 20 MB)</p>
                        <button type='button' className='btn btn-lg btn-secondary btn-icon-upload mb-2'>
                            Ladda upp fil
                        </button>
                    </div>
                </div>

                {errorModal}
            </div>
        </div>
    );
};

const renderFile = (fileName: string, index: number, onDelete: () => void) => (
    <div key={`${fileName}_${index}`} className='file file--no-icon'>
        <div className='file__content'>
            {getFileName(fileName)}
        </div>
        <button type='button' className='file__delete' onClick={onDelete}>
            Ta bort
        </button>
    </div>
)

export default PinboardMessageFileInput;