import { AppInsightsContext, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { FunctionComponent, useEffect, useState } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import ErrorBoundary from '../common/error/ErrorBoundary';
import TrackPageViews from '../common/logging/TrackPageViews';
import { initiateAppInsights } from '../common/logging/appInsights';
import Admins from '../features/administrators/Admins';
import Apartments from '../features/apartments/Apartments';
import AssetMenu from '../features/assets/AssetMenu';
import Assets from '../features/assets/Assets';
import CreateAsset from '../features/assets/CreateAsset';
import EditAsset from '../features/assets/EditAsset';
import { AuthProvider } from '../features/authentication/AuthContext';
import AuthSignInCallback from '../features/authentication/AuthSignInCallback';
import AuthSignOut from '../features/authentication/AuthSignOut';
import AuthSignOutCallback from '../features/authentication/AuthSignOutCallback';
import AuthSilentRenew from '../features/authentication/AuthSilentRenew';
import BookingOverview from '../features/bookingOverview/BookingOverview';
import BookingArchive from '../features/bookings/BookingArchive';
import Bookings from '../features/bookings/Bookings';
import { ConfigProvider } from '../features/configuration/ConfigContext';
import { Configuration } from '../features/configuration/Configuration';
import { getConfiguration } from '../features/configuration/configService';
import InternalBooking from '../features/internalBooking/InternalBooking';
import Invitation from '../features/invitation/Invitation';
import Members from '../features/members/Members';
import MembersMenu from '../features/members/MembersMenu';
import PendingMembers from '../features/members/PendingMembers';
import { ConversationProvider } from '../features/messages/ConversationContext';
import Conversations from '../features/messages/Conversations';
import MobileNavigation from '../features/mobileNavigation/MobileNavigation';
import Navigation from '../features/navigation/Navigation';
import AdvertsMenu from '../features/navigation/subNavigation/AdvertsMenu';
import BookingsMenu from '../features/navigation/subNavigation/BookingsMenu';
import MessagesMenu from '../features/navigation/subNavigation/MessagesMenu';
import SettingsMenu from '../features/navigation/subNavigation/SettingsMenu';
import PinboardMessages from '../features/pinboardMessages/PinboardMessages';
import { PropertyAssociationProvider } from '../features/propertyAssociation/PropertyAssociationContext';
import ReleaseNotes from '../features/releaseNotes/ReleaseNotes';
import GeneralSettings from '../features/settings/GeneralSettings';
import SubscriptionBanner from '../features/subscription/SubscriptionBanner';
import { ToastProvider } from '../features/toaster/ToastContext';
import Toaster from '../features/toaster/Toaster';
import UserAdverts from '../features/userAdverts/UserAdverts';
import NotFound from '../pages/NotFound';
// import ShareAsset from '../features/assets/ShareAsset';
import AccessDenied from '../features/authentication/AccessDenied';
import SelectAsset from '../features/internalBooking/SelectAsset';
import GroupChats from '../features/messages/GroupChats';
import CreatePinboardMessage from '../features/pinboardMessages/CreatePinboardMessage';
import EditPinboardMessage from '../features/pinboardMessages/EditPinboardMessage';
import ViewPinboardMessage from '../features/pinboardMessages/ViewPinboardMessage';
import SwishSettings from '../features/settings/SwishSettings';
import AssetStatistics from '../features/statistics/BookingStatistics';
import VMA from '../features/vma/VMA';
import NoSubscription from '../pages/NoSubscription';
import TrialHasEnded from '../pages/TrialHasEnded';

const ManagerApp: FunctionComponent = () => {
    const [config, setConfig] = useState<Configuration>();
    const [initializationError, setInitializationError] = useState(false);

    useEffect(() => {
        getConfiguration(
            (data) => setConfig(data),
            () => setInitializationError(true)
        );
    }, [])

    if (initializationError) {
        return <div>Kunde inte ladda konfigurationen för applikationen!</div>
    }

    if (!config) {
        return null;
    }

    const reactPlugin = new ReactPlugin();
    const appInsights = initiateAppInsights(config.appInsightsInstrumentationKey, reactPlugin)
    
    return (
        <AppInsightsContext.Provider value={reactPlugin}>
            <ErrorBoundary key="global" appInsights={appInsights}>
                <ConfigProvider config={config}>
                    <AuthProvider config={config}>
                        <BrowserRouter basename="/">
                            <ToastProvider>
                                <TrackPageViews />
                                <PropertyAssociationProvider config={config}>
                                    <ConversationProvider>
                                        <Switch>
                                            <Route path="/invitations/:id">{/*No menu*/}</Route>
                                            <Route path="/trial-has-ended/">{/*No menu*/}</Route>
                                            <Route path="/no-subscription/">{/*No menu*/}</Route>
                                            <Route path="*">
                                                <Navigation />
                                                <MobileNavigation />
                                            </Route>
                                        </Switch>

                                        <SubscriptionBanner />

                                        {/* Sub navigation */}
                                        <Switch>
                                            <Route path="/adverts/*"><ErrorBoundary key="/submenu/adverts"><AdvertsMenu /></ErrorBoundary></Route>
                                            <Route path="/bookings/*"><ErrorBoundary key="/submenu/bookings"><BookingsMenu /></ErrorBoundary></Route>
                                            <Route path="/assets/*"><ErrorBoundary key="/submenu/assets"><AssetMenu /></ErrorBoundary></Route>
                                            <Route path="/messages/*"><ErrorBoundary key="/submenu/messages"><MessagesMenu /></ErrorBoundary></Route>
                                            <Route path="/members/*"><ErrorBoundary key="/submenu/members"><MembersMenu /></ErrorBoundary></Route>
                                            <Route path="/settings/*"><ErrorBoundary key="/submenu/settings"><SettingsMenu /></ErrorBoundary></Route>
                                        </Switch>

                                        {/* Main content */}
                                        <div id="content" className="app__content">
                                            <VMA />
                                            <Switch>
                                                {/* Routes used for authenication */}
                                                <Route exact={true} path="/signin-oidc" component={AuthSignInCallback} />
                                                <Route exact={true} path="/signout-oidc" component={AuthSignOut} />
                                                <Route exact={true} path="/signout-callback-oidc" component={AuthSignOutCallback} />
                                                <Route exact={true} path="/silentrenew" component={AuthSilentRenew} />

                                                {/* Legacy redirects */}
                                                <Redirect from="/messages/bulletin-board/" to="/adverts/information/" />
                                                <Redirect from="/resources/" to="/assets/" exact />
                                                <Redirect from="/resources/create" to="/assets/create" exact />
                                                <Redirect from="/resources/create/:id" to="/assets/create/:id" exact />
                                                <Redirect from="/resources/edit/:id" to="/assets/edit/:id" exact />

                                                {/* Invitation */}
                                                <Route path="/invitations/:id"><ErrorBoundary key="/invitation"><Invitation /></ErrorBoundary></Route>

                                                {/* Subscription states */}
                                                <Route path="/trial-has-ended/" exact><ErrorBoundary key="/trial-has-ended"><TrialHasEnded /></ErrorBoundary></Route>
                                                <Route path="/no-subscription/" exact><ErrorBoundary key="/no-subscription"><NoSubscription /></ErrorBoundary></Route>

                                                {/* Application routes */}
                                                <Route path="/" exact><Redirect to="/assets/" /></Route>
                                                <Route path="/access-denied/" exact><AccessDenied /></Route>
                                                <Route path="/adverts/information/" exact><ErrorBoundary key="/adverts/information/"><PinboardMessages /></ErrorBoundary></Route>
                                                <Route path="/adverts/information/edit/:id" exact><ErrorBoundary key="/adverts/information/edit/id"><EditPinboardMessage /></ErrorBoundary></Route>
                                                <Route path="/adverts/information/create/" exact><ErrorBoundary key="/adverts/information/new"><CreatePinboardMessage /></ErrorBoundary></Route>
                                                <Route path="/adverts/information/:id" exact><ErrorBoundary key="/adverts/information/id"><ViewPinboardMessage /></ErrorBoundary></Route>
                                                <Route path="/adverts/user-adverts/" exact><ErrorBoundary key="/adverts/user-adverts/"><UserAdverts /></ErrorBoundary></Route>
                                                <Route path="/assets/" exact><ErrorBoundary key="/assets/"><Assets /></ErrorBoundary></Route>
                                                <Route path="/assets/create" exact><ErrorBoundary key="/assets/create"><CreateAsset /></ErrorBoundary></Route>
                                                <Route path="/assets/create/:id" exact><ErrorBoundary key="/assets/create/id"><EditAsset initInEditMode={true} /></ErrorBoundary></Route>
                                                <Route path="/assets/edit/:id" exact><ErrorBoundary key="/assets/edit/id"><EditAsset /></ErrorBoundary></Route>
                                                <Route path="/assets/overview/:id" exact><ErrorBoundary key="/assets/overview/id"><BookingOverview /></ErrorBoundary></Route>
                                                {/* TODO: Återställ för resursdelning <Route path="/assets/share/:id" exact><ErrorBoundary key="/assets/share/id"><ShareAsset /></ErrorBoundary></Route> */}
                                                <Route path="/bookings/" exact><ErrorBoundary key="/bookings/"><Bookings /></ErrorBoundary></Route>
                                                <Route path="/bookings/archive/" exact><ErrorBoundary key="/bookings/archive/"><BookingArchive /></ErrorBoundary></Route>
                                                <Route path="/bookings/new/" exact><ErrorBoundary key="/bookings/new/"><SelectAsset /></ErrorBoundary></Route>
                                                <Route path="/bookings/new/:id" exact><ErrorBoundary key="/bookings/new/id"><InternalBooking /></ErrorBoundary></Route>
                                                <Route path="/bookings/statistics/" exact><ErrorBoundary key="/bookings/statistics/"><AssetStatistics /></ErrorBoundary></Route>
                                                <Route path="/members/" exact><ErrorBoundary key="/members/"><Members /></ErrorBoundary></Route>
                                                <Route path="/members/pending/" exact><ErrorBoundary key="/members/pending/"><PendingMembers /></ErrorBoundary></Route>
                                                <Route path="/messages/conversations/:id?" exact><ErrorBoundary key="/messages/conversations/id"><Conversations /></ErrorBoundary></Route>
                                                <Route path="/messages/groups/:id?" exact><ErrorBoundary key="/messages/groups/"><GroupChats /></ErrorBoundary></Route>
                                                <Route path="/release-notes/" exact><ErrorBoundary key="/release-notes/"><ReleaseNotes /></ErrorBoundary></Route>
                                                <Route path="/settings/" exact><ErrorBoundary key="/settings/"><GeneralSettings /></ErrorBoundary></Route>
                                                <Route path="/settings/admins/" exact><ErrorBoundary key="/settings/admins/"><Admins /></ErrorBoundary></Route>
                                                <Route path="/settings/apartments/" exact><ErrorBoundary key="/settings/apartments/"><Apartments /></ErrorBoundary></Route>
                                                <Route path="/settings/swish/" exact><ErrorBoundary key="/settings/swish/"><SwishSettings /></ErrorBoundary></Route>
                                                <Route path="*"><NotFound /></Route>
                                            </Switch>
                                        </div>
                                    </ConversationProvider>
                                </PropertyAssociationProvider>
                                <Toaster />
                            </ToastProvider>
                        </BrowserRouter>
                    </AuthProvider>
                </ConfigProvider>
            </ErrorBoundary>
        </AppInsightsContext.Provider>
    );
};

export default ManagerApp;
