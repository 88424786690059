import { Form, Formik } from 'formik'
import React, { FunctionComponent, useContext, useEffect, useState } from 'react'
import { Asset } from '../models/Asset'
import { Option } from '../../forms/models/Option'
import { ConfigContext } from '../../configuration/ConfigContext'
import FormikChangeSubscriber from '../../forms/FormikChangeSubscriber'
import RadioBoxDropDown from '../../forms/RadioBoxDropDown'
import { noValidationSchema } from '../../forms/validators'
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext'
import { MESSAGES_SAVE_FAILED, TimeSelectionType } from '../assetConstants'
import { getTimeString } from '../assetHelpers'
import { updateAsset } from '../assetService'

type Props = {
    initialValues: Asset;
    updateValues: (values) => void;
}

type FormData = {
    bookingMaxNumberOfBlocks: string;
}

const MaxLength: FunctionComponent<Props> = ({initialValues, updateValues}) => {
    const { currentPropertyAssociationId } = useContext(PropertyAssociationContext);
    const { apiBaseUrl } = useContext(ConfigContext);
    const [ error, setError ] = useState<string | undefined>();
    const [ formData, setFormData ] = useState<FormData>();
    const [ options, setOptions ] = useState<Option[]>([]);
    const [ showWarning, setShowWarning ] = useState<boolean>(false);

    const handleChange = (values: FormData) => {
        const { bookingMaxNumberOfBlocks } = values;
        updateAsset(apiBaseUrl, currentPropertyAssociationId, initialValues.assetId,
            {
                bookingMaxNumberOfBlocks,
            },
            (data) => {
                updateValues(data);
            },
            () => {
                setError(MESSAGES_SAVE_FAILED);
            }
        )
    }
    
    useEffect(() => {
        setFormData({ bookingMaxNumberOfBlocks: initialValues.bookingMaxNumberOfBlocks?.toString() });
        setOptions(getOptions(initialValues));
        setShowWarning(getShowWarning(initialValues));
    }, [initialValues])

    if(!formData) {
        return null;
    }

    return (
        <Formik initialValues={formData} enableReinitialize={true} validationSchema={noValidationSchema} validateOnMount={true} onSubmit={() => { /* Hanterat av event */ }}>
            {({values}) => (
                <Form>
                    <FormikChangeSubscriber onChange={handleChange} />
                    <div className="mb-5">
                        <strong>Maxtid</strong>
                        <p className="mb-3">Hur många {initialValues.timeSelectionType === TimeSelectionType.Day ? 'dygn' : 'timmar'} ska max gå att boka?</p>
                        <RadioBoxDropDown name="bookingMaxNumberOfBlocks" label="Välj maxtid" options={options} values={values as any} />
                        
                        { showWarning && <div className="alert alert-warning my-3">Vald maxtid överskrider den satta bokningsbegränsningen.</div>}
                        { error && <div className="alert alert-danger">{error}</div> }
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default MaxLength

const hourlyOptions: Option[] = [
    { text: '1 timme', value: '1' },
    { text: '2 timmar', value: '2' },
    { text: '3 timmar', value: '3' },
    { text: '4 timmar', value: '4' },
    { text: '5 timmar', value: '5' },
    { text: '8 timmar', value: '8' },
    { text: '12 timmar', value: '12' },
    { text: '1 dygn', value: '24' },
] 

function getOptions(asset: Asset): Option[] {
    const options: Option[] = [];
    
    if(asset.timeSelectionType === TimeSelectionType.Day) {
        for(let day=1; day < 8; day++) {
            options.push({ text: `${day} dygn`, value: day.toString() });
        }
    }
    else if(asset.requiredMinutes === 60) {
       return hourlyOptions;
    }
    else {
        for(let block=1; block < 6; block++) {
            const length = asset.requiredMinutes * block;
            options.push({ text: getTimeString(length), value: block.toString() })
        }
    }

    return options;
}

function getShowWarning({bookingMaxBlocksPerInterval, bookingMaxNumberOfBlocks, requiredMinutes, timeSelectionType}: Asset): boolean {
    if(bookingMaxBlocksPerInterval === 0) {
        return false;
    }

    if(timeSelectionType === TimeSelectionType.Startstop && (bookingMaxNumberOfBlocks * requiredMinutes / 60) > bookingMaxBlocksPerInterval) {
        return true;
    }

    if(timeSelectionType === TimeSelectionType.Day && bookingMaxNumberOfBlocks > bookingMaxBlocksPerInterval) {
        return true;
    }

    return false;
}