import { FunctionComponent } from 'react';
import { formatTime } from '../../../common/utils/dateUtils';
import { MINUTES_PER_PIXEL } from '../bookingOverviewConstants';
import { AssetAvailability } from '../models/AssetAvailability';
import styles from './Available.module.scss';

type Props = {
    timeslot: AssetAvailability;
}

const Available: FunctionComponent<Props> = ({timeslot: { startTime, endTime, startsPreviousDay, endsNextDay }}) => (
    <div className={styles.container} style={{ top: startTime / MINUTES_PER_PIXEL, height: (endTime - startTime) / MINUTES_PER_PIXEL }}>
        <div className={styles.start}>{startsPreviousDay ? <span className={styles.large}>→</span> : formatTime(startTime)}</div>
        <div className={styles.end}>{endsNextDay ? <span className={styles.large}>→</span> : formatTime(endTime)}</div>
    </div>
)

export default Available