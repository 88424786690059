import { deleteToApi, getFromApi, postStringToApi, putToApi } from '../../common/utils/apiHelper';
import { UpdateMemberRestrictionsRequest } from './modals/UpdateMemberRestrictionsRequest';
import { GetFilteredMembersResponse } from './models/GetMembersResponse';
import { GetMembersResponse } from './models/Member';

export const getMembers = (
    apiBaseUrl: string,
    propertyAssociationId: string,
    onSuccess: (data: GetMembersResponse) => void,
    onFail: () => void
): void => {
    getFromApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/members`, onSuccess, onFail)
}

export const getFilteredMembers = (apiBaseUrl: string, propertyAssociationId: string, onSuccess: (data: GetFilteredMembersResponse) => void, onFail: () => void): void => {
    getFromApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/members/filtered`, onSuccess, onFail);
};

export const getPendingMembers = (
    apiBaseUrl: string,
    propertyAssociationId: string,
    onSuccess: (data: GetMembersResponse) => void,
    onFail: () => void
): void => {
    getFromApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/members/pending`, onSuccess, onFail)
}

export const addMember = (
    apiBaseUrl: string,
    propertyAssociationId: string,
    userId: string,
    onSuccess: () => void,
    onFail: () => void,
): void => {
    postStringToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/members`, userId, onSuccess, onFail)
}

export const removeMember = (
    apiBaseUrl: string,
    propertyAssociationId: string,
    userId: string,
    onSuccess: () => void,
    onFail: () => void,
): void => {
    deleteToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/members/${userId}`, onSuccess, onFail)
}

export const updateMemberRestrictions = (
    apiBaseUrl: string,
    propertyAssociationId: string,
    userId: string,
    advertAccess: boolean,
    assetAccess: boolean,
    onSuccess: () => void,
    onFail: () => void
): void => { 
    const data: UpdateMemberRestrictionsRequest = { advertAccess, assetAccess };
    putToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/members/${userId}/restrictions`, data, onSuccess, onFail);
}