import { FunctionComponent } from 'react';
import OverlayConfirmModal from '../../modal/OverlayConfirmModal';

type Props = {
    onDelete: () => void;
    onClose: () => void;
}

const DeleteGuestAdminModal: FunctionComponent<Props> = ({onClose, onDelete}) => (
    <OverlayConfirmModal
        heading="Ta bort gästadministratör"
        text="Är du säker på att du vill ta bort gästen?"
        visible={true}
        okButtonText="Ja, ta bort gästen"
        onCancel={onClose}
        onSubmit={onDelete} />
)

export default DeleteGuestAdminModal