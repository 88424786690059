import { FunctionComponent, useEffect, useState } from 'react';
import { addDays, formatDateRange } from '../../../common/utils/dateUtils';
import styles from './DateSelector.module.scss';
import DatePicker from './DatePicker';

type Props = {
    startDate: Date;
    setStartDate: (date: Date) => void;
}

const DateSelector: FunctionComponent<Props> = ({startDate, setStartDate}) => {
    const [ displayDate, setDisplayDate ] = useState<string>();

    useEffect(() => {
        setDisplayDate(formatDateRange(startDate, 6));
    }, [startDate]);

    return (
        <div className={styles.container}>
            <button className={`${styles.button} ${styles.left}`} onClick={() => setStartDate(addDays(startDate, -7))}></button>
            <div className={styles.date}>
                {displayDate}
                <DatePicker startDate={startDate} setStartDate={setStartDate} />
            </div>
            <button className={styles.button + ' ' + styles.right}  onClick={() => setStartDate(addDays(startDate, 7))}></button>
        </div>
    )
}

export default DateSelector