import React, { FunctionComponent } from 'react'
import Button from '../forms/Button';

type Props = {
    cancelButtonText?: string;
    heading: string;
    okButtonText?: string;
    onCancel: () => void;
    onSubmit: () => void;
    text: string;
    visible: boolean;
}

const OverlayConfirmModal: FunctionComponent<Props> = ({ cancelButtonText = 'Nej', heading, okButtonText = 'Ja', onCancel, onSubmit, text, visible }) => {
    if(!visible) {
        return null;
    }

    return (
        <div className="cover">
            <div className="cover__content">
                <div className="cover__heading">{heading}</div>
                <div className="cover__text">
                    {text}
                </div>
                <Button text={cancelButtonText} additionalClassName="btn-lg" onClick={onCancel} />
                <Button text={okButtonText} additionalClassName="btn-lg btn-secondary" isPrimary={false} onClick={onSubmit} />
            </div>
        </div>
    )
}

export default OverlayConfirmModal