/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';
declare global {
    interface Window {
        appInsights:any;
    }
}

const browserHistory = createBrowserHistory({ basename: '' });
export const initiateAppInsights = (appInsightsInstrumentationKey: string, reactPlugin: any): any => {
    if(!appInsightsInstrumentationKey) {
        // NOTE: Do not initialize app insights if instrumentation key is not provided
        return;
    }

    const appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: appInsightsInstrumentationKey || '',
            extensions: [reactPlugin],
            extensionConfig: {
                [reactPlugin.identifier]: { history: browserHistory }
            }
        }
    });
    appInsights.loadAppInsights();
    window.appInsights = appInsights;
    return appInsights;
}