import { Fragment, FunctionComponent, useContext, useState } from 'react';
import * as Yup from 'yup';
import { ConfigContext } from '../../configuration/ConfigContext';
import BooleanCheckBox from '../../forms/BooleanCheckBox';
import Label from '../../forms/Label';
import TextField from '../../forms/TextField';
import FormModal from '../../modal/FormModal';
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext';
import { ToastContext } from '../../toaster/ToastContext';
import { addApartment } from '../apartmentService';
import { LocationResponse } from '../models/GetApartmentsResponse';

type Props = {
    location: LocationResponse,
    onClose: () => void,
    refreshApartments: () => void,
}

const DEFAULT_VALUES = {
    apartmentNumber: '',
    area: '',
    numberOfRooms: '',
    addMore: false,
}

const validationSchema = Yup.object().shape({
    apartmentNumber: Yup.string().required('Lägenhetsnummer måste anges'),
})

const CreateApartmentModal: FunctionComponent<Props> = ({location: {locationId, name}, onClose, refreshApartments}) => {
    const {apiBaseUrl} = useContext(ConfigContext);
    const {currentPropertyAssociationId} = useContext(PropertyAssociationContext);
    const {addToast} = useContext(ToastContext);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState<string | undefined>();

    const onSubmit = (values, {resetForm, setFieldValue}) => {
        setError(undefined);
        setIsSubmitting(true);
        addApartment(
            apiBaseUrl,
            currentPropertyAssociationId,
            {...values, locationId},
            () => {
                addToast(`Lägenhet ${values.apartmentNumber} tillagd på ${name}`);
                setIsSubmitting(false);
                refreshApartments();
                if(values.addMore) {
                    resetForm();
                    setFieldValue('addMore', true);    
                }
                else {
                    onClose();
                }
            },
            () => {
                setIsSubmitting(false);
                setError('Kunde inte lägga till lägenhet. Kontrollera informationen i formuläret och att lägenheten inte redan finns.');
            }
        )
    }

    return (
        <FormModal 
            initialValues={DEFAULT_VALUES}
            isSubmitting={isSubmitting}
            title={`Lägg till lägenhet på ${name}`}
            validationSchema={validationSchema}
            visible={true}
            onClose={onClose}
            onSubmit={onSubmit}
            submitText="Lägg till"
        >
            {({values, errors, touched}) => {
                const formProps = {values, errors, touched};
                return (
                    <Fragment>
                        <Label name="apartmentNumber" text="Lägenhetsnummer" />
                        <TextField name="apartmentNumber" type="number" {...formProps} />

                        <Label name="area" text="Area" />
                        <TextField name="area" type="number" {...formProps} />

                        <Label name="numberOfRooms" text="Antal rum" />
                        <TextField name="numberOfRooms" type="number" {...formProps} />

                        <BooleanCheckBox name="addMore" label="Lägg till fler lägenheter" />

                        { error && <div className="alert alert-danger">{error}</div> }
                    </Fragment>
                )
            }}
        </FormModal>
    );
}

export default CreateApartmentModal