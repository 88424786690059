import React, { FunctionComponent } from 'react'

type Props = {
    children: React.ReactNode;
    heading: string;
    label: string;
}

const NewBookingLayout: FunctionComponent<Props> = ({children, heading, label}) => (
    <div className="app__inner app__inner--paper paper paper--static">
        <div className="paper__header">
            <h1 className="paper__heading">
                {heading}
            </h1>
            <div className="text-center">
                <div className="paper__badge">{label}</div>
            </div>
        </div>
        <div className="paper__content">
            {children}
        </div>
    </div>
)

export default NewBookingLayout