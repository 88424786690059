import { FunctionComponent, ReactNode } from 'react';
import cx from 'classnames';

type Props = {
    buttons?: ReactNode;
    children: ReactNode | ReactNode[];
    mainClass?: string;
    heading: string;
    noBottomMargin?: boolean;
    preamble?: ReactNode | ReactNode[] | string;
    tightenUp?: boolean;
}

const PaperLayout: FunctionComponent<Props> = ({buttons, children, heading, mainClass = 'paper__main', noBottomMargin, preamble, tightenUp = false}) => (
    <div className={cx(['app__inner app__inner--paper paper paper--static', {'pt-5': tightenUp}, {'mb-0': noBottomMargin}])}>
        <h1 className={cx(['paper__heading d-none d-md-block', {'mb-0': tightenUp}, {'mb-0': noBottomMargin}])}>
            {heading}
        </h1>
        {preamble && (
            <div className="paper__preamble">
                {preamble}
            </div>
        )}
        <div className={`paper__content ${mainClass}`}>
            {children}
        </div>
        {buttons && (
            <div className="paper__buttons">
                {buttons}
            </div>
        )}
    </div>
)

export default PaperLayout
