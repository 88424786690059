import { FunctionComponent, useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { ConfigContext } from '../../configuration/ConfigContext';
import RadioButton from '../../forms/RadioButton';
import FormModal from '../../modal/FormModal';
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext';
import { addAssetToAssetGroup } from '../assetGroupService';
import { useAssets } from '../assetService';
import { AssetListItem } from '../models/AssetListItem';
import AddAssetGroupModal from './AddAssetGroupModal';

type Props = {
    assetId: string;
    assetGroupId?: string;
    onClose: () => void;
    updateValues: () => void;
    visible: boolean;
}

type FormData = {
    assetId: string;
}

const DEFAULT_VALUES: FormData = {
    assetId: '',
}

const validationSchema = Yup.object().shape({
    assetId: Yup.string().required('Välj en resurs att koppla ihop med')
});

const ConnectionModal: FunctionComponent<Props> = ({ assetId, assetGroupId, onClose, updateValues, visible }) => {
    const { apiBaseUrl } = useContext(ConfigContext);
    const { currentPropertyAssociation } = useContext(PropertyAssociationContext);
    const { assets, isLoading } = useAssets();
    const [assetIds, setAssetIds] = useState<string[]>();
    const [availableAssets, setAvailableAssets] = useState<AssetListItem[]>([]);
    const [error, setError] = useState<string | undefined>();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showAddAssetGroupModal, setShowAddAssetGroupModal] = useState(false);

    useEffect(() => {
        if (isLoading || !assets) {
            return;
        }

        setAvailableAssets(assets.filter(asset => asset.assetId !== assetId && (assetGroupId ? !asset.assetGroupId : true)));
    }, [assets]);

    const handleSubmit = (values) => {
        if (values.assetId === '' || currentPropertyAssociation?.propertyAssociationId === undefined) {
            return;
        }

        const onError = () => {
            setError('Kunde inte koppla ihop resurser');
            setIsSubmitting(false);
        }

        setIsSubmitting(true);
        if (assetGroupId) {
            addAssetToAssetGroup(
                apiBaseUrl,
                currentPropertyAssociation.propertyAssociationId,
                assetGroupId,
                { assetIds: [values.assetId] },
                handleSuccess,
                onError,
            );
            return;
        }

        const asset = assets?.find(asset => asset.assetId === assetId);
        if (!asset) {
            return;
        }

        if (asset.assetGroupId) {
            addAssetToAssetGroup(
                apiBaseUrl,
                currentPropertyAssociation.propertyAssociationId,
                asset.assetGroupId,
                { assetIds: [assetId] },
                handleSuccess,
                onError,
            );
            return;
        }

        setAssetIds([assetId, values.assetId]);
        setShowAddAssetGroupModal(true);
    }

    const handleSuccess = () => {
        updateValues();
        setShowAddAssetGroupModal(false);
        setIsSubmitting(false);
        onClose();
    }

    const handleClose = () => {
        setShowAddAssetGroupModal(false);
        setIsSubmitting(false);
        onClose();
    }

    if (isLoading) {
        return null;
    }

    return (
        <>
            <FormModal initialValues={DEFAULT_VALUES} title="Koppla ihop resurser" submitText="Koppla" validationSchema={validationSchema} visible={visible && !showAddAssetGroupModal} isSubmitting={isSubmitting} onClose={onClose} onSubmit={handleSubmit}>
                {() => (
                    <>
                        <p>Välj resurs att koppla ihop med:</p>
                        {availableAssets.length === 0 && <p><i>Det finns inga andra resurser att koppla ihop med</i></p>}
                        {availableAssets.map(asset => <RadioButton key={asset.assetId} name="assetId" label={asset.name} value={asset.assetId} />)}
                        {error && <div className="alert alert-danger">{error}</div>}
                    </>
                )}
            </FormModal>
            {showAddAssetGroupModal && assetIds && <AddAssetGroupModal assetIds={assetIds} onClose={handleClose} onSubmit={handleSuccess} />}
        </>
    )
}

export default ConnectionModal