import { useFormikContext } from 'formik';
import { FunctionComponent, useEffect } from 'react';

type Props = {
    onChange: (values, setFieldValue) => void;
}

const FormikChangeSubscriberWithActions: FunctionComponent<Props> = ({onChange}) => {
    const { values, setFieldValue } = useFormikContext();

    useEffect(() => {
        onChange(values, setFieldValue);
    }, [values]);

    return null;
}

export default FormikChangeSubscriberWithActions
