import { Fragment, FunctionComponent, useContext, useState } from 'react';
import * as Yup from 'yup';
import { BookableAsset } from '../../assets/models/Asset';
import { ConfigContext } from '../../configuration/ConfigContext';
import BooleanCheckBox from '../../forms/BooleanCheckBox';
import TextField from '../../forms/TextField';
import { checkedValidator, requiredValidator, stringConditionalValidator } from '../../forms/validators';
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext';
import { BookingTypes } from '../BookingTypes';
import BookingForm from '../components/BookingForm';
import DatePicker from '../components/DatePicker';
import NewBookingLayout from '../components/NewBookingLayout';
import ResourceSelector from '../components/ResourceSelector';
import Restrictions from '../components/Restrictions';
import SelectedTime from '../components/SelectedTime';
import TimeSelector from '../components/TimeSelector';
import { bookIntermediate, checkRestrictions } from '../internalBookingService';
import { BookingRestrictions } from '../models/BookingRestrictions';
import { useInternalBooking } from '../useInternalBooking';

const validationSchema = Yup.object().shape({
    endTime: requiredValidator,
    firstName: requiredValidator,
    hasConsent: checkedValidator,
    invoiceAddress: requiredValidator,
    invoiceCity: requiredValidator,
    invoicePhoneNumber: requiredValidator,
    invoiceZipCode: requiredValidator,
    lastName: requiredValidator,
    resourceId: stringConditionalValidator('automaticallyAssignResource', false, requiredValidator, null),
    identifier: requiredValidator,
    startTime: requiredValidator,
})

type Props = {
    asset?: BookableAsset,
    label: string,
    setBookingType: (bookingType: BookingTypes | undefined) => void,
    setBookingId: (bookingId: string) => void
}

type FormData = {
    automaticallyAssignResource: boolean,
    endTime: string,
    firstName: string,
    hasConsent: boolean,
    invoiceAddress: string,
    invoiceCity: string,
    invoicePhoneNumber: string,
    invoiceZipCode: string,
    lastName: string,
    resourceId: string,
    identifier: string,
    startTime: string,
}

const IntermediateBookingForm: FunctionComponent<Props> = ({asset, label, setBookingType, setBookingId}) => {
    const assetId = asset?.assetId ?? '';
    const { apiBaseUrl } = useContext(ConfigContext);
    const { currentPropertyAssociationId } = useContext(PropertyAssociationContext);
    const [calendarDate, setCalendarDate] = useState(new Date());
    const [hasStartRestrictions, setHasStartRestrictions] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [restrictions, setRestrictions] = useState<BookingRestrictions | undefined>();
    const [identifierError, setIdentifierError] = useState<string | undefined>();
    const { endOptions, endTimes, error, formikRef, hasRestrictions, selectedEndTime, selectedStartTime, setError, setSelectedEndTime, setSelectedStartTime, setStartTimes, startOptions, startTimes } = useInternalBooking(assetId, restrictions?.userId);

    const handleSubmit = (values: FormData) => {
        const { startTime, endTime, resourceId, firstName, lastName, invoiceAddress, invoiceZipCode, invoiceCity, invoicePhoneNumber, identifier } = values;
        const data = { assetId, assetObjectId: resourceId, endTime, firstName, invoiceAddress, invoiceCity, invoicePhoneNumber, invoiceZipCode, lastName, identifier, startTime };

        setIsSubmitting(true);
        bookIntermediate(
            apiBaseUrl,
            currentPropertyAssociationId,
            data,
            (bookingId) => {
                setBookingId(bookingId);
                setIsSubmitting(false);
            },
            () => {
                setError('Bokningen kunde inte genomföras.');
                setIsSubmitting(false);
            }
        );
    }

    const handleMonthChange = (activeStartDate) => {
        setCalendarDate(activeStartDate);
    }

    const checkIdentifier = () => {
        const identifier = formikRef.current?.values.identifier;

        if(!identifier || !identifier.match(/^P\d+$/) && !identifier.match(/^\d{8}-\d{4}$/)) {
            setIdentifierError('Ange ett personnummer med formatet ååååmmdd-xxxx eller ett P-nummer med formatet Pxxxxxx.');
            return;
        }

        setIdentifierError(undefined);
        checkRestrictions(
            apiBaseUrl,
            currentPropertyAssociationId, 
            assetId, 
            formikRef.current?.values.identifier, 
            (data: BookingRestrictions) => {
                setRestrictions(data);
                formikRef.current?.setFieldValue('identifier', data.ssn);
            },
            () => {
                setIdentifierError('Kunde inte kontrollera personnummer, verifiera att inmatade siffror är korrekta.');
            }
        );
    }

    if(!asset) {
        return null;
    }

    const customButton = !restrictions ? <button type="button" className="btn btn-primary btn-lg btn-next" onClick={checkIdentifier}>Nästa</button> : undefined

    return (
        <NewBookingLayout heading={asset.name} label={label}>
            <BookingForm
                startTimes={startTimes}
                endTimes={endTimes}
                selectedStartTime={selectedStartTime}
                error={error}
                
                formikRef={formikRef}
                initialValues={buildInitialValues(asset)}
                validationSchema={validationSchema}
                customButton={customButton}

                isSubmitting={isSubmitting}
                onSubmit={handleSubmit}
                setBookingType={setBookingType}
                setSelectedEndTime={setSelectedEndTime}
                setSelectedStartTime={setSelectedStartTime}
            >
                {(formProps) => (
                    !restrictions ? (
                        <Fragment>
                            <h2>Boknings- och faktureringsinformation</h2>
                            <p>Börja med att ange personnummer eller P-nummer för personen som bokningen gäller.</p>
                            <div className="paper__fields mb-5">
                                <TextField name="identifier" label="Personnummer / P-nummer" placeholder="ååååmmdd-xxxx eller Pxxxxxx" {...formProps} />
                            </div>
                            {identifierError && <div className="alert alert-danger">{identifierError}</div>}
                        </Fragment>
                    ):(
                        <Fragment>
                            <h2>Boknings- och faktureringsinformation</h2>
                            <p className="paper__text">Fyll i informationen om den person bokningen gäller. Informationen används som faktureringsunderlag. Säkerställ därför att faktureringsadress är korrekt.</p>

                            <div className="paper__fields mb-5">
                                <TextField name="firstName" label="Namn" {...formProps} />
                                <TextField name="lastName" label="Efternamn" {...formProps} />
                                <TextField name="invoiceAddress" label="Fakturaadress" {...formProps} />
                                <TextField name="invoiceZipCode" label="Postnummer" {...formProps} />
                                <TextField name="invoiceCity" label="Ort" {...formProps} />
                                <TextField name="invoicePhoneNumber" label="Telefonnummer" {...formProps} />
                            </div>
                            
                            <h2>Datum och tid</h2>
                            <p>Välj datum och tid för bokningen</p>
                            
                            <div className="paper__fields">
                                <DatePicker assetId={assetId} selectedStartTime={selectedStartTime} selectedEndTime={selectedEndTime} startTimes={startTimes} userId={restrictions?.userId} onMonthChange={handleMonthChange} setFieldValue={formikRef.current?.setFieldValue} setHasRestrictions={setHasStartRestrictions} setStartTimes={setStartTimes} />

                                <TimeSelector selectedStartTime={selectedStartTime} selectedEndTime={selectedEndTime} startOptions={startOptions} endOptions={endOptions} formProps={formProps} />

                                {(hasStartRestrictions || hasRestrictions) && <div className="alert alert-warning my-3">Bokningsrestriktionerna gör att användaren för denna månad inte har några lediga tider tillgängliga.</div>}

                                {!asset.automaticallyAssignResource && <ResourceSelector assetId={asset.assetId} formikRef={formikRef} selectedStartTime={selectedStartTime} selectedEndTime={selectedEndTime} setError={setError} formProps={formProps} />}

                                <SelectedTime selectedStartTime={selectedStartTime} selectedEndTime={selectedEndTime} />
                            </div>

                            { restrictions.restrictions && restrictions.userId && <Restrictions asset={asset} calendarDate={calendarDate} restrictions={restrictions.restrictions} /> }

                            {selectedEndTime && (
                                <div className="paper__text">
                                    <h2>Kostnad</h2>
                                    <p>{selectedEndTime.price ? `${selectedEndTime.price} kr` : 'Gratis'}</p>

                                    <h2>Finns samtycke/medgivande för bokning?</h2>
                                    <p>En förmedlingsbokning kan vara förenad med en kostnad och är därmed faktureringsgrundade. Vid en förmedlingsbokning agerar du ombud åt användaren. Säkerställ därför att ett godkännande för registering av personuppgifter har samlats in.</p>
                                    
                                    <BooleanCheckBox name="hasConsent" label="Jag är medveten om att bokningen av resursen är faktureringsgrundande och har säkerställt att samtycke/medgivande finns för att registrera personuppgifter i samband med bokningen." />
                                </div>
                            )}
                        </Fragment>
                    )
                )}
            </BookingForm>
        </NewBookingLayout>
    )
}

export default IntermediateBookingForm

const buildInitialValues = (asset: BookableAsset): FormData => ({
    automaticallyAssignResource: asset.automaticallyAssignResource,
    endTime: '',
    firstName: '',
    hasConsent: false,
    invoiceAddress: '',
    invoiceCity: '',
    invoicePhoneNumber: '',
    invoiceZipCode: '',
    lastName: '',
    resourceId: '',
    identifier: '',
    startTime: '',
})