import { FunctionComponent } from 'react';
import PaperLayout from '../layout/PaperLayout';
import { withPropertyAssociation } from '../propertyAssociation/withPropertyAssociation';
import Conversation from './components/Conversation';
import ConversationsList from './components/ConversationsList';
import NoConversations from './components/NoConversations';
import { useConversations } from './hooks/useConversations';

const Conversations: FunctionComponent = () => {
    const { appendConversations, conversation, conversations, id, isLoading, totalCount } = useConversations(false);

    return (
        <PaperLayout heading="Chattar" noBottomMargin>
            {conversations.length === 0 && !isLoading ? (
                <NoConversations heading="Här visas aktiva chattar" text="Just nu finns inga konversationer att visa." />
            ) : (
                <div className='conversations'>
                    <ConversationsList
                        activeConversationId={id}
                        conversations={conversations} 
                        onLoadMore={appendConversations}
                        hasMore={totalCount > conversations.length}
                    />
                                
                    <Conversation conversation={conversation} />
                </div>
            )}
        </PaperLayout>
    );
};

export default withPropertyAssociation(Conversations);
