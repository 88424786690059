import { FunctionComponent } from 'react'
import { LocationResponse } from '../models/GetApartmentsResponse'

type Props = {
    locations: LocationResponse[],
    setLocation: (location: LocationResponse | undefined) => void,
}

const LocationList: FunctionComponent<Props> = ({locations, setLocation}) => {
    return (
        <div className="mb-5">
            <h2>Adresser</h2>
            {locations ? (
                <table className="custom-table">
                    <thead className="custom-table__head">
                        <tr>
                            <th className="custom-table__header">Adress</th>
                            <th className="custom-table__header text-end">Antal lägenheter</th>
                        </tr>
                    </thead>
                    <tbody>
                        {locations.map(location => (
                            <tr key={location.locationId} className="custom-table__row" onClick={() => setLocation(location)} role="button">
                                <td>{location.name}</td>
                                <td className="text-end">{location.count}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>Inga adresser hittades.</p>
            )}
        </div>
    )
}

export default LocationList