import { Fragment, FunctionComponent, useContext, useState } from 'react';
import * as Yup from 'yup';
import { updateArrayItem } from '../../../common/utils/arrayUtil';
import { Asset } from '../models/Asset';
import { AssetObject } from '../models/AssetObject';
import { ConfigContext } from '../../configuration/ConfigContext';
import TextField from '../../forms/TextField';
import { requiredValidator } from '../../forms/validators';
import FormModal from '../../modal/FormModal';
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext';
import { MESSAGES_SAVE_FAILED } from '../assetConstants';
import { updateAssetObject } from '../assetService';

type Props = {
    asset: Asset;
    initialValues: AssetObject;
    onClose: () => void;
    updateValues: (values) => void;
    visible: boolean;
}

type FormData = {
    name: string;
}

const validationSchema = Yup.object().shape({
    name: requiredValidator,
})

const EditObjectModal: FunctionComponent<Props> = ({ asset, initialValues, onClose, updateValues, visible }) => {
    const { currentPropertyAssociationId } = useContext(PropertyAssociationContext);
    const { apiBaseUrl } = useContext(ConfigContext);
    const [ isSubmitting, setIsSubmitting] = useState(false);
    const [ error, setError ] = useState<string | undefined>();
    
    const onSubmit = (values: FormData) => {
        setIsSubmitting(true);
        updateAssetObject(apiBaseUrl, currentPropertyAssociationId, asset.assetId, initialValues.assetObjectId, values,
            (data: AssetObject) => {
                setIsSubmitting(false);
                const objects = updateArrayItem(asset.objects, data, (a, b) => a.assetObjectId === b.assetObjectId);
                updateValues(Object.assign({}, asset, { objects }))
                onClose();
            },
            () => {
                setIsSubmitting(false);
                setError(MESSAGES_SAVE_FAILED)
            },
        )
    }

    return (
        <FormModal initialValues={initialValues} title="Ändra namn" validationSchema={validationSchema} visible={visible} isSubmitting={isSubmitting} onClose={onClose} onSubmit={onSubmit}>
            {({values, errors, touched}) => {
                const formProps = {values, errors, touched};
                return (
                    <Fragment>
                        <p>Ange namn för denna resurs.</p>
                        <TextField name="name" {...formProps} />
                        
                        { error && <div className="alert alert-danger">{error}</div> }
                    </Fragment>
                )
            }}
        </FormModal>
    )
}

export default EditObjectModal