import { FunctionComponent } from 'react';
import Modal from './Modal';

type Props = {
    heading: string;
    onClose: () => void;
    text: string;
    visible: boolean;
}

const AlertModal: FunctionComponent<Props> = ({heading, onClose, text, visible}) => (
    <Modal visible={visible} title={heading} onClose={onClose} buttons={<button className="btn btn-primary" type="button" data-dismiss="modal" onClick={onClose}>Ok</button>}>
        {text}
    </Modal>
)

export default AlertModal
