import { Fragment, FunctionComponent, useContext, useState } from 'react';
import * as Yup from 'yup';
import { Asset } from '../models/Asset';
import { AssetObject } from '../models/AssetObject';
import { ConfigContext } from '../../configuration/ConfigContext';
import TextField from '../../forms/TextField';
import { requiredValidator } from '../../forms/validators';
import FormModal from '../../modal/FormModal';
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext';
import { MESSAGES_SAVE_FAILED } from '../assetConstants';
import { addAssetObject } from '../assetService';

type Props = {
    asset: Asset;
    onClose: () => void;
    updateValues: (values) => void;
    visible: boolean;
}

type FormData = {
    name: string;
}

const validationSchema = Yup.object().shape({
    name: requiredValidator,
})

const initialValues: FormData = {
    name: '',
}

const AddObjectModal: FunctionComponent<Props> = ({ asset, onClose, updateValues, visible }) => {
    const { currentPropertyAssociationId } = useContext(PropertyAssociationContext);
    const { apiBaseUrl } = useContext(ConfigContext);
    const [ isSubmitting, setIsSubmitting] = useState(false);
    const [ error, setError ] = useState<string | undefined>();

    const onSubmit = (values: FormData) => {
        setIsSubmitting(true);
        addAssetObject(apiBaseUrl, currentPropertyAssociationId, asset.assetId, values,
            (data: AssetObject) => {
                setIsSubmitting(false);
                updateValues(Object.assign({}, asset, { objects: [...asset.objects, data] }))
                onClose();
            },
            () => {
                setIsSubmitting(false);
                setError(MESSAGES_SAVE_FAILED)
            },
        )
    }

    return (
        <FormModal initialValues={initialValues} title="Lägg till parkeringsplats" validationSchema={validationSchema} visible={visible} isSubmitting={isSubmitting} onClose={onClose} onSubmit={onSubmit}>
            {({values, errors, touched}) => {
                const formProps = {values, errors, touched};
                return (
                    <Fragment>
                        <p>Ange namn för parkeringsplatsen</p>
                        <TextField name="name" {...formProps} />

                        { error && <div className="alert alert-danger">{error}</div> }
                    </Fragment>
                )
            }}
        </FormModal>
    )
}

export default AddObjectModal