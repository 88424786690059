import { FunctionComponent, useContext } from 'react';
import { useHistory } from 'react-router';
import { ConfigContext } from '../configuration/ConfigContext';
import PaperLayout from '../layout/PaperLayout';
import { PropertyAssociationContext } from '../propertyAssociation/PropertyAssociationContext';
import { withPropertyAssociation } from '../propertyAssociation/withPropertyAssociation';
import { ToastContext } from '../toaster/ToastContext';
import { ToastMessageType } from '../toaster/models/ToastMessageType';
import PinboardMessageForm from './components/PinboardMessageForm';
import { PinboardMessageFormModel, mapFormToCreateRequest } from './models/PinboardMessageFormModel';
import { createPinboardMessage } from './pinboardMessageService';

const DEFAULT_VALUES: PinboardMessageFormModel = {
    id: '',
    title: '',
    text: '',
    filePaths: [],
    files: [],
    canUserAnswer: '',
    target: '',
}

const CreatePinboardMessage: FunctionComponent = () => {
    const { apiBaseUrl } = useContext(ConfigContext);
    const { addToast } = useContext(ToastContext);
    const { currentPropertyAssociationId, currentArea, isRBHyr } = useContext(PropertyAssociationContext);
    const history = useHistory();

    const handleValidSubmit = (messageForm: PinboardMessageFormModel) => {
        createPinboardMessage(
            apiBaseUrl,
            currentPropertyAssociationId,
            mapFormToCreateRequest(messageForm),
            () => {
                addToast('Meddelandet har skapats.');
                history.push('/adverts/information/');
            },
            () => {
                addToast('Det gick inte att skapa meddelandet', undefined, ToastMessageType.Error);
            }
        );
    };

    const initialValues = {
        ...DEFAULT_VALUES,
        target: currentArea?.propertyAssociationAreaId ? '' : 'all',
    }

    return (
        <PaperLayout 
            heading="Skapa meddelande" 
            tightenUp={true} 
            mainClass="px-0 pt-3"
            preamble={(
                <>
                    <strong>Information till boende</strong>
                    <p>Publicerade meddelanden ses av fastighetens boende på anslagstavlan i Mitt Boende-appen. {isRBHyr ? 'Hyresvärden' : 'Styrelsen'} står som avsändare.</p>
                </>
            )}
        >
            <PinboardMessageForm isNew={true} messageForm={initialValues} onCancel={() => history.push('/adverts/information/')} onValidSubmit={handleValidSubmit} submitBtnText="Publicera" />
        </PaperLayout>
    );
}

export default withPropertyAssociation(CreatePinboardMessage)