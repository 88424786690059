/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as Yup from 'yup';

export const noValidationSchema = Yup.object().shape({});

// Validatorer för enstaka fält
export const requiredValidator = Yup.string().trim().required('Fältet är obligatoriskt');
export const checkedValidator = Yup.boolean().oneOf([true], 'Måste godkännas för att kunna gå vidare');
export const optionalEmailValidator = Yup.string().email('E-postadressen är ogiltig');
export const emailValidator = requiredValidator.nullable().email('E-postadressen är ogiltig');
export const numericValidator = Yup.number().min(0, 'Fältet får inte vara negativt').required('Fältet är obligatoriskt');
export const anyValidator = Yup.string().trim().required('Ett av ovanstående alternativ måste väljas');
export const phoneNumberValidator = Yup.string().trim()
  .matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Fältet skall vara ett telefonnummer')
  .required('Fältet är obligatoriskt');

// Personnummer
export const optionalNationalIdValidator = Yup.string().matches(/^(19|20)?[0-9]{6}[- ]?[0-9]{4}$/, 'Ange som ÅÅÅÅMMDD-NNNN');
export const nationalIdValidator = requiredValidator.matches(/^(19|20)?\d{2}(01|02|03|04|05|06|07|08|09|10|11|12)((0[1-9])|(1|2)[0-9]|(30|31))\d{4}$/, 'Ange som ÅÅÅÅMMDDNNNN');

// Organisationsnummer
export const optionalOrganizationNumberValidator = Yup.string().length(11, 'Ange som XXXXXX-XXXX');
export const organizationNumberValidator = requiredValidator.length(11, 'Ange som XXXXXX-XXXX');

// Postnummer
export const postalCodeValidator = Yup.string().test('postalCode', 'Måste anges med 5 siffror', (zipCode) => { return zipCode?.replace(' ', '').length === 5 });

// Komplexa validatorer
export const stringConditionalValidator = (fields: string | string[], condition: any, validator: any, elseValidator: any) => Yup.string().when(fields, { is: condition, then: validator, otherwise: elseValidator })
export const objectConditionalValidator = (fields: string | string[], condition: any, validator: any) => Yup.object().when(fields, { is: condition, then: validator })
