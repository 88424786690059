import { FunctionComponent } from 'react';
import { formatExtendedDate } from '../../../common/utils/dateUtils';
import { ExtendedDate } from '../models/ExtendedDate';

type Props = {
    selectedEndTime: ExtendedDate | null;
    selectedStartTime: ExtendedDate | null;
}

const SelectedTime: FunctionComponent<Props> = ({ selectedStartTime, selectedEndTime }) => {
    if (!selectedStartTime || !selectedEndTime) {
        return null;
    }

    return (
        <div>
            <h2 className="mt-5">Din valda tid</h2>
            <p>{formatExtendedDate(selectedStartTime)} - {formatExtendedDate(selectedEndTime)}</p>
        </div>
    )
}

export default SelectedTime