import { FunctionComponent } from 'react'

const NoSubscription: FunctionComponent = () => {
    return (
        <div className="app__inner app__inner--center">
            <article className="text-center">
                <div className="large-heading">Prenumeration saknas</div>
                <p className="preamble">
                    Ni har inte en aktiv prenumeration. För att fortsätta använda Mitt Boende behöver ni skaffa en prenumeration.
                </p>
                <a href="https://www.riksbyggen.se/fastighetsforvaltning/mitt-boende/" className="btn btn-primary">Skaffa Mitt Boende</a>
            </article>
        </div>
    )
}

export default NoSubscription