import { FunctionComponent } from 'react';

type Props = {
    label: string;
    name: string;
    isChecked: boolean;
    isDisabled?: boolean;
    onChange: () => void;
}

const SimpleCheckBox: FunctionComponent<Props> = ({isChecked, isDisabled = false, label, name, onChange}) => (
    <div className="form-check mb-3">
        <input className="form-check-input form-check-input--checkmark" id={name} type="checkbox" checked={isChecked} disabled={isDisabled} onChange={onChange} />
        <label className="form-check-label" htmlFor={name}>{label}</label>
    </div>
)

export default SimpleCheckBox