import { Fragment, FunctionComponent, useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Asset } from '../models/Asset';
import { ConfigContext } from '../../configuration/ConfigContext';
import MaxTextField from '../../forms/MaxTextField';
import { requiredValidator } from '../../forms/validators';
import FormModal from '../../modal/FormModal';
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext';
import { MESSAGES_SAVE_FAILED } from '../assetConstants';
import { updateAsset } from '../assetService';
import { AssetTypeDefinition } from '../assetTypes/assetTypes';

type Props = {
    defaults: AssetTypeDefinition;
    initialValues: Asset;
    onClose: () => void;
    updateValues: (values) => void;
    visible: boolean;
}

type FormData = {
    serviceInformationTitle: string;
}

const validationSchema = Yup.object().shape({
    serviceInformationTitle: requiredValidator.max(50, 'Får högst vara 50 tecken'),
})

const TitleModal: FunctionComponent<Props> = ({ defaults, initialValues, onClose, updateValues, visible }) => {
    const { currentPropertyAssociationId } = useContext(PropertyAssociationContext);
    const { apiBaseUrl } = useContext(ConfigContext);
    const [ isSubmitting, setIsSubmitting] = useState(false);
    const [ error, setError ] = useState<string | undefined>();
    const [ formData, setFormData ] = useState<FormData>();
    const [ isDefault, setIsDefault ] = useState(false);

    const onSubmit = (values: FormData) => {
        setIsSubmitting(true);
        updateAsset(apiBaseUrl, currentPropertyAssociationId, initialValues.assetId,
            {
                serviceInformation: {
                    serviceInformationTitle: values.serviceInformationTitle
                }
            },
            (data) => {
                setIsSubmitting(false);
                updateValues(data);
                onClose();
            },
            () => {
                setIsSubmitting(false);
                setError(MESSAGES_SAVE_FAILED);
            },
        )
    }

    useEffect(() => {
        let serviceInformationTitle = initialValues.serviceInformation?.serviceInformationTitle;
        if (!serviceInformationTitle) {
            serviceInformationTitle = defaults.serviceInformationTitle ?? '';
            setIsDefault(true);
        }
        else {
            setIsDefault(false);
        }
        setFormData({ serviceInformationTitle });
    }, [initialValues])

    return (
        <FormModal initialValues={formData} title="Rubrik för resurs" validationSchema={validationSchema} visible={visible} isSubmitting={isSubmitting} onClose={onClose} onSubmit={onSubmit}>
            {({values, errors, touched}) => {
                const formProps = {values, errors, touched};
                return (
                    <Fragment>
                        <p>Här kan du ge resursen en rubrik.</p>
                        <MaxTextField name="serviceInformationTitle" maxLength={50} {...formProps} />
                        { error && <div className="alert alert-danger">{error}</div>}
                        { isDefault && (
                            <p><i>Texten ovan är endast ett förslag. Anpassa den gärna så att den passar er specifika resurs.</i></p>
                        )}
                    </Fragment>
                )
            }}
        </FormModal>
    )
}

export default TitleModal