import { FunctionComponent, useEffect, useState } from 'react';
import { formatDayAndDate } from '../../../common/utils/dateUtils';
import { AssetAvailability } from '../models/AssetAvailability';
import { AssetObjectSchedule } from '../models/AssetSchedule';
import Available from './Available';
import Block from './Block';
import Cancelled from './Cancelled';
import styles from './Day.module.scss';

type Props = {
    assets: AssetObjectSchedule[];
    date: Date;
    focusedBookingId: string | undefined;
    objectFilter: string[];
    objects: string[];
    setFocusedBookingId: (bookingId: string | undefined) => void;
    setSelectedBookingId: (bookingId: string) => void;
    timeslots: AssetAvailability[];
}

const Day: FunctionComponent<Props> = ({ assets, date, focusedBookingId, objectFilter, objects, setFocusedBookingId, setSelectedBookingId, timeslots }) => {
    const [ filteredAssets, setFilteredAssets ] = useState<AssetObjectSchedule[]>([]);
    
    useEffect(() => {
        if(!assets) {
            return;
        }

        if(objectFilter && objectFilter.length === 0) {
            setFilteredAssets(assets);
            return;
        }

        setFilteredAssets(assets.filter(x => objectFilter.includes(x.assetObjectId)));
    }, [assets, objectFilter]);

    return (
        <div className={styles.container}>
            <div className={styles.day}>
                {formatDayAndDate(date)}
            </div>
            <div className={styles.wrapper}>
                <div className={styles.schedule}>
                    {timeslots.map(x => <Available key={x.startTime} timeslot={x} />)}

                    {renderBookings(filteredAssets, setSelectedBookingId, focusedBookingId, setFocusedBookingId, objects)}
                </div>
            </div>
            <div className={styles.cancelled}>
                {renderCancelledBookings(filteredAssets, setSelectedBookingId)}
            </div>
        </div>
    );
}

function renderBookings(filteredAssets: AssetObjectSchedule[], setSelectedBookingId: ((bookingId: string) => void) | undefined, focusedBookingId: string | undefined, setFocusedBookingId: (bookingId: string | undefined) => void, objects: string[]) {
    if(!filteredAssets || filteredAssets.length === 0) {
        return null;
    }

    if(filteredAssets.length > 1) {
        const width = `${100 / filteredAssets.length}%`;
        return filteredAssets.map((a, index) => (
            <div key={index} className={styles.asset} style={{ left: `${(100 / filteredAssets.length) * index}%`, width }}>
                {a.activeBookings.map(b => <Block key={b.bookingId} booking={b} color={objects.indexOf(a.assetObjectId)} setSelectedBookingId={setSelectedBookingId} focusedBookingId={focusedBookingId} setFocusedBookingId={setFocusedBookingId} />)}
            </div>
        ))
    }
    
    return filteredAssets[0].activeBookings.map(x => <Block key={x.bookingId} booking={x} setSelectedBookingId={setSelectedBookingId} focusedBookingId={focusedBookingId} setFocusedBookingId={setFocusedBookingId} />)
}

function renderCancelledBookings(filteredAssets: AssetObjectSchedule[], setSelectedBookingId: ((bookingId: string) => void)) {
    if(!filteredAssets || filteredAssets.length === 0) {
        return null;
    }

    if(filteredAssets.length > 1) {
        return filteredAssets.map(x => {
            if(!x.cancelledBookings || x.cancelledBookings.length === 0) {
                return null;
            }
            
            return (
                <div key={x.assetObjectId} className={styles.block}>
                    <div className={styles.subheading}>{x.name}</div>
                    {x.cancelledBookings.map(x => <Cancelled key={x.bookingId} booking={x} setSelectedBookingId={setSelectedBookingId} />)}
                </div>
            );
        });
    }

    return filteredAssets.map(x => x.cancelledBookings.map(x => <Cancelled key={x.bookingId} booking={x} setSelectedBookingId={setSelectedBookingId} />))
}

export default Day