import cx from 'classnames';
import { FunctionComponent, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ConfigContext } from '../../configuration/ConfigContext';
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext';
import { markConversationAsRead, markConversationAsUnread } from '../conversationService';
import styles from './ReadStatus.module.scss';

type Props = {
    conversationId: string;
    isUnread?: boolean;
}

const ReadStatus: FunctionComponent<Props> = ({conversationId, isUnread = false}) => {
    const { apiBaseUrl } = useContext(ConfigContext);
    const { currentPropertyAssociationId } = useContext(PropertyAssociationContext);
    const node = useRef<HTMLDivElement>(null);
    const [showOptions, setShowOptions] = useState(false);

	const handleClickOutSide = useCallback((e: any) => {
		if (node.current && !node.current.contains(e.target)) {
			setShowOptions(false)
		}
	}, [])

    const changeStatus = useCallback(() => {
        if(isUnread) {
            markConversationAsRead(apiBaseUrl, currentPropertyAssociationId, conversationId);
        } else {
            markConversationAsUnread(apiBaseUrl, currentPropertyAssociationId, conversationId);
        }

        setShowOptions(false);
    }, [conversationId, isUnread])

    useEffect(() => {
		document.addEventListener('mousedown', handleClickOutSide);
		return () => {
			document.removeEventListener('mousedown', handleClickOutSide);
		};
	}, [])

    return (
        <div className="d-flex">
            <button type="button" className={cx([styles['badge'], { [styles['badge--unread']]: isUnread }])} onClick={changeStatus}>{isUnread ? 'Ohanterad' : 'Hanterad'}</button>
            <div className="position-relative d-inline-block" ref={node}>
                <button type="button" className={styles.button} onClick={() => setShowOptions(!showOptions)} />
                {showOptions && (
                    <div className={styles.options}>
                        <button type='button' className={styles.option} onClick={changeStatus}>
                            Markera som {isUnread ? 'hanterad' : 'ohanterad'}
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ReadStatus