import cx from 'classnames';
import { FunctionComponent } from 'react';
import { formatConversationDate } from '../../../common/utils/dateUtils';
import { ConversationModel } from '../models/ConversationModel';
import { UserInfo } from '../../shared/models/UserInfo';

type Props = {
    conversation: ConversationModel;
    isActive: boolean;
    onSelectConversation: (conversationId: string) => void;
};

const ConversationsListItem: FunctionComponent<Props> = ({ conversation, onSelectConversation, isActive }) => {
    const { latestMessage } = conversation;
    const user = conversation?.participants && conversation.participants.length > 0 ? conversation.participants[0]?.user : undefined;

    const isGroup = !!conversation?.name;

    return (
        <div
            className={cx('conversations__list-item', { 'conversations__list-item--current': isActive })}
            key={conversation.conversationId}
            onClick={() => onSelectConversation(conversation?.conversationId)}
            onKeyPress={(keyEvent) => {
                    if (keyEvent.key === 'Enter') {
                    onSelectConversation(conversation?.conversationId);
                }
            }}
            role='button'
            tabIndex={0}
        >
            <div className='conversations__list-item-inner'>
                {renderImage(isGroup, user)}
                
                <div className='flex-shrink-1 flex-grow-1'>
                    <div className='d-flex'>
                        <h3 className='text-truncate m-0'>
                            {renderName(isGroup, conversation, user)}
                        </h3>
                        <div className='meta ms-auto'>
                            {formatConversationDate(conversation?.latestMessage?.created ?? conversation?.created)}
                            {conversation?.unread && (
                                <strong className='text-primary ms-1'>
                                    <em>Ny</em>
                                </strong>
                            )}
                        </div>
                    </div>
                    {renderTopic(isGroup, conversation)}
                    <p className='text-truncate m-0' title={latestMessage?.text || ''}>
                        {latestMessage?.text || ''}&nbsp;
                    </p>
                </div>
            </div>
        </div>
    );
};

const renderImage = (showGroups: boolean, user: UserInfo | undefined) => {
    if(showGroups) {
        return <div className='conversations__icon me-3 conversations__icon--group' />
    }

    if(user?.imagePath) {
        return <img className='conversations__icon me-3' src={user.imagePath} alt='' />
    }

    return <div className='conversations__icon me-3 conversations__icon--home' />
}

const renderName = (showGroups: boolean, conversation: ConversationModel, user: UserInfo | undefined) => {
    if(showGroups) {
        return conversation?.name || '';
    }

    return user?.displayName || `${user?.firstName} ${user?.lastName}`
}

const renderTopic = (showGroups: boolean, conversation: ConversationModel) => {
    if (showGroups) {
        return 'Chattgrupp';
    }

    if (!conversation) {
        return '';
    }

    if (conversation?.bookingId) {
        return 'Bokad resurs ';
    }

    if (conversation?.pinboardMessageId) {
        return 'Styrelsen informerar';
    }

    return '';
};

export default ConversationsListItem;
