import React, { useEffect, useState } from 'react';
import cx from 'classnames';

type Size = 'small' | 'medium' | 'large';

type Props = {
    imagePath?: string;
    firstName?: string;
    lastName?: string;
    extraCss?: string;
    size?: Size;
};

const UserIcon: React.FunctionComponent<Props> = ({ firstName, lastName, imagePath, extraCss = '', size = 'medium' }) => {
    const [sizeCss, setSizeCss] = useState('');
    const [initials, setInitials] = useState('');

    const getInitials = (firstName: string | undefined, lastName: string | undefined): string => {
        const firstNameTrimmed = firstName?.trim();
        const lastNameTrimmed = lastName?.trim();
        let initialsBuilder = '';
        if (firstNameTrimmed) {
            initialsBuilder += firstNameTrimmed[0];
        }
        if (lastNameTrimmed) {
            initialsBuilder += lastNameTrimmed[0];
        }
        return initialsBuilder || '?';
    };

    const getSizeCss = (picSize: Size) => {
        let sizeCss = '';
        switch (picSize) {
            case 'small':
                sizeCss = 'conversations__icon--small';
                break;
            case 'medium':
                sizeCss = '';
                break;
            case 'large':
                sizeCss = 'conversations__icon--large';
                break;
            default:
                break;
        }
        return sizeCss;
    };

    useEffect(() => {
        setSizeCss(getSizeCss(size));
    }, [size]);

    useEffect(() => {
        const newInitials = getInitials(firstName, lastName);
        setInitials(newInitials);
    }, [firstName, lastName]);

    if (imagePath?.trim()) {
        return <img src={imagePath} alt={`bild på ${initials}`} className={cx('conversations__icon', sizeCss, extraCss)} />;
    }

    return <div className={cx('conversations__icon', sizeCss, extraCss, { 'conversations__icon--user': !initials })}>{initials}</div>;
};

export default UserIcon;
