import Axios from 'axios';
import NProgress from 'nprogress';

export const activateGlobalProgressIndicator = (): void => {
    NProgress.configure({ easing: 'ease', speed: 500, showSpinner: true });

    const handleRequest = (value: any): any => {
        NProgress.start();
        return value;
    };

    const handleResponse = (value: any): any => {
        NProgress.done();
        return value;
    };

    const handleError = (error: any): any => {
        NProgress.done();
        return Promise.reject(error);
    };

    Axios.interceptors.request.use(handleRequest, handleError);
    Axios.interceptors.response.use(handleResponse, handleError);
};
