import React from 'react';
import cx from 'classnames';
import FieldError from './FieldError';
import { getIn } from 'formik';

type Props = {
    errors;
    touched;
    name: string;
    className?: string;
};

const RadioButtonGroup: React.FunctionComponent<Props> = ({ errors, name, touched, children, className = '' }) => {
    const error = getIn(errors, name);
    const isTouched = getIn(touched, name);
    return (
        <div className={cx('radio-group', className, { 'is-invalid': error && isTouched })}>
            {children}
            <FieldError errors={errors} name={name} touched={touched} />
        </div>
    );
};

export default RadioButtonGroup;
