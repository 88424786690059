import { FunctionComponent } from 'react'
import PaperLayout from '../layout/PaperLayout'
import { withPropertyAssociation } from '../propertyAssociation/withPropertyAssociation'
import { BookingsSortDirection, BookingsSortOrder } from './bookingConstants'
import { getActiveBookings } from './bookingService'
import BookingList from './components/BookingList'

const Bookings: FunctionComponent = () => (
    <PaperLayout heading="Bokade resurser">
        <BookingList
            isArchived={false}
            defaultSortOrder={BookingsSortOrder.Date}
            defaultSortDirection={BookingsSortDirection.Ascending}
            fetchFunction={getActiveBookings}
            noBookingsHeading="Här visas bokade resurser"
            noBookingsText="Just nu finns inga aktiva bokningar. När en resurs har bokats kommer det synas här."
            filterNoBookingsHeading="Hoppsan! Här var det tomt"
            filterNoBookingsText="Just nu finns inga aktiva bokningar för den valda resursen. När den bokats kommer det synas här."
        />
    </PaperLayout>
)

export default withPropertyAssociation(Bookings)