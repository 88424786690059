import { buildQueryString, deleteWithBodyToApi, getFromApi } from '../../common/utils/apiHelper';
import { GetUserAdvertResponse, GetUserAdvertsResponse } from './models/UserAdvert';

export const deleteUserAdvert = (apiBaseUrl: string, propertyAssociationId: string, advertId: string, reason: string, onSuccess: () => void, onFail: () => void): void => {
    deleteWithBodyToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/useradverts/${advertId}`, { reason }, onSuccess, onFail);
}

export const getUserAdverts = (apiBaseUrl: string, propertyAssociationId: string, areaId: string | undefined, onSuccess: (data: GetUserAdvertsResponse) => void, onFail: () => void): void => {
    getFromApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/useradverts${buildQueryString({ areaId })}`, onSuccess, onFail);
}

export const getDeletedUserAdverts = (apiBaseUrl: string, propertyAssociationId: string, onSuccess: (data: GetUserAdvertsResponse) => void, onFail: () => void): void => {
    getFromApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/useradverts/deleted`, onSuccess, onFail);
}

export const getUserAdvert = (apiBaseUrl: string, propertyAssociationId: string, advertId: string, onSuccess: (data: GetUserAdvertResponse) => void, onFail: () => void): void => {
    getFromApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/useradverts/${advertId}`, onSuccess, onFail);
}