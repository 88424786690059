import { PortalFunction, PortalFunctionStatus } from '../propertyAssociationEnums';
import { FunctionPermissionResponse } from './GetPropertyAssociationConfigResponse';

export type FunctionPermissions = {
    createPinboardMessage: boolean,
    updateBoardMembers: boolean,
}

const DEFAULT_FUNCTION_PERMISSIONS: FunctionPermissions = {
    createPinboardMessage: false,
    updateBoardMembers: false,
}

export const mapToFunctionPermissions = (response: FunctionPermissionResponse[]): FunctionPermissions => {
    const model = DEFAULT_FUNCTION_PERMISSIONS;

    response.forEach((permission) => {
        switch (permission.function) {
            case PortalFunction.CreatePinboardMessage:
                model.createPinboardMessage = permission.status === PortalFunctionStatus.Enabled;
                break;
            case PortalFunction.UpdateBoardMembers:
                model.updateBoardMembers = permission.status === PortalFunctionStatus.Enabled;
                break;
            default:
                break
        }
    });
    
    return model;
}