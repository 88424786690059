import { FunctionComponent } from 'react'

type Props = {
    message: string;
}

const ErrorMessage: FunctionComponent<Props> = ({message}) => (
    <div className="container mt-5">
        <h1 className="heading">Något gick fel <span role="img" aria-label="emoji">😕</span></h1>
        <div className="preamble">
            <p>{message}</p>
            <button className="btn btn-primary mt-4" onClick={() => document.location.reload()}>Ladda om sidan och försök igen.</button>
        </div>
    </div>
)

export default ErrorMessage