import { Form, Formik } from 'formik';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Asset } from '../models/Asset';
import { AssetTimeSlot } from '../models/AssetTimeSlot';
import { ConfigContext } from '../../configuration/ConfigContext';
import BooleanCheckBox from '../../forms/BooleanCheckBox';
import FormikChangeSubscriber from '../../forms/FormikChangeSubscriber';
import TimeField from '../../forms/TimeField';
import { noValidationSchema } from '../../forms/validators';
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext';
import { ToastContext } from '../../toaster/ToastContext';
import { TimeSelectionType, WeekDay } from '../assetConstants';
import { updateTimeslots } from '../assetService';
import { ASSETTYPE_PARKING } from '../assetTypes/parking';
import { AssetTypeDefinition } from '../assetTypes/assetTypes';
import { ToastMessageType } from '../../toaster/models/ToastMessageType';

type Props = {
    defaults: AssetTypeDefinition;
    initialValues: Asset;
    updateValues: (values) => void;
}

type FormData = {
    startTime?: string,
    endTime?: string,
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
}

const FullDaySchedule: FunctionComponent<Props> = ({defaults, initialValues, updateValues}) => {
    const { addToast } = useContext(ToastContext);
    const { apiBaseUrl } = useContext(ConfigContext);
    const { currentPropertyAssociationId } = useContext(PropertyAssociationContext);
    const [formData, setFormData] = useState<FormData | undefined>()

    const handleChange = (values: FormData) => {
        const data = mapFromFormData(values, defaults, initialValues);
        updateTimeslots(apiBaseUrl, currentPropertyAssociationId, initialValues.assetId, { timeslots: data }, 
            (timeslots) => {
                updateValues(Object.assign({}, initialValues, { timeslots }))
            },
            () => {
                addToast('Tiderna kunde inte sparas', 'De har återställts till senast sparade versionen.', ToastMessageType.Error);
                setFormData(mapToFormData(initialValues, defaults));
            }
        );
    }

    useEffect(() => {
        setFormData(mapToFormData(initialValues, defaults));
    }, [])

    if(!formData) {
        return null;
    }

    return (
        <Formik initialValues={formData} enableReinitialize={true} validationSchema={noValidationSchema} validateOnMount={false} onSubmit={() => { /* Hanterat av event */ }}>
            {({errors, touched, values}) => (
                <Form>
                    <FormikChangeSubscriber onChange={handleChange} />

                    { initialValues.timeSelectionType === TimeSelectionType.Day && !isParking(initialValues) && (
                        <div className="mb-5">
                            <strong>Tider</strong>
                            <p className="mb-3">Ange tid för in- och utcheckning</p>
                            <div className="d-flex">
                                <TimeField name="startTime" label="Incheckning" errors={errors} touched={touched} values={values} />
                                <TimeField name="endTime" label="Utcheckning" errors={errors} touched={touched} values={values} />
                            </div>
                        </div>
                    )}

                    { initialValues.timeSelectionType === TimeSelectionType.Day && isParking(initialValues) && (
                        <div className="mb-5">
                            <strong>Tider</strong>
                            <p className="mb-3">Ange tid när dygnsbokningen ska starta och sluta</p>
                            <div className="d-flex">
                                <TimeField name="startTime" label="Tid" errors={errors} touched={touched} values={values} />
                            </div>
                        </div>
                    )}

                    <div>
                        <strong>Veckodagar</strong>
                        <p className="mb-3">Under vilka veckodagar ska {defaults.nameDefinitiveForm} vara bokningsbar?</p>

                        <div className="clearfix">
                            <div className="float-start me-4"><BooleanCheckBox name="monday" label="Måndag" /></div>
                            <div className="float-start me-4"><BooleanCheckBox name="tuesday" label="Tisdag" /></div>
                            <div className="float-start me-4"><BooleanCheckBox name="wednesday" label="Onsdag" /></div>
                            <div className="float-start me-4"><BooleanCheckBox name="thursday" label="Torsdag" /></div>
                            <div className="float-start me-4"><BooleanCheckBox name="friday" label="Fredag" /></div>
                            <div className="float-start me-4"><BooleanCheckBox name="saturday" label="Lördag" /></div>
                            <div className="float-start me-4"><BooleanCheckBox name="sunday" label="Söndag" /></div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default FullDaySchedule

function isParking(asset: Asset) {
    return asset.assetType.tag === ASSETTYPE_PARKING;
}

function mapToFormData(initialValues: Asset, { endTimeNextDay }: AssetTypeDefinition): FormData {
    const formData: FormData = { monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false, sunday: false };
    if (!initialValues?.timeslots) {
        return formData;
    }

    initialValues.timeslots.map(x => {
        switch (x.weekDay) {
            case WeekDay.Monday:
                formData.monday = true;
                break;
            case WeekDay.Tuesday:
                formData.tuesday = true;
                break;
            case WeekDay.Wednesday:
                formData.wednesday = true;
                break;
            case WeekDay.Thursday:
                formData.thursday = true;
                break;
            case WeekDay.Friday:
                formData.friday = true;
                break;
            case WeekDay.Saturday:
                formData.saturday = true;
                break;
            case WeekDay.Sunday:
                formData.sunday = true;
                break;
        }
    });

    if(endTimeNextDay) {
        const firstSlot = initialValues.timeslots[0];
        formData.startTime = firstSlot?.startTime?.length === 19 ? firstSlot.startTime.slice(11,16) : '15:00';
        formData.endTime = firstSlot?.endTime?.length === 19 ? firstSlot.endTime.slice(11,16) : '12:00';
    }

    return formData;
}

function mapFromFormData(formData: FormData, { endTimeNextDay, endTimeRestOfDay }: AssetTypeDefinition, asset: Asset): AssetTimeSlot[] {
    const timeSlots: AssetTimeSlot[] = [];
    const { startTime, endTime } = formData;
    
    const props = { 
        endTime: endTime ? `0001-01-01T${endTime}:00` : undefined,
        endTimeNextDay, 
        endTimeRestOfDay,
        startTime: startTime ? `0001-01-01T${startTime}:00` : undefined,
    };

    // TODO: Detta bör på sikt flyttas till modellen istället för att särbehandlas via kod här
    if (isParking(asset) && asset.timeSelectionType === TimeSelectionType.Day) {
        props.endTime = `0001-01-01T${startTime}:00`;
        props.endTimeNextDay = true;
        props.endTimeRestOfDay = false;
    }
    else if (isParking(asset) && asset.timeSelectionType === TimeSelectionType.Startstop) {
        props.endTime = undefined;
        props.endTimeNextDay = false;
        props.endTimeRestOfDay = true;
        props.startTime = undefined;
    }

    if(formData.monday) {
        timeSlots.push({ weekDay: WeekDay.Monday, ...props })
    }
    if(formData.tuesday) {
        timeSlots.push({ weekDay: WeekDay.Tuesday, ...props })
    }
    if(formData.wednesday) {
        timeSlots.push({ weekDay: WeekDay.Wednesday, ...props })
    }
    if(formData.thursday) {
        timeSlots.push({ weekDay: WeekDay.Thursday, ...props })
    }
    if(formData.friday) {
        timeSlots.push({ weekDay: WeekDay.Friday, ...props })
    }
    if(formData.saturday) {
        timeSlots.push({ weekDay: WeekDay.Saturday, ...props })
    }
    if(formData.sunday) {
        timeSlots.push({ weekDay: WeekDay.Sunday, ...props })
    }

    return timeSlots;
}
