import { FunctionComponent } from 'react';
import styles from './Block.module.scss';
import cx from 'classnames';

type Props = {
    assetObjectId: string;
    index: number;
    isSelected: boolean;
    name: string;
    onChange: (assetObjectId: string, isSelected: boolean) => void;
}

const ObjectCheckbox: FunctionComponent<Props> = ({assetObjectId, index, isSelected, name, onChange}) => (
    <div className="form-check">
        <input className={cx(['form-check-input form-check-input--checkmark', { [styles[`color-${index}`]]: isSelected }])} id={assetObjectId} type="checkbox" checked={isSelected} onChange={() => onChange(assetObjectId, !isSelected)} />
        <label className="form-check-label" htmlFor={assetObjectId}>{name}</label>
    </div>
)

export default ObjectCheckbox