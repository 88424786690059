import cx from 'classnames';
import { Fragment, FunctionComponent, useContext, useState } from 'react';
import { useHistory } from 'react-router';
import useDebounce from '../../../common/utils/useDebounce';
import { ConfigContext } from '../../configuration/ConfigContext';
import Button from '../../forms/Button';
import { PropertyAssociationContext } from '../PropertyAssociationContext';
import { PropertyAssociationInfo } from '../models/PropertyAssociationInfo';
import { searchPropertyAssociation } from '../propertyAssociationService';
import PropertyAssociationItem from './PropertyAssociationItem';

const SearchPropertyAssociation: FunctionComponent = () => {
    const { apiBaseUrl } = useContext(ConfigContext);
    const { allPropertyAssociations, setCurrentPropertyAssociation } = useContext(PropertyAssociationContext);
    const history = useHistory();
    const [hasError, setHasError] = useState(false);
    const [hasMoreResults, setHasMoreResults] = useState<boolean>(false);
    const [isSearching, setIsSearching] = useState<boolean>(false);
    const [searchResult, setSearchResult] = useState<PropertyAssociationInfo[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');

    const onSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsSearching(true);
        setSearchTerm(event.target.value);
    }

    const appendSearchHits = () => {
        searchPropertyAssociation(apiBaseUrl, searchTerm, searchResult.length,
            (response) => {
                const result = [...searchResult, ...response.results];
                setHasMoreResults(result.length < response.totalCount);
                setSearchResult(result);
            },
            () => setHasError(true))
    }

    const setCurrentPropertyAssociationAndRedirect = (propertyAssociation: PropertyAssociationInfo) => {
        setCurrentPropertyAssociation(propertyAssociation);
        history.push('/');
    }

    useDebounce(() => {
        if (searchTerm.length > 0) {
            setHasError(false);
            searchPropertyAssociation(apiBaseUrl, searchTerm, 0,
                ({ results, totalCount }) => {
                    setHasMoreResults(results.length < totalCount);
                    setSearchResult(results);
                    setIsSearching(false);
                },
                () => setHasError(true))
        }
        else {
            setHasMoreResults(false);
            setSearchResult([]);
            setIsSearching(false);
        }
    }, [searchTerm], 750);  // NOTE: Debounce search with 750ms delay

    return (
        <Fragment>
            <div className="text-center">
                <h2 className="large-heading">Välj engagemang för administratörer</h2>
                <p className="my-4">Som administratör kan du antingen välja ett engagemang nedan som du är kopplad till eller söka och välja fritt bland samtliga engagemang.</p>
                <input type="text" placeholder="Sök bland alla engagemang" autoFocus={true} className="form-control search-field mx-auto" value={searchTerm} onChange={onSearchTermChange} />
            </div>
            <div className={cx(['boxlist', { 'search-progress': isSearching }])}>
                {hasError && <div className="w-100 alert alert-danger" role="alert">Ett fel uppstod då engagemang skulle läsas in.</div>}

                {searchTerm.length === 0 && (
                    <Fragment>
                        {allPropertyAssociations && allPropertyAssociations.length > 0 && <div className="w-100 ps-3"><strong>Mina engagemang:</strong></div>}
                        {allPropertyAssociations?.map(x => <PropertyAssociationItem key={x.propertyAssociationId} value={x} setCurrentPropertyAssociation={setCurrentPropertyAssociationAndRedirect} />)}
                    </Fragment>
                )}

                {searchTerm.length > 0 && searchResult.length === 0 && !isSearching && (
                    <div className="text-center w-100">
                        <p><i>Inga engagemang hittades för <strong>&quot;{searchTerm}&quot;</strong>.</i></p>
                    </div>
                )}

                {searchTerm.length > 0 && searchResult.length > 0 && (
                    <Fragment>
                        <div className="w-100 ps-3"><strong>Sökresultat:</strong></div>
                        {searchResult.map(x => <PropertyAssociationItem key={x.propertyAssociationId} value={x} setCurrentPropertyAssociation={setCurrentPropertyAssociationAndRedirect} />)}
                        {hasMoreResults && (<div className="w-100 text-center pt-3"><Button text="Visa fler träffar..." additionalClassName="btn-secondary" onClick={appendSearchHits} /></div>)}
                    </Fragment>
                )}
            </div>
        </Fragment>
    )
}

export default SearchPropertyAssociation