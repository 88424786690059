import { useFormikContext } from 'formik';
import { useEffect } from 'react';

const FormikChangeSubscriber = ({onChange}: {onChange: (values, dirty, isValid, errors) => void}): null => {
    const { dirty, values, isValid, errors } = useFormikContext();
    
    useEffect(() => {
        onChange(values, dirty, isValid, errors);
    }, [values]);

    return null;
}

export default FormikChangeSubscriber
