import { Fragment, FunctionComponent, useContext, useState } from 'react';
import { Asset } from '../models/Asset';
import { ConfigContext } from '../../configuration/ConfigContext';
import CheckBoxDropDown from '../../forms/CheckBoxDropDown';
import { noValidationSchema } from '../../forms/validators';
import FormModal from '../../modal/FormModal';
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext';
import { MESSAGES_SAVE_FAILED } from '../assetConstants';
import { updateAsset } from '../assetService';
import { AssetTypeDefinition } from '../assetTypes/assetTypes';

type Props = {
    defaults: AssetTypeDefinition;
    initialValues: Asset;
    onClose: () => void;
    updateValues: (values) => void;
    visible: boolean;
}

const KeywordsModal: FunctionComponent<Props> = ({ defaults, initialValues, onClose, updateValues, visible }) => {
    const { currentPropertyAssociationId } = useContext(PropertyAssociationContext);
    const { apiBaseUrl } = useContext(ConfigContext);
    const [ isSubmitting, setIsSubmitting] = useState(false);
    const [ error, setError ] = useState<string | undefined>();

    const onSubmit = (values: Asset) => {
        setIsSubmitting(true);
        const filteredValues = values.serviceInformation?.serviceInformationKeywords?.filter(x => defaults.options.includes(x));
        updateAsset(apiBaseUrl, currentPropertyAssociationId, initialValues.assetId,
            {
                serviceInformation: {
                    serviceInformationKeywords: filteredValues
                }
            },
            (data) => {
                setIsSubmitting(false);
                updateValues(data);
                onClose();
            },
            () => {
                setIsSubmitting(false);
                setError(MESSAGES_SAVE_FAILED)
            },
        )
    }

    return (
        <FormModal initialValues={initialValues} title="Bekvämligheter" validationSchema={noValidationSchema} visible={visible} isSubmitting={isSubmitting} onClose={onClose} onSubmit={onSubmit}>
            {({values}) => {
                return (
                    <Fragment>
                        <p>Välj de bekvämligheter som finns i anslutning till resursen.</p>
                        <CheckBoxDropDown options={defaults.options} name="serviceInformation.serviceInformationKeywords" label="Välj bekvämligheter" values={values} />
                        { error && <div className="alert alert-danger">{error}</div>}
                    </Fragment>
                )
            }}
        </FormModal>
    )
}

export default KeywordsModal