import { FunctionComponent } from 'react';
import { BookableAsset } from '../../assets/models/Asset';

type Props = {
    asset: BookableAsset;
    onClick: () => void;
}

const AssetItem: FunctionComponent<Props> = ({ asset: { assetId, imageThumbnailPath, name }, onClick }) => (
    <button key={assetId} className="boxlist__item" onClick={onClick}>
        <img src={imageThumbnailPath} alt="" className="boxlist__image" />
        {name}
    </button>
)

export default AssetItem