import cx from 'classnames';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Asset } from '../assets/models/Asset';
import CheckBox from './CheckBox';

type Props = {
    label: string;
    name: string;
    options: string[];
    values: Asset;
}

const CheckBoxDropDown: FunctionComponent<Props> = ({label, name, options, values}) => {
    const [expanded, setExpanded] = useState(false);
    const [text, setText] = useState(label);
    
    useEffect(() => {
        const length = values.serviceInformation?.serviceInformationKeywords?.length ?? 0;
        if(length > 0) {
            setText(`${length} valda`);
        }
        else {
            setText(label);
        }
    }, [values])

    return (
        <div className="checkdown">
            <button className={cx(['checkdown__button', {'checkdown__button--expanded': expanded} ])} type="button" onClick={() => setExpanded(!expanded)}>
                {text}
            </button>
            { expanded && (
                <ul className="checkdown__list">
                    {options.map(x => <li key={x} className="checkdown__item"><CheckBox name={name} label={x} value={x} /></li>)}
                </ul>
            )}
        </div>
    )
}

export default CheckBoxDropDown