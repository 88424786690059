import { FunctionComponent, useContext, useEffect, useState } from 'react';
import ErrorMessage from '../../../common/error/ErrorMessage';
import { ConfigContext } from '../../configuration/ConfigContext';
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext';
import { GetUserAdvertsResponse } from '../models/UserAdvert';
import { getDeletedUserAdverts } from '../userAdvertService';

type Prop = {
    selectAdvertId: (advertId: string) => void;
}

const ListDeletedUserAdverts: FunctionComponent<Prop> = ({selectAdvertId}) => {
    const { apiBaseUrl } = useContext(ConfigContext);
    const { currentPropertyAssociationId } = useContext(PropertyAssociationContext);
    const [response, setResponse] = useState<GetUserAdvertsResponse | undefined>();
    const [error, setError] = useState<string | undefined>();

    useEffect(() => {
        getDeletedUserAdverts(apiBaseUrl, currentPropertyAssociationId, setResponse, () => setError('Kunde inte ladda anslagstavla'));
    }, []);

    if(error) {
        return <ErrorMessage message={error} />
    }

    if(!response) {
        return null;
    }

    if(response.adverts.length === 0) {
        return <p><i>Inga borttagna annonser</i></p>
    }

    return (
        <table className="custom-table">
            <thead className="custom-table__head">
                <tr>
                    <th className="custom-table__header">Rubrik</th>
                    <th className="custom-table__header text-end">Användare</th>
                </tr>
            </thead>
            <tbody>
                {response.adverts.map(({advertId, title, user}) => (
                    <tr key={advertId} className="custom-table__row" onClick={() => { selectAdvertId(advertId) }} role="button">
                        <td>{title}</td>
                        <td className="text-end">{user.firstName} {user.lastName}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default ListDeletedUserAdverts