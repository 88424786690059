import { TimeSelectionType } from '../assetConstants';
import { AssetTypeDefinition } from './assetTypes';

export const ASSETTYPE_APARTMENT = 'Apartment';

export const DEFAULTS_APARTMENT: AssetTypeDefinition = {
    alwaysUseDays: true,
    assetTypeTag: ASSETTYPE_APARTMENT,
    backdrop: '/images/defaults/backdrop/RB_gemensamma-resurser_gastlagenhet.png',
    canChangeToDaily: false,
    canChangeToHourly: false,
    canChangeToInterval: false,
    endTimeNextDay: true,
    endTimeRestOfDay: false,
    image: '/images/defaults/preview/RB_gemensamma-resurser_gastlagenhet.png',
    isSingleton: true,
    name: 'Gästlägenhet',
    nameDefinitiveForm: 'lägenheten',
    namePlural: 'lägenheter',
    nameSingular: 'lägenhet',
    options: [
        'Wifi',
        'Toalett',
        'Dusch',
        'Kök/pentry',
        'Uteplats/balkong',
        'Strykjärn',
        'Parkering ingår',
        'Husdjur tillåtna',
    ],
    prefixName: true,
    serviceInformationText: 'Ex. Gästlägenhet med sängar för två personer i källarplan. Extrasäng finns. Tillgång till egen WC med dusch i anslutning till lägenheten.',
    serviceInformationTitle: 'Känn dig som hemma!',
    thumbnail: '/images/defaults/thumbnails/RB_gemensamma-resurser_gastlagenhet.png',
    timeSelectionType: TimeSelectionType.Day,
}