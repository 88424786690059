import { FunctionComponent, useState } from 'react'
import { Asset } from '../models/Asset'
import { AssetObject } from '../models/AssetObject'
import AddObjectModal from '../modals/AddObjectModal'
import DeleteObjectModal from '../modals/DeleteObjectModal'
import EditObjectModal from '../modals/EditObjectModal'
import { AssetTypeDefinition } from '../assetTypes/assetTypes'

type Props = {
    defaults: AssetTypeDefinition;
    asset: Asset;
    updateValues: (values) => void;
}

const AdministerObjects: FunctionComponent<Props> = ({asset, defaults, updateValues}) => {
    const [showAddModal, setShowAddModal] = useState(false);
    const [objectToEdit, setObjectToEdit] = useState<AssetObject | undefined>();
    const [objectToDelete, setObjectToDelete] = useState<AssetObject | undefined>();

    return (
        <div className="mt-5">
            <strong>Hantera {defaults.namePlural}</strong>
            <p className="mb-2">Finns det fler än en {defaults.nameSingular} att boka behöver du lägga till dem här.</p>
            <div className="mb-3">
                { asset.objects?.map((assetObject) => (
                    <div key={assetObject.assetObjectId} className="recipient my-2">
                        <button type="button" className="recipient__input" onClick={() => setObjectToEdit(assetObject)}>{assetObject.name}</button>
                        <button type="button" className="recipient__change" onClick={() => setObjectToEdit(assetObject)}>Ändra</button>
                        { asset.objects.length > 1 && <button type="button" className="recipient__delete" onClick={() => setObjectToDelete(assetObject)}>Ta bort</button> }
                    </div>
                ))}
            </div>
            <button className="btn btn-lg btn-plus btn-secondary" onClick={() => setShowAddModal(true)}>Lägg till <span className="desktop-only">ytterligare</span> {defaults.nameSingular}</button>

            { showAddModal && <AddObjectModal asset={asset} updateValues={updateValues} visible={true} onClose={() => setShowAddModal(false) } /> }
            { objectToEdit && <EditObjectModal asset={asset} initialValues={objectToEdit} updateValues={updateValues} visible={true} onClose={() => setObjectToEdit(undefined) } /> }
            { objectToDelete && <DeleteObjectModal asset={asset} initialValues={objectToDelete} updateValues={updateValues} onClose={() => setObjectToDelete(undefined) } /> }
        </div>
    )
}

export default AdministerObjects