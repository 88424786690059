import { Fragment, FunctionComponent, useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Asset } from '../models/Asset';
import { ConfigContext } from '../../configuration/ConfigContext';
import RadioBoxDropDown from '../../forms/RadioBoxDropDown';
import RadioButton from '../../forms/RadioButton';
import TextField from '../../forms/TextField';
import FormModal from '../../modal/FormModal';
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext';
import { BookingCancellationTerms, MESSAGES_SAVE_FAILED, TimeSelectionType } from '../assetConstants';
import { getIntervalOptions, isBookingTypePerHour, isBookingTypePerInterval } from '../assetHelpers';
import { updateAsset } from '../assetService';
import { ASSETTYPE_PARKING } from '../assetTypes/parking';
import { AssetTypeDefinition } from '../assetTypes/assetTypes';

type Props = {
    defaults: AssetTypeDefinition;
    initialValues: Asset;
    onClose: () => void;
    updateValues: (values) => void;
    visible: boolean;
}

type FormData = {
    requiredMinutes: number;
    bookingType: BookingType;
    price: number;
    priceType: PriceType;
}

enum BookingType {
    Hourly = '1',
    Interval = '2',
    Daily = '3',
}

enum PriceType {
    Free = '1',
    Payment = '2',
}

const intervalOptions = getIntervalOptions(60, 1380, 60);

const validationSchema = Yup.object().shape({})
    .test('price', null as any, x => {
        if (x.priceType === PriceType.Free || parseInt(x.price) > 0) {
            return true;
        }

        return new Yup.ValidationError('Ett pris måste fyllas i', null, 'price');
    })
    .test('requiredMinutes', null as any, x => {
        if (x.bookingType !== BookingType.Interval || parseInt(x.requiredMinutes) > 0) {
            return true;
        }

        return new Yup.ValidationError('Längd på tidsintervall måste fyllas i', null, 'requiredMinutes');
    });

const PriceModal: FunctionComponent<Props> = ({ defaults, initialValues, onClose, updateValues, visible }) => {
    const { currentPropertyAssociationId } = useContext(PropertyAssociationContext);
    const { apiBaseUrl } = useContext(ConfigContext);
    const [ error, setError ] = useState<string | undefined>();
    const [ formData, setFormData ] = useState<FormData>();
    const [ initialBookingType, setInitialBookingType ] = useState<BookingType | undefined>();
    const [ isSubmitting, setIsSubmitting] = useState(false);

    const onSubmit = (values: FormData) => {
        setIsSubmitting(true);
        const priceType = values.priceType;
        const price = priceType === PriceType.Free ? 0 : values.price;
        const timeSelectionType = getTimeSelectionType(values.bookingType);
        const requiredMinutes = getRequiredMinutes(values.requiredMinutes, values.bookingType, initialValues);

        const payload: any = {
            timeSelectionType,
            requiredMinutes,
            price,
        };

        if(price === 0) {
            payload.bookingCancellationTerms = BookingCancellationTerms.Free;
            payload.lateCancellationFeePercentage = 0;
        }

        updateAsset(apiBaseUrl, currentPropertyAssociationId, initialValues.assetId,
            payload,
            (data) => {
                setIsSubmitting(false);
                updateValues(data);
                onClose();
            },
            () => {
                setIsSubmitting(false);
                setError(MESSAGES_SAVE_FAILED);
            }
        )
    }

    useEffect(() => {
        const { price, requiredMinutes } = initialValues;
        const priceType = price === 0 ? PriceType.Free : PriceType.Payment;
        
        let bookingType = BookingType.Daily;
        if(isBookingTypePerHour(initialValues)) {
            bookingType = BookingType.Hourly;
        }
        else if(isBookingTypePerInterval(initialValues)) {
            bookingType = BookingType.Interval;
        }

        setInitialBookingType(bookingType);
        setFormData({ priceType, price, requiredMinutes, bookingType });
    }, [initialValues])

    if(!formData) {
        return null;
    }

    return (
        <FormModal initialValues={formData} title="Pris" validationSchema={validationSchema} visible={visible} isSubmitting={isSubmitting} onClose={onClose} onSubmit={onSubmit}>
            {({errors, touched, values}) => {
                return (
                    <Fragment>
                        { (defaults.canChangeToDaily || defaults.canChangeToHourly || defaults.canChangeToInterval) && (
                            <p>Hur ska resursen bokas?</p>
                        )}

                        { defaults.canChangeToHourly && (
                            <div className="my-3">
                                <RadioButton label="Per timme" name="bookingType" value={BookingType.Hourly} />
                            </div>
                        )}

                        { defaults.canChangeToDaily && (
                            <div className="my-3">
                                <RadioButton label="Per dygn" name="bookingType" value={BookingType.Daily} />
                            </div>
                        )}

                        { defaults.canChangeToInterval && (
                            <div className="my-3">
                                <RadioButton label="Per tidsintervall" name="bookingType" value={BookingType.Interval} />
                                { values.bookingType === BookingType.Interval && (
                                    <div className="--indent-double">
                                        <RadioBoxDropDown name="requiredMinutes" label="Välj tidsintervall" options={intervalOptions} values={values} />
                                    </div>
                                )}
                            </div>
                        )}

                        <p>Ange hur resursbokningen kommer debiteras.</p>
                        <div className="my-3">
                            <RadioButton label="Resursen är gratis" name="priceType" value={PriceType.Free} />
                        </div>
                        <div className="my-3">
                            <RadioButton label={getPriceTypeLabel(values, initialValues)} name="priceType" value={PriceType.Payment} />
                            { values.priceType === PriceType.Payment && (
                                <div className="--indent-double">
                                    <TextField name="price" type="number" width={25} errors={errors} touched={touched} values={values} />
                                </div>
                            )}
                        </div>

                        { (initialBookingType !== values.bookingType && (initialBookingType === BookingType.Daily || values.bookingType === BookingType.Daily)) && <div className="alert alert-danger">Om du byter hur resursen bokas kommer bokningsbara tider att nollställas.</div> }

                        { error && <div className="alert alert-danger">{error}</div> }
                    </Fragment>
                )
            }}
        </FormModal>
    )
}

export default PriceModal

function getPriceTypeLabel(values: any, asset: Asset): string {
    if(values.bookingType === BookingType.Hourly) {
        return 'Debiteras per timme (pris i SEK)';
    }

    if(asset.assetType.tag === ASSETTYPE_PARKING) {
        return 'Debiteras per dygn (pris i SEK)';
    }

    return 'Debiteras per tillfälle (tidsintervall) (pris i SEK)';
}

function getTimeSelectionType(bookingType: BookingType): TimeSelectionType {
    switch (bookingType) {
        case BookingType.Daily:
            return TimeSelectionType.Day;
        case BookingType.Hourly:
        case BookingType.Interval:
        default:
            return TimeSelectionType.Startstop;
    }
}

function getRequiredMinutes(formMinutes: number | string, bookingType: BookingType, asset: Asset): number {
    if (bookingType === BookingType.Hourly) {
        return 60;
    }

    if (bookingType === BookingType.Interval) {
        return typeof(formMinutes) === 'string' ? parseInt(formMinutes) : formMinutes;
    }

    if (bookingType === BookingType.Daily && asset.timeSelectionType === TimeSelectionType.Day) {
        // If not changed and daily, return current value
        return asset.requiredMinutes;
    }

    if (bookingType === BookingType.Daily) {
        // Default to one whole day
        return 1440;
    }

    return 0;
}