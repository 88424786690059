import cx from 'classnames';
import { FunctionComponent, useState } from 'react';
import { ToastMessage } from '../models/ToastMessage';
import { ToastMessageType } from '../models/ToastMessageType';

type Props = {
    removeToast: (id: number) => void;
}

const Toast: FunctionComponent<ToastMessage & Props> = ({id, message, removeToast, title, type}) => {
    const [showCloseButton, setShowCloseButton] = useState(type === ToastMessageType.Static);

    return (
        <div className="custom-toast">
            <div className={cx(['custom-toast__content', { 'custom-toast__content--error': type === ToastMessageType.Error }, { 'custom-toast__content--static': type === ToastMessageType.Static }])}>
                {title && (
                    <div className="custom-toast__title">
                        {title}
                        {showCloseButton && <button type="button" onClick={() => { setShowCloseButton(false); removeToast(id); }} className="custom-toast__close">✖ Dölj</button>}
                    </div>
                )}
                {message && <div>{message}</div>}
            </div>
        </div>
    );
}

export default Toast
