import { FunctionComponent, useState } from 'react'
import Button from '../../forms/Button'
import Modal from '../../modal/Modal'
import PreviewBlock from '../../shared/PreviewBlock'
import { ApartmentResponse } from '../models/GetApartmentsResponse'
import DeleteApartment from './DeleteApartment'
import EditApartmentModal from './EditApartmentModal'

type Props = {
    apartment: ApartmentResponse,
    onClose: () => void,
    refreshApartments: () => void,
}

const ApartmentModal: FunctionComponent<Props> = ({ apartment, onClose, refreshApartments }) => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);

    if(showDeleteModal) {
        return <DeleteApartment apartment={apartment} onClose={onClose} refreshApartments={refreshApartments} />
    }

    if(showEditModal) {
        return <EditApartmentModal apartment={apartment} refreshApartments={refreshApartments} onClose={() => setShowEditModal(false)} />
    }

    const onEdit = () => {
        setShowEditModal(true);
    }

    const onDelete = () => {
        setShowDeleteModal(true);
    }

    return (
        <Modal onClose={onClose} buttons={getButtons(onClose, onEdit, apartment.count === 0 ? onDelete : null)} title={`Lägenhet ${apartment.name}`} visible={true}>
            <PreviewBlock title="Area" icon="hash">
                {apartment.area === 0 ? <em>Okänd</em> : `${apartment.area} m²`}
            </PreviewBlock>
            <PreviewBlock title="Antal rum" icon="hash">
                {apartment.numberOfRooms === 0 ? <em>Okänt</em> : apartment.numberOfRooms}
            </PreviewBlock>
            <PreviewBlock title="Boende" icon="person">
                {apartment.users?.length > 0 ? (
                    apartment.users.map((user, index) => (
                        <div key={index}>{user.name}</div>
                    ))
                ) : (
                    <em>Okänt</em>
                )}
            </PreviewBlock>
        </Modal>
    )
}

function getButtons(onClose: () => void, onEdit: () => void, onDelete: (() => void) | null) {
    return (
        <>
            {onDelete && <Button  text="Ta bort" isPrimary={false} additionalClassName="btn-secondary btn-icon-delete" onClick={onDelete} />}
            <Button text="Redigera" isPrimary={false} additionalClassName="btn-secondary btn-icon-edit" onClick={onEdit} />
            <Button text="Stäng" isPrimary={true} onClick={onClose} />
        </>
    )
}

export default ApartmentModal