import { FunctionComponent, useContext } from 'react';
import { useHistory } from 'react-router';
import { PropertyAssociationContext } from './PropertyAssociationContext';
import PropertyAssociationAreaItem from './components/PropertyAssociationAreaItem';
import { PropertyAssociationArea } from './models/PropertyAssociationArea';

const SelectPropertyAssociationArea: FunctionComponent = () => {
    const history = useHistory();
    const { allAreas, setCurrentArea } = useContext(PropertyAssociationContext);

    const setCurrentAreaAndRedirect = (area: PropertyAssociationArea) => {
        setCurrentArea(area);
        history.push('/');
    }
    
    return (
        <div className="app__inner mt-4">
            <div className="text-center">
                <h1 className="large-heading">Välj ett område</h1>
                <p className="preamble">
                    Det här är alternativen du kan välja mellan.
                </p>
            </div>
            <div className="boxlist">
                { allAreas?.map(x => <PropertyAssociationAreaItem key={x.propertyAssociationAreaId} value={x} setCurrentArea={setCurrentAreaAndRedirect} />)}
            </div>
        </div>
    )
}

export default SelectPropertyAssociationArea