import { FunctionComponent } from 'react';
import { useDropzone } from 'react-dropzone';
import styles from './ImageUpload.module.scss';
import Icon from '../../shared/Icon';

type Props = {
    fileName?: string;
    setImage: (image: File | undefined) => void;
}

const ImageUpload: FunctionComponent<Props> = ({fileName, setImage}) => {
    const handleDrop = (acceptedFiles: File[]) => {
        if(acceptedFiles.length === 0) {
            return;
        }

        setImage(acceptedFiles[0]);
    }

    const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({ 
        accept: 'image/jpeg, image/png, application/pdf',
        multiple: false,
        onDrop: handleDrop,
        maxSize: 5000000,
    });

    return (
        <div {...getRootProps()} className={styles.container}>
            <div className={styles.file}>
                <Icon type="attachment" width={20} height={20} className={styles.icon} />
                <em> eller dra och släpp en bild/PDF för att {fileName ? 'ersätta vald' : 'bifoga'}</em>
                {isDragAccept && ' 👍'}
                {isDragReject && ' ⛔'}
            </div>
            <input {...getInputProps()} />
        </div>
    )
}

export default ImageUpload