import { FunctionComponent, useContext } from 'react';
import { Link } from 'react-router-dom';
import { PropertyAssociationContext } from '../propertyAssociation/PropertyAssociationContext';
import { withPropertyAssociation } from '../propertyAssociation/withPropertyAssociation';

const Assets: FunctionComponent = () => {
    const { currentPropertyAssociation } = useContext(PropertyAssociationContext);

    return (
        <div className="app__inner app__inner--center">
            <div className="text-center">
                <h1 className="large-heading">Välkommen!</h1>
                <p className="preamble">
                    I Mitt Boende Portal kan du på snabbt och enkelt hantera och lägga till nya bokningsbara resurser för {currentPropertyAssociation?.name}.
                </p>
                
                <Link to="/assets/create" className="btn btn-lg btn-primary">Lägg till resurs</Link>
            </div>
        </div>
    )
}

export default withPropertyAssociation(Assets)