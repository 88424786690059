import React, { FunctionComponent, useState } from 'react'
import cx from 'classnames';

type Props = {
    available?: boolean;
    suffix: string;
    title: string;
}

const MobileMenuSection: FunctionComponent<Props> = ({available = true, children, suffix, title}) => {
    const [ expanded, setExpanded] = useState(false);
    
    if(!available) {
        return null;
    }

    return (
        <div className={cx(['mobile-menu__section', {'mobile-menu__section--expanded': expanded}])}>
            <button className={`mobile-menu__heading mobile-menu__heading--${suffix}`} onClick={() => setExpanded(!expanded)}>{title}</button>
            { expanded && children }
        </div>
    )
}

export default MobileMenuSection