import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { AvailableResource } from '../models/AvailableResource';
import { ExtendedDate } from '../models/ExtendedDate';
import { Option } from '../../forms/models/Option';
import { ConfigContext } from '../../configuration/ConfigContext';
import RadioBoxDropDown from '../../forms/RadioBoxDropDown';
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext';
import { getAvailableResources } from '../internalBookingService';

type Props = {
    assetId: string;
    formikRef: any;
    formProps: any;
    selectedEndTime: ExtendedDate | null;
    selectedStartTime: ExtendedDate | null;
    setError: (message: string) => void;
}

const ResourceSelector: FunctionComponent<Props> = ({assetId, formikRef, formProps, selectedEndTime, selectedStartTime, setError}) => {
    const { apiBaseUrl } = useContext(ConfigContext);
    const { currentPropertyAssociationId } = useContext(PropertyAssociationContext);
    const [options, setOptions] = useState<Option[]>([]);
   
    useEffect(() => {
        if(!selectedEndTime) {
            setOptions([]);
            return;
        }

        getAvailableResources(
            apiBaseUrl,
            currentPropertyAssociationId,
            assetId,
            { startTime: selectedStartTime?.value, endTime: selectedEndTime?.value },
            (data) => {
                setOptions(createOptions(data, formikRef));
            },
            () => {
                setError('Kunde inte hämta tillgängliga resurser.');
                setOptions([]);
            }
        )
    }, [selectedEndTime]);

    if(options.length === 0) {
        return null;
    }

    return (
        <div>
            <h2 className="mt-5">Resurs</h2>
            <p className="mb-3">Välj vilken resurs som ska bokas</p>
            <div>
                <RadioBoxDropDown name="resourceId" label="Resurs" options={options} {...formProps} />
            </div>
        </div>
    )
}

export default ResourceSelector

function createOptions(data: AvailableResource[], formikRef: any): Option[] {
    const options = data.map(x => ({ text: x.name, value: x.resourceId }));

    // Set resourceId to already selected if still in options, otherwise first option if only one, otherwise null
    const selectedResourceId = formikRef.current?.values.resourceId;
    if (options.some(x => x.value === selectedResourceId)) {
        formikRef.current?.setFieldValue('resourceId', selectedResourceId);
    } else if (options.length === 1) {
        formikRef.current?.setFieldValue('resourceId', options[0].value);
    } else {
        formikRef.current?.setFieldValue('resourceId', null);
    }

    return options;
}
