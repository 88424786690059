import { FunctionComponent, ReactNode, useCallback } from 'react';
import { useHistory } from 'react-router';
import { ConversationModel } from '../models/ConversationModel';
import ConversationsListItem from './ConversationsListItem';

type Props = {
    activeConversationId: string;
    children?: ReactNode | ReactNode[];
    conversations: ConversationModel[];
    hasMore: boolean;
    onLoadMore: () => void;
    showGroups?: boolean;
};

const ConversationsList: FunctionComponent<Props> = ({ activeConversationId, children, conversations, hasMore, onLoadMore, showGroups = false }) => {
    const { push } = useHistory();

    const onSelectConversation = useCallback(
        showGroups ?
            (conversationId: string) => push(`/messages/groups/${conversationId}`) :
            (conversationId: string) => push(`/messages/conversations/${conversationId}`),
        [showGroups]
    );

    return (
        <div className='conversations__list'>
            {children}
            {conversations?.map(x => (
                <ConversationsListItem
                    conversation={x}
                    isActive={!!x.conversationId && x.conversationId === activeConversationId}
                    key={x.conversationId}
                    onSelectConversation={onSelectConversation} />
            ))}

            {hasMore && <div className="py-3 px-4"><button type='button' className='btn btn-primary w-100' onClick={onLoadMore}>Ladda fler...</button></div>}
        </div>
    );
};

export default ConversationsList;
