import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { getBooking } from '../bookings/bookingService';
import { BookingDetails } from '../bookings/models/BookingDetails';
import { BookingStatus } from '../bookings/models/BookingStatus';
import { ConfigContext } from '../configuration/ConfigContext';
import AlertModal from '../modal/AlertModal';
import { PropertyAssociationContext } from '../propertyAssociation/PropertyAssociationContext';
import CancellationView from './views/CancellationView';
import DetailsView from './views/DetailsView';
import MessageView from './views/MessageView';

type Props = {
    bookingId: string;
    hasMultipleObjects: boolean;
    onClose: () => void;
    onRefresh: () => void;
}

enum ModalView {
    CANCELLATION,
    DETAILS,
    MESSAGE,
}

const BookingModal: FunctionComponent<Props> = ({ bookingId, hasMultipleObjects, onClose, onRefresh }) => {
    const { apiBaseUrl } = useContext(ConfigContext);
    const { currentPropertyAssociationId } = useContext(PropertyAssociationContext);
    const [ booking, setBooking ] = useState<BookingDetails>();
    const [ isArchived, setIsArchived ] = useState<boolean>(false);
    const [ errorMessage, setErrorMessage ] = useState<string | undefined>();
    const [ activeView, setActiveView ] = useState<ModalView>();

    useEffect(() => {
        getBooking(
            apiBaseUrl,
            currentPropertyAssociationId,
            bookingId,
            (booking) => {
                setBooking(booking);
                setIsArchived(getArchiveStatus(booking));
                setActiveView(ModalView.DETAILS);
            },
            () => {
                setErrorMessage('Kunde inte hitta den efterfrågade bokningen.');
            }
        );
    }, [bookingId]);

    if(errorMessage) {
        return <AlertModal heading="Ett fel har uppstått" text={errorMessage} onClose={onClose} visible={true} />
    }
    
    if(!booking) {
        return null;
    }

    const showCancellationView = isArchived ? undefined : () => setActiveView(ModalView.CANCELLATION);
    const showMessageView = booking.isInternalBooking || booking.isIntermediateBooking ? undefined : () => setActiveView(ModalView.MESSAGE);

    switch(activeView) {
        case ModalView.CANCELLATION:
            return <CancellationView booking={booking} onClose={onClose} onRefresh={onRefresh} />
        case ModalView.DETAILS:
            return <DetailsView booking={booking} hasMultipleObjects={hasMultipleObjects} isArchived={isArchived} onClose={onClose} showCancellationView={showCancellationView} showMessageView={showMessageView} />
        case ModalView.MESSAGE:
            return <MessageView booking={booking} onClose={onClose} />;
        default:
            return null;
    }
}

const getArchiveStatus = (booking: BookingDetails): boolean => {
    if(booking.status !== BookingStatus.Booked) {
        return true;
    }

    if(new Date(booking.endTime) < new Date()) {
        return true;
    }
    
    return false;
}

export default BookingModal