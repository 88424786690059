import { FunctionComponent } from 'react'
import cx from 'classnames';
import styles from './Tabs.module.scss';
import { TabOption } from './models/TabOption';

type Props = {
    activeTab: string,
    onChange: (value: string) => void,
    tabs: TabOption[],
    tight?: boolean,
}

const Tabs: FunctionComponent<Props> = ({activeTab, onChange, tabs, tight = false}) => {
    return (
        <div className={cx([styles.container, {[styles.tight]: tight}])}>
            {tabs.map((x) => (
                <button key={x.value} onClick={() => onChange(x.value)} className={cx([styles.tab, { [styles.active]: x.value === activeTab }])}>{x.title}</button>
            ))}
        </div>
    )
}

export default Tabs