import { FunctionComponent } from 'react';
import { AssetBooking } from '../models/AssetBooking';
import styles from './Cancelled.module.scss';

type Props = {
    booking: AssetBooking;
    setSelectedBookingId: (bookingId: string) => void;
}

const Cancelled: FunctionComponent<Props> = ({booking: { bookingId, startTime, endTime, bookedBy, isFirst, isLast }, setSelectedBookingId}) => (
    <button className={styles.container} onClick={() => setSelectedBookingId(bookingId)}>
        <div className={styles.time}>{getTimeRange(startTime, endTime, isFirst, isLast)}</div>
        <div className={styles.name}>{bookedBy}</div>
    </button>
)

function getTimeRange(startTime: string, endTime: string, isFirst: boolean, isLast: boolean) {
    if(isFirst && isLast) {
        return `${startTime} - ${endTime}`;
    }

    return `${startTime} → ${endTime}`;
}

export default Cancelled