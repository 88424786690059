import { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import ErrorMessage from '../../common/error/ErrorMessage';
import { ConfigContext } from '../configuration/ConfigContext';
import SimpleCheckBox from '../forms/SimpleCheckBox';
import PaperLayout from '../layout/PaperLayout';
import OverlayConfirmModal from '../modal/OverlayConfirmModal';
import { PropertyAssociationContext } from '../propertyAssociation/PropertyAssociationContext';
import { withPropertyAssociation } from '../propertyAssociation/withPropertyAssociation';
import PreviewBlock from '../shared/PreviewBlock';
import PreviewHeading from '../shared/PreviewHeading';
import { ToastContext } from '../toaster/ToastContext';
import { ToastMessageType } from '../toaster/models/ToastMessageType';
import PinboardMessageStatisticsModal from './components/PinboardMessageStatisticsModal';
import { GetPinboardMessageResponse } from './models/GetPinboardMessageResponse';
import { GetPinboardMessageStatisticsResponse } from './models/GetPinboardMessageStatisticsResponse';
import { getFileName } from './pinboardMessageHelpers';
import { deletePinboardMessage, getPinboardMessage, getStatistics, setSettings } from './pinboardMessageService';

const ViewPinboardMessage: FunctionComponent = () => {
    const history = useHistory();
    const { id } = useParams<any>();
    const { apiBaseUrl } = useContext(ConfigContext);
    const { currentPropertyAssociationId, currentFunctionPermissions } = useContext(PropertyAssociationContext);
    const { addToast } = useContext(ToastContext);
    const [disableInput, setDisableInput] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [messageToDeleteId, setMessageToDeleteId] = useState<string>('');
    const [pinboardMessage, setPinboardMessage] = useState<GetPinboardMessageResponse | undefined>();
    const [showStatistics, setShowStatistics] = useState<boolean>(false);
    const [statistics, setStatistics] = useState<GetPinboardMessageStatisticsResponse>();
    const [usersCanRespond, setUsersCanRespond] = useState<boolean>(false);

    const handleClose = useCallback(() => {
        history.push('/adverts/information/');
    }, [])

    const handleDelete = useCallback(() => {
        setMessageToDeleteId(id);
    }, [id])

    const handleDeleteConfirmed = useCallback(() => {
        setIsDeleting(true);
        deletePinboardMessage(
            apiBaseUrl,
            currentPropertyAssociationId,
            id,
            () => {
                addToast('Meddelandet har tagits bort.', undefined, ToastMessageType.Success);
                setIsDeleting(false);
                history.push('/adverts/information/');
            },
            () => {
                addToast('Kunde inte ta bort meddelandet.', undefined, ToastMessageType.Error);
                setIsDeleting(false);
            }
        );
        setMessageToDeleteId('');
    }, [])

    const handleEdit = useCallback(() => {
        history.push(`/adverts/information/edit/${id}`);
    }, [id])

    const loadMessage = () => {
        getPinboardMessage(
            apiBaseUrl,
            currentPropertyAssociationId,
            id,
            (data) => {
                setPinboardMessage(data);
                setUsersCanRespond(data.usersCanRespond ?? false);
            },
            () => {
                setErrorMessage('Kunde inte ladda meddelandet.');
                setPinboardMessage(undefined);
            }
        );
    };
    
    useEffect(() => {
        if (!id) {
            return;
        }
        
        loadMessage();
        
        getStatistics(
            apiBaseUrl,
            currentPropertyAssociationId,
            id,
            (data) => setStatistics(data),
            () => setStatistics(undefined)
        );
    }, [id]);

    if(errorMessage) {
        return <ErrorMessage message={errorMessage} />
    }

    if(!pinboardMessage) {
        return null
    }

    const toggleUserCanRespond = () => {
        setDisableInput(true);
        setSettings(
            apiBaseUrl,
            currentPropertyAssociationId,
            id,
            !usersCanRespond,
            () => {
                setUsersCanRespond(!usersCanRespond);
                addToast('Inställningarna för meddelandet har uppdaterats', `Boende kan nu ${usersCanRespond ? 'inte längre' : ''} svara på meddelandet.`, ToastMessageType.Success);
                setDisableInput(false);
            },
            () => {
                addToast('Ett fel har uppstått', 'Kunde inte uppdatera inställningarna för meddelandet.', ToastMessageType.Error);
                setDisableInput(false);
            }
        );
    }

    const heading = pinboardMessage.propertyAssociationArea ? `${pinboardMessage.title} (${pinboardMessage.propertyAssociationArea.name})` : pinboardMessage.title;

    return (
        <PaperLayout 
            heading={heading}
            tightenUp={true} 
            mainClass="px-0 pt-0"
            preamble={(
                <>
                    <strong>Information till boende</strong>
                    <p>
                        Publicerade meddelanden ses av fastighetens boende på anslagstavlan i Mitt Boende-appen.
                    </p>

                    <SimpleCheckBox name="userCanRespond" label="Boende kan svara på detta meddelande" isChecked={usersCanRespond} isDisabled={disableInput} onChange={toggleUserCanRespond} />

                    {statistics ? (
                        <p>Detta meddelande har lästs av <strong>{statistics.readCount}/{statistics.totalCount}</strong> lägenheter. <span onClick={() => setShowStatistics(true)} role="presentation" className="hover-link">Se detaljerad statistik</span></p>
                    ) : (
                        <p>Hämtar statistik för meddelandet...</p>
                    )}
                </>
            )}
        >
            <button className="paper__close" onClick={handleClose}>Stäng</button>

            <div className="preview">
                <PreviewHeading>{pinboardMessage.title}</PreviewHeading>
                <PreviewBlock><div dangerouslySetInnerHTML={{ __html: pinboardMessage.text }}></div></PreviewBlock>
                {pinboardMessage.filePaths?.length > 0 && (
                    <div className="preview__file-block">
                        <div className="preview__divider"></div>
                        <strong>Bifogade filer</strong>
                        {pinboardMessage.filePaths.map((filePath) => renderFile(filePath))}
                    </div>
                )}
            </div>

            {currentFunctionPermissions?.createPinboardMessage && (
                <div className="paper__buttons">
                    <div className="paper__secondary-buttons">
                        <button type="button" className="btn btn-lg btn-secondary btn-icon-delete" disabled={isDeleting} onClick={handleDelete}>Ta bort</button>
                    </div>
                    <div className="paper__primary-buttons">
                        <button type="button" className="btn btn-lg btn-primary btn-icon-edit" disabled={isDeleting} onClick={handleEdit}>Ändra</button>
                    </div>
                </div>
            )}

            {statistics && showStatistics && <PinboardMessageStatisticsModal statistics={statistics} onClose={() => setShowStatistics(false) }/>}
            <OverlayConfirmModal
                visible={!!messageToDeleteId}
                heading="Ta bort meddelande?"
                text="Publicerade meddelanden är synliga för de boende via anslagstavlan i Mitt Boende-appen. Ett borttaget meddelande försvinner helt från anslagstavlan. Åtgärden går inte att ångra."
                cancelButtonText="Avbryt"
                okButtonText="Ta bort meddelande"
                onCancel={() => setMessageToDeleteId('')}
                onSubmit={handleDeleteConfirmed}
            />
        </PaperLayout>
    );
}

const renderFile = (filePath: string) => {
    const fileName = getFileName(filePath);
    const extension = fileName.split('.').pop()?.toLowerCase();

    if(extension === 'pdf') {
        return (
            <a key={filePath} href={filePath} target="_blank" rel="noreferrer" className="file file--reverse">
                <div className="file__content">
                    {fileName}
                </div>
            </a>
        );
    }

    return (
        <a key={filePath} href={filePath} target="_blank" rel="noreferrer" className="file file--reverse file--no-icon">
            <div className="file__content">
                {fileName}
            </div>
        </a>
    );
};

export default withPropertyAssociation(ViewPinboardMessage, false)
