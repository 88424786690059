import { FunctionComponent, useContext } from 'react';
import PickPropertyAssociation from './components/PickPropertyAssociation';
import SearchPropertyAssociation from './components/SearchPropertyAssociation';
import { PropertyAssociationContext } from './PropertyAssociationContext';

const SelectPropertyAssociation: FunctionComponent = () => {
    const { isAdmin } = useContext(PropertyAssociationContext);

    return (
        <div className="app__inner mt-4">
            { isAdmin ? <SearchPropertyAssociation /> : <PickPropertyAssociation /> }
        </div>
    )
}

export default SelectPropertyAssociation