import { Fragment, FunctionComponent, useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Asset } from '../models/Asset';
import { EntranceMethod } from '../models/EntranceMethod';
import { ConfigContext } from '../../configuration/ConfigContext';
import FormikChangeSubscriberWithActions from '../../forms/FormikChangeSubscriberWithActions';
import RadioButton from '../../forms/RadioButton';
import TextField from '../../forms/TextField';
import { phoneNumberValidator, requiredValidator, stringConditionalValidator } from '../../forms/validators';
import FormModal from '../../modal/FormModal';
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext';
import { MESSAGES_SAVE_FAILED } from '../assetConstants';
import { getEntranceMethods as getAvailableEntranceMethods, updateEntranceMethod } from '../assetService';

type Props = {
    initialValues: Asset;
    onClose: () => void;
    updateValues: (values) => void;
    visible: boolean;
}

type FormData = {
    assetEntranceMethodId: string;
    contactInformation: string;
    contactName: string;
    haveContactInformation: boolean;
    haveOtherInformation: boolean;
    otherInformation: string;
}
type EntranceMethodPutModel = {
    assetEntranceMethodId: string,
    contactName: string,
    contactInformation: string,
    otherInformation: string,
    haveContactInformation: boolean,
    haveOtherInformation: boolean,

}

const Empty: FormData = {
    assetEntranceMethodId: '',
    contactInformation: '',
    contactName: '',
    haveContactInformation: false,
    haveOtherInformation: false,
    otherInformation: '',
}

const validationSchema = Yup.object().shape({
    assetEntranceMethodId: requiredValidator,
    contactInformation: stringConditionalValidator('haveContactInformation', true, phoneNumberValidator, null),
    contactName: stringConditionalValidator('haveContactInformation', true, requiredValidator, null),
    otherInformation: Yup.string().nullable().notRequired(),
})
const entranceMethodPutPayload = (values: EntranceMethodPutModel) => {
    return {
        assetEntranceMethodId: values.assetEntranceMethodId,
        contactName: values.haveContactInformation ? values.contactName : '',
        contactInformation: values.haveContactInformation ? values.contactInformation : '',
        otherInformation: values.haveOtherInformation ? values.otherInformation : ''
    }

}
const EntranceMethodModal: FunctionComponent<Props> = ({ initialValues, onClose, updateValues, visible }) => {
    const { currentPropertyAssociationId } = useContext(PropertyAssociationContext);
    const { apiBaseUrl } = useContext(ConfigContext);
    const [availableEntranceMethods, setAvailableEntranceMethods] = useState<EntranceMethod[] | undefined>();
    const [error, setError] = useState<string | undefined>();
    const [formData, setFormData] = useState<FormData>()
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onSubmit = (values: FormData) => {
        setIsSubmitting(true);

        updateEntranceMethod(apiBaseUrl, currentPropertyAssociationId, initialValues.assetId, entranceMethodPutPayload(values),
            (entranceMethod) => {

                updateValues(Object.assign({}, initialValues, { entranceMethod }));
                close();
            },
            () => setError(MESSAGES_SAVE_FAILED),
            () => setIsSubmitting(false)
        )
    }

    const handleChange = (values, setFieldValue) => {
        const entranceMethod = availableEntranceMethods?.find(x => x.assetEntranceMethodId === values.assetEntranceMethodId);
        if (!entranceMethod) {
            return;
        }
        setFieldValue('haveContactInformation', entranceMethod.haveContactInformation);
        setFieldValue('haveOtherInformation', entranceMethod.haveOtherInformation);
    }

    useEffect(() => {
        if (visible && !availableEntranceMethods) {
            getAvailableEntranceMethods(apiBaseUrl, initialValues.assetType.tag,
                setAvailableEntranceMethods,
                () => { throw new Error('Kunde inte ladda alternativ för åtkomst') }
            );
        }
    }, [visible])

    useEffect(() => {
        const savedValues = initialValues?.entranceMethod;
        if (!savedValues) {
            setFormData(Empty);
            return;
        }

        const entranceMethod = availableEntranceMethods?.find(x => x.assetEntranceMethodId === savedValues.assetEntranceMethodId);
        setFormData({
            ...savedValues,
            haveContactInformation: entranceMethod?.haveContactInformation ?? false,
            haveOtherInformation: entranceMethod?.haveOtherInformation ?? false,
        });
    }, [initialValues, availableEntranceMethods])

    const close = () => {
        setError('')
        onClose();
    }
    if (!availableEntranceMethods) {
        return null;
    }

    return (
        <FormModal initialValues={formData} title='Åtkomst' validationSchema={validationSchema} visible={visible} isSubmitting={isSubmitting} onClose={close} onSubmit={onSubmit}>
            {({ errors, touched, values }) => (
                <Fragment>
                    <FormikChangeSubscriberWithActions onChange={handleChange} />
                    <p>Ange hur gäster får tillgång till resursen.</p>
                    {availableEntranceMethods.map(x => (
                        <div key={x.assetEntranceMethodId} className="my-3">
                            <RadioButton name="assetEntranceMethodId" label={x.description} value={x.assetEntranceMethodId} />
                            {values.assetEntranceMethodId === x.assetEntranceMethodId && x.haveOtherInformation && (
                                <div className="--indent-double mb-4">
                                    <TextField name="otherInformation" label={x.otherPlaceholderText} width={50} errors={errors} touched={touched} values={values} />
                                </div>
                            )}
                            {values.assetEntranceMethodId === x.assetEntranceMethodId && x.haveContactInformation && (
                                <div className="--indent-double mb-5">
                                    <TextField name="contactName" label="Namn" width={50} errors={errors} touched={touched} values={values} />
                                    <TextField name="contactInformation" label="Telefon" width={50} errors={errors} touched={touched} values={values} />
                                </div>
                            )}
                        </div>
                    ))}
                    {error && <div className="alert alert-danger">{error}</div>}
                </Fragment>
            )}
        </FormModal>
    )
}

export default EntranceMethodModal