import React, { FunctionComponent, useContext } from 'react';
import RedirectLoader from './RedirectLoader';
import { AuthContext } from './AuthContext';

const AuthSignInCallback: FunctionComponent = () => {
    const { signinRedirectCallback } = useContext(AuthContext);
    
    signinRedirectCallback();
    
    return <RedirectLoader />;
};

export default AuthSignInCallback;