import { Fragment, FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { ConfigContext } from '../../configuration/ConfigContext';
import SimpleCheckBox from '../../forms/SimpleCheckBox';
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext';
import { updatePropertyAssociationSettings } from '../../settings/settingsService';
import { MembersSortDirection, MembersSortOrder } from '../memberConstants';
import { sortMembers } from '../membersSort';
import MemberDetailsModal from '../modals/MemberDetailsModal';
import { GetMembersResponse, Member } from '../models/Member';
import MemberItem from './MemberItem';

declare module 'yup' {
    // tslint:disable-next-line
    interface ArraySchema<T> {
        unique(message?: any): ArraySchema<T>;
    }
}

type Props = {
    defaultSortDirection: MembersSortDirection;
    defaultSortOrder: MembersSortOrder;
    isPending: boolean;
    fetchFunction: (apiBaseUrl: string, propertyAssociationId: string, onSuccess: (data: GetMembersResponse) => void, onFail: () => void) => void;
};

const BookingList: FunctionComponent<Props> = ({
    defaultSortDirection,
    defaultSortOrder,
    isPending,
    fetchFunction
}) => {
    const { apiBaseUrl } = useContext(ConfigContext);
    const { currentPropertyAssociationId } = useContext(PropertyAssociationContext);
    const history = useHistory();
    const location = useLocation();
    const { search } = useLocation();
    const [membersInfo, setMembersInfo] = useState<GetMembersResponse | undefined>();
    const [selectedMember, setSelectedMember] = useState<Member | undefined>();
    const [sortOrder, setSortOrder] = useState(defaultSortOrder);
    const [sortDirection, setSortDirection] = useState(defaultSortDirection);
    const [sortedMembers, setSortedMembers] = useState<Member[]>([]);
    
    const updateMembers = () => {
        fetchFunction(
            apiBaseUrl,
            currentPropertyAssociationId,
            (data: GetMembersResponse) => {
                setMembersInfo(data ?? []);
            },
            () => {
                setMembersInfo(undefined);
            }
        );
    };

    const emptyFunction = useCallback(() => { /* empty */ }, []);

    useEffect(() => {
        updateMembers();
    }, [sortOrder, sortDirection]); //LÄGG TILL OM MAN VILL GÖRA FETCH när någon av dessa ändras!

    useEffect(() => {
        const membersSorted = sortMembers(membersInfo?.members || [], sortOrder);
        if (sortDirection === MembersSortDirection.Descending) {
            membersSorted.reverse();
        }
        setSortedMembers(membersSorted);
    }, [membersInfo, sortOrder, sortDirection]);
    
    useEffect(() => {
        if (!search || !membersInfo?.members) {
            return;
        }

        const userId = new URLSearchParams(search).get('userId');
        const member = membersInfo.members.find((x) => x.user?.userId === userId);
        if (member) {
            setSelectedMember(member);
        }
    }, [search, membersInfo?.members]);

    const deselectMember = () => {
        history.replace({ pathname: location.pathname });
        setSelectedMember(undefined);
    }

    const showMemberDetails = (member: Member) => {
        const params = new URLSearchParams({ userId: member.user?.userId || '' });
        history.replace({ pathname: location.pathname, search: params.toString() });
        setSelectedMember(member);
    }

    const sortItems = (sortBy: MembersSortOrder) => {
        if (sortBy !== sortOrder) {
            setSortDirection(MembersSortDirection.Ascending);
            setSortOrder(sortBy);
            return;
        }

        if (sortDirection === MembersSortDirection.Ascending) {
            setSortDirection(MembersSortDirection.Descending);
            return;
        }

        setSortDirection(MembersSortDirection.Ascending);
    };

    const renderHeader = (text: string, sortBy: MembersSortOrder) => {
        if (sortBy !== sortOrder) {
            return (
                <th className='bookings__header bookings__header--no-sort' onClick={() => sortItems(sortBy)}>
                    {text}
                </th>
            );
        }

        if (sortDirection === MembersSortDirection.Ascending) {
            return (
                <th className='bookings__header bookings__header--ascending' onClick={() => sortItems(sortBy)}>
                    {text}
                </th>
            );
        }

        return (
            <th className='bookings__header bookings__header--descending' onClick={() => sortItems(sortBy)}>
                {text}
            </th>
        );
    };

    const toggleRequireMembership = () => {
        const requireMembership = !(membersInfo?.requireMembership ?? false);
        updatePropertyAssociationSettings(apiBaseUrl, currentPropertyAssociationId, { requireMembership }, updateMembers, emptyFunction);
    }

    return (
        <Fragment>
            <SimpleCheckBox name="requireMembership" label="Kräv manuellt hanterad åtkomst av folkbokförda användare för att dessa ska kunna boka resurser och ta del av anslagstavlan." isChecked={membersInfo?.requireMembership ?? false} onChange={toggleRequireMembership} />
            <table className='bookings'>
                <thead className='bookings__head'>
                    <tr>
                        {renderHeader('Förnamn', MembersSortOrder.FirstName)}
                        {renderHeader('Efternamn', MembersSortOrder.LastName)}
                        <th></th>
                        <th className="d-none d-md-table-cell"></th>
                    </tr>
                </thead>
                <tbody>
                    {sortedMembers.map((x) => (
                        <MemberItem
                            key={x.user.userId}
                            member={x}
                            onClick={() => showMemberDetails(x)}
                            requireMembership={membersInfo?.requireMembership ?? false}
                            isPending={isPending}
                        />
                    ))}
                </tbody>
            </table>
            {selectedMember && (
                <MemberDetailsModal 
                    onClose={deselectMember}
                    member={selectedMember}
                    onMemberUpdated={updateMembers}
                    isPending={isPending}
                />
            )}
        </Fragment>
    );
};

export default BookingList;
