import { FunctionComponent, useContext, useState } from 'react';
import PaperLayout from '../layout/PaperLayout';
import { withPropertyAssociation } from '../propertyAssociation/withPropertyAssociation';
import Tabs from '../shared/Tabs';
import ListActiveUserAdverts from './components/ListActiveUserAdverts';
import ListDeletedUserAdverts from './components/ListDeletedUserAdverts';
import UserAdvertDetailsModal from './modals/UserAdvertDetailsModal';
import { ToastContext } from '../toaster/ToastContext';

const TAB_NAMES_ACTIVE = 'active';
const TAB_NAMES_DELETED = 'deleted';

const TABS = [
    { title: 'Aktiva annonser', value: TAB_NAMES_ACTIVE },
    { title: 'Borttagna annonser', value: TAB_NAMES_DELETED },
]

const UserAdverts: FunctionComponent = () => {
    const { addToast } = useContext(ToastContext);
    const [activeTab, setActiveTab] = useState(TAB_NAMES_ACTIVE);
    const [selectedAdvertId, setSelectedAdvertId] = useState<string | undefined>();
    const [deletedAdverts, setDeletedAdverts] = useState<string[]>([]);

    const selectAdvertId = (advert: any) => { setSelectedAdvertId(advert); }

    const onModalClose = () => { 
        setSelectedAdvertId(undefined); 
    }

    const onAdvertDeleted = (advertId: string) => {
        setDeletedAdverts([...deletedAdverts, advertId]);
        addToast('Anonsen har tagits bort', 'Kan hittas under fliken "Borttagna annonser"');
    }

    return (
        <PaperLayout heading="Dela, hyr, sälj" tightenUp={true} mainClass="pt-4">
            <Tabs activeTab={activeTab} onChange={setActiveTab} tabs={TABS} />

            {activeTab === TAB_NAMES_ACTIVE && <ListActiveUserAdverts selectAdvertId={selectAdvertId} deletedAdverts={deletedAdverts} />}
            {activeTab === TAB_NAMES_DELETED && <ListDeletedUserAdverts selectAdvertId={selectAdvertId} />}

            {selectedAdvertId && <UserAdvertDetailsModal advertId={selectedAdvertId} onClose={onModalClose} onAdvertDeleted={onAdvertDeleted} />}
        </PaperLayout>
    )
}

export default withPropertyAssociation(UserAdverts, false)