import cx from 'classnames';
import React, { FunctionComponent, useState } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import SubNavigation from '../navigation/SubNavigation';
import { withWaitForPropertyAssociation } from '../propertyAssociation/withWaitForPropertyAssociation';

const MembersMenu: FunctionComponent = () => {
    const location = useLocation();
    const currentPath = location.pathname;
    const [expanded, setExpanded] = useState(true)

    return (
        <SubNavigation heading="Åtkomst" isExpanded={expanded} onExpand={setExpanded}>
            <div className="submenu__content">
                <div className="submenu__links">
                    { renderLink('/members/', 'Aktiva', currentPath) }
                    { renderLink('/members/pending/', 'Förfrågningar', currentPath) }
                </div>
            </div>
        </SubNavigation>
    );
}

export default withWaitForPropertyAssociation(MembersMenu, false)

function renderLink(path: string, text: string, currentPath: string): React.ReactNode {
    const isCurrentPath = currentPath === path;
    return <Link to={path} className={cx(['submenu__link', { 'submenu__link--current': isCurrentPath }])}>{text}</Link>
}