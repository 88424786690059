import { Fragment, FunctionComponent, useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Asset } from '../models/Asset';
import { ConfigContext } from '../../configuration/ConfigContext';
import MaxTextField from '../../forms/MaxTextField';
import FormModal from '../../modal/FormModal';
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext';
import { MESSAGES_SAVE_FAILED } from '../assetConstants';
import { refreshAssets, updateAsset } from '../assetService';
import { AssetTypeDefinition } from '../assetTypes/assetTypes';

type Props = {
    defaults: AssetTypeDefinition;
    initialValues: Asset;
    onClose: () => void;
    updateValues: (values) => void;
    visible: boolean;
}

type FormData = {
    name: string;
    prefixName: boolean;
}

const validationSchema = Yup.object().shape({
    name: Yup.string().max(25, 'Får högst vara 25 tecken').when('prefixName', {
        is: false,
        then: Yup.string().required('Namn måste fyllas i'),
    }),
})

const NameModal: FunctionComponent<Props> = ({ defaults, initialValues, onClose, updateValues, visible }) => {
    const { currentPropertyAssociationId, currentArea } = useContext(PropertyAssociationContext);
    const { apiBaseUrl } = useContext(ConfigContext);
    const [ isSubmitting, setIsSubmitting] = useState(false);
    const [ error, setError ] = useState<string | undefined>();
    const [ formData, setFormData ] = useState<FormData>();

    const onSubmit = (values: FormData) => {
        setIsSubmitting(true);

        const name = getName(defaults, values);

        updateAsset(apiBaseUrl, currentPropertyAssociationId, initialValues.assetId,
            { name },
            (data) => {
                setIsSubmitting(false);
                updateValues(data);
                refreshAssets(apiBaseUrl, currentPropertyAssociationId, currentArea?.propertyAssociationAreaId);
                onClose();
            },
            () => {
                setIsSubmitting(false);
                setError(MESSAGES_SAVE_FAILED);
            },
        )
    }

    useEffect(() => {
        const { prefixName } = defaults;
        let name = '';

        if (!prefixName) {
            name = initialValues.name;
        }
        else if (initialValues.name.length > (defaults.name.length + 3)) {
            name = initialValues.name.substring(defaults.name.length + 3);
        }

        setFormData({ name, prefixName });
    }, [initialValues]);
    
    return (
        <FormModal initialValues={formData} title="Namn på resurs" validationSchema={validationSchema} visible={visible} isSubmitting={isSubmitting} onClose={onClose} onSubmit={onSubmit}>
            {({values, errors, touched}) => {
                const formProps = {values, errors, touched};
                return (
                    <Fragment>
                        <p>Här kan du namnge resursen, på ett tydligt sätt som gör det enkelt att hitta och boka den!</p>
                        <MaxTextField name="name" maxLength={25} {...formProps} />
                        {error && <div className="alert alert-danger">{error}</div>}
                        {defaults.prefixName ? (
                            <p><i>Ovanstående namn kommer att visas med <strong>&quot;{defaults.name} - &quot;</strong> före, om fältet ovan lämnas tomt blir namnet <strong>&quot;{defaults.name}&quot;</strong>.</i></p>
                        ) : (
                            <p><i>Texten ovan är endast ett förslag. Anpassa den gärna så att den passar er specifika resurs.</i></p>
                        )}
                    </Fragment>
                )
            }}
        </FormModal>
    )
}

function getName(defaults: AssetTypeDefinition, values: FormData): string {
    if(!defaults.prefixName) {
        return values.name;
    }

    if(values.name.length > 0) {
        return `${defaults.name} - ${values.name}`;
    }

    return defaults.name;
}

export default NameModal
