import { Fragment, FunctionComponent, useContext, useState } from 'react';
import BooleanCheckBox from '../../forms/BooleanCheckBox';
import Label from '../../forms/Label';
import RadioBoxDropDown from '../../forms/RadioBoxDropDown';
import { Option } from '../../forms/models/Option';
import { noValidationSchema } from '../../forms/validators';
import FormModal from '../../modal/FormModal';
import { AssetTimeSlot } from '../models/AssetTimeSlot';
import { WeekDay } from '../assetConstants';
import { ToastMessageType } from '../../toaster/models/ToastMessageType';
import { ToastContext } from '../../toaster/ToastContext';

type Props = {
    onClose: () => void;
    setTimeslots: (timeslots: AssetTimeSlot[], onSuccess: () => void, onFailure: () => void) => void;
    timeslots: AssetTimeSlot[];
}

type FormData = {
    copyFrom: string;
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
}

const DEFAULT_VALUES: FormData = {
    copyFrom: '0',
    monday: false,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
    sunday: true,
}

const COPY_FROM_OPTIONS: Option[] = [
    { value: '0', text: 'Måndag' },
    { value: '1', text: 'Tisdag' },
    { value: '2', text: 'Onsdag' },
    { value: '3', text: 'Torsdag' },
    { value: '4', text: 'Fredag' },
    { value: '5', text: 'Lördag' },
    { value: '6', text: 'Söndag' },
]

const CopyDayModal: FunctionComponent<Props> = ({onClose, setTimeslots, timeslots}) => {
    const { addToast } = useContext(ToastContext);
    const [ isSubmitting, setIsSubmitting] = useState(false)

    const onSubmit = (values: FormData) => {
        setIsSubmitting(true)
        
        const copyFrom = parseInt(values.copyFrom);
        const source = timeslots.filter(slot => slot.weekDay === copyFrom);
        const days = getDays(copyFrom, values);
        const slots = timeslots.filter(slot => !days.includes(slot.weekDay) && slot.weekDay !== copyFrom);

        days.forEach(day => {
            source.map(slot => {
                slots.push({
                    ...slot,
                    weekDay: day
                })
            })
        });

        setTimeslots(
            slots, 
            () => {
                setIsSubmitting(false);
                addToast('Tiderna har kopierats', '', ToastMessageType.Success)
                onClose();
            },
            () => {
                setIsSubmitting(false);
                addToast('Tiderna kunde inte kopieras', '', ToastMessageType.Error);
            }
        );
    }

    return (
        <FormModal initialValues={DEFAULT_VALUES} title="Kopiera dag" submitText="Kopiera" validationSchema={noValidationSchema} visible={true} isSubmitting={isSubmitting} onClose={onClose} onSubmit={onSubmit}>
            {({ values, errors, touched }) => {
                const formProps = { values, errors, touched }
                const copyFrom = values.copyFrom;

                return (
                    <Fragment>
                        <div className="mb-3">
                            <Label name="copyFrom" text="Kopiera från:" />
                            <RadioBoxDropDown name="copyFrom" label="Kopiera från" fixed={true} options={COPY_FROM_OPTIONS} {...formProps} />
                        </div>

                        <Label name="daySelection" text="Kopiera till:" />
                        <div className="d-flex justify-content-between mt-2">
                            <BooleanCheckBox name="monday" label="Måndag" disabled={copyFrom === '0'} />
                            <BooleanCheckBox name="tuesday" label="Tisdag" disabled={copyFrom === '1'} />
                            <BooleanCheckBox name="wednesday" label="Onsdag" disabled={copyFrom === '2'} />
                            <BooleanCheckBox name="thursday" label="Torsdag" disabled={copyFrom === '3'} />
                            <BooleanCheckBox name="friday" label="Fredag" disabled={copyFrom === '4'} />
                            <BooleanCheckBox name="saturday" label="Lördag" disabled={copyFrom === '5'} />
                            <BooleanCheckBox name="sunday" label="Söndag" disabled={copyFrom === '6'} />
                        </div>
                    </Fragment>
                )
            }}
        </FormModal>
    )
}

const getDays = (copyFrom: number, values: FormData): WeekDay[] => {
    const days: WeekDay[] = [ copyFrom as WeekDay ];

    if(values.monday && copyFrom !== WeekDay.Monday) {
        days.push(WeekDay.Monday);
    }
    if(values.tuesday && copyFrom !== WeekDay.Tuesday) {
        days.push(WeekDay.Tuesday);
    }
    if(values.wednesday && copyFrom !== WeekDay.Wednesday) {
        days.push(WeekDay.Wednesday);
    }
    if(values.thursday && copyFrom !== WeekDay.Thursday) {
        days.push(WeekDay.Thursday);
    }
    if(values.friday && copyFrom !== WeekDay.Friday) {
        days.push(WeekDay.Friday);
    }
    if(values.saturday && copyFrom !== WeekDay.Saturday) {
        days.push(WeekDay.Saturday);
    }
    if(values.sunday && copyFrom !== WeekDay.Sunday) {
        days.push(WeekDay.Sunday);
    }

    return days;
}

export default CopyDayModal
