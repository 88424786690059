import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import { addDays, getStartOfWeek } from '../../../common/utils/dateUtils';
import styles from './DatePicker.module.scss';

type Props = {
    startDate: Date;
    setStartDate: (date: Date) => void;
}

const DatePicker: FunctionComponent<Props> = ({startDate, setStartDate}) => {
    const [ endDate, setEndDate ] = useState<Date>();
    const [ showCalendar, setShowCalendar ] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement>(null);
    const hideCalendar = useCallback((e:any) => {
        if (ref.current && !ref.current.contains(e.target)) {
            setShowCalendar(false);
        }
    }, []);

    useEffect(() => {
        setEndDate(addDays(startDate, 6));
    }, [startDate]);

	useEffect(() => {
		document.addEventListener('mousedown', hideCalendar);
		return () => {
			document.removeEventListener('mousedown', hideCalendar);
		};
	}, [])

    const handleChange = (date: Date) => {
        setStartDate(getStartOfWeek(date));
        setShowCalendar(false);
    }

    return (
        <div className={styles.container} ref={ref}>
            <button onClick={() => setShowCalendar(!showCalendar)} className={styles.button}></button>
            {showCalendar && (
                <Calendar
                    calendarType="ISO 8601"
                    onChange={handleChange}
                    showNeighboringMonth={true}
                    value={(startDate && endDate) ? [startDate, endDate] : [null, null]} />
            )}
        </div>
    )
}

export default DatePicker