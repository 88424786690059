import React, { FunctionComponent } from 'react';
import { AssetStatus } from '../assetConstants';

type Props = {
    activeStep: number;
    index: number;
    assetStatus: AssetStatus;
    setActiveStep: (step) => void;
    title: string;
}

const AssetTab: FunctionComponent<Props> = ({activeStep, index, assetStatus, setActiveStep, title}) => {
    if(assetStatus === AssetStatus.Published) {
        if(activeStep === index) {
            return <div className="paper__tab  paper__tab--active paper__tab--complete">{index + 1}. {title}</div>
        }
        return <button className="paper__tab  paper__tab--complete" onClick={() => setActiveStep(index)}>{index + 1}. {title}</button>
    }
    if(activeStep === index) {
        return <div className="paper__tab  paper__tab--active">{index + 1}. {title}</div>
    }

    if(assetStatus !== AssetStatus.Created || index < activeStep) {
        return <button className="paper__tab  paper__tab--complete" onClick={() => setActiveStep(index)}>{index + 1}. {title}</button>
    }

    return <div className="paper__tab">{index + 1}. {title}</div>
}

export default AssetTab