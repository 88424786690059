import { FormikProps } from 'formik';
import { useContext, useEffect, useRef, useState } from 'react';
import { isSameDate } from '../../common/utils/dateUtils';
import { ExtendedDate, mapDate } from './models/ExtendedDate';
import { Option } from '../forms/models/Option';
import { ConfigContext } from '../configuration/ConfigContext';
import { PropertyAssociationContext } from '../propertyAssociation/PropertyAssociationContext';
import { getAvailableEndTimes } from './internalBookingService';

export type InternalBookingHook = {
    endOptions: Option[];
    endTimes: ExtendedDate[] | undefined;
    error: string | undefined;
    formikRef: React.MutableRefObject<FormikProps<any> | undefined>;
    hasRestrictions: boolean;
    selectedEndTime: ExtendedDate | null;
    selectedStartTime: ExtendedDate | null;
    setError: (error: string | undefined) => void;
    setSelectedEndTime: (endTime: ExtendedDate | null) => void;
    setSelectedStartTime: (startTime: ExtendedDate | null) => void;
    setStartTimes: (startTimes: ExtendedDate[]) => void;
    startOptions: Option[];
    startTimes: ExtendedDate[];
}

export const useInternalBooking = (assetId: string, userId?: string): InternalBookingHook => {
    const { apiBaseUrl } = useContext(ConfigContext);
    const { currentPropertyAssociationId } = useContext(PropertyAssociationContext);
    const formikRef = useRef<FormikProps<any>>()
    const [endOptions, setEndOptions] = useState<Option[]>([]);
    const [endTimes, setEndTimes] = useState<ExtendedDate[]>()
    const [error, setError] = useState<string | undefined>();
    const [hasRestrictions, setHasRestrictions] = useState<boolean>(false);
    const [selectedEndTime, setSelectedEndTime] = useState<ExtendedDate | null>(null);
    const [selectedStartTime, setSelectedStartTime] = useState<ExtendedDate | null>(null);
    const [startOptions, setStartOptions] = useState<Option[]>([]);
    const [startTimes, setStartTimes] = useState<ExtendedDate[]>([]);

    useEffect(() => {
        if(!selectedStartTime) {
            return;
        }

        const filteredTimes = startTimes.filter(x => isSameDate(selectedStartTime, x.date));
        const options = filteredTimes.map(x => ({ text: x.date.toLocaleString(), value: x.value }));
        setStartOptions(options);

        getAvailableEndTimes(
            apiBaseUrl,
            currentPropertyAssociationId,
            assetId,
            userId,
            selectedStartTime.value,
            (data) => {
                setHasRestrictions(data.restrictionsPrevent);
                const times = data.availableEndTimes.map((d) => mapDate(d.time, d.price));
                setEndTimes(times);
                const options = times.map(x => ({ text: x.date.toLocaleString(), value: x.value }));
                setEndOptions(options);
                formikRef.current?.setFieldValue('endTime', times.length > 0 ? times[0].value : null);
            },
            () => {
                setError('Kunde inte hämta tillgängliga tider.');
                setEndOptions([]);
                setEndTimes(undefined);
            }
        );
    }, [selectedStartTime])
    
    return {
        endOptions,
        endTimes,
        error,
        formikRef,
        hasRestrictions,
        selectedEndTime,
        selectedStartTime,
        setError,
        setSelectedEndTime,
        setSelectedStartTime,
        setStartTimes,
        startOptions,
        startTimes,
    };
}