import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { ConfigContext } from '../../configuration/ConfigContext';
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext';
import { ConversationContext } from '../ConversationContext';
import { getConversationMessage, getConversations, getGroupConversations } from '../conversationService';
import { ConversationModel, PagedConversationModel } from '../models/ConversationModel';

type States = {
    appendConversations: () => void;
    conversation: ConversationModel | null;
    conversations: ConversationModel[];
    resetConversations: () => void;
    id: string;
    isLoading: boolean;
    totalCount: number;
};

export const useConversations = (showGroups: boolean): States => {
    const { apiBaseUrl } = useContext(ConfigContext);
    const { currentPropertyAssociationId } = useContext(PropertyAssociationContext);
    const { lastUpdatedConversationId, lastMessageId, unreadConversationIds } = useContext(ConversationContext);
    const { push } = useHistory();
    const { id } = useParams<any>();
    const [conversation, setConversation] = useState<ConversationModel | null>(null);
    const [conversations, setConversations] = useState<ConversationModel[]>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        fetchConversations();
    }, []);

    useEffect(() => {
        if(!conversations || conversations.length === 0) {
            return;
        }

        if(id && conversation?.conversationId === id) {
            return;
        }

        if (id) {
            const conversation = conversations?.find(x => x.conversationId === id);
            if(!conversation) {
                setConversation(null);
                return;
            }

            setConversation(conversation);
            return;
        }

        const firstId = conversations[0].conversationId;
        if(showGroups) {
            push(`/messages/groups/${firstId}`);
        }
        else {
            push(`/messages/conversations/${firstId}`);
        }
    }, [id, conversations]);

    useEffect(() => {
        if (!unreadConversationIds) {
            return;
        }

        const updatedConversations = conversations.map(x => {
            return {
                ...x,
                unread: unreadConversationIds.includes(x.conversationId),
            };
        });

        setConversations(updatedConversations);

        if(conversation) {
            setConversation({
                ...conversation,
                unread: unreadConversationIds.includes(conversation.conversationId),
            });
        }    
    }, [unreadConversationIds]);

    useEffect(() => {
        if (!lastUpdatedConversationId || !lastMessageId) {
            return;
        }

        const conversation = conversations?.find(x => x.conversationId === lastUpdatedConversationId);
        if (!conversation) {
            return;
        }

        getConversationMessage(
            apiBaseUrl,
            currentPropertyAssociationId,
            lastUpdatedConversationId,
            lastMessageId,
            (message) => {
                const updatedConversation: ConversationModel = {
                    ...conversation,
                    latestMessage: message,
                    unread: unreadConversationIds.includes(id),
                };

                const updatedConversations = [updatedConversation, ...conversations.filter(x => x.conversationId !== lastUpdatedConversationId)];
                setConversations(updatedConversations);
            },
            () => { /* Non-lethal error */ }
        )
    }, [lastUpdatedConversationId, lastMessageId]);

    const appendConversations = useCallback(() => {
        const endpoint = showGroups ? getGroupConversations : getConversations;

        endpoint(
            apiBaseUrl,
            currentPropertyAssociationId,
            currentPage + 1,
            (data: PagedConversationModel) => {
                const {currentPage, results} = data.conversationPage;
                setCurrentPage(currentPage);
                setTotalCount(totalCount);
                setConversations([...conversations, ...results]);
            },
            () => {
                console.error('Kunde inte hämta konversationer');
            }
        );
    }, [apiBaseUrl, currentPropertyAssociationId, currentPage, showGroups, conversations])

    const fetchConversations = useCallback(() => {
        const endpoint = showGroups ? getGroupConversations : getConversations;

        endpoint(
            apiBaseUrl,
            currentPropertyAssociationId,
            0,
            (data: PagedConversationModel) => {
                const {currentPage, results, totalCount} = data.conversationPage;
                setCurrentPage(currentPage);
                setTotalCount(totalCount);
                setConversations(results);
                setIsLoading(false)
            },
            () => {
                console.error('Kunde inte hämta konversationer');
            }
        );
    }, [apiBaseUrl, currentPropertyAssociationId, showGroups])

    const resetConversations = useCallback(() => {
        setCurrentPage(0);
        setTotalCount(0);
        setConversation(null);
        setConversations([]);
        fetchConversations();
    }, [fetchConversations]);

    return { appendConversations, conversation, conversations, resetConversations, id, totalCount, isLoading };
}