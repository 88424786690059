import { FunctionComponent, useState } from 'react';
import { tryFormatDateTime } from '../../../common/utils/dateUtils';
import { BookingDetails } from '../../bookings/models/BookingDetails';
import { InvoiceStatus } from '../../bookings/models/InvoiceStatus';
import { BookingStatus } from '../../bookings/models/BookingStatus';
import Button from '../../forms/Button';
import Modal from '../../modal/Modal';
import PreviewBlock from '../../shared/PreviewBlock';

type Props = {
    booking: BookingDetails;
    hasMultipleObjects: boolean;
    isArchived: boolean;
    onClose: () => void;
    showCancellationView?: () => void;
    showMessageView?: () => void;
}

const DetailsView: FunctionComponent<Props> = ({ booking, hasMultipleObjects, isArchived, onClose, showCancellationView, showMessageView }) => {
    const [ buttons ] = useState(getButtons(onClose, showCancellationView, showMessageView));

    const title = hasMultipleObjects ? `${booking.assetName} - ${booking.assetObjectName}` : booking.assetName;
    let apartmentInfo = booking.apartmentAddress ? booking.apartmentAddress : '';
    if(booking.apartmentNumber) {
        apartmentInfo += ` (lgh ${booking.apartmentNumber})`;
    }

    return (
        <Modal onClose={onClose} buttons={buttons} title={title} visible={true}>
            <PreviewBlock title="Bokad av" icon="person">
                <div className="d-flex flex-wrap">
                    <div className="me-1">{booking.userName}{apartmentInfo ? ', ' : ''}</div>
                    <div>{apartmentInfo}</div>
                </div>
                
                {tryFormatDateTime(booking.created)}<br />

                <div className="preview__block-heading mt-4">Bokningstyp</div>
                {getBookingType(booking)}
            </PreviewBlock>

            <PreviewBlock title="Telefonnummer" icon="phone">
                {booking.isIntermediateBooking ? booking.intermediateInfo?.invoicePhoneNumber : booking.userPhoneNumber}
            </PreviewBlock>

            <PreviewBlock title="Period" icon="calendar">
                {getDurationText(booking)}
            </PreviewBlock>

            <PreviewBlock title="Pris" icon="price">
                {getPriceText(booking)}
            </PreviewBlock>

            {isArchived && (
                <PreviewBlock title="Status" icon="exclamation">
                    {renderStatus(booking)}
                </PreviewBlock>
            )}

            <PreviewBlock title="Bokning" icon="hash">
                {booking.bookingReference}
            </PreviewBlock>

            {booking.isInternalBooking && booking.internalBookingComment && (
                <PreviewBlock title="Kommentar" icon="comment">
                    {booking.internalBookingComment}
                </PreviewBlock>
            )}
        </Modal>
    )
}

const getButtons = (onClose: () => void, showCancellationView?: () => void, showMessageView?: () => void) => (
    <>
        {showCancellationView && <Button text="Avboka" onClick={showCancellationView} />}
        {showMessageView && <Button text="Skicka meddelande" onClick={showMessageView} />}
        <Button text="Stäng" isPrimary={false} additionalClassName="btn-secondary" onClick={onClose} />
    </>
)

const getBookingType = (booking: BookingDetails) => {
    if (booking.isInternalBooking) {
        return 'Internbokning';
    }

    if (booking.isIntermediateBooking) {
        return `Förmedlingsbokning gjord av ${booking.intermediateInfo?.bookerFirstName} ${booking.intermediateInfo?.bookerLastName}`;
    }

    return 'Boendebokning';
}

const getDurationText = (booking: BookingDetails): string => {
    if (!booking.startTime || !booking.endTime) {
        return 'Ingen period tillgänglig'
    }

    if (isOnSameDay(booking.startTime, booking.endTime)) {
        return `${booking.startTime.slice(0, 10)}, kl ${booking.startTime.slice(11, 16)} - kl ${booking.endTime.slice(11, 16)}`
    }

    return `${booking.startTime.slice(0, 10)}, kl ${booking.startTime.slice(11, 16)} -  ${booking.endTime.slice(0, 10)}, kl ${booking.endTime.slice(11, 16)}`;
}

const getPriceText = (booking: BookingDetails): string => {
    if (booking.isInternalBooking) {
        return 'Internbokningar är gratis';
    }

    if (booking.price > 0) {
        return `${booking.price} kr`;
    }
    
    return 'Gratis';
}

const isOnSameDay = (startTime: string, endTime: string): boolean => {
    const startDate = new Date(startTime).getDate();
    const endDate = new Date(endTime).getDate();
    return startDate === endDate;
}

const renderStatus = (booking: BookingDetails): string => {
    let status = '';

    if (booking.status === BookingStatus.Cancelled) {
        status = `Avbokad: ${booking.cancellationTime?.slice(0, 10)}, kl ${booking.cancellationTime?.slice(11, 16)}.`;
    }
    else {
        status = 'Genomförd.';
    }

    switch (booking.generalInvoiceStatus) {
        case InvoiceStatus.Cancelled:
            status += ' Faktura annulerad.';
            break;
        case InvoiceStatus.Pending:
            status += ' Faktura väntande.';
            break;
        case InvoiceStatus.HandledExternally:
            status += ' Hanterad externt.';
            break;
        case InvoiceStatus.Paid:
            status += ' Markerad som betald.';
            break;
        case InvoiceStatus.None:
        default:
            break;
    }

    return status;
}

export default DetailsView