export enum AssetStatus {
    Created = 0,
    Published = 1,
    UnPublished = 2,
}

export enum TimeSelectionType {
    None = 0,
    Slot = 1, // Enskilda slots
    Startstop = 2, // Inga glapp i tid
    Day = 3, // Inga glapp i dagar
    Subscription = 4
}

export enum WeekDay {
    Monday = 0,
    Tuesday = 1,
    Wednesday = 2,
    Thursday = 3,
    Friday = 4,
    Saturday = 5,
    Sunday = 6
}

export enum BookingCancellationTerms {
    Free = 0,
    Free24 = 1,
    Free48 = 2,
    Free1w = 7,
}

export const MESSAGES_SAVE_FAILED = 'Ett fel uppstod vid sparningen, försök igen.';
