import { FunctionComponent, useEffect } from 'react';
import { AssetObject } from '../../assets/models/AssetObject';
import ObjectCheckbox from './ObjectCheckbox';
import styles from './ObjectSelector.module.scss';

type Props = {
    objectFilter: string[];
    objects: AssetObject[];
    setObjectFilter: (objectFilter: string[]) => void;
}

const ObjectSelector: FunctionComponent<Props> = ({objectFilter, objects, setObjectFilter}) => {
    useEffect(() => {
        if(!objects) {
            return;
        }

        setObjectFilter(objects.map(x => x.assetObjectId));
    }, [objects]);

    const onChange = (assetObjectId: string, isSelected: boolean) => {
        if(isSelected) {
            setObjectFilter([...objectFilter, assetObjectId]);
        } else {
            setObjectFilter(objectFilter.filter(x => x !== assetObjectId));
        }
    }

    return (
        <div className={styles.container}>
            {objects.map((x, index) => <ObjectCheckbox key={x.assetObjectId} index={index} {...x} isSelected={objectFilter.includes(x.assetObjectId)} onChange={onChange} />)}
        </div>
    )
}

export default ObjectSelector