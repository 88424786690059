/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect } from 'react'

export default function useDebounce(effect:()=>void, deps:any, delay:number):any {
  useEffect(() => {
    const handler = setTimeout(() => effect(), delay)

    return () => clearTimeout(handler)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps || [], delay])
}
