import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { ConfigContext } from '../configuration/ConfigContext';
import { PropertyAssociationContext } from '../propertyAssociation/PropertyAssociationContext';
import { withWaitForPropertyAssociation } from '../propertyAssociation/withWaitForPropertyAssociation';
import VMABanner from './components/VMABanner';
import { AlertMessage } from './models/AlertMessage';
import { getStatuses } from './statusService';
import { getSeenAlerts, setAlertAsSeen } from './vmaStorage';

const VMA: FunctionComponent = () => {
    const [vma, setVMA] = useState<AlertMessage[]>();
    const { currentPropertyAssociation } = useContext(PropertyAssociationContext);
    const { apiBaseUrl } = useContext(ConfigContext);
    const [ seenAlerts ] = useState(getSeenAlerts());

    useEffect(() => {
        if(!currentPropertyAssociation) {
            return;
        }

        getStatuses(apiBaseUrl, currentPropertyAssociation.propertyAssociationId, (data) => {
            setVMA(data.alertMessages);
        }, () => {
            console.error('Failed to load VMA');
        });
    }, [])

    if(!vma) {
        return null;
    }

    return (
        <div>
            {vma.map((status) => {
                if(seenAlerts.includes(status.alertMessageId)) {
                    return null;
                }

                return <VMABanner key={status.alertMessageId} {...status} onDismiss={setAlertAsSeen} />
            })}
        </div>
    )
}

export default withWaitForPropertyAssociation(VMA)