import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { tryFormatDate } from '../../../common/utils/dateUtils';
import { BookableAsset } from '../../assets/models/Asset';
import { BookingRestriction } from '../models/BookingRestrictions';
import { TimeSelectionType } from '../../assets/assetConstants';

type Props = {
    asset: BookableAsset;
    calendarDate: Date;
    restrictions: BookingRestriction[];
}

type Restriction = {
    activeLeft?: number;
    availableActive?: number;
    availableBlocks?: number;
    availableOccasions?: number;
    blocksLeft?: number;
    endDate: string;
    isCurrent: boolean;
    occasionsLeft?: number;
    startDate: string;
}

const Restrictions: FunctionComponent<Props> = ({asset, calendarDate, restrictions}) => {
    const [restrictionList, setRestrictionList] = useState<Restriction[]>([]);
    const isDaily = asset.timeSelectionType === TimeSelectionType.Day;

    useEffect(() => {
        const restrictionList = restrictions.map(x => ({
            isCurrent: isBetweenDates(calendarDate, x.interval.start, x.interval.end),
            startDate: tryFormatDate(x.interval.start),
            endDate: tryFormatDate(x.interval.end),
            activeLeft: x.activeLeft,
            blocksLeft: x.blocksLeft,
            occasionsLeft: x.occasionsLeft,
            availableActive: x.availableActive,
            availableBlocks: x.availableBlocks,
            availableOccasions: x.availableOccasions,
        }));
        setRestrictionList(restrictionList);
    }, [calendarDate, restrictions])

    if(restrictionList.length === 0) {
        return null;
    }

    return (
        <div>
            <h3 className="mt-4">Bokningsrestriktioner</h3>
            <p>
                {restrictionList.map(x => {
                    let interval = `${x.startDate} - ${x.endDate}: `;
                    let amount: string;

                    if (x.availableActive) {
                        interval = '';
                        amount = `${x.activeLeft} av ${x.availableActive} aktiva bokningar kvar`;
                    }
                    else if (x.availableBlocks) {
                        amount = `${x.blocksLeft} av ${x.availableBlocks} ${isDaily ? 'dygn' : 'timmar'} kvar`;
                    }
                    else if (x.availableOccasions) {
                        amount = `${x.occasionsLeft} av ${x.availableOccasions} tillfällen kvar`;
                    }
                    else {
                        return <Fragment key={x.startDate}>Inga restriktioner.</Fragment>;
                    }

                    if (x.isCurrent) {
                        return (
                            <strong key={x.startDate}>
                                {interval}{amount}<br />
                            </strong>
                        )
                    }

                    return (
                        <Fragment key={x.startDate}>
                            {interval}{amount}<br />
                        </Fragment>
                    )
                })}
            </p>
        </div>
    )
}

export default Restrictions

function isBetweenDates(date: Date, start: string, end: string): any {
    const startDate = new Date(start);
    const endDate = new Date(end);
    return date >= startDate && date < endDate;
}
