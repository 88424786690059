import cx from 'classnames';
import { Field, getIn } from 'formik';
import React, { FunctionComponent } from 'react';
import Label from './Label';

type Props = {
    disabled?: boolean;
    errors: any;
    label?: string;
    name: string;
    placeholder?: string;
    rows?: number;
    touched: any;
    values: any;
}

const TextArea: FunctionComponent<Props> = ({disabled, label, name, placeholder, rows = 3, values, errors, touched}) => {
    const value = getIn(values, name);
	const error = getIn(errors, name);
    const isTouched = getIn(touched, name);

    return (
        <div className="mb-3">
            {label && <Label name={name} text={label} />}
            <Field
                autoComplete="off"
                className={cx('form-control', { 'is-invalid': error && isTouched }, { 'is-valid': !error && isTouched })}
                component="textarea"
                disabled={disabled}
                id={name}
                name={name}
                placeholder={placeholder}
                rows={rows}
                value={value}
            />
            {error && isTouched && <div className="field-error text-danger">{error}</div>}
        </div>
    )
}

export default TextArea
