import { FunctionComponent, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { withAuth } from '../authentication/withAuth'
import { ConfigContext } from '../configuration/ConfigContext'
import Button from '../forms/Button'
import { getInvitation, respondToInvitation } from './invitationService'
import InvitationLayout from './layouts/InvitationLayout'
import { InvitationInfoResponse } from './models/InvitationInfoResponse'
import { ToastContext } from '../toaster/ToastContext'
import { ToastMessageType } from '../toaster/models/ToastMessageType'

const Invitation: FunctionComponent = () => {
    const { apiBaseUrl } = useContext(ConfigContext);
    const { addToast } = useContext(ToastContext);
    const { id } = useParams() as any;
    const [invitation, setInvitation] = useState<InvitationInfoResponse>();
    const [error, setError] = useState<string>();

    useEffect(() => {
        getInvitation(apiBaseUrl, id, setInvitation, () => setError('Vi kunde inte hitta inbjudan. Kontakta din förening för att få en ny länk.'));
    }, [id]);

    const gotoStartPage = () => document.location.href = '/';

    const acceptInvitation = () => {
        respondToInvitation(apiBaseUrl, id, { agree: true }, gotoStartPage, () => addToast('Vi kunde inte acceptera din inbjudan. Kontakta din förening för att få en ny länk.', '', ToastMessageType.Error));
    }

    const declineInvitation = () => {
        respondToInvitation(apiBaseUrl, id, { agree: false }, gotoStartPage, () => addToast('Vi kunde spara ditt svar. Försök igen.', '', ToastMessageType.Error));
    }

    if(error) {
        return (
            <InvitationLayout heading="Ett fel har uppstått">
                <p>{error}</p>
                <Button text="Gå till startsidan" isPrimary={false} additionalClassName="btn-secondary" onClick={() => document.location.href = '/'} />
            </InvitationLayout>
        )
    }

    if(!invitation) {
        return null
    }

    return (
        <InvitationLayout heading={`Bekräfta din inbjudan till ${invitation.propertyAssociationName}`}>
            <p>Du har blivit inbjuden att få tillgång till {invitation.propertyAssociationName}. Du har möjlighet att antingen acceptera eller avvisa din inbjudan med hjälp av knapparna nedan.</p>
            <Button text="Jag accepterar inbjudan" onClick={acceptInvitation} />
            <Button text="Nej tack, jag avvisar inbjudan" isPrimary={false} onClick={declineInvitation} />
        </InvitationLayout>
    )
}

export default withAuth(Invitation)