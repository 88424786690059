import cx from 'classnames';
import { FunctionComponent, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AssetListItem } from './models/AssetListItem';
import SubNavigation from '../navigation/SubNavigation';
import { withWaitForPropertyAssociation } from '../propertyAssociation/withWaitForPropertyAssociation';
import { AssetStatus } from './assetConstants';
import { isDraft, isUnpublished } from './assetHelpers';
import { useAssets } from './assetService';

const AssetMenu: FunctionComponent = () => {
    const { assets, isLoading } = useAssets();
    const [expanded, setExpanded] = useState(true)
    const location = useLocation();
    const currentPath = location.pathname;

    return (
        <SubNavigation heading="Resurser" isExpanded={expanded} onExpand={setExpanded}>
            <div className="submenu__content">
                { renderContent(assets, isLoading, currentPath) }
            </div>
            <div className="submenu__footer">
                <Link to="/assets/create" className="btn btn-primary">Lägg till resurs</Link>
            </div>
        </SubNavigation>
    );
}

export default withWaitForPropertyAssociation(AssetMenu)

const renderContent = (assets: AssetListItem[] | undefined, isLoading: boolean, currentPath: string) => {
    if(isLoading) {
        return (
            <div className="submenu__placeholder">
                <div>Laddar...</div>
            </div>
        )
    }
    
    if(!assets || assets.length === 0) {
        return (
            <div className="submenu__placeholder">
                <div>Ni har ännu inte lagt upp några resurser.</div>
            </div>
        )
    }

    const orderedList = orderByStatusAndName(assets);

    return (
        <div className="submenu__links">
            { orderedList.map(x => renderMenuItem(x, currentPath))}
        </div>
    )
}

const renderMenuItem = ({name, assetId, status}: AssetListItem, currentPath: string) => {
    const path = `/assets/edit/${assetId}/`;
    const isCurrentPath = currentPath === path || currentPath.endsWith(assetId);
    const classNames = getClassNames(status, isCurrentPath);

    return <Link to={path} key={assetId} className={classNames}>{name}</Link>
}

const getClassNames = (assetStatus: AssetStatus, isCurrentPath: boolean): string | undefined => {
    return cx([
        'submenu__link', 
        { 'submenu__link--draft': isDraft(assetStatus) }, 
        { 'submenu__link--unpublished': isUnpublished(assetStatus) },
        { 'submenu__link--current': isCurrentPath }
    ]);
}

const orderByStatusAndName = (assets: AssetListItem[]) => {
    const sortedAssets = assets.sort((a, b) => a.name.localeCompare(b.name));
    const publishedAssets = sortedAssets.filter(x => x.status === AssetStatus.Published);
    const unpublishedAssets = assets.filter(x => x.status !== AssetStatus.Published);
    return publishedAssets.concat(unpublishedAssets);
}

