import { FunctionComponent, useEffect, useState } from 'react';
import { Asset } from '../../assets/models/Asset';
import { createAssetSchedule } from '../bookingOverviewHelpers';
import { AssetSchedule } from '../models/AssetSchedule';
import { GetAssetBookingsResponse } from '../models/GetAssetBookingsResponse';
import Day from './Day';
import Week from './Week';

type Props = {
    asset: Asset;
    bookings: GetAssetBookingsResponse;
    objectFilter: string[];
    setSelectedBookingId: (bookingId: string) => void;
    startDate: Date;
}

const DetailedSchedule: FunctionComponent<Props> = ({asset, bookings, objectFilter, setSelectedBookingId, startDate}) => {
    const [ assetSchedule, setAssetSchedule ] = useState<AssetSchedule>();
    const [ focusedBookingId, setFocusedBookingId ] = useState<string | undefined>();
    const [ objects, setObjects ] = useState<string[]>([]);

    useEffect(() => {
        if(!bookings) {
            return;
        }

        const assetSchedule = createAssetSchedule(asset, bookings.assetBookings, startDate);
        setAssetSchedule(assetSchedule);
    }, [bookings]);

    useEffect(() => {
        if(!asset) {
            return;
        }

        setObjects(asset.objects.map(x => x.assetObjectId));
    }, [asset]);

    if(!assetSchedule) {
        return null;
    }

    return (
        <Week>
            {assetSchedule.days.map(x => <Day key={x.date.toISOString()} {...x} objectFilter={objectFilter} objects={objects} setSelectedBookingId={setSelectedBookingId} focusedBookingId={focusedBookingId} setFocusedBookingId={setFocusedBookingId} />)}
        </Week>
    )
}

export default DetailedSchedule