import { Fragment, FunctionComponent, useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { ConfigContext } from '../../configuration/ConfigContext';
import BooleanCheckBox from '../../forms/BooleanCheckBox';
import RadioBoxDropDown from '../../forms/RadioBoxDropDown';
import RadioButton from '../../forms/RadioButton';
import { Option } from '../../forms/models/Option';
import { requiredValidator } from '../../forms/validators';
import FormModal from '../../modal/FormModal';
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext';
import { BookingCancellationTerms, MESSAGES_SAVE_FAILED } from '../assetConstants';
import { isBookingTypePerDay } from '../assetHelpers';
import { updateBookingConditions } from '../assetService';
import { Asset } from '../models/Asset';

enum RestrictionType {
    OCCASIONS = 'OCCASIONS',
    BLOCKS = 'BLOCKS',
    NONE = 'NONE',
    ACTIVE = 'ACTIVE',
    ACTIVE_BLOCKS = 'ACTIVE_BLOCKS',
}

type Props = {
    initialValues: Asset;
    onClose: () => void;
    updateValues: (values) => void;
    visible: boolean;
}

type FormData = {
    bookingCancellationTerms: string;
    bookingMaxActive: string;
    bookingMaxActiveBlocks: string;
    bookingMaxBlocksPerInterval: string;
    bookingMaxOccasionsPerInterval: string;
    maxPerMonthsInterval: string;
    lateCancellationFeePercentage: string;
    requireVerifiedPhoneNumber: boolean;
    restrictionType: RestrictionType;
}

const validationSchema = Yup.object().shape({
    bookingCancellationTerms: requiredValidator,
    bookingMaxActive: Yup.string().when('restrictionType', {
        is: RestrictionType.ACTIVE,
        then: requiredValidator,
    }),
    bookingMaxActiveBlocks: Yup.string().when('restrictionType', {
        is: RestrictionType.ACTIVE_BLOCKS,
        then: requiredValidator,
    }),
    bookingMaxBlocksPerInterval: Yup.string().when('restrictionType', {
        is: RestrictionType.BLOCKS,
        then: requiredValidator,
    }),
    bookingMaxOccasionsPerInterval: Yup.string().when('restrictionType', {
        is: RestrictionType.OCCASIONS,
        then: requiredValidator,
    }),
    maxPerMonthsInterval: Yup.string().when('restrictionType', {
        is: (value) => value === RestrictionType.OCCASIONS || value === RestrictionType.BLOCKS,
        then: requiredValidator,
    }),
})

const buildOptions = (maxCount, singular, plural) => {
    const options: Option[] = []
    for (let index = 1; index <= maxCount; index++) {
        options.push({
            value: index.toString(),
            text: `${index.toString()} ${index === 1 ? singular : plural}`,
        })
    }
    return options
}

const buildOptionsWithNone = (maxCount, singular, plural, noneText) => {
    const options = buildOptions(maxCount, singular, plural);
    options.unshift({ value: '0', text: noneText });
    return options;
}

const MAX_ACTIVE_OPTIONS = buildOptions(10, 'bokning', 'bokningar');

const MAX_ACTIVE_DAYS_OPTIONS = buildOptionsWithNone(10, 'dygn', 'dygn', 'Ingen begränsning');

const MAX_ACTIVE_HOURS_OPTIONS = buildOptionsWithNone(10, 'timme', 'timmar', 'Ingen begränsning');

const MAX_DAYS_OPTIONS = buildOptions(10, 'dygn', 'dygn');

const MAX_HOURS_OPTIONS = buildOptions(10, 'timme', 'timmar');

const INVTERVAL_OPTIONS: Option[] = [{ value: '3', text: 'Per kvartal' }, { value: '12', text: 'Per år' }];

const ConditionsModal: FunctionComponent<Props> = ({ initialValues, onClose, updateValues, visible }) => {
    const { apiBaseUrl } = useContext(ConfigContext);
    const { currentPropertyAssociationId } = useContext(PropertyAssociationContext);
    const [error, setError] = useState<string | undefined>();
    const [formData, setFormData] = useState<FormData | undefined>();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onSubmit = (values: FormData) => {
        setIsSubmitting(true);
        const { bookingCancellationTerms, bookingMaxActive, bookingMaxActiveBlocks, bookingMaxOccasionsPerInterval, bookingMaxBlocksPerInterval, 
            maxPerMonthsInterval, lateCancellationFeePercentage, requireVerifiedPhoneNumber, restrictionType } = values;

        updateBookingConditions(apiBaseUrl, currentPropertyAssociationId, initialValues.assetId,
            {
                bookingCancellationTerms: bookingCancellationTerms,
                bookingMaxActive: restrictionType === RestrictionType.ACTIVE ? bookingMaxActive : 0,
                bookingMaxActiveBlocks: restrictionType === RestrictionType.ACTIVE_BLOCKS ? bookingMaxActiveBlocks : 0,
                bookingMaxOccasionsPerInterval: restrictionType === RestrictionType.OCCASIONS ? bookingMaxOccasionsPerInterval : 0,
                bookingMaxBlocksPerInterval: restrictionType === RestrictionType.BLOCKS ? bookingMaxBlocksPerInterval : 0,
                maxPerMonthsInterval: restrictionType === RestrictionType.BLOCKS || restrictionType === RestrictionType.OCCASIONS ? maxPerMonthsInterval : 0,
                lateCancellationFeePercentage: bookingCancellationTerms === BookingCancellationTerms.Free.toString() ? 0 : lateCancellationFeePercentage,
                requireVerifiedPhoneNumber: requireVerifiedPhoneNumber,
            },
            (data) => {
                setIsSubmitting(false);
                updateValues(data);
                onClose();
            },
            () => {
                setIsSubmitting(false);
                setError(MESSAGES_SAVE_FAILED);
            }
        )
    }

    useEffect(() => {
        setFormData({
            bookingCancellationTerms: initialValues.bookingCancellationTerms?.toString(),
            bookingMaxOccasionsPerInterval: toValueOrEmpty(initialValues.bookingMaxOccasionsPerInterval),
            bookingMaxActive: toValueOrEmpty(initialValues.bookingMaxActive),
            bookingMaxActiveBlocks: toValueOrEmpty(initialValues.bookingMaxActiveBlocks),
            bookingMaxBlocksPerInterval: toValueOrEmpty(initialValues.bookingMaxBlocksPerInterval),
            maxPerMonthsInterval: toValueOrEmpty(initialValues.maxPerMonthsInterval),
            lateCancellationFeePercentage: initialValues.lateCancellationFeePercentage === 0 ? '100' : initialValues.lateCancellationFeePercentage?.toString(),
            requireVerifiedPhoneNumber: initialValues.requireVerifiedPhoneNumber,
            restrictionType: getRestrictionType(initialValues),
        })
    }, [initialValues])

    if (!formData) {
        return null;
    }

    const isDaily = isBookingTypePerDay(initialValues);

    return (
        <FormModal initialValues={formData} title="Bokningsvillkor" validationSchema={validationSchema} visible={visible} isSubmitting={isSubmitting} onClose={onClose} onSubmit={onSubmit}>
            {({ values }) => {
                return (
                    <Fragment>
                        <p className="mt-4 mb-3">Hur många bokningar ska en boende kunna göra på denna resurs under en viss period?</p>
                        <div className="mt-3 mb-4">
                            <div className="mb-3">
                                <RadioButton name="restrictionType" value={RestrictionType.NONE} label="Obegränsat" />
                            </div>
                            <div className="mb-3">
                                <RadioButton name="restrictionType" value={RestrictionType.ACTIVE} label="Max antal aktiva bokningar" />
                            </div>
                            <div className="mb-3">
                                <RadioButton name="restrictionType" value={RestrictionType.ACTIVE_BLOCKS} label={`Max antal aktiva ${isDaily ? 'dygn' : 'timmar'}`} />
                            </div>
                            <div className="mb-3">
                                <RadioButton name="restrictionType" value={RestrictionType.OCCASIONS} label="Max antal bokningar" />
                            </div>

                            <RadioButton name="restrictionType" value={RestrictionType.BLOCKS} label={`Max antal ${isDaily ? 'dygn' : 'timmar'}`} />
                        </div>
                        {values.restrictionType === RestrictionType.ACTIVE && (
                            <Fragment>
                                <p className="mt-4 mb-2">Hur många aktiva bokningar ska en boende kunna ha på denna resurs?</p>
                                <div className="mb-4 d-flex">
                                    <div className="me-2">
                                        <RadioBoxDropDown name="bookingMaxActive" label="Välj max antal aktiva" options={MAX_ACTIVE_OPTIONS} values={values} />
                                    </div>
                                </div>
                            </Fragment>
                        )}
                        {values.restrictionType === RestrictionType.OCCASIONS && (
                            <Fragment>
                                <p className="mt-4 mb-2">Hur många bokningar ska en boende kunna ha på denna resurs under en viss period?</p>
                                <div className="mb-4 d-flex">
                                    <div className="me-2">
                                        <RadioBoxDropDown name="bookingMaxOccasionsPerInterval" label="Välj max antal bokningar" options={MAX_ACTIVE_OPTIONS} values={values} />
                                    </div>
                                    <RadioBoxDropDown name="maxPerMonthsInterval" label="Välj period" options={INVTERVAL_OPTIONS} values={values} />
                                </div>
                            </Fragment>
                        )}
                        {values.restrictionType === RestrictionType.BLOCKS && (
                            <Fragment>
                                <p className="mt-4 mb-2">Hur många {isDaily ? 'dygn' : 'timmar'} ska en boende kunna boka denna resurs under en viss period?</p>
                                <div className="mb-4 d-flex">
                                    <div className="me-2">
                                        <RadioBoxDropDown name="bookingMaxBlocksPerInterval" label={`Välj max antal ${isDaily ? 'dygn' : 'timmar'}`} options={isDaily ? MAX_DAYS_OPTIONS : MAX_HOURS_OPTIONS} values={values} />
                                    </div>
                                    <RadioBoxDropDown name="maxPerMonthsInterval" label="Välj period" options={INVTERVAL_OPTIONS} values={values} />
                                </div>
                            </Fragment>
                        )}
                        {values.restrictionType === RestrictionType.ACTIVE_BLOCKS && (
                            <Fragment>
                                <p className="mt-4 mb-2">Hur många {isDaily ? 'dygn' : 'timmar'} ska en boende kunna ha bokade framåt i tiden?</p>
                                <div className="mb-4">
                                    <RadioBoxDropDown name="bookingMaxActiveBlocks" label={`Välj max antal aktiva ${isDaily ? 'dygn' : 'timmar'}`} options={isDaily ? MAX_ACTIVE_DAYS_OPTIONS : MAX_ACTIVE_HOURS_OPTIONS} values={values} />
                                </div>
                            </Fragment>
                        )}
                        {initialValues.price > 0 && (
                            <Fragment>
                                <p>Ange bokningsvillkoren för resursen. Begränsningar gäller ej för bokningar i styrelsens namn.</p>
                                <div className="d-flex my-3">
                                    <div className="w-180px">
                                        <RadioButton label="Fri" name="bookingCancellationTerms" value={`${BookingCancellationTerms.Free}`} />
                                    </div>
                                    <label className="modal-label modal-label--light" htmlFor="todo_false">Användaren kan avboka när som helst utan kostnad</label>
                                </div>
                                <div className="d-flex my-3">
                                    <div className="w-180px">
                                        <RadioButton label="Flexibel 24 tim" name="bookingCancellationTerms" value={`${BookingCancellationTerms.Free24}`} />
                                    </div>
                                    <label className="modal-label modal-label--light" htmlFor="todo_false">Kostnadsfri avbokning fram till 24 timmar före bokad tid</label>
                                </div>
                                <div className="d-flex my-3">
                                    <div className="w-180px">
                                        <RadioButton label="Flexibel 48 tim" name="bookingCancellationTerms" value={`${BookingCancellationTerms.Free48}`} />
                                    </div>
                                    <label className="modal-label modal-label--light" htmlFor="todo_false">Kostnadsfri avbokning fram till 48 timmar före bokad tid</label>
                                </div>
                                <div className="d-flex my-3">
                                    <div className="w-180px">
                                        <RadioButton label="Flexibel 1 vecka" name="bookingCancellationTerms" value={`${BookingCancellationTerms.Free1w}`} />
                                    </div>
                                    <label className="modal-label modal-label--light" htmlFor="todo_false">Kostnadsfri avbokning fram till 1 vecka före bokad tid</label>
                                </div>
                            </Fragment>
                        )}
                        {initialValues.price > 0 && values.bookingCancellationTerms !== BookingCancellationTerms.Free.toString() && (
                            <Fragment>
                                <p className="mt-4 mb-2">Ej avbokad tid debiteras med</p>
                                <div className="mb-4">
                                    <div className="my-3">
                                        <RadioButton name="lateCancellationFeePercentage" value="100" label="Hela beloppet" />
                                    </div>
                                    <div className="my-3">
                                        <RadioButton name="lateCancellationFeePercentage" value="50" label="Halva beloppet" />
                                    </div>
                                </div>
                            </Fragment>
                        )}
                        <BooleanCheckBox name="requireVerifiedPhoneNumber" label="Kräv att den boende har ett verifierat telefonnummer för att kunna boka denna resurs" />
                        {error && <div className="alert alert-danger">{error}</div>}
                    </Fragment>
                )
            }}
        </FormModal>
    )
}

const getRestrictionType = (asset: Asset) => {
    if (asset.bookingMaxActive > 0) {
        return RestrictionType.ACTIVE;
    }
    if (asset.bookingMaxActiveBlocks > 0) {
        return RestrictionType.ACTIVE_BLOCKS;
    }
    if (asset.bookingMaxOccasionsPerInterval > 0) {
        return RestrictionType.OCCASIONS;
    }
    if (asset.maxPerMonthsInterval > 0) {
        return RestrictionType.BLOCKS;
    }
    return RestrictionType.NONE;
}

const toValueOrEmpty = (value?: number) => {
    if(!value || value === 0) {
        return '';
    }
    return value.toString();
}

export default ConditionsModal