import { TimeSelectionType } from '../assetConstants';
import { AssetTypeDefinition } from './assetTypes';

export const ASSETTYPE_LAUNDRYROOM = 'LaundryRoom';

export const DEFAULTS_LAUNDRYROOM: AssetTypeDefinition = {
    alwaysUseDays: false,
    assetTypeTag: ASSETTYPE_LAUNDRYROOM,
    backdrop: '/images/defaults/backdrop/RB_gemensamma-resurser_tvattstuga.png',
    canChangeToDaily: false,
    canChangeToHourly: false,
    canChangeToInterval: false,
    endTimeNextDay: false,
    endTimeRestOfDay: false,
    image: '/images/defaults/preview/RB_gemensamma-resurser_tvattstuga.png',
    isSingleton: false,
    name: 'Tvättstuga',
    nameDefinitiveForm: 'tvättstugan',
    namePlural: 'tvättstugor',
    nameSingular: 'tvättstuga',
    options: [
        'Wifi',
        'Toalett',
        'Mangel',
        'Torkskåp',
        'Torktumlare',
    ],
    prefixName: true,
    serviceInformationText: 'Ex. Gemensam tvättstuga med möjlighet till tvätt, tork och mangling.',
    serviceInformationTitle: 'Boka tvättid när det passar dig',
    thumbnail: '/images/defaults/thumbnails/RB_gemensamma-resurser_tvattstuga.png',
    timeSelectionType: TimeSelectionType.Startstop
};
