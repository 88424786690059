import { FunctionComponent, useState } from 'react';
import { PropertyAssociationInfo } from '../models/PropertyAssociationInfo';

type Props = {
    setCurrentPropertyAssociation: (propertyAssociation: PropertyAssociationInfo) => void;
    value: PropertyAssociationInfo;
}

const BASE_CLASS_NAME = 'boxlist__item boxlist__item--slim boxlist__item--no-image';

const PropertyAssociationItem: FunctionComponent<Props> = ({ setCurrentPropertyAssociation, value }) => {
    const [className] = useState(getClassName(value));
    
    return (
        <button onClick={() => setCurrentPropertyAssociation(value)} className={className}>
            {value.name}
        </button>
    )
}

function getClassName(propertyAssociation: PropertyAssociationInfo) {
    const {endDate, isTrialSubscription} = propertyAssociation;

    if (endDate === null) {
        return `${BASE_CLASS_NAME} boxlist__item--valid`;
    }

    if (new Date(endDate) < new Date()) {
        return `${BASE_CLASS_NAME} boxlist__item--invalid`;
    }

    if (isTrialSubscription) {
        return `${BASE_CLASS_NAME} boxlist__item--trial`;
    }

    return `${BASE_CLASS_NAME} boxlist__item--warning`;
}

export default PropertyAssociationItem