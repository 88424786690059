import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

const AssetPublished: FunctionComponent = () => (
    <div className="paper__content  paper__main">
        <div className="paper__message">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="57" height="56"><path d="m328.84 109.75 48.57 24.32 24.28 48.63a16 16 0 0 0 28.62 0l24.28-48.63 48.57-24.32a16 16 0 0 0 0-28.66l-48.57-24.32-24.28-48.63c-5.43-10.85-23.19-10.85-28.62 0l-24.28 48.63-48.57 24.32a16 16 0 0 0 0 28.66zM396.5 83a16.1 16.1 0 0 0 7.16-7.16L416 51.14l12.34 24.74A16.1 16.1 0 0 0 435.5 83l24.72 12.38-24.72 12.42a16.2 16.2 0 0 0-7.16 7.16L416 139.7 403.66 115a16.2 16.2 0 0 0-7.16-7.16l-24.72-12.42zm106.66 318.53-48.57-24.31-24.28-48.63c-5.43-10.86-23.19-10.86-28.62 0l-24.28 48.63-48.57 24.31a16 16 0 0 0 0 28.67l48.57 24.31 24.28 48.63a16 16 0 0 0 28.62 0l24.28-48.63 48.57-24.31a16 16 0 0 0 0-28.67zm-67.66 26.71a16.17 16.17 0 0 0-7.16 7.17L416 460.15l-12.34-24.74a16.17 16.17 0 0 0-7.16-7.17l-24.72-12.37 24.72-12.38a16.17 16.17 0 0 0 7.16-7.17L416 371.59l12.34 24.73a16.17 16.17 0 0 0 7.16 7.17l24.72 12.38zM384 255.64a16.06 16.06 0 0 0-8.84-14.33L262.59 185 206.31 72.23c-5.43-10.86-23.19-10.86-28.62 0L121.41 185 8.84 241.31a16 16 0 0 0 0 28.67l112.57 56.36 56.28 112.71a16 16 0 0 0 28.62 0l56.28-112.71L375.16 270a16.09 16.09 0 0 0 8.84-14.36zm-140.5 44.43a16.1 16.1 0 0 0-7.16 7.16L192 396.06l-44.34-88.83a16.1 16.1 0 0 0-7.16-7.16l-88.72-44.43 88.72-44.42a16.2 16.2 0 0 0 7.16-7.16L192 115.23l44.34 88.83a16.2 16.2 0 0 0 7.16 7.16l88.72 44.42z" /></svg>
            <h1>Härligt!</h1>
            <p>Er resurs är nu publicerad och kan bokas direkt i Mitt Boende-appen.</p>
            <Link to="/assets/" className="btn btn-lg btn-primary btn-shadow">Tillbaka till startsidan</Link>
        </div>
    </div>
)

export default AssetPublished