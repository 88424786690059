import { FunctionComponent } from 'react';
import { PropertyAssociationArea } from '../models/PropertyAssociationArea';

type Props = {
    setCurrentArea: (area: PropertyAssociationArea) => void;
    value: PropertyAssociationArea;
}

const PropertyAssociationAreaItem: FunctionComponent<Props> = ({ setCurrentArea, value }) => (
    <button onClick={() => setCurrentArea(value)} className="boxlist__item boxlist__item--slim boxlist__item--no-image">
        {value.name}
    </button>
)

export default PropertyAssociationAreaItem