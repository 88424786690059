import cx from 'classnames';
import { Fragment, FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { downloadAsCsvFile } from '../../../common/utils/csvHelper';
import { tryFormatDateTimeRange } from '../../../common/utils/dateUtils';
import BookingModal from '../../bookingModal/BookingModal';
import { ConfigContext } from '../../configuration/ConfigContext';
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext';
import { BookingsSortDirection, BookingsSortOrder } from '../bookingConstants';
import { Booking } from '../models/Booking';
import { BookingStatus } from '../models/BookingStatus';
import { InvoiceStatus } from '../models/InvoiceStatus';
import { useBookingHistory } from '../useBookingHistory';
import AssetFilter from './AssetFilter';
import BookingItem from './BookingItem';
import NoBookings from './NoBookings';

type Props = {
    defaultSortDirection: BookingsSortDirection;
    defaultSortOrder: BookingsSortOrder;
    fetchFunction: (apiBaseUrl: string, propertyAssociationId: string, assetId: string | undefined, areaId: string | undefined, onSuccess: (data: Booking[]) => void, onFail: () => void) => void;
    filterNoBookingsHeading: string;
    filterNoBookingsText: string;
    isArchived: boolean;
    noBookingsHeading: string;
    noBookingsText: string;
};

const BookingList: FunctionComponent<Props> = ({
    defaultSortDirection,
    defaultSortOrder,
    fetchFunction,
    filterNoBookingsHeading,
    filterNoBookingsText,
    isArchived,
    noBookingsHeading,
    noBookingsText,
}) => {
    const { apiBaseUrl } = useContext(ConfigContext);
    const { currentPropertyAssociationId, currentArea } = useContext(PropertyAssociationContext);
    const [assetId, setAssetId] = useState<string | undefined>();
    const [bookings, setBookings] = useState<Booking[] | undefined>();
    const [bookingId, setBookingId] = useState<string | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const [sortDirection, setSortDirection] = useState(defaultSortDirection);
    const [sortedBookings, setSortedBookings] = useState<Booking[]>([]);
    const [sortOrder, setSortOrder] = useState(defaultSortOrder);
    useBookingHistory(setBookingId);
    
    const fetchBookings = () => {
        setIsLoading(true);
        fetchFunction(
            apiBaseUrl,
            currentPropertyAssociationId,
            assetId,
            currentArea?.propertyAssociationAreaId,
            (data: Booking[]) => {
                setIsLoading(false);
                setBookings(data ?? []);
            },
            () => {
                setIsLoading(false);
                setBookings([]);
            }
        );
    }

    useEffect(() => {
        fetchBookings();
    }, [assetId]);

    useEffect(() => {
        const bookingsSorted = sortBookings(bookings || [], sortOrder);
        
        if (sortDirection === BookingsSortDirection.Descending) {
            bookingsSorted.reverse();
        }

        setSortedBookings(bookingsSorted);
    }, [bookings, sortOrder, sortDirection]);

    const exportBookings = useCallback(() => {
        if(!sortedBookings) {
            return;
        }

        const data = sortedBookings.map((x) => {
            return [
                `#${x.bookingReference}` ?? '',
                x.assetName,
                tryFormatDateTimeRange(x.startTime, x.endTime),
                x.userName ?? '-',
                x.status === BookingStatus.Cancelled ? 'Avbokad' : 'Genomförd',
                getInvoiceStatusText(x.generalInvoiceStatus, x.status, x.hasFreeCancellation),
            ];
        });

        data.unshift(['Bokningsref.', 'Resurs', 'Tid', 'Bokad av', 'Status', 'Fakturastatus']);

        downloadAsCsvFile(data, 'Bokningshistorik.csv');
    }, [sortedBookings]);

    if (!bookings) {
        return null;
    }

    return (
        <Fragment>
            {(bookings.length === 0 && !assetId && !isLoading) ? (
                <NoBookings noBookingsHeading={noBookingsHeading} noBookingsText={noBookingsText} />
            ) : (
                <Fragment>
                    <AssetFilter exportBookings={exportBookings} setAssetId={setAssetId} />
                    <table className='bookings'>
                        <thead className='bookings__head'>
                            <tr>
                                {isArchived && renderHeader('Bokningsref.', BookingsSortOrder.Reference, sortOrder, sortDirection, setSortOrder, setSortDirection, 'd-none d-md-table-cell')}
                                {renderHeader('Resurs', BookingsSortOrder.Name, sortOrder, sortDirection, setSortOrder, setSortDirection)}
                                {renderHeader('Tid', BookingsSortOrder.Date, sortOrder, sortDirection, setSortOrder, setSortDirection)}
                                {renderHeader('Bokad av', BookingsSortOrder.BookedBy, sortOrder, sortDirection, setSortOrder, setSortDirection, 'd-none d-md-table-cell')}
                                <th className="d-none d-md-table-cell"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedBookings.map((x) => (
                                <BookingItem booking={x} isArchived={isArchived} key={x.bookingId} showDetails={setBookingId} />
                            ))}
                        </tbody>
                    </table>
                    {!bookings?.length && <NoBookings noBookingsHeading={filterNoBookingsHeading} noBookingsText={filterNoBookingsText} />}
                </Fragment>
            )}

            {bookingId && <BookingModal bookingId={bookingId} hasMultipleObjects={false} onClose={() => setBookingId(undefined)} onRefresh={fetchBookings} />}
        </Fragment>
    );
};

function getInvoiceStatusText(invoiceStatus: number, bookingStatus: BookingStatus | undefined, hasFreeCancellation: boolean): string {
    switch(invoiceStatus) {
        case InvoiceStatus.Cancelled:
            return 'Annulerad';
        case InvoiceStatus.HandledExternally:
            return 'Externt';
        case InvoiceStatus.Paid:
            return 'Betald';
        case InvoiceStatus.Pending:
            return 'Väntande';
        case InvoiceStatus.Created:
            return 'Skapad';
    }

    if(bookingStatus === BookingStatus.Cancelled && hasFreeCancellation) {
        return 'Fri avbokning';
    }

    return '';
}

function sortItems(sortBy: BookingsSortOrder, sortOrder: BookingsSortOrder, sortDirection: BookingsSortDirection, setSortOrder: (sortOrder: BookingsSortOrder) => void, setSortDirection: (sortDirection: BookingsSortDirection) => void ) {
    if (sortBy !== sortOrder) {
        setSortDirection(BookingsSortDirection.Ascending);
        setSortOrder(sortBy);
        return;
    }

    if (sortDirection === BookingsSortDirection.Ascending) {
        setSortDirection(BookingsSortDirection.Descending);
        return;
    }

    setSortDirection(BookingsSortDirection.Ascending);
}

function renderHeader(text: string, sortBy: BookingsSortOrder, sortOrder: BookingsSortOrder, sortDirection: BookingsSortDirection, setSortOrder: (sortOrder: BookingsSortOrder) => void, setSortDirection: (sortDirection: BookingsSortDirection) => void, additionalClass = '') {
    const className = cx(
        'bookings__header',
        additionalClass,
        { 'bookings__header--no-sort': sortBy !== sortOrder },
        { 'bookings__header--ascending': sortBy === sortOrder && sortDirection === BookingsSortDirection.Ascending },
        { 'bookings__header--descending': sortBy === sortOrder && sortDirection === BookingsSortDirection.Descending }
    );

    return (
        <th className={className} onClick={() => sortItems(sortBy, sortOrder, sortDirection, setSortOrder, setSortDirection)}>
            {text}
        </th>
    );
}

function sortBookings(bookings: Booking[], sortOrder: BookingsSortOrder = BookingsSortOrder.None): Booking[] {
    if (!bookings) {
        return [];
    }

    const sortedBookings = bookings.slice();
    switch (sortOrder) {
        case BookingsSortOrder.Name:
            sortedBookings.sort(compareName);
            break;
        case BookingsSortOrder.Date:
            sortedBookings.sort(compareDates);
            break;
        case BookingsSortOrder.BookedBy:
            sortedBookings.sort(compareUserName);
            break;
        case BookingsSortOrder.Reference:
            sortedBookings.sort(compareReference);
            break;
        default:
            break;
    }

    return sortedBookings;
}

function compareReference(bookingA: Booking, bookingB: Booking): number {
    const bookingAReference = bookingA.bookingReference ?? '';
    const bookingBReference = bookingB.bookingReference ?? '';

    return bookingAReference.localeCompare(bookingBReference);
}

function compareName(bookingA: Booking, bookingB: Booking): number {
    const bookingAAssetname = bookingA?.assetName ?? ''
    const bookingBAssetname = bookingB?.assetName ?? ''

    return bookingBAssetname.toLocaleLowerCase().localeCompare(bookingAAssetname);
}

function compareUserName(bookingA: Booking, bookingB: Booking): number {
    const bookingAUsername = bookingA?.userName ?? ''
    const bookingBUsername = bookingB?.userName ?? ''

    return bookingBUsername.toLocaleLowerCase().localeCompare(bookingAUsername);
}

function compareDates(bookingA: Booking, bookingB: Booking): number {
    const bookingADate = new Date(bookingA?.startTime ?? 0).getTime();
    const bookingBDate = new Date(bookingB?.startTime ?? 0).getTime();

    return bookingBDate - bookingADate;
}

export default BookingList;
