import { Member } from './models/Member';
import { MembersSortOrder } from './memberConstants';

export function compareFirstName(memberA: Member, memberB: Member): any {
    const memberAFirstName = memberA?.user?.firstName ?? ''
    const memberBFirstName = memberB?.user?.firstName ?? ''
    return memberBFirstName.toLocaleLowerCase().localeCompare(memberAFirstName);
}

export function compareLastName(memberA: Member, memberB: Member): any {
    const memberALastName = memberA?.user?.lastName ?? ''
    const memberBLastName = memberB?.user?.lastName ?? ''
    return memberBLastName.toLocaleLowerCase().localeCompare(memberALastName);
}

export function sortMembers(
  members: Member[],
  sortOrder: MembersSortOrder = MembersSortOrder.FirstName
): Member[] {
  if (!members) {
    return []
  }
  const membersCopy = members.slice();

  switch (sortOrder) {
    case MembersSortOrder.FirstName:
      membersCopy.sort(compareFirstName);
      break;
    case MembersSortOrder.LastName:
      membersCopy.sort(compareLastName);
      break;
    default:
      break;
  }
  
  return membersCopy;
}