import { Form, Formik } from 'formik';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Asset } from '../models/Asset';
import { ConfigContext } from '../../configuration/ConfigContext';
import FormikChangeSubscriber from '../../forms/FormikChangeSubscriber';
import RadioButton from '../../forms/RadioButton';
import { noValidationSchema } from '../../forms/validators';
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext';
import { MESSAGES_SAVE_FAILED } from '../assetConstants';
import { updateAsset } from '../assetService';

type Props = {
    initialValues: Asset;
    updateValues: (values) => void;
}

type FormData = {
    bookingMaxDaysInAdvance: string;
}

const Availability: FunctionComponent<Props> = ({initialValues, updateValues}) => {
    const { apiBaseUrl } = useContext(ConfigContext);
    const { currentPropertyAssociationId } = useContext(PropertyAssociationContext);
    const [ formData, setFormData ] = useState<FormData | undefined>();
    const [ error, setError ] = useState<string | undefined>();
    
    const handleChange = (values: FormData) => {
        const { bookingMaxDaysInAdvance } = values;
        updateAsset(apiBaseUrl, currentPropertyAssociationId, initialValues.assetId,
            {
              bookingMaxDaysInAdvance,
            },
            (data) => {
                updateValues(data);
            },
            () => {
                setError(MESSAGES_SAVE_FAILED);
            }
        )
    }
    
    useEffect(() => {
        setFormData({
            bookingMaxDaysInAdvance: initialValues.bookingMaxDaysInAdvance?.toString(),
        })
    }, [initialValues])

    if(!formData) {
        return null;
    }

    return (
        <Formik initialValues={formData} enableReinitialize={true} validationSchema={noValidationSchema} validateOnMount={true} onSubmit={() => { /* Hanterat av event */ }}>
            {() => (
                <Form>
                    <FormikChangeSubscriber onChange={handleChange} />
                    <div className="paper__content">
                        <strong>Tillgänglighet</strong>
                        <p>Hur lång tid i förväg ska en boende kunna boka denna resurs?</p>
                        <div className="my-3">
                            <RadioButton name="bookingMaxDaysInAdvance" label="1 månad" value="31" />
                        </div>
                        <div className="my-3">
                            <RadioButton name="bookingMaxDaysInAdvance" label="3 månader" value="92" />
                        </div>
                        <div className="my-3">
                            <RadioButton name="bookingMaxDaysInAdvance" label="6 månader" value="183" />
                        </div>
                        <div className="my-3">
                            <RadioButton name="bookingMaxDaysInAdvance" label="1 år" value="366" />
                        </div>
                        
                        { error && <div className="alert alert-danger">{error}</div> }
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default Availability