import { addDays, addHours, isSame, tryFormatTime } from '../../common/utils/dateUtils';
import { TimeSelectionType } from '../assets/assetConstants';
import { Asset } from '../assets/models/Asset';
import { AssetSchedule } from './models/AssetSchedule';
import { AssetBookingResponse } from './models/GetAssetBookingsResponse';

export const createAssetSchedule = (asset: Asset, assetBookingResponses: AssetBookingResponse[], startDate: Date): AssetSchedule => {
    const { timeslots } = asset;

    const assetSchedule: AssetSchedule = {
        days: new Array(7),
    };

    for (let i = 0; i < 7; i++) {
        assetSchedule.days[i] = {
            assets: new Array(asset.objects.length).fill(null).map((_, index) => {
                const { name, assetObjectId } = asset.objects[index];
                return (
                    { activeBookings: [], assetObjectId, cancelledBookings: [], name } as any
                );
            }),
            date: addDays(startDate, i),
            timeslots: [],
        };
    }

    assetBookingResponses.map((assetBookingResponse) => {
        const { activeBookings, cancelledBookings } = assetBookingResponse;
        const index = asset.objects.findIndex(x => x.assetObjectId === assetBookingResponse.assetObjectId);
        if(index === -1) {
            console.log(`Asset not found: ${assetBookingResponse.assetObjectId}`)
            return;
        }

        activeBookings.map(({ bookingId, start, stop, bookedBy }) => {
            const bookings = createBookingsForIntersectingDateRange(startDate, addDays(startDate, 7), start, stop, bookingId, bookedBy, asset.timeSelectionType);
            bookings.map(({ day, booking }) => {
                assetSchedule.days[day].assets[index].activeBookings.push(booking);
            });
        });

        cancelledBookings.map(({ bookingId, start, stop, bookedBy }) => {
            const { day, booking } = createBooking(start, stop, bookingId, bookedBy, asset.timeSelectionType);
            assetSchedule.days[day].assets[index].cancelledBookings.push(booking);
        });
    });

    timeslots.map((timeslot) => {
        if(!timeslot.startTime || !timeslot.endTime) {
            return;
        }

        const startDateTime = new Date(timeslot.startTime);
        const endDateTime = new Date(timeslot.endTime);
        const startTime = startDateTime.getHours() * 60 + startDateTime.getMinutes();
        let endTime = endDateTime.getHours() * 60 + endDateTime.getMinutes();

        if (asset.timeSelectionType === TimeSelectionType.Day) {
            // NOTE: Although the time span is day, the timeslot ends an hour too early.
            endTime += 60;
        }

        if (endTime <= startTime) {
            endTime += 24 * 60;
        }

        if (endTime > 24 * 60) {
            assetSchedule.days[timeslot.weekDay].timeslots.push({ startTime, endTime: 24 * 60, endsNextDay: true });
            assetSchedule.days[(timeslot.weekDay + 1) % 7].timeslots.push({ startTime: 0, endTime: endTime - 24 * 60, startsPreviousDay: true });
            return;
        }

        assetSchedule.days[timeslot.weekDay].timeslots.push({ startTime, endTime });
    });

    return assetSchedule;
}

function createBooking(start: string, stop: string, bookingId: string, bookedBy: string, timeSelectionType: TimeSelectionType) {
    const startDate = new Date(start);
    let endDate = new Date(stop);

    if (timeSelectionType === TimeSelectionType.Day) {
        // NOTE: Although the time span is day, the timeslot ends an hour too early.
        endDate = addHours(endDate, 1);        
    }

    const startInMinutes = startDate.getHours() * 60 + startDate.getMinutes();
    const lengthInMinutes = (endDate.getTime() - startDate.getTime()) / 1000 / 60;
    const day = (startDate.getDay() + 6) % 7;
    const isSameDay = isSame(startDate, endDate);
    const startTime = tryFormatTime(startDate);
    const endTime = isSameDay ? tryFormatTime(endDate) : `${endDate.getDate()}/${endDate.getMonth() + 1} ${tryFormatTime(endDate)}`;
    const booking = { bookingId, startTime, endTime, startInMinutes, lengthInMinutes, bookedBy, isFirst: true, isLast: isSameDay };

    return { day, booking };
}

function createBookingsForIntersectingDateRange(startDateRange: Date, endDateRange: Date, start: string, stop: string, bookingId: string, bookedBy: string, timeSelectionType: TimeSelectionType) {
    const startDate = new Date(start);
    let endDate = new Date(stop);

    if (timeSelectionType === TimeSelectionType.Day) {
        // NOTE: Although the time span is day, the timeslot ends an hour too early.
        endDate = addHours(endDate, 1);        
    }

    const startDateWithoutTime = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
    const endDateWithoutTime = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
    const numberOfDays = (endDateWithoutTime.getTime() - startDateWithoutTime.getTime()) / 1000 / 60 / 60 / 24 + 1;
    const bookings: any[] = [];

    for (let i = 0; i < numberOfDays; i++) {
        const date = addDays(startDate, i);
        if (!(date >= startDateRange && date <= endDateRange)) {
            continue;
        }

        const isFirst = i === 0;
        const isLast = i === numberOfDays - 1;
        const day = (date.getDay() + 6) % 7;
        const startInMinutes = calculateStartInMinutes(startDate, isFirst);
        const lengthInMinutes = calculateLengthInMinutes(startDate, endDate, isFirst, isLast);
        const startTime = tryFormatTime(startDate);
        const endTime = tryFormatTime(endDate);
        const booking = { bookingId, startTime, endTime, startInMinutes, lengthInMinutes, bookedBy, isFirst, isLast };

        bookings.push({ day, booking })   
    }

    return bookings;
}

function calculateStartInMinutes(startDate: Date, isFirst: boolean) {
    if (isFirst) {
        return startDate.getHours() * 60 + startDate.getMinutes();
    }

    return 0;
}

function calculateLengthInMinutes(startDate: Date, endDate: Date, isFirst: boolean, isLast: boolean) {
    if (isFirst && isLast) {
        return (endDate.getTime() - startDate.getTime()) / 1000 / 60;
    }

    if (isFirst) {
        return 24 * 60 - startDate.getHours() * 60 - startDate.getMinutes();
    }

    if (isLast) {
        return endDate.getHours() * 60 + endDate.getMinutes();
    }

    return 24 * 60;
}
