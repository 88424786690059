import cx from 'classnames';
import React, { FunctionComponent } from 'react';

type Props = {
    additionalClassName?: string;
    disabled?: boolean;
    isPrimary?: boolean;
    isSubmitting?: boolean;
    onClick?: () => void;
    text: string;
    type?: 'button' | 'reset' | 'submit' | undefined;
}

const Button: FunctionComponent<Props> = ({ text, type = 'button', additionalClassName, disabled = false, isPrimary = true, isSubmitting = false, onClick = undefined }) => {
    const className = cx(['btn', { 'btn-primary': isPrimary }, { [`${additionalClassName}`]: additionalClassName ? true : false }]);

	if (disabled || isSubmitting) {
		return <button className={className} type={type} disabled>{text}</button>
	}

    return <button className={className} type={type} onClick={onClick}>{text}</button>
}

export default Button