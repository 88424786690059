import React, { Fragment, FunctionComponent } from 'react';

type Props = {
    buttons: any;
    children: any;
    onClose?: () => void;
    title: string;
    visible: boolean;
    width?: string | number;
}

const Modal: FunctionComponent<Props> = ({ buttons, children, title, visible, width, onClose }) => {
    if(!visible) {
        return null;
    }
    
    const style = width ? { '--bs-modal-width': width } as any : {};

    return (
        <Fragment>
            <div className="modal show" tabIndex={-1} aria-modal="true" role="dialog" style={style}>
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{title}</h4>
                            {onClose && <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close" onClick={onClose}><span className="visuallyhidden" aria-hidden="true">×</span></button>}
                        </div>
                        <div className="modal-body">
                            {children}
                        </div>
                        <div className="modal-footer">
                            {buttons}
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal-backdrop fade show"></div>
        </Fragment>
    );
}

export default Modal