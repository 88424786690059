import { FunctionComponent, useContext, useState } from 'react';
import * as Yup from 'yup';
import { ConfigContext } from '../../configuration/ConfigContext';
import TextField from '../../forms/TextField';
import { requiredValidator } from '../../forms/validators';
import FormModal from '../../modal/FormModal';
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext';
import { deleteUserAdvert } from '../userAdvertService';

type FormData = {
    reason: string;
}

type Props = {
    advertId: string;
    onAdvertDeleted: (advertId: string) => void;
    onClose: () => void;
}

const initialValues: FormData = {
    reason: ''
}

const validationSchema = Yup.object().shape({
    reason: requiredValidator
});

const DeleteUserAdvertModal: FunctionComponent<Props> = ({advertId, onAdvertDeleted, onClose}) => {
    const { apiBaseUrl } = useContext(ConfigContext);
    const { currentPropertyAssociationId } = useContext(PropertyAssociationContext);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState<string | undefined>();

    const onSubmit = ({ reason }: FormData) => {
        setIsSubmitting(true);
        deleteUserAdvert(apiBaseUrl, currentPropertyAssociationId, advertId, reason, onSubmitted, onError);
    }

    const onSubmitted = () => {
        setIsSubmitting(false);
        onAdvertDeleted(advertId);
        onClose();
    }

    const onError = () => {
        setIsSubmitting(false);
        setError('Kunde inte ta bort posten');
    }

    return (
        <FormModal initialValues={initialValues} validationSchema={validationSchema} onClose={onClose} title="Ta bort posten" submitText="Ta bort" isSubmitting={isSubmitting} onSubmit={onSubmit} visible={true}>
            {({values, errors, touched}) => (
                <>
                    <TextField name="reason" label="Ange anledning till varför posten tas bort:" disabled={isSubmitting} values={values} errors={errors} touched={touched} />
                    { error && <div className="alert alert-danger">{error}</div> }
                </>
            )}
        </FormModal>
    )
}

export default DeleteUserAdvertModal