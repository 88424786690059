import { TimeSelectionType } from '../assetConstants';
import { AssetTypeDefinition } from './assetTypes';

export const ASSETTYPE_PARKING = 'Parking';

export const DEFAULTS_PARKING: AssetTypeDefinition = {
    alwaysUseDays: true,
    assetTypeTag: ASSETTYPE_PARKING,
    backdrop: '/images/defaults/backdrop/RB_gemensamma-resurser_gastparkering.png',
    canChangeToDaily: true,
    canChangeToHourly: true,
    canChangeToInterval: false,
    endTimeNextDay: true,
    endTimeRestOfDay: true,
    image: '/images/defaults/preview/RB_gemensamma-resurser_gastparkering.png',
    isSingleton: false,
    name: 'Gästparkering',
    nameDefinitiveForm: 'parkeringsplatsen',
    namePlural: 'parkeringsplatser',
    nameSingular: 'parkeringsplats',
    options: [
        'Parkering (bil)',
        'Parkering (motorcykel)',
        'Laddstolpe (elbil)',
        'Motorvärmare',
        'Kallgarage',
        'Varmgarage',
    ],
    prefixName: true,
    serviceInformationText: 'Ex. Gästparkering på innergården. Hyres/bokas per dygn. Gästparkeringskort finns att hämta.',
    serviceInformationTitle: 'Välkommen på besök!',
    thumbnail: '/images/defaults/thumbnails/RB_gemensamma-resurser_gastparkering.png',
    timeSelectionType: TimeSelectionType.Startstop,
}