import { FunctionComponent } from 'react';
import { Member } from '../models/Member';

type Props = {
    member: Member;
    onClick: () => void;
    requireMembership: boolean;
    isPending: boolean;
};

const MemberItem: FunctionComponent<Props> = ({ member, onClick, isPending }) => (
    <tr className="bookings__row" onClick={onClick}>
        <td>{member.user.firstName}</td>
        <td>{member.user.lastName}</td>
        <td>{renderSPARStatus(member.fromSPAR)}</td>
        <td className="d-none d-md-table-cell">
            <div className='d-flex justify-content-end'>
                {renderMemberStatus(isPending, member)}
            </div>
        </td>
    </tr>
)

const renderMemberStatus = (isPending: boolean, member: Member) => {
    if(isPending) {
        return <div className='bookings__status badge rounded-pill fw-light bg-danger'>Ej åtkomst</div>
    }

    if(member.hasAdvertAccess === false || member.hasAssetAccess === false) {
        return <div className='bookings__status badge rounded-pill fw-light bg-warning'>Begränsad</div>
    }

    return <div className='bookings__status badge rounded-pill fw-light bg-success'>Åtkomst</div>
};

const renderSPARStatus = (fromSPAR: boolean) => {
    return (
        <div className='d-flex justify-content-end'>
            {fromSPAR ? (
                <div className='bookings__status badge rounded-pill fw-light bg-success'>Folkbokförd</div>
            ) : (
                <div className='bookings__status badge rounded-pill fw-light bg-danger'>Ej folkbokförd</div>
            )}
        </div>
    )
}

export default MemberItem;