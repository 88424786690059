import cx from 'classnames';
import { Form, Formik, FormikHelpers } from 'formik';
import { Fragment, FunctionComponent } from 'react';

type Props = {
    children: any;
    initialValues: any;
    isSubmitting: boolean;
    large?: boolean;
    onClose: () => void;
    onSubmit: (values, formikHelpers: FormikHelpers<any>) => void;
    scrollable?: boolean;
    title: string;
    validationSchema: any;
    visible: boolean;
    submitText?: string;
}

const FormModal: FunctionComponent<Props> = ({ children, initialValues, isSubmitting, large = true, onClose, onSubmit, scrollable = true, submitText, title, validationSchema, visible}) => {
    if(!visible) {
        return null;
    }

    return (
        <Fragment>
            <Formik initialValues={initialValues} enableReinitialize={true} validationSchema={validationSchema} validateOnMount={true} onSubmit={onSubmit}>
                {({isValid, values, errors, touched, setValues}) => (
                    <Form>
                        <div className="modal show" tabIndex={-1} aria-modal="true" role="dialog">
                            <div className={cx(['modal-dialog modal-dialog-centered', {'modal-dialog-scrollable': scrollable}, {'modal-lg': large}])} role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title">{title}</h4>
                                        {onClose && <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close" onClick={onClose}><span className="visuallyhidden" aria-hidden="true">×</span></button>}
                                    </div>
                                    <div className="modal-body">
                                            { children({values, errors, touched, setValues}) }
                                    </div>
                                    <div className="modal-footer">
                                        <button className="btn btn-secondary" type="button" disabled={isSubmitting} onClick={onClose}>Avbryt</button>
                                        <button className="btn btn-primary" type="submit" disabled={!isValid || isSubmitting}>{submitText || 'Spara'}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>

            <div className="modal-backdrop fade show"></div>
        </Fragment>
    )
}

export default FormModal