import React, { FunctionComponent, useContext } from 'react'
import { ConfigContext } from '../../configuration/ConfigContext';
import AlertModal from '../../modal/AlertModal';
import ConfirmModal from '../../modal/ConfirmModal'
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext';
import { ToastContext } from '../../toaster/ToastContext';
import { deleteApartment } from '../apartmentService';
import { ApartmentResponse } from '../models/GetApartmentsResponse';

type Props = {
    apartment: ApartmentResponse;
    onClose: () => void;
    refreshApartments: () => void;
}

const DeleteApartment: FunctionComponent<Props> = ({apartment: {apartmentId}, onClose, refreshApartments}) => {
    const {apiBaseUrl} = useContext(ConfigContext);
    const {currentPropertyAssociationId} = useContext(PropertyAssociationContext);
    const {addToast} = useContext(ToastContext);
    const [error, setError] = React.useState<string | undefined>();

    const onSubmit = () => {
        setError(undefined);
        deleteApartment(
            apiBaseUrl,
            currentPropertyAssociationId,
            apartmentId,
            () => {
                addToast('Lägenhet borttagen');
                refreshApartments();
                onClose();
            },
            () => {
                setError('Kunde inte ta bort lägenhet!');
            }
        )
    }
 
    if(error) {
        return <AlertModal heading="Ett fel uppstod" text={error} onClose={() => setError(undefined)} visible={true} />
    }

    return (
        <ConfirmModal heading="Ta bort lägenhet" text="Vill du verkligen ta bort lägenheten? Denna åtgärd går ej att ångra." onSubmit={onSubmit} onClose={onClose} visible={true} />
    )
}

export default DeleteApartment