import cx from 'classnames';
import { FunctionComponent, useEffect, useRef } from 'react';
import { MINUTES_PER_PIXEL } from '../bookingOverviewConstants';
import { AssetBooking } from '../models/AssetBooking';
import styles from './Block.module.scss';

type Props = {
    booking: AssetBooking;
    color?: number;
    focusedBookingId: string | undefined;
    setFocusedBookingId: (bookingId: string | undefined) => void;
    setSelectedBookingId?: (bookingId: string) => void;
}

const Block: FunctionComponent<Props> = ({booking, color, focusedBookingId, setFocusedBookingId, setSelectedBookingId}) => {
    const { lengthInMinutes, startInMinutes } = booking;
    const ref = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        if (ref.current) {
            const element = ref.current;
            element.addEventListener('mouseenter', () => { setFocusedBookingId(booking.bookingId) });
            element.addEventListener('mouseleave', () => { setFocusedBookingId(undefined) });
        }
    }, [ref.current]);

    const handleClick = () => {
        if (setSelectedBookingId) {
            setSelectedBookingId(booking.bookingId);
        }
    }

    if(color !== undefined) {
        return <button ref={ref} className={cx([styles.container, styles[`color-${color}`], {[styles.hover]: booking.bookingId === focusedBookingId}])} style={{ height: lengthInMinutes / MINUTES_PER_PIXEL, top: startInMinutes / MINUTES_PER_PIXEL }} onClick={handleClick}></button>
    }

    return (
        <button ref={ref} className={cx([styles.container, {[styles.hover]: booking.bookingId === focusedBookingId}])} style={{ height: lengthInMinutes / MINUTES_PER_PIXEL, top: startInMinutes / MINUTES_PER_PIXEL }} onClick={handleClick}>
            {renderTime(booking)}
            <div className={styles.name}>{booking.bookedBy}</div>
        </button>
    )
}

function renderTime(booking: AssetBooking) {
    const { endTime, isFirst, isLast, startTime } = booking;
    if(!isFirst && !isLast) {
        return <div className={styles.time}><span className={styles.large}>→</span></div>
    }

    if(!isFirst) {
        return <div className={styles.time}><span className={styles.large}>→</span> {endTime}</div>
    } 

    if(!isLast) {
        return <div className={styles.time}>{startTime} <span className={styles.large}>→</span></div>
    }

    return <div className={styles.time}>{`${startTime} - ${endTime}`}</div>
}

export default Block