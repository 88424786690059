import cx from 'classnames';
import { FunctionComponent } from 'react';
import { tryFormatDateDayMonthTime } from '../../../common/utils/dateUtils';
import { ConversationMessageModel } from '../models/ConversationMessageModel';
import { ConversationMessageSenderType } from '../models/ConversationMessageSenderType';
import { UserInfo } from '../../shared/models/UserInfo';
import UserIcon from './UserIcon';
import { ConversationMessageAttachmentContentType } from '../models/ConversationMessageAttachmentContentType';

type Props = {
    message: ConversationMessageModel;
};

const ConversationMessage: FunctionComponent<Props> = ({ message: { attachmentFileName, attachmentPath, attachmentType, created, sender, text, type } }) => (
    <div className='conversations__message-list-item' key={created}>
        <div className='meta mb-2 text-center'>{tryFormatDateDayMonthTime(created)}</div>
        <div className={cx('d-flex col-12 col-md-10 ', { 'ms-auto flex-row-reverse': type === ConversationMessageSenderType.Self, 'me-auto': type === ConversationMessageSenderType.Other })}>
            <UserIcon {...sender} size='small' extraCss={`align-self-end ${type === ConversationMessageSenderType.Other ? 'me-2' : 'ms-2'}`} />
            <div className={cx('p-3 m-0 flex-grow-1 border-4 conversations__message-list-item-content', { 'bg-white': type === ConversationMessageSenderType.Other }, { 'conversations__message-list-item--bg-grey': type === ConversationMessageSenderType.Self }, { 'py-0': type === ConversationMessageSenderType.Status })}>
                {renderUser(sender)}
                <p className='m-0'>{text}</p>
                {attachmentType === ConversationMessageAttachmentContentType.Image && attachmentPath && (
                    <div className='d-flex'>
                        <a className='me-auto text-decoration-none bg-transparent' href={attachmentPath} target='_blank' rel='noreferrer'>
                            <img className='conversations__icon rounded-0 my-2' src={attachmentPath} alt={attachmentFileName} />
                        </a>
                    </div>
                )}
                {attachmentType === ConversationMessageAttachmentContentType.Pdf && attachmentPath && (
                    <>
                        <div className="meta my-0">Bifogad fil: {attachmentFileName}</div>
                        <a href={attachmentPath} rel="noreferrer" target="_blank" className="btn btn-secondary mx-0 mt-2">Öppna PDF</a>
                    </>
                )}
            </div>
        </div>
    </div>
);

const renderUser = (user: UserInfo) => {
    if (!user) {
        return null;
    }
    
    const { displayName, firstName, lastName } = user;
    return (
        <div className='meta mb-1'>
            {displayName || `${firstName} ${lastName}`}
        </div>
    );
};

export default ConversationMessage;
