import { FunctionComponent, ReactNode, useState } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import SubNavigation from '../SubNavigation';
import cx from 'classnames';
import { withWaitForPropertyAssociation } from '../../propertyAssociation/withWaitForPropertyAssociation';

const SettingsMenu: FunctionComponent = () => {
    const location = useLocation();
    const currentPath = location.pathname;
    const [expanded, setExpanded] = useState(true);

    return (
        <SubNavigation heading="Inställningar" isExpanded={expanded} onExpand={setExpanded}>
            <div className="submenu__content">
                <div className="submenu__links">
                    { renderLink('/settings/admins/', 'Administratörer', currentPath) }
                    { renderLink('/settings/', 'Allmänna inställningar', currentPath) }
                    { renderLink('/settings/swish/', 'Swish', currentPath) }
                    { renderLink('/settings/apartments/', 'Lägenheter', currentPath) }
                </div>
            </div>
        </SubNavigation>
    );
}

export default withWaitForPropertyAssociation(SettingsMenu, false)

function renderLink(path: string, text: string, currentPath: string): ReactNode {
    const isCurrentPath = currentPath === path;
    return <Link to={path} className={cx(['submenu__link', { 'submenu__link--current': isCurrentPath }])}>{text}</Link>
}