import { FunctionComponent } from 'react';
import { useHistory } from 'react-router';

const NewBookingButton: FunctionComponent = () => {
    const { push } = useHistory();

    return (
        <button className='btn btn-secondary' onClick={() => push('/bookings/new/')}>Boka</button>
    )
}

export default NewBookingButton