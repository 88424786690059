import { FunctionComponent, useContext, useState } from 'react';
import * as Yup from 'yup';
import { ConfigContext } from '../../configuration/ConfigContext';
import RadioButton from '../../forms/RadioButton';
import TextField from '../../forms/TextField';
import { emailValidator } from '../../forms/validators';
import { createInvitationToPropertyAssociation } from '../../invitation/invitationService';
import FormModal from '../../modal/FormModal';
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext';
import { ToastContext } from '../../toaster/ToastContext';
import { PropertyAssociationAccessLevel } from '../adminEnums';

type Props = {
    isVisible: boolean,
    onClose: () => void,
    reload: () => void,
}

const INITIAL_VALUES = {
    email: '',
    accessLevel: PropertyAssociationAccessLevel.Guest,
}

const validationSchema = Yup.object().shape({
    email: emailValidator,
    accessLevel: Yup.string().required('Välj behörighet')
})

const AddGuestAdminModal: FunctionComponent<Props> = ({onClose, reload}) => {
    const { apiBaseUrl } = useContext(ConfigContext);
    const { addToast } = useContext(ToastContext);
    const { currentPropertyAssociationId } = useContext(PropertyAssociationContext);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = ({accessLevel, email}) => {
        setIsSubmitting(true);

        createInvitationToPropertyAssociation(apiBaseUrl, currentPropertyAssociationId, { accessLevel, email },
            () => {
                setIsSubmitting(false);
                addToast(`Inbjudan skickad till ${email}`);
                reload();
                onClose();
            },
            () => {
                setIsSubmitting(false);
                addToast('Kunde inte skicka inbjudan. Kontrollera att e-postadressen är korrekt.', 'error');
            }
        )
    }

    return (
        <FormModal 
            initialValues={INITIAL_VALUES} 
            isSubmitting={isSubmitting}
            large={false}
            onClose={onClose}
            onSubmit={handleSubmit}
            submitText="Bjud in"
            title="Bjud in gäst" 
            validationSchema={validationSchema} 
            visible={true}
        >
            {({values, errors, touched}) => {
                const formProps = { values, errors, touched };

                return (
                    <div>
                        <p>Bjud in en extern person som kan agera administratör genom att ange en e-postadress att skicka inbjudan till.</p>                       
                        <TextField name="email" label="E-post" placeholder="namn@exempel.se" {...formProps} />
                        <div className="mt-4 mb-2">
                            <strong>Behörighet</strong>
                        </div>
                        <RadioButton name="accessLevel" label="Begränsad gäståtkomst" value={`${PropertyAssociationAccessLevel.Guest}`} />
                        <RadioButton name="accessLevel" label="Full gäståtkomst" value={`${PropertyAssociationAccessLevel.Adjunct}`} />
                    </div>
                );
            }}
        </FormModal>
    );
}

export default AddGuestAdminModal;