import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { FunctionComponent, useContext, useState } from 'react';
import { ConfigContext } from '../../configuration/ConfigContext';
import Modal from '../../modal/Modal';
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext';
import { setImageOrder } from '../assetService';
import { Asset } from '../models/Asset';
import styles from './ImageSortModal.module.scss';

type Props = {
    asset: Asset;
    onClose: () => void;
    onSubmitted: () => void;
}

type ImageItem = {
    originalPath: string;
    path: string;
}

const ImageSortModal: FunctionComponent<Props> = ({ asset, onClose, onSubmitted }) => {
    const { apiBaseUrl } = useContext(ConfigContext);
    const { currentPropertyAssociationId } = useContext(PropertyAssociationContext);
    const [items, setItems] = useState<ImageItem[]>(asset.images ?? []);
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState<string | undefined>();
    
    const onSave = () => {
        setSubmitting(true);
        setError(undefined);

        const data = { paths: items.map(i => i.originalPath) };
        setImageOrder(apiBaseUrl, currentPropertyAssociationId, asset.assetId, data, onSubmitted, onError);
        onClose();
    }

    const onError = () => {
        setSubmitting(false);
        setError('Det gick inte att spara ordningen på bilderna.');
    }

    const buttons = (
        <>
            <button type="button" className="btn btn-secondary" onClick={onClose}>Avbryt</button>
            <button type="button" className="btn btn-primary" onClick={onSave} disabled={submitting}>Spara</button>
        </>
    );

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const reorderedItems = reorder(
            items,
            result.source.index,
            result.destination.index
        );

        setItems(reorderedItems);
    }

    return (
        <Modal title="Sortera bilder" visible={true} width="80vw" onClose={onClose} buttons={buttons}>
            <p>Sortera bilderna genom att dra och släppa dem i önskad ordning.</p>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" direction="horizontal">
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps} className={styles.container}>
                            {items.map((item, index) => (
                                <Draggable key={item.originalPath} draggableId={item.originalPath} index={index}>
                                    {(provided) => (
                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                            <img src={item.path} alt="" className={styles.image} />
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            {error && <div className="alert alert-danger">{error}</div>}
        </Modal>
    )
}

const reorder = (list: ImageItem[], startIndex: number, endIndex: number): ImageItem[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result as ImageItem[];
};

export default ImageSortModal