import cx from 'classnames';
import { FunctionComponent, useEffect, useRef, useState } from 'react';

type Props = {
    onDelete: (originalPath: string) => void;
    imagePath: string;
    originalPath: string;
};

const SmallImage: FunctionComponent<Props> = ({onDelete, imagePath, originalPath}) => {
    const [ isExpanded, setIsExpanded ] = useState(false);
    const node = useRef<HTMLDivElement>(null);
    
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (node.current && !node.current.contains(event.target)) {
                setIsExpanded(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleDelete = (originalPath: string) => {
        setIsExpanded(false);
        onDelete(originalPath);
    }

    return (
        <div ref={node} className="preview__image-block  preview__image-block--thumb preview__image-block--with-hover w-50" key={originalPath}>
            <button className="preview__image-wrapper" onClick={() => setIsExpanded(!isExpanded)}>
                <img src={imagePath} alt="" className="preview__image" />
            </button>
            <div className={cx(['dropdown-menu preview__dropdown-menu', { 'show': isExpanded }])}>
                <button className="dropdown-item" onClick={() => handleDelete(originalPath)}>Ta bort</button>
            </div>
        </div>
    )
}

export default SmallImage