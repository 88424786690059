import { Fragment, FunctionComponent, useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { MESSAGE_SEND_FAIL } from '../../bookings/bookingConstants';
import { BookingDetails } from '../../bookings/models/BookingDetails';
import { ConfigContext } from '../../configuration/ConfigContext';
import TextArea from '../../forms/TextArea';
import { requiredValidator } from '../../forms/validators';
import { postConversationMessage } from '../../messages/conversationService';
import FormModal from '../../modal/FormModal';
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext';
import { ToastContext } from '../../toaster/ToastContext';

type Props = {
    booking: BookingDetails;
    onClose: () => void;
}

type FormValues = {
    text: string;
};

const validationSchema = Yup.object().shape({
    text: requiredValidator,
});

const INITIAL_VALUES: FormValues = {
    text: '',
}

const MessageView: FunctionComponent<Props> = ({ booking, onClose }) => {
    const { currentPropertyAssociationId } = useContext(PropertyAssociationContext);
    const { apiBaseUrl } = useContext(ConfigContext);
    const { addToast } = useContext(ToastContext);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState<string | undefined>();

    useEffect(() => {
        if(!booking) {
            return;
        }

        if(!booking.conversationId) {
            setError('Det finns ingen konversation för denna bokning.');
        }
    }, [booking]);

    if(!booking) {
        return null;
    }

    const onSubmit = (values: FormValues) => {
        if(!booking.conversationId) {
            setError('Det finns ingen konversation för denna bokning.');
            return;
        }

        setIsSubmitting(true);
        postConversationMessage(
            apiBaseUrl,
            currentPropertyAssociationId,
            booking.conversationId,
            values.text,
            undefined,
            true,
            () => {
                setIsSubmitting(false);
                addToast('Meddelandet har skickats.');
                onClose();
            },
            () => {
                setIsSubmitting(false);
                setError(MESSAGE_SEND_FAIL);
            }
        );
    };

    return (
        <FormModal
            submitText='Skicka'
            initialValues={INITIAL_VALUES}
            title='Skicka meddelande'
            validationSchema={validationSchema}
            visible={true}
            isSubmitting={isSubmitting}
            onClose={onClose}
            onSubmit={onSubmit}
        >
            {({ values, errors, touched }) => {
                const formProps = { values, errors, touched };
                return (
                    <Fragment>
                        <p>Här kan du skriva ett meddelande till slutanvändaren rörande bokningen.</p>
                        <TextArea name='text' {...formProps} />
                        {error && <div className='alert alert-danger'>{error}</div>}
                    </Fragment>
                );
            }}
        </FormModal>
    );
}

export default MessageView