/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useContext } from 'react';
import useSWR, { mutate } from 'swr';
import { useAsyncError } from '../../common/error/useAsyncError';
import { axiosFetcher, deleteToApi, deleteWithBodyToApi, getFromApi, postStringToApi, postToApi, putStringToApi, putToApi } from '../../common/utils/apiHelper';
import { GetAssetBookingsResponse } from '../bookingOverview/models/GetAssetBookingsResponse';
import { ConfigContext } from '../configuration/ConfigContext';
import { PropertyAssociationContext } from '../propertyAssociation/PropertyAssociationContext';
import { AddSharedPropertyAssociationRequest } from './models/AddSharedPropertyAssociationRequest';
import { Asset } from './models/Asset';
import { AssetListItem } from './models/AssetListItem';
import { AssetNotification } from './models/AssetNotification';
import { AssetObject } from './models/AssetObject';
import { AssetTimeSlot } from './models/AssetTimeSlot';
import { DeleteImageRequest } from './models/DeleteImageRequest';
import { EntranceMethod } from './models/EntranceMethod';
import { GetSharedPropertyAssociationsResponse } from './models/GetSharedPropertyAssociationsResponse';
import { ReorderImagesRequest } from './models/ReorderImagesRequest';
import { CreateAssetRequest } from './models/CreateAssetRequest';

type ServiceLoader = {
    assets: AssetListItem[] | undefined;
    isLoading: boolean;
}

const options = {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
}

export const useAssets = (): ServiceLoader => {
    const { currentPropertyAssociationId, currentArea } = useContext(PropertyAssociationContext)
    const { apiBaseUrl } = useContext(ConfigContext);
    const { data, error } = useSWR<AssetListItem[]>(`${apiBaseUrl}/propertyassociations/${currentPropertyAssociationId}/assets${getAreaParam(currentArea?.propertyAssociationAreaId)}`, axiosFetcher, options);
    const throwAsyncError = useAsyncError();

    if (error) {
        throwAsyncError(new Error('Kunde inte hämta gemensamma resurser.'));
    }

    return {
        assets: data,
        isLoading: !error && !data,
    };
}

export const refreshAssets = (apiBaseUrl: string, propertyAssociationId: string, areaId: string | undefined): void => {
    mutate(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/assets${getAreaParam(areaId)}`);
}

export const createAsset = (apiBaseUrl: string, propertyAssociationId: string, data: CreateAssetRequest, onSuccess: (assetId: string) => void, onFail: () => void): void => {
    postToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/assets/`, data, onSuccess, onFail)
}

export const getEntranceMethods = (apiBaseUrl: string, assetTypeTag: string, onSuccess: (data: EntranceMethod[]) => void, onFail: () => void): void => {
    getFromApi(`${apiBaseUrl}/entrancemethods/${assetTypeTag}`, onSuccess, onFail);
}

export const updateEntranceMethod = (apiBaseUrl: string, propertyAssociationId: string, assetId: string, data: any, onSuccess: (data: EntranceMethod) => void, onFail: () => void, always?: () => void): void => {
    putToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/assets/${assetId}/entrancemethod`, data, onSuccess, onFail, always);
}

export const updateEntranceMethods = (apiBaseUrl: string, propertyAssociationId: string, assetId: string, data: any, onSuccess: (data: EntranceMethod[]) => void, onFail: () => void, always?: () => void): void => {
    putToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/assets/${assetId}/entrancemethods/batch`, data, onSuccess, onFail, always);
}

export const getAsset = (apiBaseUrl: string, propertyAssociationId: string, assetId: string, onSuccess: (data: Asset) => void, onFail: () => void): void => {
    getFromApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/assets/${assetId}`, onSuccess, onFail)
}

export const publishAsset = (apiBaseUrl: string, propertyAssociationId: string, assetId: string, onSuccess: (assetId: string) => void, onFail: () => void, always?: () => void): void => {
    putToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/assets/${assetId}/publish`, {}, onSuccess, onFail, always);
}

export const deleteAsset = (apiBaseUrl: string, propertyAssociationId: string, assetId: string, onSuccess: () => void, onFail: () => void, always?: () => void): void => {
    deleteToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/assets/${assetId}`, onSuccess, onFail, always);
}

export const unpublishAsset = (apiBaseUrl: string, propertyAssociationId: string, assetId: string, onSuccess: (assetId: string) => void, onFail: () => void, always: () => void): void => {
    putToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/assets/${assetId}/unpublish`, {}, onSuccess, onFail, always);
}

export const updateAsset = (apiBaseUrl: string, propertyAssociationId: string, assetId: string, data: any, onSuccess: (data: Asset) => void, onFail: () => void): void => {
    putToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/assets/${assetId}`, data, onSuccess, onFail)
}

export const updateBookingConditions = (apiBaseUrl: string, propertyAssociationId: string, assetId: string, data: any, onSuccess: (data: Asset) => void, onFail: () => void): void => {
    putToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/assets/${assetId}/bookingconditions`, data, onSuccess, onFail)
}

export const uploadImage = (apiBaseUrl: string, propertyAssociationId: string, assetId: string, file: File, onSuccess: (url: string) => void, onFail: () => void): void => {
    const data = new FormData();
    data.append('file', file);

    postToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/assets/${assetId}/image`, data, onSuccess, onFail)
}

export const deleteImage = (apiBaseUrl: string, propertyAssociationId: string, assetId: string, imagePath: string, onSuccess: () => void, onFail: () => void): void => {
    const data: DeleteImageRequest = { imagePath };
    deleteWithBodyToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/assets/${assetId}/image/`, data, onSuccess, onFail)
}

export const uploadTerms = (apiBaseUrl: string, propertyAssociationId: string, assetId: string, file: File, onSuccess: (url: string) => void, onFail: () => void): void => {
    const data = new FormData();
    data.append('file', file);

    postToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/assets/${assetId}/terms`, data, onSuccess, onFail)
}

export const deleteTerms = (apiBaseUrl: string, propertyAssociationId: string, assetId: string, onSuccess: () => void, onFail: () => void): void => {
    deleteToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/assets/${assetId}/terms`, onSuccess, onFail)
}

export const updateTimeslots = (apiBaseUrl: string, propertyAssociationId: string, assetId: string, data: any, onSuccess: (data: AssetTimeSlot[]) => void, onFail: () => void): void => {
    postToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/assets/${assetId}/timeslots/batch`, data, onSuccess, onFail)
}

export const addAssetObject = (apiBaseUrl: string, propertyAssociationId: string, assetId: string, data: any, onSuccess: (data: AssetObject) => void, onFail: () => void): void => {
    postToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/assets/${assetId}/objects`, data, onSuccess, onFail)
}

export const updateAssetObject = (apiBaseUrl: string, propertyAssociationId: string, assetId: string, assetObjectId: string, data: any, onSuccess: (data: AssetObject) => void, onFail: () => void): void => {
    putToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/assets/${assetId}/objects/${assetObjectId}`, data, onSuccess, onFail)
}

export const deleteAssetObject = (apiBaseUrl: string, propertyAssociationId: string, assetId: string, assetObjectId: string, onSuccess: () => void, onFail: () => void): void => {
    deleteToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/assets/${assetId}/objects/${assetObjectId}`, onSuccess, onFail)
}

export const getAssetNotifications = (apiBaseUrl: string, propertyAssociationId: string, assetId: string, onSuccess: (data: AssetNotification) => void, onFail: () => void): void => {
    getFromApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/assets/${assetId}/notifications`, onSuccess, onFail)
}

export const updateAssetNotification = (apiBaseUrl: string, propertyAssociationId: string, assetId: string, data: any, onSuccess: (data: Asset) => void, onFail: () => void): void => {
    putToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/assets/${assetId}/notifications`, data, onSuccess, onFail)
}

export const createAssetNotifier = (apiBaseUrl: string, propertyAssociationId: string, assetId: string, data: string, onSuccess: (data: Asset) => void, onFail: () => void): void => {
    postStringToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/assets/${assetId}/notifications/notifiers`, data, onSuccess, onFail)
}

export const updateAssetNotifier = (apiBaseUrl: string, propertyAssociationId: string, assetId: string, notifierId: string, email: string, onSuccess: () => void, onFail: () => void): void => {
    putStringToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/assets/${assetId}/notifications/notifiers/${notifierId}`, email, onSuccess, onFail)
}

export const deleteAssetNotifier = (apiBaseUrl: string, propertyAssociationId: string, assetId: string, notifierId: string, onSuccess: () => void, onFail: () => void): void => {
    deleteToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/assets/${assetId}/notifications/notifiers/${notifierId}`, onSuccess, onFail)
}

export const getAssetBookings = (apiBaseUrl: string, propertyAssociationId: string, assetId: string, from: string, to: string, onSuccess: (data: GetAssetBookingsResponse) => void, onFail: () => void): void => {
    getFromApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/assets/${assetId}/bookings?from=${from}&to=${to}`, onSuccess, onFail)
}

export const getSharedPropertyAssociations = (apiBaseUrl: string, propertyAssociationId: string, assetId: string, onSuccess: (data: GetSharedPropertyAssociationsResponse) => void, onFail: () => void): void => {
    getFromApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/assets/${assetId}/sharedpropertyassociations`, onSuccess, onFail)
}

export const addSharedPropertyAssociation = (apiBaseUrl: string, propertyAssociationId: string, assetId: string, data: AddSharedPropertyAssociationRequest, onSuccess: (data: any) => void, onFail: () => void): void => {
    postToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/assets/${assetId}/sharedpropertyassociations`, data, onSuccess, onFail)
}

export const deleteSharedPropertyAssociation = (apiBaseUrl: string, propertyAssociationId: string, assetId: string, sharedPropertyAssociationId: string, onSuccess: () => void, onFail: () => void): void => {
    deleteToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/assets/${assetId}/sharedpropertyassociations/${sharedPropertyAssociationId}`, onSuccess, onFail)
}

export const setImageOrder = (apiBaseUrl: string, propertyAssociationId: string, assetId: string, data: ReorderImagesRequest, onSuccess: () => void, onFail: () => void): void => {
    postToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/assets/${assetId}/images/order`, data, onSuccess, onFail)
}

const getAreaParam = (areaId: string | undefined): string => {
    return areaId ? `?areaId=${areaId}` : '';
}