import { Field } from 'formik';
import React, { FunctionComponent } from 'react';

type Props = {
    label: string;
    name: string;
    value: string;
    onClick?: () => void;
}

const CheckBox: FunctionComponent<Props> = ({ label, name, value, onClick }) => {
    const id = `${name}_${value}`;
    
    return (
        <div className="form-check">
            <Field className="form-check-input form-check-input--checkmark" name={name} id={id} value={value} type="checkbox" onClick={onClick} />
            <label className="form-check-label" htmlFor={id}>{label}</label>
        </div>
    );
}

export default CheckBox;
