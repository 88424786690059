import { FunctionComponent, useContext, useState } from 'react';
import { ConfigContext } from '../../configuration/ConfigContext';
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext';
import ListItem from '../../shared/ListItem';
import { MenuOption } from '../../shared/models/MenuOption';
import { ToastContext } from '../../toaster/ToastContext';
import { removePropertyAssociationAdminGuest, updateAccessLevel } from '../adminService';
import DeleteGuestAdminModal from '../modals/DeleteGuestAdminModal';
import { Admin } from '../models/Admin';
import { PropertyAssociationAccessLevel } from '../adminEnums';
import OverlayConfirmModal from '../../modal/OverlayConfirmModal';

type Props = {
    onAdminUpdated: () => void;
    admins: Admin[],
}

const AdminList: FunctionComponent<Props> = ({onAdminUpdated, admins}) => {
    const { apiBaseUrl } = useContext(ConfigContext);
    const { currentPropertyAssociationId } = useContext(PropertyAssociationContext);
    const { addToast } = useContext(ToastContext);
    const [invitedUserToDelete, setInvitedUserToDelete] = useState<string | undefined>();
    const [userToMakeAdjunct, setUserToMakeAdjunct] = useState<string | undefined>();
    const [userToMakeGuest, setUserToMakeGuest] = useState<string | undefined>();

    const guestMenuOptions: MenuOption[] = [
        {
            title: 'Ta bort',
            action: (id?: string) => setInvitedUserToDelete(id),
        },
        {
            title: 'Sätt full gäståtkomst',
            action: (id?: string) => setUserToMakeAdjunct(id),
        }
    ];

    const adjunctMenuOptions: MenuOption[] = [
        {
            title: 'Ta bort',
            action: (id?: string) => setInvitedUserToDelete(id),
        },
        {
            title: 'Sätt begränsad gäståtkomst',
            action: (id?: string) => setUserToMakeGuest(id),
        }
    ];

    const onDeleteInvitedUser = () => {
        if(!invitedUserToDelete) {
            return;
        }

        removePropertyAssociationAdminGuest(apiBaseUrl, currentPropertyAssociationId, invitedUserToDelete,
            () => {
                setInvitedUserToDelete(undefined);
                addToast('Gäst borttagen');
                onAdminUpdated();
            },
            () => {
                addToast('Kunde inte ta bort gäst', 'error');
            }
        )
    }

    const onMakeAdjunct = () => {
        if(!userToMakeAdjunct) {
            return;
        }

        updateAccessLevel(apiBaseUrl, currentPropertyAssociationId, userToMakeAdjunct, PropertyAssociationAccessLevel.Adjunct,
            () => {
                setUserToMakeAdjunct(undefined);
                addToast('Gäst har nu full gäståtkomst');
                onAdminUpdated();
            },
            () => {
                addToast('Kunde inte ändra behörighet', 'error');
            }
        )
    }

    const onMakeGuest = () => {
        if(!userToMakeGuest) {
            return;
        }

        updateAccessLevel(apiBaseUrl, currentPropertyAssociationId, userToMakeGuest, PropertyAssociationAccessLevel.Guest,
            () => {
                setUserToMakeGuest(undefined);
                addToast('Gäst har nu begränsad gäståtkomst');
                onAdminUpdated();
            },
            () => {
                addToast('Kunde inte ändra behörighet', 'error');
            }
        )
    }

    return (
        <div className="pb-5">
            {admins?.map((x) => renderAdmin(x, guestMenuOptions, adjunctMenuOptions))}

            {invitedUserToDelete && <DeleteGuestAdminModal onClose={() => setInvitedUserToDelete(undefined)} onDelete={onDeleteInvitedUser} />}
            {userToMakeAdjunct && <OverlayConfirmModal heading="Höj behörighet" text="Är du säker på att du vill höja gästens behörighet till full? Användaren kommer att komma åt samtliga funktioner." visible={true} okButtonText="Ja, höj behörighet" onCancel={() => setUserToMakeAdjunct(undefined)} onSubmit={onMakeAdjunct} />}
            {userToMakeGuest && <OverlayConfirmModal heading="Sänk behörighet" text="Är du säker på att du vill sänka behörighet till begränsad?" visible={true} okButtonText="Ja, sänk behörighet" onCancel={() => setUserToMakeGuest(undefined)} onSubmit={onMakeGuest} />}
        </div>
    );
}

function renderAdmin({firstName, lastName, invited, accessLevel, userId}: Admin, guestMenuOptions: MenuOption[], adjunctMenuOptions: MenuOption[]) {
    let role = '';
    let menuOptions: MenuOption[] | undefined;

    if (!invited) {
        role = '';
    }
    else if(accessLevel === PropertyAssociationAccessLevel.Guest) {
        role = 'Begränsad gäståtkomst';
        menuOptions = guestMenuOptions;
    }
    else if(accessLevel === PropertyAssociationAccessLevel.Adjunct) {
        role = 'Full gäståtkomst';
        menuOptions = adjunctMenuOptions;
    }
    
    const fields = [`${firstName} ${lastName}`, role];

    return <ListItem key={userId} id={userId} fields={fields} menuOptions={menuOptions} />
}

export default AdminList;