import React, { Fragment, FunctionComponent } from 'react'
import Modal from './Modal'

type Props = {
    cancelText?: string;
    heading: string;
    onClose: () => void;
    onSubmit: () => void;
    okText?: string;
    text: string;
    visible: boolean;
}

const ConfirmModal: FunctionComponent<Props> = ({heading, onClose, onSubmit, text, visible, okText = 'Ja', cancelText = 'Nej'}) => {
    return (
        <Modal visible={visible} title={heading} onClose={onClose} buttons={
            <Fragment>
                <button className="btn btn-primary" type="button" data-dismiss="modal" onClick={onSubmit}>{okText}</button>
                <button className="btn btn-secondary" type="button" data-dismiss="modal" onClick={onClose}>{cancelText}</button>
            </Fragment>
        }>
            { text }
        </Modal>
    )
}

export default ConfirmModal
