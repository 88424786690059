import { deleteToApi, getFromApi, postToApi } from '../../common/utils/apiHelper'
import { AddAssetsToGroupRequest } from './models/AddAssetsToGroupRequest'
import { CreateAssetGroupRequest } from './models/CreateAssetGroupRequest'
import { GetAssetGroupResponse, GetAssetGroupsResponse } from './models/GetAssetGroupsResponse'
import { RemoveAssetsFromGroupRequest } from './models/RemoveAssetsFromGroupRequest'

export const getAssetGroups = (apiBaseUrl: string, propertyAssociationId: string, onSuccess: (response: GetAssetGroupsResponse) => void, onError: () => void): void => {
    getFromApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/assetgroups`, onSuccess, onError)
}

export const getAssetGroup = (apiBaseUrl: string, propertyAssociationId: string, assetGroupId: string, onSuccess: (response: GetAssetGroupResponse) => void, onError: () => void): void => {
    getFromApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/assetgroups/${assetGroupId}`, onSuccess, onError)
}

export const createAssetGroup = (apiBaseUrl: string, propertyAssociationId: string, data: CreateAssetGroupRequest, onSuccess: (assetGroupId: string) => void, onError: () => void): void => {
    postToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/assetgroups`, data, onSuccess, onError)
}

export const deleteAssetGroup = (apiBaseUrl: string, propertyAssociationId: string, assetGroupId: string, onSuccess: () => void, onError: () => void): void => {
    deleteToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/assetgroups/${assetGroupId}`, onSuccess, onError)
}

export const addAssetToAssetGroup = (apiBaseUrl: string, propertyAssociationId: string, assetGroupId: string, data: AddAssetsToGroupRequest, onSuccess: () => void, onError: () => void): void => {
    postToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/assetgroups/${assetGroupId}/add`, data, onSuccess, onError)
}

export const removeAssetFromAssetGroup = (apiBaseUrl: string, propertyAssociationId: string, assetGroupId: string, data: RemoveAssetsFromGroupRequest, onSuccess: () => void, onError: () => void): void => {
    postToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/assetgroups/${assetGroupId}/remove`, data, onSuccess, onError)
}
