/* eslint-disable react/display-name */
import React, { useContext } from 'react';
import RedirectLoader from './RedirectLoader';
import { AuthContext } from './AuthContext';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const withAuth = (Component: any) => (props: any) => {
	const { isAuthenticated, signinRedirect } = useContext(AuthContext);
    
    if(!isAuthenticated) {
        signinRedirect();
        return <RedirectLoader />;
    }
	
	return <Component {...props} />;
};