import { deleteToApi, getSilentFromApi, postToApi, putToApi } from '../../common/utils/apiHelper';
import { ConversationDetails } from './models/ConversationDetails';
import { ConversationMessageModel } from './models/ConversationMessageModel';
import { PagedConversationModel } from './models/ConversationModel';
import { CreateConversationResponse } from './models/CreateConversationResponse';
import { GetUnreadConversationsResponse } from './models/GetUnreadConversationsResponse';

export const createConversationGroup = (apiBaseUrl: string, propertyAssociationId: string, name: string, propertyAssociationAsParticipant:boolean, userIds: string[], onSuccess: (response: CreateConversationResponse) => void, onFail: () => void): void => {
    postToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/conversations`, { name, propertyAssociationAsParticipant, userIds }, onSuccess, onFail);
};

export const deleteConversation = (apiBaseUrl: string, propertyAssociationId: string, conversationId: string, onSuccess: () => void, onFail: () => void): void => {
    deleteToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/conversations/${conversationId}`, onSuccess, onFail);
}

export const updateConversationGroup = (apiBaseUrl: string, propertyAssociationId: string, conversationId: string, name: string, propertyAssociationAsParticipant: boolean, userIds: string[], onSuccess: () => void, onFail: () => void): void => {
    putToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/conversations/${conversationId}`, { name, propertyAssociationAsParticipant, userIds }, onSuccess, onFail);
}

export const getConversations = (apiBaseUrl: string, propertyAssociationId: string, page: number, onSuccess: (data: PagedConversationModel) => void, onFail: () => void): void => {
    getSilentFromApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/conversations?page=${page}`, onSuccess, onFail);
};

export const getGroupConversations = (apiBaseUrl: string, propertyAssociationId: string, page: number, onSuccess: (data: PagedConversationModel) => void, onFail: () => void): void => {
    getSilentFromApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/conversations/groups?page=${page}`, onSuccess, onFail);
};

export const getConversation = (apiBaseUrl: string, propertyAssociationId: string, conversationId: string, onSuccess: (data: ConversationDetails) => void, onFail: () => void): void => {
    getSilentFromApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/conversations/${conversationId}`, onSuccess, onFail);
};

export const getConversationMessage = (apiBaseUrl: string, propertyAssociationId: string, conversationId: string, messageId: string, onSuccess: (data: ConversationMessageModel) => void, onFail: () => void): void => {
    getSilentFromApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/conversations/${conversationId}/messages/${messageId}`, onSuccess, onFail);
};

export const getUnreadConversations = (apiBaseUrl: string, propertyAssociationId: string, onSuccess: (response: GetUnreadConversationsResponse) => void, onFail: () => void): void => {
    getSilentFromApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/conversations/unread`, onSuccess, onFail);
};

export const markConversationAsRead = (apiBaseUrl: string, propertyAssociationId: string, conversationId: string): void => {
    putToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/conversations/${conversationId}/read`, null, () => { /* No action */ }, () => { /* No action */ });
};

export const markConversationAsUnread = (apiBaseUrl: string, propertyAssociationId: string, conversationId: string): void => {
    putToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/conversations/${conversationId}/unread`, null, () => { /* No action */ }, () => { /* No action */ });
};

export const postConversationMessage = (apiBaseUrl: string, propertyAssociationId: string, conversationId: string, text: string, file: File | undefined, doNotSendNotification: boolean, onSuccess: () => void, onFail: () => void): void => {
    const formData = new FormData();
    formData.append('text', text);
    formData.append('doNotSendNotification', JSON.stringify(doNotSendNotification));

    if(file) { 
        formData.append('file', file);
    }
    
    postToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/conversations/${conversationId}`, formData, onSuccess, onFail);
};
