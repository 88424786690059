import { FunctionComponent } from 'react';
import PaperLayout from '../layout/PaperLayout';
import { withPropertyAssociation } from '../propertyAssociation/withPropertyAssociation';
import { BookingsSortDirection, BookingsSortOrder } from './bookingConstants';
import { getArchivedBookings } from './bookingService';
import BookingList from './components/BookingList';

const BookingArchive: FunctionComponent = () => (
    <PaperLayout heading="Bokningshistorik">
        <BookingList
            isArchived={true}
            defaultSortOrder={BookingsSortOrder.Date}
            defaultSortDirection={BookingsSortDirection.Descending}
            fetchFunction={getArchivedBookings}
            noBookingsHeading="Här visas tidigare bokningar"
            noBookingsText="Just nu finns inga bokningar i arkivet. När en resurs nyttjats kommer det synas här."
            filterNoBookingsHeading="Hoppsan! Här var det tomt"
            filterNoBookingsText="Just nu finns inga arkiverade bokningar för den valda resursen. När den bokats kommer det synas här."
        />
    </PaperLayout>
)

export default withPropertyAssociation(BookingArchive)