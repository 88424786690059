import { createContext, FunctionComponent, useCallback, useEffect, useState } from 'react';
import { ToastMessage } from './models/ToastMessage';
import { ToastMessageType } from './models/ToastMessageType';

const defaultValues: ToastContextValue = {
    toasts: [],
    addToast: () => { console.log('not available') },
    clearToasts: () => { console.log('not available') },
    removeToast: () => { console.log('not available') }
}

type ToastContextValue = {
    toasts: ToastMessage[];
    addToast: (title: string, message?: string | JSX.Element, type?: ToastMessageType) => void;
    clearToasts: () => void;
    removeToast: (id: number) => void;
}

export const ToastContext = createContext<ToastContextValue>(defaultValues);

export const ToastProvider: FunctionComponent = (props) => {
    const addToast = useCallback((title: string, message?: string | JSX.Element, type: ToastMessageType = ToastMessageType.Success) => {
        const id = Math.round(Math.random()*99999);
        setToasts(toasts => [{ id, message, title, type }, ...toasts]);
        if(type !== ToastMessageType.Static) {
            setTimeout(() => removeToast(id), 5000)
        }
    }, []);

    const clearToasts = useCallback(() => {
        setToasts([]);
    }, []);

    const removeToast = useCallback((id: number) => {
        setToasts(x => (x.filter(x => x.id !== id)));
    }, []);

    const [ toasts, setToasts ] = useState<ToastMessage[]>([]);
    const [ value, setValue ] = useState({ addToast, clearToasts, removeToast, toasts });

    useEffect(() => {
        setValue({ addToast, clearToasts, removeToast, toasts })
    }, [addToast, clearToasts, removeToast, toasts])

    return (
        <ToastContext.Provider 
            value={value} 
            {...props} 
        />
    )
}