import React from 'react';
import ReactDOM from 'react-dom';
import { findAsArray, first } from './domUtils';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function render(component: any, selector: string): void {
    const root = first(selector);
    if (root === null) {
        return;
    }

    const element = React.createElement(component);
    ReactDOM.render(element, root);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function renderWithProps(component: any, selector: string, props = {}): void {
    const roots = findAsArray(selector);

    roots.map((root) => {
        const allProps = Object.assign(props, JSON.parse(root.dataset.props || '{}'));
        const element = React.createElement(component, allProps);

        ReactDOM.render(element, root);
    });
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function renderOutsideWithProps(component:any, selector: string, props = {}): void {
    const roots = findAsArray(selector);
    const body = first('body');

    roots.map((root : any) => {
        const allProps = Object.assign(props, JSON.parse(root.dataset.props || '{}'));
        const element = React.createElement(component, allProps);
        body && body.appendChild(root);
        if(allProps.hidden) {
            root.style.display = 'none';
        }
        ReactDOM.render(element, root);
    });
}
