import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { BookableAsset } from '../assets/models/Asset';
import { ConfigContext } from '../configuration/ConfigContext';
import { PropertyAssociationContext } from '../propertyAssociation/PropertyAssociationContext';
import AssetItem from './components/AssetItem';
import { getBookableAssets } from './internalBookingService';
import { withPropertyAssociation } from '../propertyAssociation/withPropertyAssociation';

const SelectAsset: FunctionComponent = () => {
    const { apiBaseUrl } = useContext(ConfigContext)
    const { currentPropertyAssociationId, currentArea } = useContext(PropertyAssociationContext)
    const { push } = useHistory();
    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [bookableAssets, setBookableAssets] = useState<BookableAsset[]>([])

    const setAsset = (asset: BookableAsset) => {
        push(`/bookings/new/${asset.assetId}`)
    }

    useEffect(() => {
        setIsLoading(true);
        getBookableAssets(
            apiBaseUrl,
            currentPropertyAssociationId,
            currentArea?.propertyAssociationAreaId,
            (data) => {
                const sortedAssets = data?.sort((a, b) => a.name.localeCompare(b.name)) ?? [];
                setBookableAssets(sortedAssets);
                setIsLoading(false);
            },
            () => {
                setIsLoading(false);
                setHasError(true);
            }
        )
    }, [])

    return (
        <div className="app__inner app__inner--center">
            <div className="text-center">
                <h1 className="large-heading">Vilken resurs vill du boka?</h1>
                <p className="preamble">I nästa steg väljer du ifall det är en intern- eller förmedlingsbokning som du vill göra.</p>
            </div>
            <div>
                {hasError && (
                    <div className="alert my-3 text-center">Kunde hitta några resurser. Vänligen försök igen.</div>
                )}
                {!isLoading && (
                    <div className='boxlist'>
                        {bookableAssets.map(x => <AssetItem key={x.assetId} asset={x} onClick={() => setAsset(x)} />)}
                    </div>
                )}
            </div>
        </div>
    )
}

export default withPropertyAssociation(SelectAsset);