import { FunctionComponent, useState } from 'react'
import Button from '../../forms/Button'
import Modal from '../../modal/Modal'
import { ApartmentResponse, LocationResponse, GetPinboardMessageStatisticsResponse } from '../models/GetPinboardMessageStatisticsResponse'

type Props = {
    onClose: () => void,
    statistics: GetPinboardMessageStatisticsResponse,
}

const PinboardMessageStatisticsModal: FunctionComponent<Props> = ({onClose, statistics}) => {
    const [location, setLocation] = useState<LocationResponse | undefined>();
    const [apartment, setApartment] = useState<ApartmentResponse | undefined>();

    let heading = 'Statistik';
    if(apartment && location) {
        heading = `${heading} för lägenhet ${apartment.name} på ${location.name}`;
    } else if(location) {
        heading = `${heading} för ${location.name}`;
    }

    return (
        <Modal title={heading} visible={true} buttons={(
            <>
                {getSecondaryButton(location, apartment, setLocation, setApartment)}
                <button className="btn btn-primary" type="button" data-dismiss="modal" onClick={onClose}>Stäng</button>
            </>
        )}>
            <div>
                {renderStatistics(statistics, location, apartment, setLocation, setApartment)}
            </div>
        </Modal>
    )
}

function getClassNames(item) {
    const classNames = 'd-flex justify-content-between list-hover';

    if(item.readCount === 0) {
        return `${classNames} list-marked--unread`;
    }

    if(item.readCount === item.totalCount) {
        return `${classNames} list-marked--read`;
    }

    return `${classNames} list-marked--partial`;
}

function getSecondaryButton(location, apartment, setLocation, setApartment) {
    if(apartment) {
        return <Button key="back_1" text="Visa lägenheter" isPrimary={false} additionalClassName="btn-secondary btn-prev" onClick={() => setApartment(undefined)} />;
    }

    if(location) {
        return <Button key="back_2" text="Visa adresser" isPrimary={false} additionalClassName="btn-secondary btn-prev" onClick={() => setLocation(undefined)} />;
    }

    return null;
}

function renderStatistics(statistics, location, apartment, setLocation, setApartment) {
    if(apartment) {
        return renderApartmentStatistics(apartment);
    }

    if(location) {
        return renderLocationStatistics(location, setApartment);
    }

    return renderAllLocations(statistics, setLocation);
}

function renderAllLocations(statistics: GetPinboardMessageStatisticsResponse, setLocation) {
    return (
        <div>
            <div className="d-flex justify-content-between my-2">
                <div><strong>Adress</strong></div>
                <div><strong>Lägenheter där minst en person läst</strong></div>
            </div>
            {statistics.locations.map((x, index) => (
                <div key={`location_${index}`} onClick={() => setLocation(x)} role="presentation" className={getClassNames(x)}>
                    <div>{x.areaName ? `${x.name} (${x.areaName})` : x.name}</div>
                    <div>{x.readCount} av {x.totalCount}</div>
                </div>
            ))}
        </div>
    );
}

function renderLocationStatistics(location: LocationResponse, setApartment) {
    return (
        <div>
            <div className="d-flex justify-content-between my-2">
                <div><strong>Lägenhet</strong></div>
                <div><strong>Personer som läst</strong></div>
            </div>
            {location.apartments.map((x, index) => (
                <div key={`apartment_${index}`} onClick={() => setApartment(x)} role="presentation" className={getClassNames(x)}>
                    <div>{x.name}</div>
                    <div>{x.readCount} av {x.totalCount}</div>
                </div>
            ))}
        </div>
    );
}

function renderApartmentStatistics(apartment: ApartmentResponse) {
    return (
        <div>
            <div className="d-flex justify-content-between my-2">
                <div><strong>Person</strong></div>
                <div><strong>Läst</strong></div>
            </div>
            {apartment.users.map((x, index) => (
                <div key={`person_${index}`} className={'d-flex justify-content-between list-striped'}>
                    <div>{x.name}</div>
                    <div>{x.hasRead ? (
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="18" height="18" style={{ marginRight: '4px' }}><path fill="#34C759" d="M435.848 83.466 172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z"/></svg>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width="12" height="18" style={{ marginRight: '8px' }}><path fill="#FF3B30" d="m207.6 256 107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"/></svg>
                    )}</div>
                </div>
            ))}
        </div>
    );
}

export default PinboardMessageStatisticsModal

