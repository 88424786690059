import cx from 'classnames';
import { FunctionComponent, ReactNode, useState } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import SubNavigation from '../SubNavigation';
import { withWaitForPropertyAssociation } from '../../propertyAssociation/withWaitForPropertyAssociation';

const BookingsMenu: FunctionComponent = () => {
    const location = useLocation();
    const currentPath = location.pathname;
    const [expanded, setExpanded] = useState(true)

    return (
        <SubNavigation heading="Bokningar"  isExpanded={expanded} onExpand={setExpanded}>
            <div className="submenu__content">
                <div className="submenu__links">
                    { renderLink('/bookings/', 'Aktiva bokningar', currentPath) }
                    { renderLink('/bookings/archive/', 'Bokningshistorik', currentPath) }
                    { renderLink('/bookings/new/', 'Boka', currentPath) }
                    { renderLink('/bookings/statistics/', 'Statistik', currentPath) }
                </div>
            </div>
        </SubNavigation>
    );
}

export default withWaitForPropertyAssociation(BookingsMenu)

function renderLink(path: string, text: string, currentPath: string): ReactNode {
    const isCurrentPath = currentPath === path;
    return <Link to={path} className={cx(['submenu__link', { 'submenu__link--current': isCurrentPath }])}>{text}</Link>
}
