import { getFromApi, postToApi } from '../../common/utils/apiHelper';
import { CreateInvitationRequest } from './models/CreateInvitationRequest';
import { GetInvitationsResponse } from './models/GetInvitationsResponse';
import { InvitationInfoResponse } from './models/InvitationInfoResponse';
import { RespondToInvitationRequest } from './models/RespondToInvitationRequest';

export const getInvitation = (apiBaseUrl: string, invitationId: string, onSuccess: (data: InvitationInfoResponse) => void, onFail: () => void): void => {
    getFromApi(`${apiBaseUrl}/invitations/${invitationId}`, onSuccess, onFail);
}

export const respondToInvitation = (apiBaseUrl: string, invitationId: string, data: RespondToInvitationRequest, onSuccess: (data: GetInvitationsResponse) => void, onFail: () => void): void => {
    postToApi(`${apiBaseUrl}/invitations/${invitationId}/respond`, data, onSuccess, onFail);
}

export const getInvitationsInPropertyAssociation = (apiBaseUrl: string, propertyAssociationId: string, onSuccess: (response: GetInvitationsResponse) => void, onFail: () => void): void => {
    getFromApi(`${apiBaseUrl}/propertyAssociations/${propertyAssociationId}/invitations`, onSuccess, onFail);
}

export const createInvitationToPropertyAssociation = (apiBaseUrl: string, propertyAssociationId: string, data: CreateInvitationRequest, onSuccess: (data: GetInvitationsResponse) => void, onFail: () => void): void => {
    postToApi(`${apiBaseUrl}/propertyAssociations/${propertyAssociationId}/invitations`, data, onSuccess, onFail);
}

export const invalidateInvitation = (apiBaseUrl: string, propertyAssociationId: string, invitationId: string, onSuccess: () => void, onFail: () => void): void => {
    getFromApi(`${apiBaseUrl}/propertyAssociations/${propertyAssociationId}/invitations/${invitationId}/invalidate`, onSuccess, onFail);
}
