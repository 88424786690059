import { FunctionComponent } from 'react';
import { useHistory } from 'react-router';
import { tryFormatDate } from '../../../common/utils/dateUtils';
import { PinboardMessageResponse } from '../models/GetPinboardMessagesResponse';

type Props = PinboardMessageResponse;

const PinboardMessageItem: FunctionComponent<Props> = ({ propertyAssociationArea, publishDate, pinboardMessageId, title, usersCanRespond }) => {
    const history = useHistory();

    const goToEdit = () => {
        history.push(`/adverts/information/${pinboardMessageId}`);
    };

    const handleKeyPress = (keyEvent: { key: string }) => {
        if (keyEvent?.key === 'Enter') {
            goToEdit();
        }
    };

    const heading = propertyAssociationArea ? `${title} (${propertyAssociationArea.name})` : title;

    return (
        <div role='button' tabIndex={0} onKeyDown={handleKeyPress} className='hs-list__row' onClick={goToEdit}>
            <div className='col-6 ps-3'>
                <h4 className='my-0'>{heading}</h4>
            </div>
            <div className='col-3 d-flex align-items-center'>
                {usersCanRespond ? 'Boende kan svara' : 'Boende kan inte svara'}
            </div>
            <div className='col-3 d-flex justify-content-end align-items-center'>
                <div className='mx-4 px-2'>{tryFormatDate(publishDate)}</div>
            </div>
        </div>
    );
};

export default PinboardMessageItem;
