export function downloadAsCsvFile(data: string[][], fileName: string): void {
    const csv = convertArrayToCsv(data);
    downloadAsFile(csv, fileName, 'text/csv');
}

function convertArrayToCsv(data: string[][]): string {
    const csv = data.map(row => row.map(cell => `"${cell}"`.replace(/;/g, ',')).join(';')).join('\n');

    // Add BOM to support Excel
    return '\uFEFF' + csv;
}

export function downloadAsFile(data: string, fileName: string, type: string): void {
    const blob = new Blob([data], { type });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(url);
}