export enum BookingsSortOrder {
    None = 0,
    Name = 1,
    Date = 2,
    BookedBy = 4,
    Reference = 5,
}

export enum BookingsSortDirection {
    Ascending = 0,
    Descending = 1,
}

export const MESSAGE_SEND_FAIL = 'Det gick inte att skicka meddelandet. Var vänlig försök igen.'

export const BOOKING_CANCEL_FAIL = 'Det gick inte att genomföra avbokningen. Var vänlig försök igen.'

export const BOOKING_NOT_FOUND = 'Bokningen hittades inte.'