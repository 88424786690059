import { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import ErrorMessage from '../../common/error/ErrorMessage';
import { addDays, getStartOfWeek, tryFormatDate } from '../../common/utils/dateUtils';
import { getAsset, getAssetBookings } from '../assets/assetService';
import { Asset } from '../assets/models/Asset';
import { ConfigContext } from '../configuration/ConfigContext';
import PaperLayout from '../layout/PaperLayout';
import { PropertyAssociationContext } from '../propertyAssociation/PropertyAssociationContext';
import { withPropertyAssociation } from '../propertyAssociation/withPropertyAssociation';
import styles from './BookingOverview.module.scss';
import DateSelector from './components/DateSelector';
import DetailedSchedule from './components/DetailedSchedule';
import ObjectSelector from './components/ObjectSelector';
import { GetAssetBookingsResponse } from './models/GetAssetBookingsResponse';
import BookingModal from '../bookingModal/BookingModal';

const BookingOverview: FunctionComponent = () => {
    const { id } = useParams() as any;
    const { apiBaseUrl } = useContext(ConfigContext);
    const { currentPropertyAssociationId } = useContext(PropertyAssociationContext);
    const { push } = useHistory();
    const [ asset, setAsset ] = useState<Asset>();
    const [ bookings, setBookings ] = useState<GetAssetBookingsResponse>();
    const [ error, setError ] = useState<string | undefined>(undefined);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ selectedBookingId, setSelectedBookingId ] = useState<string | undefined>();
    const [ objectFilter, setObjectFilter ] = useState<string[]>([]);
    const [ startDate, setStartDate ] = useState<Date>(getStartOfWeek(new Date()));

    const onClose = useCallback(() => {
        push(`/assets/edit/${id}`);
    }, [id])

    useEffect(() => {
        if(!id) {
            return;
        }

        getAssetInformation();
    }, [id]);

    useEffect(() => {
        if(!id) {
            return;
        }
        
        getBookings();
    }, [id, startDate]);

    const getAssetInformation = () => {
        getAsset(
            apiBaseUrl,
            currentPropertyAssociationId,
            id,
            setAsset,
            () => setError('Kunde inte hämta resursinformation')
        )
    }

    const getBookings = () => {
        const from = tryFormatDate(startDate);
        const to = tryFormatDate(addDays(startDate, 7));
        
        setIsLoading(true);
        getAssetBookings(
            apiBaseUrl, 
            currentPropertyAssociationId, 
            id, 
            from, 
            to,
            (response) => {
                setIsLoading(false);
                setBookings(response);
            }, 
            () => {
                setIsLoading(false)
                setError('Kunde inte hämta bokningar');
            }
        );
    }

    if(error) {
        return <ErrorMessage message={error} />
    }

    if(!asset || !bookings) {
        return null;
    }

    return (
        <PaperLayout heading="Bokningsöversikt" tightenUp={true}>
            <button className="paper__back" onClick={onClose}>Tillbaka till resursen</button>

            <div className={styles.preamble}>
                Visar alla bokningar under den valda veckan. Klicka på en bokning för att komma till detaljvyn.
                Vill du inspirera fler boende att använda resursen? Använd gärna våra framtagna mallar för 
                utdelning till boende och anslag i trapphuset.
                &nbsp;<a href="https://mitt.riksbyggen.se/styrelsearbete/foreningen/hemshare/" target="_blank" rel="noreferrer">Läs mer här</a> 
            </div>

            <DateSelector startDate={startDate} setStartDate={setStartDate} />

            {asset.objects.length > 1 && <ObjectSelector objects={asset.objects} objectFilter={objectFilter} setObjectFilter={setObjectFilter} />}

            <DetailedSchedule asset={asset} bookings={bookings} objectFilter={objectFilter} startDate={startDate} setSelectedBookingId={setSelectedBookingId} />
            
            {selectedBookingId && <BookingModal bookingId={selectedBookingId} hasMultipleObjects={asset.objects?.length > 1} onClose={() => setSelectedBookingId(undefined)} onRefresh={getBookings} />}

            {isLoading && <div className={styles.blocker}></div>}
        </PaperLayout>
    )
}

export default withPropertyAssociation(BookingOverview)
