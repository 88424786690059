import { FunctionComponent } from 'react';
import { ExtendedDate } from '../models/ExtendedDate';
import { Option } from '../../forms/models/Option';
import RadioBoxDropDown from '../../forms/RadioBoxDropDown';

type Props = {
    endOptions: Option[];
    formProps: any;
    selectedEndTime: ExtendedDate | null;
    selectedStartTime: ExtendedDate | null;
    startOptions: Option[];
}

const TimeSelector: FunctionComponent<Props> = ({selectedStartTime, selectedEndTime, startOptions, endOptions, formProps}) => {
    if(!selectedStartTime || !selectedEndTime || !(startOptions.length > 1 || endOptions.length > 1)) {
        return null;
    }

    return (
        <div className="d-md-flex">
            <div className="me-md-4">
                <p className="mt-5 mb-3">Starttid</p>
                <div>
                    <RadioBoxDropDown name="startTime" label="Starttid" options={startOptions} {...formProps} />
                </div>
            </div>
            <div>
                <p className="mt-5 mb-3">Sluttid</p>
                <div>
                    <RadioBoxDropDown name="endTime" label="Sluttid" options={endOptions} {...formProps} />
                </div>
            </div>
        </div>
    )
}

export default TimeSelector