import { Form, Formik } from 'formik';
import { FunctionComponent, ReactNode } from 'react';
import { useHistory } from 'react-router';
import FormikChangeSubscriber from '../../forms/FormikChangeSubscriber';
import { BookingTypes } from '../BookingTypes';
import { ExtendedDate } from '../models/ExtendedDate';

type Props = {
    children: (formProps) => ReactNode;
    customButton?: ReactNode;
    endTimes: ExtendedDate[] | undefined;
    error: string | undefined;
    formikRef: any;
    initialValues: any;
    isSubmitting: boolean;
    onSubmit: (values) => void;
    selectedStartTime: ExtendedDate | null;
    setBookingType: (bookingType: BookingTypes | undefined) => void;
    setSelectedEndTime: (endTime: ExtendedDate | null) => void;
    setSelectedStartTime: (startTime: ExtendedDate | null) => void;
    startTimes: ExtendedDate[];
    validationSchema: any;
}

const BookingForm: FunctionComponent<Props> = ({children, customButton, endTimes, error, formikRef, initialValues, isSubmitting, onSubmit, selectedStartTime, setBookingType, setSelectedEndTime, setSelectedStartTime, startTimes, validationSchema}) => {
    const { push } = useHistory();

    const handleFormChange = (values: any) => {
        if(values.startTime !== selectedStartTime?.value) {
            const startTime = startTimes.find(x => x.value === values.startTime);
            setSelectedStartTime(startTime ?? null);
            setSelectedEndTime(null);
        }

        const endTime = endTimes?.find(x => x.value === values.endTime);
        setSelectedEndTime(endTime ?? null);
    }

    return (
        <Formik
            initialValues={initialValues}
            innerRef={formikRef as any}
            enableReinitialize={false}
            validationSchema={validationSchema}
            validateOnMount={true}
            onSubmit={onSubmit}
        >
            {({ isValid, values, errors, touched }) => {
                const formProps = { values, errors, touched };
                return (
                    <Form>
                        <FormikChangeSubscriber onChange={(values) => handleFormChange(values)} />
                        
                        {children(formProps)}

                        {error && <div className="alert alert-danger">{error}</div>}

                        <div className="paper__buttons">
                            <div className="paper__secondary-buttons">
                                <button type="button" className="btn btn-lg btn-secondary" onClick={() => push('/bookings/')}>Avbryt</button>
                            </div>
                            <div className="paper__primary-buttons">
                                <button type="button" className='btn btn-secondary btn-lg btn-prev' onClick={() => setBookingType(undefined)}>Tillbaka</button>
                                {customButton ? customButton : <button type="submit" className="btn btn-success btn-lg" disabled={!isValid || isSubmitting}>Boka</button>}
                            </div>
                        </div>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default BookingForm