import { FunctionComponent, useContext } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Toast from './components/Toast';
import { ToastContext } from './ToastContext';

const Toaster: FunctionComponent = () => {
    const { removeToast, toasts } = useContext(ToastContext)
    
    return (
        <div className="custom-toaster">
            <TransitionGroup component={null}> 
            { toasts.map((toast) => (
                <CSSTransition key={toast.id} timeout={{ enter: 1000, exit: 2000 }} classNames="custom-toast-">
                    <Toast {...toast} removeToast={removeToast} />
                </CSSTransition>
            ))}
            </TransitionGroup>
        </div>
    )
}

export default Toaster
