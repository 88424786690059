import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { ConfigContext } from '../../configuration/ConfigContext';
import Button from '../../forms/Button';
import AlertModal from '../../modal/AlertModal';
import Modal from '../../modal/Modal';
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext';
import { UserAdvertDetails } from '../models/UserAdvert';
import { getUserAdvert } from '../userAdvertService';
import PreviewBlock from '../../shared/PreviewBlock';
import styles from './UserAdvertDetailsModal.module.scss';
import DeleteUserAdvertModal from './DeleteUserAdvertModal';

type Props = {
    advertId: string;
    onAdvertDeleted: (advertId: string) => void;
    onClose: () => void;
}

const UserAdvertDetailsModal: FunctionComponent<Props> = ({advertId, onAdvertDeleted, onClose}) => {
    const { apiBaseUrl } = useContext(ConfigContext);
    const { currentPropertyAssociationId } = useContext(PropertyAssociationContext);
    const [advert, setAdvert] = useState<UserAdvertDetails | undefined>();
    const [deleteAdvert, setDeleteAdvert] = useState(false);
    const [error, setError] = useState<string | undefined>();

    useEffect(() => {
        getUserAdvert(apiBaseUrl, currentPropertyAssociationId, advertId, ({advert}) => setAdvert(advert), () => setError('Kunde inte ladda post'));
    }, []);

    if(error) {
        return <AlertModal heading="Ett fel har uppstått" text={error} onClose={onClose} visible={true} />
    }

    if(!advert) {
        return null;
    }

    if(deleteAdvert) {
        return (
            <DeleteUserAdvertModal advertId={advertId} onAdvertDeleted={onAdvertDeleted} onClose={onClose} />
        )
    }

    return (
        <Modal onClose={onClose} buttons={getButtons(advert, onClose, setDeleteAdvert)} title="Detaljer" visible={true}>
            <PreviewBlock title={advert.title}>
                {advert.text}
            </PreviewBlock>
            {advert.images?.length > 0 && (
                <PreviewBlock title="Bilder">
                    <div className={styles.images}>
                        {advert.images.map((image, index) => (
                            <img key={index} src={image} alt="" className={styles.images__item} />
                        ))}
                    </div>
                </PreviewBlock>
            )}
            <PreviewBlock title="Skapad av" icon="person">
                {advert.user.firstName} {advert.user.lastName}
            </PreviewBlock>
            {advert.removalInfo && (
                <PreviewBlock title="Borttagen" icon="exclamation">
                    Borttagen av {advert.removalInfo.removerFirstName} {advert.removalInfo.removerLastName}:<br />
                    &quot;{advert.removalInfo.reason}&quot;
                </PreviewBlock>
            )}
        </Modal>
    )
}

function getButtons(advert: UserAdvertDetails, onClose: () => void, setDeleteAdvert: (deleteAdvert: boolean) => void) {
    return (
        <>
            {!advert.removalInfo && <Button  text="Ta bort" isPrimary={false} additionalClassName="btn-secondary btn-icon-delete" onClick={() => setDeleteAdvert(true)} />}
            <Button text="Stäng" isPrimary={true} onClick={onClose} />
        </>
    )
}

export default UserAdvertDetailsModal