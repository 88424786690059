import { Fragment, FunctionComponent, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { getActiveBookings } from '../../bookings/bookingService';
import { Booking } from '../../bookings/models/Booking';
import { ConfigContext } from '../../configuration/ConfigContext';
import Button from '../../forms/Button';
import OverlayAlertModal from '../../modal/OverlayAlertModal';
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext';
import { ToastContext } from '../../toaster/ToastContext';
import { displayBookingCancellationTerms, displayBookingType, displayLateCancellationFee, displayMaxBookings, displayPrice, isDraft, isPublished, isUnpublished } from '../assetHelpers';
import { deleteAsset, getAsset, getSharedPropertyAssociations, publishAsset, refreshAssets, unpublishAsset } from '../assetService';
import { AssetTypeDefinition } from '../assetTypes/assetTypes';
import AssetGroupList from '../components/AssetGroupList';
import EntranceMethod from '../components/EntranceMethod';
import DeleteModal from '../modals/DeleteModal';
import { Asset } from '../models/Asset';

type Props = {
    defaults: AssetTypeDefinition;
    setIsInEditMode: (isInEditMode: boolean) => void;
    asset: Asset;
    updateValues: (values) => void;
}

const AssetOverview: FunctionComponent<Props> = ({ defaults, asset, setIsInEditMode, updateValues }) => {
    const history = useHistory();
    const { addToast } = useContext(ToastContext);
    const { apiBaseUrl } = useContext(ConfigContext);
    const { currentPropertyAssociationId, currentArea } = useContext(PropertyAssociationContext);
    const [error, setError] = useState<string | null>();
    const [isDeleting, setIsDeleting] = useState(false);
    const [isWorking, setIsWorking] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [shareCount, setShareCount] = useState(0);
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [bookings, setBookings] = useState<Booking[]>([]);

    const areaId = currentArea?.propertyAssociationAreaId;

    useEffect(() => {
        getActiveBookings(
            apiBaseUrl, 
            currentPropertyAssociationId, 
            asset.assetId, 
            currentArea?.propertyAssociationAreaId,
            (bookings) => setBookings(bookings), 
            () => setError('Kunde inte hämta bokningar för resursen')
        );
    }, [])

    useEffect(() => {
        if(isDraft(asset.status)) {
            return;
        }

        getSharedPropertyAssociations(
            apiBaseUrl,
            currentPropertyAssociationId,
            asset.assetId,
            ({propertyAssociations}) => {
                setShareCount(propertyAssociations.length);
            },
            () => { /* NOTE: Do nothing */ }
        )
    }, [asset?.status])

    const unpublish = () => {
        if (!isWorking) {
            setIsWorking(true);
        }

        unpublishAsset(apiBaseUrl, currentPropertyAssociationId, asset.assetId,
            (assetId) => {
                getAsset(apiBaseUrl, currentPropertyAssociationId, assetId, (data: Asset) => {
                    updateValues(data);
                    refreshAssets(apiBaseUrl, currentPropertyAssociationId, areaId);
                }, () => () => setError('Kunde inte dölja resursen.'))
            },
            () => setError('Kunde inte dölja resursen.'),
            () => setIsWorking(false)
        );
    }

    const onDeleteAsset = () => {
        deleteAsset(apiBaseUrl, currentPropertyAssociationId, asset.assetId,
            () => {
                addToast(`Resursen '${asset.name}' är nu borttagen`);
                refreshAssets(apiBaseUrl, currentPropertyAssociationId, areaId);
                history.push('/assets/');
            },
            () => setError('Kunde inte ta bort resursen.')
        );
    }

    const publish = () => {
        if (!isWorking) {
            setIsWorking(true);
        }

        publishAsset(apiBaseUrl, currentPropertyAssociationId, asset.assetId,
            (assetId: string) => {
                getAsset(apiBaseUrl, currentPropertyAssociationId, assetId, (data: Asset) => {
                    updateValues(data);
                    refreshAssets(apiBaseUrl, currentPropertyAssociationId, areaId);
                }, () => setError('Kunde inte uppdatera resursen.'))
            },
            () => setError('Kunde inte publicera resursen.'),
            () => setIsWorking(false)
        );
    }

    const haveBookings = (bookings: Booking[]): boolean => bookings.length > 0;
    const monthsInAdvance = Math.floor(Number(asset.bookingMaxDaysInAdvance) / 30);

    return (
        <Fragment>
            <div className="paper__main">
                {isUnpublished(asset.status) && (
                    <div className="paper__preamble  paper__preamble--unpublished">
                        <strong>Dold resurs</strong>
                        <p>Den här resursen är dold. För att göra den synlig i Mitt Boende-appen tryck på knappen ”Publicera”.</p>
                    </div>
                )}
                <div className="preview">
                    {asset.images?.length > 0 ? (
                        <div className="d-flex flex-wrap p-relative">
                            {asset.images.map((image, index) => {
                                if (index === 0) {
                                    return (
                                        <div className="preview__image-block  w-100" key={image.originalPath}>
                                            <div className="preview__image-wrapper">
                                                <img src={image.path} alt="" className="preview__image" />
                                            </div>
                                        </div>
                                    )
                                }
        
                                return (
                                    <div className="preview__image-block  preview__image-block--thumb w-50" key={image.originalPath}>
                                        <div className="preview__image-wrapper" >
                                            <img src={image.path} alt="" className="preview__image" />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    ) : (
                        <div className="preview__image-block">
                            <div className="preview__image-wrapper">
                                {/* <img src={asset.images[0].path} alt="" className="preview__image" /> */}
                                <img src={defaults.image} alt="" className="preview__image preview__image--default" />
                            </div>
                        </div>
                    )}

                    <div className="preview__block  preview__block--read-only  preview__heading">
                        {asset.serviceInformation?.serviceInformationTitle ? (
                            asset.serviceInformation.serviceInformationTitle
                        ) : (
                            <i className="preview__default">{defaults.serviceInformationTitle}</i>
                        )}
                    </div>
                    <div className="preview__block  preview__block--read-only">
                        {asset.serviceInformation?.serviceInformationText ? (
                            asset.serviceInformation.serviceInformationText
                        ) : (
                            <i className="preview__default">{defaults.serviceInformationText}</i>
                        )}
                    </div>
                    {asset.serviceInformation?.serviceInformationKeywords?.length > 0 && (
                        <div className="preview__block  preview__block--read-only">
                            <ul className="preview__list">
                                {asset.serviceInformation?.serviceInformationKeywords?.map(x => <li key={x}>{x}</li>)}
                            </ul>
                        </div>
                    )}
                    <div className="preview__file-block">
                        <strong>Bokningsregler</strong>
                        {!asset.terms && <p>Inga upplagda bokningsregler</p>}
                        {asset.terms && (
                            <div className="file">
                                <div className="file__content">
                                    <a href={asset.terms} target="_blank" rel="noreferrer" className="file__link">Bokningsregler</a>
                                    Se bokningsreglerna här
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="preview__block  preview__block--read-only  preview__block--price">
                        <div className="preview__block-heading">Pris</div>
                        {displayPrice(asset)}<br />
                        {asset.price === 0 && displayBookingType(asset)}
                    </div>
                    <div className="preview__block  preview__block--read-only  preview__block--key">
                        <div className="preview__block-heading">Åtkomst</div>
                        <EntranceMethod asset={asset} />
                    </div>
                    <div className="preview__block  preview__block--read-only  preview__block--calendar">
                        <div className="preview__block-heading">Bokningsvillkor</div>
                        {displayBookingCancellationTerms(asset)}<br />
                        {displayLateCancellationFee(asset)}
                        <div>{displayMaxBookings(asset)}</div>
                        {asset.requireVerifiedPhoneNumber && <div>Telefonnummer krävs vid bokning.</div>}
                    </div>
                </div>
                <div className="paper__content  paper__content--preview">
                    <strong>Tillgänglighet</strong>
                    <p>En boende kan boka denna resurs {monthsInAdvance} {monthsInAdvance === 1 ? 'månad' : 'månader'} i förväg.</p>
                </div>
                { asset.assetGroupId && (
                    <div className="alert alert-warning mb-0 rounded-0">
                        <div className="paper__content  paper__content--preview py-0">
                            <strong>Kopplad till andra resurser</strong>
                            <p className="mb-2">Tillgängliga tider vid bokning påverkas även av bokningar för följande resurser:</p>
                            <AssetGroupList assetGroupId={asset.assetGroupId} asset={asset} />
                        </div>
                    </div>
                )}
            </div>

            {/* // NOTE: Used for desktop only */}
            <div className="paper__buttons  desktop-only">
                <div className="paper__secondary-buttons">
                    {isDraft(asset.status) && <button type="button" className="btn btn-lg btn-secondary btn-icon-delete" disabled={isWorking} onClick={() => setIsDeleting(true)}>Ta bort</button>}
                    {isUnpublished(asset.status) && haveBookings(bookings) && <button type="button" className="btn btn-lg btn-secondary btn-icon-delete" disabled={isWorking} onClick={() => setError('Resursen är för tillfället bokad. Endast obokade resurser kan tas bort.')}>Ta bort resurs</button>}
                    {isUnpublished(asset.status) && !haveBookings(bookings) && <button type="button" className="btn btn-lg btn-secondary btn-icon-delete" disabled={isWorking} onClick={() => setIsDeleting(true)}>Ta bort</button>}
                    {isPublished(asset.status) && <button type="button" className="btn btn-lg btn-secondary btn-icon-delete btn-disabled" onClick={() => setError('Endast dolda resurser kan tas bort. Vill du ta bort denna resurs måste du först dölja den.')}>Ta bort</button>}
                    {isPublished(asset.status) && <button type="button" className="btn btn-lg btn-primary btn-icon-hide" disabled={isWorking} onClick={unpublish}>Dölj</button>}
                    {isPublished(asset.status) && <button type="button" className="btn btn-lg btn-primary px-4" disabled={isWorking} onClick={() => {history.push(`/bookings/new/${asset.assetId}`)}}>Boka</button>}
                    {!isDraft(asset.status) && <Button text="Bokningsöversikt" additionalClassName="btn-lg btn-less-padding" disabled={isWorking} onClick={() => {history.push(`/assets/overview/${asset.assetId}`)}} />}
                    {/* TODO: Återställ för resursdelning {!isDraft(asset.status) && <Button text={`Dela (${shareCount})`} additionalClassName="btn-lg btn-less-padding" disabled={isWorking} onClick={() => {history.push(`/assets/share/${asset.assetId}`)}} />} */}
                </div>
                <div className="paper__primary-buttons">
                    {isUnpublished(asset.status) && <button type="button" className="btn btn-lg btn-primary btn-icon-publish" disabled={isWorking} onClick={publish}>Publicera</button>}
                    <button type="button" className="btn btn-lg btn-primary btn-icon-edit" disabled={isWorking} onClick={() => setIsInEditMode(true)}>Ändra</button>
                </div>
            </div>

            <div className="paper__buttons  mobile-only">
                {showMobileMenu && (
                    // NOTE: Used for mobile native style menu
                    <div className="native-menu">
                        <div className="native-menu__content">
                            <div className="native-menu__actions">
                                {isDraft(asset.status) && <button type="button" className="native-menu__action" disabled={isWorking} onClick={() => setIsDeleting(true)}>Ta bort resurs</button>}
                                {isUnpublished(asset.status) && haveBookings(bookings) && <button type="button" className="native-menu__action" disabled={isWorking} onClick={() => setError('Resursen är för tillfället bokad. Endast obokade resurser kan tas bort.')}>Ta bort resurs</button>}
                                {isUnpublished(asset.status) && !haveBookings(bookings) && <button type="button" className="native-menu__action" disabled={isWorking} onClick={() => setIsDeleting(true)}>Ta bort resurs</button>}
                                {isPublished(asset.status) && <button type="button" className="native-menu__action" onClick={() => setError('Endast dolda resurser kan tas bort. Vill du ta bort denna resurs måste du först dölja den.')}>Ta bort resurs</button>}
                                {isPublished(asset.status) && <button type="button" className="native-menu__action" disabled={isWorking} onClick={unpublish}>Dölj resurs</button>}
                                {isPublished(asset.status) && <button type="button" className="native-menu__action" disabled={isWorking} onClick={() => {history.push(`/bookings/new/${asset.assetId}`)}}>Boka</button>}
                                {!isDraft(asset.status) && <button type="button" className="native-menu__action" disabled={isWorking} onClick={() => {history.push(`/assets/overview/${asset.assetId}`)}}>Bokningsöversikt</button>}
                                {/* TODO: Återställ för resursdelning {!isDraft(asset.status) && <button type="button" className="native-menu__action" disabled={isWorking} onClick={() => {history.push(`/assets/share/${asset.assetId}`)}}>Dela resurs</button>} */}
                                {isUnpublished(asset.status) && <button type="button" className="native-menu__action" disabled={isWorking} onClick={publish}>Publicera resurs</button>}
                                <button type="button" className="native-menu__action" disabled={isWorking} onClick={() => setIsInEditMode(true)}>Ändra</button>
                            </div>
                            <button type="button" className="native-menu__button" onClick={() => setShowMobileMenu(false)}>Avbryt</button>
                        </div>
                    </div>
                )}
                <button type="button" className="btn btn-lg btn-primary w-100" disabled={isWorking} onClick={() => setShowMobileMenu(true)}>Hantera resurs</button>
            </div>

            {error && <OverlayAlertModal heading="Ooops!" text={error} visible={true} onClick={() => setError(null)} />}
            {isDeleting && <DeleteModal name={asset.name} onCancel={() => setIsDeleting(false)} onDelete={onDeleteAsset} />}
        </Fragment>
    );
}

export default AssetOverview