import { TimeSelectionType } from '../assetConstants';
import { AssetTypeDefinition } from './assetTypes';

export const ASSETTYPE_OTHER = 'Other';

export const DEFAULTS_OTHER: AssetTypeDefinition = {
    alwaysUseDays: false,
    assetTypeTag: ASSETTYPE_OTHER,
    backdrop: '/images/defaults/backdrop/RB_gemensamma-resurser_ovrigt.png',
    canChangeToDaily: true,
    canChangeToHourly: true,
    canChangeToInterval: true,
    endTimeNextDay: true,
    endTimeRestOfDay: false,
    image: '/images/defaults/preview/RB_gemensamma-resurser_ovrigt.png',    
    isSingleton: true,
    name: 'Övrigt',
    nameDefinitiveForm: 'resurser',
    namePlural: 'resurser',
    nameSingular: 'resurs',
    options: [
        'Diskmaskin',
        'Dusch',
        'Frys',
        'Husdjur tillåtna',
        'Kallgarage',
        'Kylskåp',
        'Kök/pentry',
        'Laddstolpe (elbil)',
        'Mikrovågsugn',
        'Motorvärmare',
        'Parkering (bil)',
        'Parkering (motorcykel)',
        'Parkering ingår',
        'Relaxstolar',
        'Relaxstolar',
        'Strykjärn',
        'Toalett',
        'Uteplats/balkong',
        'Varmgarage',
        'Wifi',
    ],
    prefixName: false,
    serviceInformationText: 'Ex. Här kan du beskriva övriga resurser du vill erbjuda för bokning. Allt från grillar till cyklar!',
    serviceInformationTitle: 'Vad vill du erbjuda?',
    thumbnail: '/images/defaults/thumbnails/RB_gemensamma-resurser_ovrigt.png',
    timeSelectionType: TimeSelectionType.Startstop,
}