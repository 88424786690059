import { ExtendedDate } from '../../features/internalBooking/models/ExtendedDate';

const dayNames = ['Söndag', 'Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag'];

const monthNames = ['jan', 'feb', 'mars', 'april', 'maj', 'juni', 'juli', 'aug', 'sep', 'okt', 'nov', 'dec'];

function makeDoubleDigitString(value: number) {
    return value < 10 ? `0${value}` : `${value}`;
}

export function tryFormatDate(value: string | Date): string {
    if (!value) {
        return '';
    }
    try {
        const date = new Date(value);
        const year = date.getFullYear();
        const month = makeDoubleDigitString(date.getMonth() + 1);
        const day = makeDoubleDigitString(date.getDate());
        return `${year}-${month}-${day}`;
    } catch {
        return '';
    }
}

export function tryFormatDateTime(value: string | Date, fallback = ''): string {
    if (!value) {
        return fallback;
    }
    try {
        const date = new Date(value);
        const year = date.getFullYear();
        const month = makeDoubleDigitString(date.getMonth() + 1);
        const day = makeDoubleDigitString(date.getDate());
        const hours = makeDoubleDigitString(date.getHours());
        const minutes = makeDoubleDigitString(date.getMinutes());
        return `${year}-${month}-${day} ${hours}:${minutes}`;
    } catch {
        return fallback;
    }
}

export function tryFormatDateTimeRange(startDateTime: string | Date, endDateTime: string | Date): string {
    if (!startDateTime) {
        return '-';
    }

    if (!endDateTime) {
        return tryFormatDateTime(startDateTime);
    }

    const startDate = tryFormatDate(startDateTime);
    const startTime = tryFormatTime(startDateTime);
    const endDate = tryFormatDate(endDateTime);
    const endTime = tryFormatTime(endDateTime);

    if (startDate === endDate) {
        return `${startDate} ${startTime} – ${endTime}`;
    }

    return `${startDate} ${startTime} – ${endDate} ${endTime}`;
}

export function tryFormatTime(value: string | Date): string {
    if (!value) {
        return '';
    }
    try {
        const date = new Date(value);

        const hours = makeDoubleDigitString(date.getHours());
        const minutes = makeDoubleDigitString(date.getMinutes());
        return `${hours}:${minutes}`;
    } catch {
        return '';
    }
}

export function tryFormatDateDayMonth(value: string | Date): string {
    if (!value) {
        return '';
    }
    try {
        const date = new Date(value);

        const month = date.toLocaleDateString('default', { month: 'short' });
        const day = date.getDate();
        return `${day} ${month}`;
    } catch {
        return '';
    }
}

export function tryFormatDateDayMonthTime(value: string | Date): string {
    if (!value) {
        return '';
    }
    try {
        const date = new Date(value);

        const month = date.toLocaleDateString('default', { month: 'short' });
        const day = date.getDate();
        const hours = makeDoubleDigitString(date.getHours());
        const minutes = makeDoubleDigitString(date.getMinutes());

        if (isSameYear(date, new Date())) {
            return `${day} ${month} ${hours}:${minutes}`;
        }

        return `${day} ${month} ${date.getFullYear()} ${hours}:${minutes}`;
    } catch {
        return '';
    }
}

export function tryFormatDateDayMonthTimeStartEnd(startValue: string | Date, endValue: string | Date): string {
    return `${tryFormatDateDayMonth(startValue)} ${tryFormatTime(startValue)} - ${tryFormatTime(endValue)}`;
}

export function tryFormatDateDayDashMonth(value: string | Date): string {
    if (!value) {
        return '';
    }
    try {
        const date = new Date(value);

        const month = makeDoubleDigitString(date.getMonth() + 1);
        const day = makeDoubleDigitString(date.getDate());
        return `${day}-${month}`;
    } catch {
        return '';
    }
}

export function isToday(value: string | Date): boolean {
    if (!value) {
        return false;
    }
    try {
        const date = new Date(value);
        const today = new Date();
        return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
    } catch {
        return false;
    }
}

export function isSameYear(a: Date, b: Date): boolean {
    return a.getFullYear() === b.getFullYear();
}

export function getTime(value: string | Date): number {
    if (!value) {
        return new Date(0,0,0).getTime();
    }
    try {
        return new Date(value).getTime();
    } catch {
        return new Date(0,0,0).getTime();
    }
}

export function compareDates(valueA: string | Date, valueB: string | Date): number {
    const diff = getTime(valueA) - getTime(valueB);
    return diff;
}

export function isSameDate(x: ExtendedDate, value: Date): boolean {
    return x.year === value.getFullYear() && x.month === value.getMonth() && x.day === value.getDate();
}

export function isSame(a: Date, b: Date): boolean {
    return a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth() && a.getDate() === b.getDate();
}

export function formatDate(date: Date): string {
    if(!date) {
        return '';
    }
    
    const localeString = date.toLocaleString('sv-SE', { weekday: 'long', day: 'numeric', month: 'long', hour: 'numeric', minute: 'numeric' });
    
    return localeString.charAt(0).toUpperCase() + localeString.slice(1);
}

export function formatDateString(date: string): string {
    if(!date) {
        return '';
    }
    
    return formatDate(new Date(date));
}

export function formatExtendedDate(x: ExtendedDate): string {
    if(!x || !x.date) {
        return '';
    }
    
    return formatDate(x.date);
}

export function formatDayAndDate(date: Date): string {
    if(!date) {
        return '';
    }
    
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const weekday = date.getDay();
    
    return `${dayNames[weekday]} ${day}/${month}`;
}

export function addDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

export function addHours(date: Date, hours: number): Date {
    const result = new Date(date);
    result.setHours(result.getHours() + hours);
    return result;
}

export function formatDateRange(startDate: Date, days: number): string {
    const endDate = addDays(startDate, days);
    const startMonth = startDate.getMonth() + 1;
    const endMonth = endDate.getMonth() + 1;
    const startDay = startDate.getDate();
    const endDay = endDate.getDate();
    const startYear = startDate.getFullYear();
    const endYear = endDate.getFullYear();

    if(startYear !== endYear) {
        return `${startDay} ${monthNames[startMonth - 1]} ${startYear} - ${endDay} ${monthNames[endMonth - 1]} ${endYear}`;
    }

    if (startMonth === endMonth && startYear === endYear) {
        return `${startDay} - ${endDay} ${monthNames[startMonth - 1]} ${startYear}`;
    }

    return `${startDay} ${monthNames[startMonth - 1]} - ${endDay} ${monthNames[endMonth - 1]} ${startYear}`;
}

export function formatTime(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const minutesLeft = minutes % 60;
    return `${hours}:${makeDoubleDigitString(minutesLeft)}`;
}

export function getStartOfWeek(date: Date): Date {
    date.setHours(0, 0, 0, 0);
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(date.setDate(diff));
}

export function formatConversationDate(value: Date | string | null): string {
    if (!value) {
        return '';
    }

    try {
        const date = new Date(value);
        const now = new Date();

        if(isToday(date)) {
            return tryFormatTime(date);
        }

        if(addDays(date, 7) > now) {
            return dayNames[date.getDay()];
        }

        if(isSameYear(date, now)) {
            return `${date.getDate()} ${monthNames[date.getMonth()]}`;
        }

        return date.getFullYear().toString();
    }
    catch {
        return '';
    }
}