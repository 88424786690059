import cx from 'classnames';
import { Field, getIn } from 'formik';
import React, { FunctionComponent } from 'react';
import FormField from './FormField';
import Label from './Label';

type Props = {
    component?: string;
    disabled?: boolean;
    errors: any;
    label?: string;
    maxLength: number;
    name: string;
    placeholder?: string;
    rows?: number;
    touched: any;
    type?: string;
    values: any;
    width?: number;
}

const MaxTextField: FunctionComponent<Props> = ({component = 'input', disabled, label, maxLength, name, placeholder, rows, type = 'text', width, values, errors, touched}) => {
    const value = getIn(values, name);
	const error = getIn(errors, name);
    const isTouched = getIn(touched, name);

    return (
        <FormField width={width}>
            {label && <Label name={name} text={label} />}
            <Field
                autoComplete="off"
                className={cx('form-control', { 'is-invalid': error && isTouched }, { 'is-valid': !error && isTouched })}
                component={component}
                disabled={disabled}
                id={name}
                maxLength={maxLength}
                name={name}
                placeholder={placeholder}
                rows={rows}
                type={type}
                value={value}
            />
            <div className="form-count">{`${value?.length}/${maxLength} tecken` }</div>
            {error && isTouched && <div className="field-error text-danger">{error}</div>}
        </FormField>
    )
}

export default MaxTextField