import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { ConfigContext } from '../../configuration/ConfigContext';
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext';
import { getAssetGroup } from '../assetGroupService';
import { Asset } from '../models/Asset';
import { GetAssetGroupResponse } from '../models/GetAssetGroupsResponse';

type Props = {
    assetGroupId: string;
    asset: Asset;
    onRemove?: (assetId: string) => void;
}

const AssetGroupList: FunctionComponent<Props> = ({ assetGroupId, asset, onRemove }) => {
    const { apiBaseUrl } = useContext(ConfigContext);
    const { currentPropertyAssociation } = useContext(PropertyAssociationContext);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [assetGroup, setAssetGroup] = useState<GetAssetGroupResponse>();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getAssetGroup(
            apiBaseUrl,
            currentPropertyAssociation?.propertyAssociationId ?? '',
            assetGroupId,
            (response) => {
                setAssetGroup(response);
                setIsLoading(false);
            },
            () => setErrorMessage('Kunde inte ladda resursgrupp.')
        );
    }, [apiBaseUrl, asset, currentPropertyAssociation?.propertyAssociationId]);

    if (errorMessage) {
        return <div>{errorMessage}</div>
    }

    if (isLoading || !assetGroup ) {
        return <div>Laddar...</div>
    }

    const handleRemove = (e: React.MouseEvent<HTMLAnchorElement>, assetId: string) => {
        e.preventDefault();
        onRemove?.(assetId);
    }

    return (
        <ul className="mb-3">
            { assetGroup.assets.map(({assetId, name}) => {
                if (assetId === asset.assetId) {
                    return null;
                }

                return (
                    <li key={assetId}>
                        {name}
                        {onRemove && <a href={`#${assetId}`} role="button" className="ms-2" onClick={(e) => handleRemove(e, assetId)}>Ta bort</a>}
                    </li>
                );
            })}
        </ul>
    );
}

export default AssetGroupList