import { FunctionComponent } from 'react'
import ListItem from '../../shared/ListItem'
import { GuestInvitation } from '../models/GuestInvitation'

type Props = {
    invitations: GuestInvitation[],
}

const InactiveInvitationList: FunctionComponent<Props> = ({ invitations }) => (
    <div className="pb-5">
        {invitations?.map(({ id, email, accessLevel, status }) => <ListItem key={id} id={id} fields={[`${email} (${accessLevel})`, status ?? 'Besvarades ej']} />)}
    </div>
)

export default InactiveInvitationList