import NProgress from 'nprogress';
import React, { FunctionComponent } from 'react';

const RedirectLoader: FunctionComponent = () => {
    NProgress.start();

    return <div className="block-ui"></div>;
}

export default RedirectLoader
