import React, { FunctionComponent, useContext } from 'react';
import RedirectLoader from './RedirectLoader';
import { AuthContext } from './AuthContext';

const AuthSilentRenew: FunctionComponent = () => {
    const { signinSilentCallback } = useContext(AuthContext);

    signinSilentCallback();

    return <RedirectLoader />;
};

export default AuthSilentRenew;