import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { ConfigContext } from '../configuration/ConfigContext';
import PaperLayout from '../layout/PaperLayout';
import { PropertyAssociationContext } from '../propertyAssociation/PropertyAssociationContext';
import { withPropertyAssociation } from '../propertyAssociation/withPropertyAssociation';
import { getApartments } from './apartmentService';
import ApartmentList from './components/ApartmentList';
import LocationList from './components/LocationList';
import { GetApartmentsResponse, LocationResponse } from './models/GetApartmentsResponse';

const Apartments: FunctionComponent = () => {
    const { apiBaseUrl } = useContext(ConfigContext);
    const { currentPropertyAssociationId, currentArea } = useContext(PropertyAssociationContext);
    const [response, setResponse] = useState<GetApartmentsResponse>()
    const [location, setLocation] = useState<LocationResponse | undefined>()

    const loadApartments = () => {
        getApartments(
            apiBaseUrl,
            currentPropertyAssociationId,
            currentArea?.propertyAssociationAreaId,
            setResponse,
            () => { throw new Error('Kunde inte ladda lägenheter.'); }
        );
    }

    useEffect(() => {
        loadApartments();
    }, [])

    useEffect(() => {
        if (response && location) {
            const refreshedLocation = response.locations.find(l => l.locationId === location.locationId);
            setLocation(refreshedLocation);
        }
    }, [response]);

    if (!response) {
        return null
    }

    return (
        <PaperLayout heading="Lägenheter" tightenUp={true}>
            {location ? (
                <ApartmentList location={location} refreshApartments={loadApartments} setLocation={setLocation} />
            ) : (
                <LocationList locations={response.locations} setLocation={setLocation} />
            )}
        </PaperLayout>
    )
}

export default withPropertyAssociation(Apartments, false)