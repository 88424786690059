import { Component } from 'react';
import ErrorMessage from './ErrorMessage';

interface IErrorBoundaryState {
	hasError: boolean;
	errorMessage: string | null;
}

class ErrorBoundary extends Component<any, IErrorBoundaryState> {
	constructor(props: unknown) {
		super(props);
		this.state = { hasError: false, errorMessage: null };
	}

	static getDerivedStateFromError(error: Error): IErrorBoundaryState {

		return { hasError: true, errorMessage: error.message };
	}

	componentDidCatch(error: Error, info: unknown): void {
		console.log(error, info);
	}

	render(): any {
		const { hasError, errorMessage } = this.state;
		if (hasError) {
			return <ErrorMessage message={errorMessage ?? 'Ett fel har uppstått'} />
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
