import { FunctionComponent } from 'react';
import Button from '../../forms/Button';

type Props = {
    buttonText?: string;
    heading: string;
    onButtonClick?: () => void;
    text: string;
}

const NoConversations: FunctionComponent<Props> = ({buttonText, heading, onButtonClick, text}) => (
    <div className="paper__message  paper__message--large">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="57" height="57"><path d="M256 64c123.5 0 224 79 224 176S379.5 416 256 416c-28.3 0-56.3-4.3-83.2-12.8l-15.2-4.8-13 9.2c-23 16.3-58.5 35.3-102.6 39.6 12-15.1 29.8-40.4 40.8-69.6l7.1-18.7-13.7-14.6C47.3 313.7 32 277.6 32 240c0-97 100.5-176 224-176m0-32C114.6 32 0 125.1 0 240c0 47.6 19.9 91.2 52.9 126.3C38 405.7 7 439.1 6.5 439.5c-6.6 7-8.4 17.2-4.6 26 3.8 8.8 12.4 14.5 22 14.5 61.5 0 110-25.7 139.1-46.3 29 9.1 60.2 14.3 93 14.3 141.4 0 256-93.1 256-208S397.4 32 256 32z"/></svg>
        <div className="paper__subheading">{heading}</div>
        <p>{text}</p>
        { buttonText && onButtonClick && <Button onClick={onButtonClick} text={buttonText} /> }
    </div>
)

export default NoConversations