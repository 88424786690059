import { isEmpty } from 'lodash';
import { FunctionComponent } from 'react';
import { Asset } from '../models/Asset';

type Props = {
    asset: Asset;
}

const EntranceMethod: FunctionComponent<Props> = ({asset}) => {
    const entranceMethod = asset.entranceMethod;
    if(!entranceMethod) {
        return <i className="preview__default">Ange hur gästen får tillgång till resursen</i>;
    }

    const { contactName, contactInformation, otherInformation} = entranceMethod;

    const fields: string[] = [];
    if(contactName) {
        fields.push(contactName);
    }
    if(contactInformation) {
        fields.push(contactInformation);
    }
    if(otherInformation) {
        fields.push(otherInformation);
    }
    return (
        <div>
            <>{entranceMethod.description}<br /></>
            {!isEmpty(contactName) && <><span>{contactName}, {contactInformation}</span> <br /></>}
            {!isEmpty(otherInformation) && <span>{otherInformation}</span> }
        </div>
    )
}

export default EntranceMethod