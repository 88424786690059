import { FunctionComponent } from 'react';
import PaperLayout from '../layout/PaperLayout';
import { withPropertyAssociation } from '../propertyAssociation/withPropertyAssociation';
import MemberList from './components/MemberList';
import { MembersSortDirection, MembersSortOrder } from './memberConstants';
import { getPendingMembers } from './memberService';

const PendingMembers: FunctionComponent = () => (
    <PaperLayout heading="Åtkomstförfrågningar" tightenUp={true} mainClass="pt-4">
        <div className="mb-4">
            De boende som listas här har laddat ned Mitt Boende-appen och accepterat användarvillkoren.
        </div>
        <MemberList
            isPending={true}
            defaultSortOrder={MembersSortOrder.FirstName}
            defaultSortDirection={MembersSortDirection.Descending}
            fetchFunction={getPendingMembers} />
    </PaperLayout>
)

export default withPropertyAssociation(PendingMembers, false)