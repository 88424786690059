import React, { FunctionComponent } from 'react'
import Button from '../forms/Button'

type Props = {
    heading: string;
    onClick: () => void;
    text: string;
    visible: boolean;
}

const OverlayAlertModal: FunctionComponent<Props> = ({ heading, onClick, text, visible }) => {
    if(!visible) {
        return null;
    }

    return (
        <div className="cover">
            <div className="cover__content">
                <div className="cover__heading">{heading}</div>
                <div className="cover__text">
                    {text}
                </div>
                <Button text="Okej" additionalClassName="btn-lg" onClick={onClick} />
            </div>
        </div>
    )
}

export default OverlayAlertModal