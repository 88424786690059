import React, { FunctionComponent, useEffect } from 'react';
import AnimateHeight from '../../shared/AnimateHeight';
import { AlertMessage } from '../models/AlertMessage';
import styles from './VMABanner.module.scss';

type Props = AlertMessage & {
    onDismiss: (alertMessageId: string) => void;
}

const VMABanner: FunctionComponent<Props> = ({ alertMessageId, backgroundColorHex, link, linkText, onDismiss, text, title }: Props) => {
    const [isExpanded, setIsExpanded] = React.useState(false);

    useEffect(() => {
        setIsExpanded(true);
    }, [])

    const handleClose = () => {
        setIsExpanded(false);
        onDismiss(alertMessageId);
    }

    return (
        <AnimateHeight animateOpacity duration={500} height={isExpanded ? 'auto' : 0} >
            <div className={styles.banner} style={{ background: backgroundColorHex }}>
                {/* <div className={styles.banner__icon}></div> */}
                <div className={styles.banner__text}>
                    {title && <div className={styles.banner__heading}>{title}</div>}
                    <p>{text}</p>
                    {link && <p><a href={link} rel="noreferrer" target="_blank">{linkText}</a></p>}
                </div>
                <button type="button" onClick={handleClose} className={styles.banner__close}>✖ Dölj</button>
            </div>
        </AnimateHeight>
    )
}

export default VMABanner