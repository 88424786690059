
const colors = ['#00A2BB', '#AD3E8D', '#E9641F', '#5F725F', '#015D82', '#8C5829', '#0D4159', '#6B3C5E', '#E5777E', '#868686'];

export function generateColourFromName(name: string): string {
    const hash = hashCode(name);
    const colorIndex = Math.abs(hash) % colors.length;
    return colors[colorIndex];    
}

export function getIndexedColor(index: number): string {
    const colorIndex = index % colors.length;
    const colorGeneration = Math.floor(index / colors.length);
    const darkenAmount = colorGeneration * 0.1;
    const color = colors[colorIndex];
    return darkenColor(color, darkenAmount);
}

function darkenColor(color: string, amount: number): string {
    const colorRgb = hexToRgb(color);
    const newColorRgb = {
        r: Math.max(colorRgb.r - amount * 255, 0),
        g: Math.max(colorRgb.g - amount * 255, 0),
        b: Math.max(colorRgb.b - amount * 255, 0),
    };
    return rgbToHex(newColorRgb);
}

function hexToRgb(hex: string): { r: number, g: number, b: number } {
    const hexWithoutHash = hex.replace('#', '');
    const bigint = parseInt(hexWithoutHash, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return { r, g, b };
}

function rgbToHex(rgb: { r: number, g: number, b: number }): string {
    const { r, g, b } = rgb;
    const hex = ((r << 16) | (g << 8) | b).toString(16);
    return '#' + new Array(Math.abs(hex.length - 7)).join('0') + hex;
}

function hashCode(name: string): number {
    let hash = 0;
    if (name.length === 0) {
        return hash;
    }
    for (let i = 0; i < name.length; i++) {
        const char = name.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash = hash & hash;
    }
    return hash;
}
