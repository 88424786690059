import cx from 'classnames';
import { Fragment, FunctionComponent, useCallback, useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Asset } from '../models/Asset';
import { ConfigContext } from '../../configuration/ConfigContext';
import OverlayAlertModal from '../../modal/OverlayAlertModal';
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext';
import { deleteTerms, uploadTerms } from '../assetService';

type Props = {
    asset: Asset;
    updateValues: (values) => void;
};

const AssetFile: FunctionComponent<Props> = ({asset, updateValues}) => {
    const { apiBaseUrl } = useContext(ConfigContext);
    const { currentPropertyAssociationId } = useContext(PropertyAssociationContext);
    const [ error, setError ] = useState<string | undefined>()

    const onDrop = useCallback((acceptedFiles: File[]) => {
        if(acceptedFiles.length === 0) {
            return;
        }

        const file = acceptedFiles[0];
        if(file.size > 10000000) {
            setError('Filen överskrider tillåten maxstorlek')
            return;
        }

        uploadTerms(apiBaseUrl, currentPropertyAssociationId, asset.assetId, file, 
            (terms) => {
                updateValues(Object.assign(asset, { terms }))
            },
            () => setError('Det gick tyvärr inte att ladda upp filen.')
        );
    }, [asset]);
        
    const handleDelete = () => {
        deleteTerms(apiBaseUrl, currentPropertyAssociationId, asset.assetId,
            () => {
                updateValues(Object.assign(asset, { terms: null }))
            },
            () => setError('Det gick tyvärr inte att ta bort filen.'))
    }

    const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({ 
        accept: 'application/pdf',
        multiple: false,
        onDrop,
    });

    const errorModal = error && <OverlayAlertModal heading="Ooops!" text={error} visible={true} onClick={() => setError(undefined)} />

    if(asset.terms) {
        return (
            <Fragment>
                <div className="preview__file-block">
                    <strong>Bokningsregler (valfritt)</strong>
                    {/* <Tooltip text="TODO: Lorem ipsum" /> */}
                    <div className="file">
                        <div className="file__content">
                            <a href={asset.terms} rel="noreferrer" target="_blank" className="file__link">Bokningsregler</a>
                            Se bokningsreglerna här
                        </div>
                        <button className="file__delete" onClick={handleDelete}>Ta bort</button>
                    </div>
                </div>
                { errorModal }
            </Fragment>
        );
    }

    return (
        <Fragment>
            <div className={cx(['preview__file-block', {'preview__file-block--accept': isDragAccept}, {'preview__file-block--reject': isDragReject} ])} {...getRootProps()}>
                <input {...getInputProps()} />
                <strong>Bokningsregler (valfritt)</strong>
                <div className="mt-1 mb-3">Giltigt format är PDF (maximal storlek 10 MB)</div>
                <button className="btn btn-lg btn-secondary btn-icon-upload mb-2">Ladda upp fil</button>
            </div>
            { errorModal }
        </Fragment>
    )
}

export default AssetFile
