import { FunctionComponent } from 'react'

const NoPropertyAssociation: FunctionComponent = () => (
    <div className="app__inner app__inner--center">
        <article className="text-center">
            <div className="large-heading">Engagemang saknas</div>
            <p className="preamble">
                Det verkar som att du inte är kopplad till något engagemang.<br />
                Kontakta din förening för att få hjälp med en inbjudan.
            </p>
        </article>
    </div>
)

export default NoPropertyAssociation