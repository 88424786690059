import React, { FunctionComponent, useCallback } from 'react'
import { Field, useField, FieldHelperProps } from 'formik'

type Props = {
    label: string;
    name: string;
    onClick?: () => void;
    readonly?: boolean;
    value: any;
}

const parseNumberAndUpdateFieldValue = (setValue: FieldHelperProps<boolean>['setValue']) => event => setValue(event.target.value === 'true');

const BooleanRadioButton: FunctionComponent<Props> = ({ label, name, onClick, readonly = false, value }) => {
	const id = `${name}_${value}`;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [field, meta, helpers] = useField<boolean>({ name });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleChange = useCallback(
        parseNumberAndUpdateFieldValue(helpers.setValue), 
        [helpers.setValue]
    );

	return (
        <div className="form-check mb-2">
            <Field
				className="form-check-input form-check-input--checkmark"
				name={name}
                id={id}
                disabled={readonly}
                onClick={onClick}
				type="radio"
                value={value}
                onChange={handleChange}
			/>
            <label className="form-check-label" htmlFor={id}>{label}</label>
        </div>
	)
}

export default BooleanRadioButton
