import { FunctionComponent, useContext } from 'react';
import { ConfigContext } from '../../configuration/ConfigContext';
import Modal from '../../modal/Modal';
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext';
import PreviewBlock from '../../shared/PreviewBlock';
import { ToastContext } from '../../toaster/ToastContext';
import EditAccess from '../components/EditAccess';
import { addMember, removeMember } from '../memberService';
import { Member } from '../models/Member';

type Props = {
    isPending: boolean,
    member: Member,
    onClose: () => void,
    onMemberUpdated: () => void,
}

const MemberDetailsModal: FunctionComponent<Props> = ({ isPending, member, onClose, onMemberUpdated }) => {
    const { apiBaseUrl } = useContext(ConfigContext);
    const { currentPropertyAssociationId } = useContext(PropertyAssociationContext);
    const { addToast } = useContext(ToastContext);

    const AddMember = () => {
        addMember(apiBaseUrl, currentPropertyAssociationId, member.user.userId,
            () => {
                onClose()
                addToast('Åtkomst tillagd')
                onMemberUpdated()
            }, 
            () => {
                onClose()
            }
        )
    }

    const RemoveMember = () => {
        removeMember(apiBaseUrl, currentPropertyAssociationId, member.user.userId,
            () => {
                onClose()
                addToast('Åtkomst borttagen')
                onMemberUpdated()
            },
            () => {
                onClose()
            }
        )
    }

    const AddMemberButton = () => {
        if (!member.hasAccess && isPending) {
            return <button onClick={() => AddMember()} className="btn btn-primary" type="button">Lägg till åtkomst</button>
        }
        return null
    };
 
    const RemoveMemberButton = () => {
        if (!member.fromSPAR && member.hasAccess) {
            return <button onClick={() => RemoveMember()} className="btn btn-primary" type="button">Ta bort all åtkomst</button>
        }
        return null
    };

    return (
        <Modal onClose={onClose} buttons={<><AddMemberButton /><RemoveMemberButton /></>} title='Åtkomst' visible={true}>
            <PreviewBlock title='Boende' icon='person'>
                {`${member.user.firstName} ${member.user.lastName}`}
            </PreviewBlock>
            <PreviewBlock title='Adress' icon='exclamation'>
                {member.propertyAssociationAddresses?.find(() => true)?.street ?? 'Ingen adress inom BRF:en'}
            </PreviewBlock>
            { !isPending && <EditAccess member={member} onMemberUpdated={onMemberUpdated} /> }
        </Modal>
    )
}

export default MemberDetailsModal