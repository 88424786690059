import React, { FunctionComponent } from 'react'

type Props = {
    name: string,
    text: string,
}

const Label: FunctionComponent<Props> = ({name, text}) => (
    <label htmlFor={name} className="form-label">{text}</label>
)

export default Label