import React, { FunctionComponent, useContext } from 'react';
import RedirectLoader from './RedirectLoader';
import { AuthContext } from './AuthContext';

const AuthSignOut: FunctionComponent = () => {
    const { signout } = useContext(AuthContext);

    signout();

    return <RedirectLoader />;
};

export default AuthSignOut;