/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { buildQueryString, getFromApi, postStringToApi, postToApi } from '../../common/utils/apiHelper';
import { BookableAsset } from '../assets/models/Asset';
import { AvailableResource } from './models/AvailableResource';
import { AvailableEndTimesWithRestrictions, AvailableStartTimesWithRestrictions } from './models/AvailableTime';

export const getBookableAssets = (
    apiBaseUrl: string,
    propertyAssociationId: string,
    areaId: string | undefined,
    onSuccess: (data: BookableAsset[]) => void,
    onFail: () => void
) : void => {
    getFromApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/internalbookings/bookable${buildQueryString({areaId})}`, onSuccess, onFail)
}

export const getBookableAsset = (
    apiBaseUrl: string,
    propertyAssociationId: string,
    assetId: string,
    onSuccess: (data: BookableAsset) => void,
    onFail: () => void
) : void => {
    getFromApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/internalbookings/${assetId}`, onSuccess, onFail)
}

export const getAvailableStartTimes = (
    apiBaseUrl: string,
    propertyAssociationId: string,
    assetId: string,
    userId: string | undefined,
    data: any,
    onSuccess: (data: AvailableStartTimesWithRestrictions) => void,
    onFail: () => void,
) : void => {
    if (userId) {
        postToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/internalbookings/${assetId}/available/starttimes/foruser/${userId}`, data, onSuccess, onFail)
    } 
    else {
        postToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/internalbookings/${assetId}/available/starttimes`, data, (availableStartTimes) => onSuccess({ availableStartTimes, restrictionsPrevent: false }), onFail)
    }
}

export const getAvailableEndTimes = (
    apiBaseUrl: string,
    propertyAssociationId: string,
    assetId: string,
    userId: string | undefined,
    data: string,
    onSuccess: (data: AvailableEndTimesWithRestrictions) => void,
    onFail: () => void,
) : void => {
    if (userId) {
        postStringToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/internalbookings/${assetId}/available/endtimes/foruser/${userId}`, data, onSuccess, onFail)
    }
    else {
        postStringToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/internalbookings/${assetId}/available/endtimes`, data, (availableEndTimes) => onSuccess({ availableEndTimes, restrictionsPrevent: false }), onFail)
    }
}

export const getAvailableResources = (
    apiBaseUrl: string,
    propertyAssociationId: string,
    assetId: string,
    data: any,
    onSuccess: (data: AvailableResource[]) => void,
    onFail: () => void,
) : void => {
    postToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/internalbookings/${assetId}/available/resources`, data, onSuccess, onFail)
}

export const bookInternally = (
    apiBaseUrl: string,
    propertyAssociationId: string,
    data: any,
    onSuccess: (data: any) => void,
    onFail: () => void,
) : void => {
    postToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/internalbookings/`, data, onSuccess, onFail)
}

export const bookIntermediate = (
    apiBaseUrl: string,
    propertyAssociationId: string,
    data: any,
    onSuccess: (data: any) => void,
    onFail: () => void,
) : void => {
    postToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/internalbookings/intermediate`, data, onSuccess, onFail);
}

export const checkRestrictions = (
    apiBaseUrl: string,
    propertyAssociationId: string,
    assetId: string,
    identifier: string,
    onSuccess: (data: any) => void,
    onFail: () => void,
) : void => {
    getFromApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/internalbookings/${assetId}/restrictions/${identifier}`, onSuccess, onFail);
}