import cx from 'classnames';
import { FunctionComponent } from 'react';
import { Link, useLocation } from 'react-router-dom';

type Props = {
    available?: boolean;
    match?: string;
    path: string;
    notificationCount?: number;
}

const MenuItem: FunctionComponent<Props> = ({ notificationCount = 0, match, path, children, available = true }) => {
    const location = useLocation();
    const prefix = match ? match : path;
    const className = cx('menu__item', { 'menu__item--active': location?.pathname.startsWith(prefix) })

    if(!available) {
        return null;
    }

    return (
        <Link to={path} className={className}>
            { children }
            { notificationCount > 0 && <span className="menu__badge">{notificationCount}</span> }
        </Link>
    )
}

export default MenuItem