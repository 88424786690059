/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { deleteToApi, getFromApi, postToApi, putToApi } from '../../common/utils/apiHelper';
import { Admin } from './models/Admin';

export const getPropertyAssociationAdmins = (
    apiBaseUrl: string,
    propertyAssociationId: string,
    onSuccess: (data: Admin[]) => void,
    onFail: () => void
): void => {
    getFromApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/managers`, onSuccess, onFail)
}

// Deprecated
export const addPropertyAssociationAdminGuest = (
    apiBaseUrl: string,
    propertyAssociationId: string,
    data: any,
    onSuccess: () => void,
    onFail: () => void
): void => {
    postToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/managers`, data, onSuccess, onFail)
}

export const removePropertyAssociationAdminGuest = (
    apiBaseUrl: string,
    propertyAssociationId: string,
    userId: string,
    onSuccess: () => void,
    onFail: () => void
): void => {
    deleteToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/managers/${userId}`, onSuccess, onFail)
}

export const updateAccessLevel = (
    apiBaseUrl: string,
    propertyAssociationId: string,
    userId: string,
    accessLevel: number,
    onSuccess: () => void,
    onFail: () => void
): void => {
    putToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/managers/${userId}/accesslevel/${accessLevel}`, {}, onSuccess, onFail)
}

export const updatePropertyAssociationAdmins = (
    apiBaseUrl: string,
    propertyAssociationId: string,
    onSuccess: () => void,
    onFail: () => void
): void => {
    postToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/managers/update`, undefined, onSuccess, onFail)  
}
