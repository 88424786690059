import { FunctionComponent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AssetStatus } from '../assets/assetConstants';
import { useAssets } from '../assets/assetService';
import { AssetListItem } from '../assets/models/AssetListItem';
import PaperLayout from '../layout/PaperLayout';
import { withPropertyAssociation } from '../propertyAssociation/withPropertyAssociation';
import EmptyList from '../shared/EmptyList';
import Tabs from '../shared/Tabs';
import { TabOption } from '../shared/models/TabOption';
import AssetFilter from './components/AssetFilter';
import AssetRevenueChart from './components/AssetRevenueChart';

const currentYear = new Date().getFullYear();

const months: TabOption[] = [
    { title: 'Hela året', value: '' },
    { title: 'Jan', value: '1' },
    { title: 'Feb', value: '2' },
    { title: 'Mar', value: '3' },
    { title: 'Apr', value: '4' },
    { title: 'Maj', value: '5' },
    { title: 'Jun', value: '6' },
    { title: 'Jul', value: '7' },
    { title: 'Aug', value: '8' },
    { title: 'Sep', value: '9' },
    { title: 'Okt', value: '10' },
    { title: 'Nov', value: '11' },
    { title: 'Dec', value: '12' },
]

const AssetStatistics: FunctionComponent = () => {
    const { assets, isLoading } = useAssets();
    const [filteredAssets, setFilteredAssets] = useState<AssetListItem[]>([]);
    const [years] = useState(buildTabs());
    const [activeYear, setActiveYear] = useState(`${currentYear}`);
    const [activeMonth, setActiveMonth] = useState('');
    const [assetFilter, setAssetFilter] = useState<string[]>([]);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        if(isLoading) {
            return;
        }

        if(!assets) {
            setIsReady(true);
            return;
        }

        const filteredAssets = assets.filter((x) => x.status !== AssetStatus.Created);
        setFilteredAssets(filteredAssets);
        setIsReady(true);
    }, [assets, isLoading])

    if (!isReady) {
        return null;
    }

    if (!assets) {
        return (
            <PaperLayout heading="Statistik för resurser" tightenUp={true}>
                <EmptyList heading="Det finns inga resurser att visa statistik för">
                    <p>Klicka på knappen nedan för att lägga till er första resurs.</p>
                    <Link to="/assets/create" className="btn btn-lg btn-primary">Lägg till resurs</Link>
                </EmptyList>
            </PaperLayout>
        );
    }

    const chartProps = { activeYear, activeMonth, assetFilter, setActiveMonth };

    return (
        <PaperLayout heading="Statistik för resurser" tightenUp={true}>
            <Tabs activeTab={activeYear} onChange={setActiveYear} tabs={years} tight={true} />
            <Tabs activeTab={activeMonth} onChange={setActiveMonth} tabs={months} />

            <AssetFilter assets={filteredAssets} setAssetFilter={setAssetFilter} />

            <h2>Intäkter i kr</h2>
            <AssetRevenueChart {...chartProps} />
        </PaperLayout>
    )
}

function buildTabs(): TabOption[] {
    const fromYear = 2021;
    const tabs: TabOption[] = [];

    for (let year = fromYear; year <= currentYear; year++) {
        tabs.push({ title: `${year}`, value: `${year}` });
    }

    return tabs;
}

export default withPropertyAssociation(AssetStatistics)