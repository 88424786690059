import cx from 'classnames';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import styles from './ListItem.module.scss';
import { MenuOption } from './models/MenuOption';

type Props = {
    id: string;
    fields: string | string[],
    menuOptions?: MenuOption[],
}

const ListItem: FunctionComponent<Props> = ({fields, id, menuOptions}) => {
    const [showOptions, setShowOptions] = useState(false);
    const node = useRef<HTMLDivElement>(null);

	useEffect(() => {
        if(!menuOptions) {
            return;
        }

		document.addEventListener('mousedown', handleClickOutSide);
		return () => {
			document.removeEventListener('mousedown', handleClickOutSide);
		};
	}, [menuOptions])

	const handleClickOutSide = (e: any) => {
		if (node.current && !node.current.contains(e.target)) {
			setShowOptions(false)
		}
	}

    return (
        <div className={cx(styles.row, {[styles['two-columns']]: fields.length === 2})}>
            <div className={styles.fields}>
                {renderFields(fields)}
            </div>
            {menuOptions && (
                <div ref={node} className={styles.wrapper}>
                    <button className={styles.button} type="button" onClick={() => setShowOptions(!showOptions)}></button>
                    {showOptions && (
                        <div className={styles.options}>
                            {menuOptions.map((x) => <button key={x.title} className={styles.option} type="button" onClick={() => x.action(id)}>{x.title}</button>)}
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

function renderFields(fields: string | string[]) {
    if (typeof fields === 'string') {
        return <div className={styles.field}>{fields}</div>
    }

    return fields.map((x) => <div key={x} className={styles.field}>{x}</div>)
}

export default ListItem