import { FunctionComponent } from 'react';
import Button from '../../forms/Button';

type Props = {
    name: string;
    onCancel: () => void;
    onDelete: () => void;
}

const DeleteModal: FunctionComponent<Props> = ({ name, onCancel, onDelete }) => (
    <div className="cover">
        <div className="cover__content">
            <div className="cover__heading">Ta bort resurs?</div>
            <div className="cover__text">
                Är du säker på att du vill ta bort <strong>{name}</strong>?<br />
                En borttagen resurs går ej att återställa.
            </div>
            <Button text="Avbryt" additionalClassName="btn-lg" onClick={onCancel} />
            <Button text="Ta bort resurs" additionalClassName="btn-lg btn-secondary" isPrimary={false} onClick={onDelete} />
        </div>
    </div>
)

export default DeleteModal