import { Fragment, FunctionComponent, useContext, useState } from 'react';
import * as Yup from 'yup';
import { Asset } from '../models/Asset';
import { ConfigContext } from '../../configuration/ConfigContext';
import TextField from '../../forms/TextField';
import { emailValidator } from '../../forms/validators';
import FormModal from '../../modal/FormModal';
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext';
import { MESSAGES_SAVE_FAILED } from '../assetConstants';
import { updateAsset } from '../assetService';

type Props = {
    initialValues: Asset;
    onClose: () => void;
    updateValues: (values) => void;
    visible: boolean;
}
const validationSchema = Yup.object().shape({
    email: emailValidator.nullable(),
})

const EditRecipientModal: FunctionComponent<Props> = ({ initialValues, onClose, updateValues, visible }) => {
    const { apiBaseUrl } = useContext(ConfigContext);
    const { currentPropertyAssociationId } = useContext(PropertyAssociationContext);
    const [ error, setError ] = useState<string | undefined>();
    const [ isSubmitting, setIsSubmitting] = useState(false);

    const onSubmit = ({email}: Asset) => {
        setIsSubmitting(true);
        updateAsset(apiBaseUrl, currentPropertyAssociationId, initialValues.assetId, { email },
            (asset: Asset) => {
                setIsSubmitting(false);
                updateValues(asset);
                onClose();
            },
            () => {
                setIsSubmitting(false);
                setError(MESSAGES_SAVE_FAILED)
            },
        )
    }

    return (
        <FormModal initialValues={initialValues} title="Ändra mottagare" validationSchema={validationSchema} visible={visible} isSubmitting={isSubmitting} onClose={onClose} onSubmit={onSubmit}>
            {({values, errors, touched}) => {
                const formProps = {values, errors, touched};
                return (
                    <Fragment>
                        <p>Ange e-postadressen som ska få notiser för denna resurs.</p>
                        <TextField name="email" {...formProps} />
                        
                        { error && <div className="alert alert-danger">{error}</div> }
                    </Fragment>
                )
            }}
        </FormModal>
    )
}

export default EditRecipientModal