import { createContext, FunctionComponent, useEffect, useState } from 'react';
import ErrorMessage from '../../common/error/ErrorMessage';
import { Configuration } from '../configuration/Configuration';
import AuthService, { IAuthService } from './AuthService';

type Auth = {
    accessToken: string | null;
    isAuthenticated: boolean;
    signinRedirect: () => void;
    signinRedirectCallback: () => void;
    signinSilentCallback: () => void;
    signout: () => void;
    signoutRedirectCallback: () => void;
}

type Props = {
    config: Configuration;
}

export const AuthContext = createContext<Auth>({} as Auth);

export const AuthProvider: FunctionComponent<Props> = ({children, config}) => {
    const [authentication, setAuthentication] = useState<Auth>();
    const [authService, setAuthService] = useState<IAuthService>();
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        if(authService) {
            // NOTE: Prevent multiple instances of AuthService when hot reloading is active during development
            return;
        }

        setAuthService(new AuthService(config));
    }, [config])

    useEffect(() => {
        if(!authService) {
            return;
        }

        authService.addUserSignedOutEvent(() => {
            const { signinRedirect, signinRedirectCallback, signinSilentCallback, signout, signoutRedirectCallback } = authService;
            setAuthentication({accessToken: null, isAuthenticated: false, signinRedirect, signinRedirectCallback, signinSilentCallback, signout, signoutRedirectCallback});
        });

        refreshAuthState(authService, setAuthentication, setHasError);
    }, [authService])

    if(hasError) {
        return <ErrorMessage message="Ett oväntat fel har uppstått. Ladda om sidan och försök igen." />
    }

    if(!authentication) {
        return null
    }

    return (
        <AuthContext.Provider value={authentication}>
            {children}
        </AuthContext.Provider>
    )
}

const refreshAuthState = (authService: IAuthService, setAuthentication: (auth: Auth) => void, setHasError: (hasError: boolean) => void) => {
    if(!authService) {
        return;
    }
    
    authService.getUser().then(user => {
        if(!user || !authService.isAuthenticated(user)) {
            const { signinRedirect, signinRedirectCallback, signinSilentCallback, signout, signoutRedirectCallback } = authService;
            setAuthentication({accessToken: null, isAuthenticated: false, signinRedirect, signinRedirectCallback, signinSilentCallback, signout, signoutRedirectCallback});
            return;
        }

        authService.setAccessToken(user);
        const { signinRedirect, signinRedirectCallback, signinSilentCallback, signout, signoutRedirectCallback } = authService;
        setAuthentication({accessToken: user.access_token, isAuthenticated: true, signinRedirect, signinRedirectCallback, signinSilentCallback, signout, signoutRedirectCallback});
    })
    .catch((e: Error) => {
        if(e.message === 'login_required') {
            authService.signinRedirect();
            return;
        }

        setHasError(true);
    });
};