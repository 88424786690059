export const getFileName = (filePath: string | undefined | null): string => {
    if(!filePath) {
        return '';
    }

    const split = filePath.split('relativePathToMediaObject=');
    if(split.length !== 2) {
        // Fallback to just returning the last part of the path
        const split = filePath.split('/');
        return split[split.length - 1];
    }

    const relativePathToMediaObject = split[1].split('&')[0];
    const path = decodeURIComponent(relativePathToMediaObject);
    const slugs = path.split('/');
    return slugs[slugs.length - 1];
}