import { FunctionComponent, useContext, useState } from 'react'
import { ConfigContext } from '../../configuration/ConfigContext'
import SimpleCheckBox from '../../forms/SimpleCheckBox'
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext'
import { ToastContext } from '../../toaster/ToastContext'
import { ToastMessageType } from '../../toaster/models/ToastMessageType'
import { updateMemberRestrictions } from '../memberService'
import { Member } from '../models/Member'

type Props = {
    member: Member,
    onMemberUpdated: () => void,
}

const EditAccess: FunctionComponent<Props> = ({member, onMemberUpdated}) => {
    const { apiBaseUrl } = useContext(ConfigContext);
    const { currentPropertyAssociationId } = useContext(PropertyAssociationContext);
    const { addToast } = useContext(ToastContext);
    const [hasAdvertAccess, setHasAdvertAccess] = useState(member.hasAdvertAccess);
    const [hasAssetAccess, setHasAssetAccess] = useState(member.hasAssetAccess);

    const onError = () => {
        addToast('Något gick fel', 'Kunde tyvärr inte uppdatera behörigheten', ToastMessageType.Error);
    }

    const updateAdvertAccess = () => {
        const newHasAdvertAccess = !hasAdvertAccess;
        updateMemberRestrictions(
            apiBaseUrl, 
            currentPropertyAssociationId, 
            member.user.userId, 
            newHasAdvertAccess, 
            hasAssetAccess, 
            () => {
                setHasAdvertAccess(newHasAdvertAccess);
                onMemberUpdated();
            }, 
            onError
        );
    }

    const updateAssetAccess = () => {
        const newHasAssetAccess = !hasAssetAccess;
        updateMemberRestrictions(
            apiBaseUrl, 
            currentPropertyAssociationId, 
            member.user.userId, 
            hasAdvertAccess, 
            newHasAssetAccess, 
            () => {
                setHasAssetAccess(newHasAssetAccess);
                onMemberUpdated();
            }, 
            onError
        );
    }

    return  (
        <div className='ps-4 pt-3'>
            <SimpleCheckBox label='Åtkomst till anslagstavlan' name='accessToBoard' isChecked={hasAdvertAccess} onChange={updateAdvertAccess} />
            <SimpleCheckBox label='Åtkomst till gemensamma resurser' name='accessToAssets' isChecked={hasAssetAccess} onChange={updateAssetAccess} />
        </div>
    )
}

export default EditAccess