import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

const Unauthorized: FunctionComponent = () => (
    <div className="app__inner app__inner--center">
        <article className="text-center">
            <div className="large-heading">Behörighet saknas <span role="img" aria-label="emoji">✋</span></div>
            <p className="preamble">
                Du har tyvärr inte behörighet till den efterfrågade resursen.
            </p>
            <Link to="/" className="btn btn-lg btn-primary">Gå till startsidan</Link>
        </article>
    </div>
)

export default Unauthorized
