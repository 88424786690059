import { FunctionComponent, useContext, useState } from 'react'
import { Asset } from '../models/Asset'
import { AssetObject } from '../models/AssetObject'
import { ConfigContext } from '../../configuration/ConfigContext'
import Button from '../../forms/Button'
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext'
import { MESSAGES_SAVE_FAILED } from '../assetConstants'
import { deleteAssetObject } from '../assetService'

type Props = {
    asset: Asset;
    initialValues: AssetObject;
    onClose: () => void;
    updateValues: (values) => void;
}

const DeleteObjectModal: FunctionComponent<Props> = ({asset, initialValues, onClose, updateValues}) => {
    const { currentPropertyAssociationId } = useContext(PropertyAssociationContext);
    const { apiBaseUrl } = useContext(ConfigContext);
    const [ isSubmitting, setIsSubmitting] = useState(false);
    const [ error, setError ] = useState<string | undefined>();

    const handleDelete = () => {
        setIsSubmitting(true);
        deleteAssetObject(apiBaseUrl, currentPropertyAssociationId, asset.assetId, initialValues.assetObjectId,
            () => {
                setIsSubmitting(false);
                updateValues(Object.assign({}, asset, { objects: asset.objects.filter(x => x.assetObjectId !== initialValues.assetObjectId) }))
                onClose();
            },
            () => {
                setIsSubmitting(false);
                setError(MESSAGES_SAVE_FAILED)
            },
        )
    }

    return (
        <div className="cover">
            <div className="cover__content">
                <div className="cover__heading">Ta bort objektet?</div>
                <div className="cover__text">
                    Är du säker på att du vill ta bort <strong>{initialValues.name}</strong> från denna resurs?<br />
                    
                    { error && <div className="alert alert-danger">{error}</div> }
                </div>
                <Button text="Avbryt" additionalClassName="btn-lg" isSubmitting={isSubmitting} onClick={onClose} />
                <Button text="Ta bort objekt" additionalClassName="btn-lg btn-secondary" isPrimary={false} isSubmitting={isSubmitting} onClick={handleDelete} />
            </div>
        </div>
    )
}

export default DeleteObjectModal