import cx from 'classnames';
import React, { FunctionComponent, useEffect, useState } from 'react';
import SubNavigationHeading from './SubNavigationHeading';

type Props = { 
  heading: string;
  isExpanded?: boolean,
  onExpand?: (isExpand: boolean) => void
}

const SubNavigation: FunctionComponent<Props> = ({ children, heading, isExpanded, onExpand}) => {
    const [expanded, setExpanded] = useState(true);
    useEffect(() => { 
      setExpanded(!!isExpanded)
    }, [isExpanded])
    const onClickExpand = () => {
      if (!onExpand) {
        setExpanded(!expanded)
      } else {
        onExpand(!expanded)
      }
    }
    return (
        <div className={cx(['submenu', { 'submenu--expanded': expanded }])}>
            <button className="submenu__button" onClick={onClickExpand}>Fäll ut</button>
            <SubNavigationHeading heading={heading} />
            { children }
        </div>
    )
}

export default SubNavigation