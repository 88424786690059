import React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAsyncError = () => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [ _, setError ] = React.useState();
	return React.useCallback(
		(e) => {
			setError(() => {
				throw e;
			});
		},
		[ setError ]
	)
}