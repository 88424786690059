import { Fragment, FunctionComponent, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useAsyncError } from '../../common/error/useAsyncError';
import { Asset } from './models/Asset';
import { ConfigContext } from '../configuration/ConfigContext';
import OverlayConfirmModal from '../modal/OverlayConfirmModal';
import { PropertyAssociationContext } from '../propertyAssociation/PropertyAssociationContext';
import { withPropertyAssociation } from '../propertyAssociation/withPropertyAssociation';
import { ToastContext } from '../toaster/ToastContext';
import AssetTab from './components/AssetTab';
import NameModal from './modals/NameModal';
import { isDraft } from './assetHelpers';
import { deleteAsset, getAsset, refreshAssets } from './assetService';
import { DefaultsLookup, DEFAULTS_EMPTY } from './assetTypes/assetTypes';
import AssetNotifications from './steps/AssetNotifications';
import AssetOverview from './steps/AssetOverview';
import AssetPreview from './steps/AssetPreview';
import AssetPublished from './steps/AssetPublished';
import AssetReadyToPublish from './steps/AssetReadyToPublish';
import AssetSchedule from './steps/AssetSchedule';

type Props = {
    initInEditMode?: boolean;
}

const steps = [
    { title: 'Översikt', availableIfPublished: true },
    { title: 'Bokningsbara tider', availableIfPublished: true },
    { title: 'Notiser', availableIfPublished: true },
    { title: 'Publicera', availableIfPublished: false },
]

const EditAsset: FunctionComponent<Props> = ({initInEditMode = false}) => {
    const throwAsyncError = useAsyncError();
    const { push } = useHistory();
    const { id } = useParams() as any;
    const { addToast } = useContext(ToastContext);
    const { apiBaseUrl } = useContext(ConfigContext);
    const { currentPropertyAssociationId, currentArea } = useContext(PropertyAssociationContext);
    const [ activeStep, setActiveStep ] = useState(0);
    const [ defaults, setDefaults ] = useState(DEFAULTS_EMPTY);
    const [ isInEditMode, setIsInEditMode ] = useState(initInEditMode);
    const [ savedAt, setSavedAt ] = useState('');
    const [ asset, setAsset ] = useState<Asset | undefined>();
    const [ showDeleteModal, setShowDeleteModal ] = useState(false);
    const [ showNameModal, setShowNameModal ] = useState(false);
    
    const fetchAsset = () => getAsset(apiBaseUrl, currentPropertyAssociationId, id, onAssetLoaded, () => throwAsyncError('Kunde inte ladda resursen.'))
    useEffect(() => {
        // NOTE: Nollställ alla states
        setActiveStep(0);
        setDefaults(DEFAULTS_EMPTY);
        setIsInEditMode(initInEditMode);
        setSavedAt('');
        setAsset(undefined);
        setShowDeleteModal(false);
        setShowNameModal(false);
        fetchAsset()
    }, [id])

    const onAssetLoaded = (data: Asset) => {
        const newDefaults = DefaultsLookup[data.assetType.tag]
        setDefaults(newDefaults ?? DEFAULTS_EMPTY);
        setAsset(data);
    }

    const updateValues = () => {
        fetchAsset()
        setSavedAt(new Date().toLocaleTimeString());
    }

    const deleteDraft = () => {
        if(!asset) {
            gotoAssets();
            return;
        }
        
        deleteAsset(apiBaseUrl, currentPropertyAssociationId, asset.assetId,
            () => {
                addToast('Utkastet är nu borttaget');
                refreshAssets(apiBaseUrl, currentPropertyAssociationId, currentArea?.propertyAssociationAreaId);
                gotoAssets();
            },
            gotoAssets
        );
    }

    const gotoNext = () => {
        setActiveStep(activeStep + 1);
    }

    const gotoPrevious = () => {
        setActiveStep(activeStep - 1);
    }
    
    const gotoAssets = () => {
        push('/assets/');
    }

    if (!asset || !defaults) {
        return null;
    }

    const renderCancelButton = () => {
        if(isDraft(asset.status) && initInEditMode) {
            return <div className="paper__secondary-buttons"><button className="btn btn-lg btn-secondary" onClick={() => setShowDeleteModal(true)}>Avbryt</button></div>
        }
        
        return <div className="paper__secondary-buttons"></div>;
    }

    const actions = {
        gotoNext,
        gotoPrevious,
        renderCancelButton,
        setIsInEditMode,
        updateValues
    }
    const assetIsDraft = isDraft(asset.status)

    return (
        <div className="app__inner app__inner--paper paper">
            <div className="paper__header">
                <h1 className="paper__heading">
                    { isInEditMode ? (
                        <button className="edit-this" onClick={() => setShowNameModal(true) }>{asset.name}</button>
                    ) : (
                        <Fragment>{asset.name}</Fragment>
                    )}
                </h1>
                <button className="paper__close" onClick={gotoAssets}>Stäng</button>
                { savedAt && <div className="paper__status">Senast sparad {savedAt}</div> }
                { isInEditMode && (
                    <div className="paper__tabs">
                        { steps.map((x, index) => (assetIsDraft || x.availableIfPublished) && (
                            <AssetTab key={index} activeStep={activeStep} index={index} assetStatus={asset.status} setActiveStep={setActiveStep} title={x.title} />
                        ))}
                    </div>
                )}
            </div>

            { activeStep === 0 && !isInEditMode && <AssetOverview defaults={defaults} asset={asset} {...actions} /> }
            { activeStep === 0 && isInEditMode && <AssetPreview defaults={defaults} asset={asset} {...actions} /> }
            { activeStep === 1 && <AssetSchedule defaults={defaults} asset={asset} {...actions} /> }
            { activeStep === 2 && <AssetNotifications asset={asset} {...actions} /> }
            { activeStep === 3 && <AssetReadyToPublish asset={asset} {...actions} /> }
            { activeStep === 4 && <AssetPublished /> }

            <NameModal initialValues={asset} defaults={defaults} updateValues={updateValues} visible={showNameModal} onClose={() => setShowNameModal(false) } />
            <OverlayConfirmModal heading="Ta bort utkast?" text="Vill du ta bort utkastet? En borttagen resurs går ej att återställa." okButtonText="Ta bort utkast" cancelButtonText="Avbryt" visible={showDeleteModal} onSubmit={deleteDraft} onCancel={gotoAssets} />
        </div>
    );
}

export default withPropertyAssociation(EditAsset)