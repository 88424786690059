import { FunctionComponent } from 'react'
import { tryFormatDateDayMonthTimeStartEnd } from '../../../common/utils/dateUtils'
import { BookingDetails } from '../../bookings/models/BookingDetails'
import Button from '../../forms/Button'
import { GetPinboardMessageResponse } from '../../pinboardMessages/models/GetPinboardMessageResponse'
import { ConversationModel } from '../models/ConversationModel'
import { ConversationParticipantUser } from '../models/ConversationParticipantUser'
import UserIcon from './UserIcon'

type Props = {
    booking: BookingDetails | undefined;
    conversation: ConversationModel | null;
    pinboardMessage: GetPinboardMessageResponse | undefined;
    setShowEditGroupModal: (show: boolean) => void;
    showGroups: boolean;
    user: ConversationParticipantUser | undefined;
}

const ConversationHeader: FunctionComponent<Props> = ({booking, conversation, pinboardMessage, setShowEditGroupModal, showGroups, user}) => {
    if(conversation?.name) {
        return (
            <div className='conversations__item-header'>
                <div className='conversations__icon me-2 conversations__icon--group' />

                <div>
                    <h3 className='my-0'>
                        {conversation?.name || ''}
                    </h3>
                    {renderMembers(conversation)}
                </div>

                {showGroups && (
                    <div className='ms-auto'>
                        <Button isPrimary={false} additionalClassName='btn-secondary' text='Administrera' onClick={() => setShowEditGroupModal(true)} />
                    </div>
                )}
            </div>
        )
    }

    if(user) {
        return (
            <div className='conversations__item-header'>
                <UserIcon {...user} extraCss='me-2' />
                <div>
                    <h3 className='my-0'>
                        {`${user.firstName} ${user.lastName}`}
                    </h3>
                    <p className='m-0 text-truncate'>{renderDescription(booking, pinboardMessage)}</p>
                </div>
            </div>
        )
    }

    return null
}

const renderDescription = (booking, pinboardMessage) => {
    if (booking) {
        return `${booking.assetName}, ${tryFormatDateDayMonthTimeStartEnd(booking.startTime, booking.endTime)}`
    }

    if (pinboardMessage) {
        return pinboardMessage.pinboardMessage.title
    }

    return null;
}

const renderMembers = (conversation: ConversationModel | null) => {
    const users = conversation?.participants?.filter(x => x.user) || [];
    const firstUsers = users.slice(0, 3);
    const remainingUserCount = users.length - firstUsers.length;
    
    return (
        <p className='m-0'>
            {firstUsers.map(({user}) => (`${user.firstName} ${user.lastName}`)).join(', ')}
            {remainingUserCount > 0 && <strong> +{remainingUserCount}</strong>}
        </p>
    )
}

export default ConversationHeader