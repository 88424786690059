import cx from 'classnames';
import { Form, Formik } from 'formik';
import { FunctionComponent, createRef, useEffect, useState } from 'react';
import { objectAsArray } from '../../../common/utils/objectUtils';
import { AssetTypeDefinition, DefaultsLookup } from '../../assets/assetTypes/assetTypes';
import { AssetListItem } from '../../assets/models/AssetListItem';
import Button from '../../forms/Button';
import CheckBox from '../../forms/CheckBox';
import { noValidationSchema } from '../../forms/validators';
import styles from './AssetFilter.module.scss';

type Props = {
    assets: AssetListItem[] | undefined,
    setAssetFilter: (assetFilter: string[]) => void,
}

type GroupedAssets = {
    assetTypeTag: string,
    assets: AssetListItem[],
}

type FormData = {
    assetFilter: string[]
}

const AssetFilter: FunctionComponent<Props> = ({assets, setAssetFilter}) => {
    const [groupedAssets] = useState(groupAssets(assets));
    const [filterText, setFilterText] = useState('Alla resurser');
    const [formData] = useState(createFormData(assets));
    const [isExpanded, setIsExpanded] = useState(false);
    const ref = createRef<HTMLDivElement>();

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if(!ref.current) {
                return;
            }

            if(!ref.current.contains(event.target as Node)) {
                setIsExpanded(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    }, [ref])

    if(!groupAssets) {
        return null;
    }

    const assetTypes = objectAsArray(DefaultsLookup);

    const onSubmit = (values: FormData) => {
        setAssetFilter(values.assetFilter);
        setIsExpanded(false);

        if(values.assetFilter.length === 0) {
            setFilterText('Alla resurser');
            return;
        }

        if(values.assetFilter.length === assets?.length) {
            setFilterText('Alla resurser');
            return;
        }

        setFilterText(`${values.assetFilter.length}/${assets?.length} resurser valda`);
    }

    return (
        <Formik initialValues={formData} enableReinitialize={true} validationSchema={noValidationSchema} validateOnMount={false} onSubmit={onSubmit}>
            {({setValues, values}) => (
                <Form>
                    <div className={styles.container} ref={ref}>
                        <button type="button" className={cx([styles.button, {[styles['button--expanded']]: isExpanded}])} onClick={() => setIsExpanded(!isExpanded)}>{filterText}</button>
                        {isExpanded && (
                            <div className={styles.dropdown}>
                                {assetTypes.map((x) => renderGroup(x, groupedAssets, setValues, values))}
                                <div className={styles.buttongroup}>
                                    <Button type="button" isPrimary={false} text="Markera alla" onClick={() => setValues(formData)} />
                                    <Button type="button" isPrimary={false} text="Avmarkera alla" onClick={() => setValues({ assetFilter: [] })} />
                                    <Button type="submit" text="Filtrera" />
                                </div>
                            </div>
                        )}
                    </div>
                </Form>
            )}
        </Formik>
    )
}

function groupAssets(assets: AssetListItem[] | undefined): GroupedAssets[] {
    if(!assets) {
        return [];
    }

    const groupedAssets = assets.reduce((groups: GroupedAssets[], asset: AssetListItem) => {
        const group = groups.find((x) => x.assetTypeTag === asset.assetTypeTag);
        if (group) {
            group.assets.push(asset);
        } else {
            groups.push({
                assetTypeTag: asset.assetTypeTag,
                assets: [asset]
            });
        }
        return groups;
    }, []);

    return groupedAssets;
}

function renderGroup(assetType: AssetTypeDefinition, groupedAssets: GroupedAssets[], setValues: (values: FormData) => void, values: FormData) {
    const group = groupedAssets.find((x) => x.assetTypeTag === assetType.assetTypeTag);
    if(!group) {
        return null;
    }

    return (
        <div className={styles.list} key={assetType.assetTypeTag}>
            <button type="button" className={styles.title} onClick={() => toggleValues(group.assets, values, setValues)}>{assetType.namePlural}</button>
            {group.assets.map((x) => <CheckBox key={x.assetId} name="assetFilter" value={x.assetId} label={x.name} />)}
        </div>
    )
}

function toggleValues(assets: AssetListItem[], values: FormData, setValues: (values: FormData) => void): void {
    const assetIds = assets.map((x) => x.assetId);

    if(assetIds.length === 0) {
        return;
    }

    let assetFilter = values.assetFilter.filter((x) => assetIds.indexOf(x) === -1);

    if(values.assetFilter.indexOf(assetIds[0]) === -1) {
        assetFilter = assetFilter.concat(assetIds);
    }

    setValues({ assetFilter });
}

function createFormData(assets: AssetListItem[] | undefined): FormData {
    if(!assets) {
        return { assetFilter: [] };
    }

    return {
        assetFilter: assets.map((x) => x.assetId)
    }
}

export default AssetFilter
