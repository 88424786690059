import { Fragment, FunctionComponent, useContext, useState } from 'react'
import * as Yup from 'yup'
import { ConfigContext } from '../../configuration/ConfigContext'
import Label from '../../forms/Label'
import TextField from '../../forms/TextField'
import FormModal from '../../modal/FormModal'
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext'
import { ToastContext } from '../../toaster/ToastContext'
import { updateApartment } from '../apartmentService'
import { ApartmentResponse } from '../models/GetApartmentsResponse'

type Props = {
    apartment: ApartmentResponse,
    onClose: () => void,
    refreshApartments: () => void,
}

const validationSchema = Yup.object().shape({
})

const EditApartmentModal: FunctionComponent<Props> = ({apartment, onClose, refreshApartments}) => {
    const {apiBaseUrl} = useContext(ConfigContext);
    const {currentPropertyAssociationId} = useContext(PropertyAssociationContext);
    const {addToast} = useContext(ToastContext);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState<string | undefined>();

    const onSubmit = ({area, numberOfRooms}) => {
        setIsSubmitting(true);
        updateApartment(
            apiBaseUrl,
            currentPropertyAssociationId,
            apartment.apartmentId,
            {area: area ?? 0, numberOfRooms: numberOfRooms ?? 0},
            () => {
                addToast('Lägenhet uppdaterad');
                setIsSubmitting(false);
                refreshApartments();
                onClose();
            },
            () => {
                setIsSubmitting(false);
                setError('Kunde inte uppdatera lägenhet!');
            }
        )
    }

    return (
        <FormModal 
            initialValues={apartment}
            isSubmitting={isSubmitting}
            title={`Lägenhet ${apartment.name}`}
            validationSchema={validationSchema}
            visible={true}
            onClose={onClose}
            onSubmit={onSubmit}
        >
            {({values, errors, touched}) => {
                const formProps = {values, errors, touched};
                return (
                    <Fragment>
                        <Label name="area" text="Area" />
                        <TextField name="area" type="number" {...formProps} />

                        <Label name="numberOfRooms" text="Antal rum" />
                        <TextField name="numberOfRooms" type="number" {...formProps} />

                        { error && <div className="alert alert-danger">{error}</div> }
                    </Fragment>
                )
            }}
        </FormModal>
    )
}

export default EditApartmentModal