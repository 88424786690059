import { Fragment, FunctionComponent, useContext, useState } from 'react'
import * as Yup from 'yup'
import BooleanCheckBox from '../../forms/BooleanCheckBox'
import Button from '../../forms/Button'
import FormField from '../../forms/FormField'
import Label from '../../forms/Label'
import RadioBoxDropDown from '../../forms/RadioBoxDropDown'
import FormModal from '../../modal/FormModal'
import { ToastContext } from '../../toaster/ToastContext'
import { ToastMessageType } from '../../toaster/models/ToastMessageType'
import { WeekDay } from '../assetConstants'
import { mapFromSchedule, mapToSchedule } from '../components/WeekSchedule'
import { AssetTimeSlot } from '../models/AssetTimeSlot'

type Props = {
    onClose: () => void;
    setTimeslots: (timeslots: AssetTimeSlot[], onSuccess: () => void, onFailure: () => void) => void;
    timeslots: AssetTimeSlot[];
}

type FormData = {
    daySelection: DaySelection;
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
    startTime: string;
    endTime: string;
    overwriteExisting: boolean;
}

enum DaySelection {
    All,
    Weekdays,
    Weekends,
}

const validationSchema = Yup.object().shape({   
    startTime: Yup.number().required().min(0).max(1440).test('startTime', 'Starttid måste vara mindre än sluttid', function (value) {
        return !value || (value < parseInt(this.parent.endTime))
    }),
})

const DEFAULT_VALUES: FormData = {
    daySelection: DaySelection.All,
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
    sunday: true,
    startTime: '08',
    endTime: '17',
    overwriteExisting: false,
}

const TIMES = [
    { value: '00', text: '00:00' }, { value: '01', text: '01:00' }, { value: '02', text: '02:00' }, { value: '03', text: '03:00' },
    { value: '04', text: '04:00' }, { value: '05', text: '05:00' }, { value: '06', text: '06:00' }, { value: '07', text: '07:00' },
    { value: '08', text: '08:00' }, { value: '09', text: '09:00' }, { value: '10', text: '10:00' }, { value: '11', text: '11:00' },
    { value: '12', text: '12:00' }, { value: '13', text: '13:00' }, { value: '14', text: '14:00' }, { value: '15', text: '15:00' },
    { value: '16', text: '16:00' }, { value: '17', text: '17:00' }, { value: '18', text: '18:00' }, { value: '19', text: '19:00' },
    { value: '20', text: '20:00' }, { value: '21', text: '21:00' }, { value: '22', text: '22:00' }, { value: '23', text: '23:00' },
    { value: '24', text: '24:00' },
]

const EasyPlannerModal: FunctionComponent<Props> = ({onClose, setTimeslots, timeslots}) => {
    const { addToast } = useContext(ToastContext);
    const [ isSubmitting, setIsSubmitting] = useState(false)

    const onSubmit = (values: FormData) => {
        setIsSubmitting(true)

        const days = getDays(values);
        let slots: AssetTimeSlot[] = [];
        const startTime = getDateTime(values.startTime);
        const endTime = getDateTime(values.endTime);

        for (const weekDay of days) {
            slots.push({ weekDay, startTime, endTime, endTimeNextDay: false, endTimeRestOfDay: false });
        }

        if(!values.overwriteExisting) {
            slots = mergeTimeslots(slots, timeslots);
        }

        setTimeslots(
            slots,
            () => {
                addToast('Schemat har skapats', '', ToastMessageType.Success);
                setIsSubmitting(false);
                onClose();
            },
            () => {
                addToast('Kunde inte skapa schemat', '', ToastMessageType.Error);
                setIsSubmitting(false);
            }
        )
    }

    return (
        <FormModal initialValues={DEFAULT_VALUES} title="Verktyg för att skapa schema" validationSchema={validationSchema} visible={true} isSubmitting={isSubmitting} onClose={onClose} onSubmit={onSubmit}>
            {({ values, errors, touched, setValues }) => {
                const formProps = { values, errors, touched }
                return (
                    <Fragment>
                        <div className="mb-3">
                            Snabbval:
                            <Button text="Alla dagar" isPrimary={false} onClick={() => makeDaySelection(setValues, values, DaySelection.All)} />
                            <Button text="Vardagar" isPrimary={false} onClick={() => makeDaySelection(setValues, values, DaySelection.Weekdays)} />
                            <Button text="Helger" isPrimary={false} onClick={() => makeDaySelection(setValues, values, DaySelection.Weekends)} />
                            <Button text="Hela dygnet" isPrimary={false} onClick={() => makeTimeSelection(setValues, values, '00', '24')} />
                        </div>

                        <div className="d-flex justify-content-between">
                            <Label name="daySelection" text="Veckodagar:" />
                            <BooleanCheckBox name="monday" label="Måndag" />
                            <BooleanCheckBox name="tuesday" label="Tisdag" />
                            <BooleanCheckBox name="wednesday" label="Onsdag" />
                            <BooleanCheckBox name="thursday" label="Torsdag" />
                            <BooleanCheckBox name="friday" label="Fredag" />
                            <BooleanCheckBox name="saturday" label="Lördag" />
                            <BooleanCheckBox name="sunday" label="Söndag" />
                        </div>

                        <div className="d-flex mt-2">
                            <FormField width={50}>
                                <Label name="startTime" text="Starttid:" />
                                <RadioBoxDropDown name="startTime" label="Starttid" fixed={true} options={TIMES} {...formProps} />
                            </FormField>
                            <FormField width={50}>
                                <Label name="endTime" text="Sluttid:" />
                                <RadioBoxDropDown name="endTime" label="Sluttid" fixed={true} options={TIMES} {...formProps} />
                            </FormField>
                        </div>

                        <div className="mt-3">
                            <BooleanCheckBox name="overwriteExisting" label="Skriv över befintliga tider (detta kommer ersätta alla sparade tider)" />
                        </div>
                    </Fragment>
                )
            }}
        </FormModal>
    )
}

const makeDaySelection = (setValues: (values: any) => void, values: FormData, daySelection: DaySelection) => {
    switch (daySelection) {
        case DaySelection.All:
            setValues(
                Object.assign({}, values, {
                    monday: true,
                    tuesday: true,
                    wednesday: true,
                    thursday: true,
                    friday: true,
                    saturday: true,
                    sunday: true,
                })
            );
            break
        case DaySelection.Weekdays:
            setValues(
                Object.assign({}, values, {
                    monday: true,
                    tuesday: true,
                    wednesday: true,
                    thursday: true,
                    friday: true,
                    saturday: false,
                    sunday: false,
                })
            );
            break
        case DaySelection.Weekends:
            setValues(
                Object.assign({}, values, {
                    monday: false,
                    tuesday: false,
                    wednesday: false,
                    thursday: false,
                    friday: false,
                    saturday: true,
                    sunday: true,
                })
            );
            break
    }
}

const makeTimeSelection = (setValues: (values: any) => void, values: FormData, startTime: string, endTime: string) => {
    setValues(
        Object.assign({}, values, {
            startTime,
            endTime,
        })
    );
};

const getDays = (values: FormData): WeekDay[] => {
    const days: WeekDay[] = [];

    if(values.monday) {
        days.push(WeekDay.Monday);
    }
    if(values.tuesday) {
        days.push(WeekDay.Tuesday);
    }
    if(values.wednesday) {
        days.push(WeekDay.Wednesday);
    }
    if(values.thursday) {
        days.push(WeekDay.Thursday);
    }
    if(values.friday) {
        days.push(WeekDay.Friday);
    }
    if(values.saturday) {
        days.push(WeekDay.Saturday);
    }
    if(values.sunday) {
        days.push(WeekDay.Sunday);
    }

    return days;
}

const getDateTime = (time: string): string => {
    if(time === '24') {
        return '0001-01-02T00:00:00';
    }

    return `0001-01-01T${time}:00:00`;
}

const mergeTimeslots = (newSlots: AssetTimeSlot[], existingSlots: AssetTimeSlot[]): AssetTimeSlot[] => {
    const newSchedule = mapToSchedule(newSlots);
    const existingSchedule = mapToSchedule(existingSlots);

    for(let d = 0; d < 7; d++) {
        for(let t = 0; t < 24; t++) {
            if(newSchedule[d][t] === true) {
                existingSchedule[d][t] = true;
            }
        }
    }

    return mapFromSchedule(existingSchedule);
}

export default EasyPlannerModal