import { getIn } from 'formik';
import { FunctionComponent } from 'react';

type Props = {
    errors;
    extraClassName?: string;
    ignoreTouched?: boolean;
    name: string;
    touched;
}

const FieldError: FunctionComponent<Props> = ({errors, extraClassName, name, touched, ignoreTouched = false}) => {
    const error = getIn(errors, name);
    const isTouched = getIn(touched, name)

    if(!error || !(isTouched || ignoreTouched)) {
        return null;
    }

    return <div className={`field-error text-danger ${extraClassName}`}>{error}</div>
}

export default FieldError