import { useEffect } from 'react';
import { useLocation } from 'react-router';

export const useBookingHistory = (setDetailBookingId: (bookingId: string) => void): void => {
    const { search } = useLocation();
    
    useEffect(() => {
        if (!search) {
            return;
        }
        
        const bookingId = new URLSearchParams(search).get('bookingId');
        if (bookingId) {
            setDetailBookingId(bookingId);
        }
    }, []);
};