import React, { FunctionComponent, useState } from 'react';
import { Configuration } from './Configuration';

type Props = {
    config: Configuration;
}

export const ConfigContext = React.createContext<Configuration>({} as Configuration);

export const ConfigProvider: FunctionComponent<Props> = ({children, config}) => {
    const [ value ] = useState(config);

    return (
        <ConfigContext.Provider value={value}>
            {children}
        </ConfigContext.Provider>
    )
}