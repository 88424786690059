import { FunctionComponent, useContext, useEffect, useState } from 'react'
import ErrorMessage from '../../../common/error/ErrorMessage'
import { ConfigContext } from '../../configuration/ConfigContext'
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext'
import { UserAdvert } from '../models/UserAdvert'
import { getUserAdverts } from '../userAdvertService'

type Props = {
    deletedAdverts: string[];
    selectAdvertId: (advertId: string) => void;
}

const ListActiveUserAdverts: FunctionComponent<Props> = ({deletedAdverts, selectAdvertId}) => {
    const { apiBaseUrl } = useContext(ConfigContext);
    const { currentPropertyAssociationId, currentArea } = useContext(PropertyAssociationContext);
    const [adverts, setAdverts] = useState<UserAdvert[] | undefined>();
    const [error, setError] = useState<string | undefined>();

    useEffect(() => {
        getUserAdverts(apiBaseUrl, currentPropertyAssociationId, currentArea?.propertyAssociationAreaId, ({adverts}) => setAdverts(adverts), () => setError('Kunde inte ladda anslagstavla'));
    }, []);

    useEffect(() => {
        if(adverts) {
            setAdverts(
                adverts.filter(advert => !deletedAdverts.includes(advert.advertId))
            );
        }
    }, [deletedAdverts]);

    if(error) {
        return <ErrorMessage message={error} />
    }

    if(!adverts) {
        return null;
    }

    if(adverts.length === 0) {
        return <p><i>Inga aktiva annonser</i></p>
    }

    return (
        <table className="custom-table">
            <thead className="custom-table__head">
                <tr>
                    <th className="custom-table__header">Rubrik</th>
                    <th className="custom-table__header text-end">Användare</th>
                </tr>
            </thead>
            <tbody>
                {adverts.map(({advertId, title, user}) => (
                    <tr key={advertId} className="custom-table__row" onClick={() => { selectAdvertId(advertId) }} role="button">
                        <td>{title}</td>
                        <td className="text-end">{user.firstName} {user.lastName}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default ListActiveUserAdverts