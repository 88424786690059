import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { formatDateString } from '../../../common/utils/dateUtils';
import { BookingDetails } from '../../bookings/models/BookingDetails';
import { getBooking } from '../../bookings/bookingService';
import { ConfigContext } from '../../configuration/ConfigContext';
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext';
import NewBookingLayout from '../components/NewBookingLayout';

type Props = {
    bookingId: string;
    label: string;
}

const BookingConfirmation: FunctionComponent<Props> = ({ bookingId, label }) => {
    const { apiBaseUrl } = useContext(ConfigContext);
    const { currentPropertyAssociationId } = useContext(PropertyAssociationContext);
    const [booking, setBooking] = useState<BookingDetails | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();

    useEffect(() => {
        getBooking(
            apiBaseUrl,
            currentPropertyAssociationId,
            bookingId,
            (booking) => setBooking(booking),
            () => setErrorMessage('Kunde inte läsa upp bokningen.')
        )
    }, [bookingId]);

    if(errorMessage) {
        return (
            <NewBookingLayout heading="Ett fel har uppstått" label={label}>
                <div className="alert alert-danger">{errorMessage}</div>
            </NewBookingLayout>
        )
    }

    if (!booking) {
        return null;
    }

    return (
        <NewBookingLayout heading={booking.assetName} label={label}>
            <div className="paper__message pt-1">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="57" height="56"><path d="m328.84 109.75 48.57 24.32 24.28 48.63a16 16 0 0 0 28.62 0l24.28-48.63 48.57-24.32a16 16 0 0 0 0-28.66l-48.57-24.32-24.28-48.63c-5.43-10.85-23.19-10.85-28.62 0l-24.28 48.63-48.57 24.32a16 16 0 0 0 0 28.66zM396.5 83a16.1 16.1 0 0 0 7.16-7.16L416 51.14l12.34 24.74A16.1 16.1 0 0 0 435.5 83l24.72 12.38-24.72 12.42a16.2 16.2 0 0 0-7.16 7.16L416 139.7 403.66 115a16.2 16.2 0 0 0-7.16-7.16l-24.72-12.42zm106.66 318.53-48.57-24.31-24.28-48.63c-5.43-10.86-23.19-10.86-28.62 0l-24.28 48.63-48.57 24.31a16 16 0 0 0 0 28.67l48.57 24.31 24.28 48.63a16 16 0 0 0 28.62 0l24.28-48.63 48.57-24.31a16 16 0 0 0 0-28.67zm-67.66 26.71a16.17 16.17 0 0 0-7.16 7.17L416 460.15l-12.34-24.74a16.17 16.17 0 0 0-7.16-7.17l-24.72-12.37 24.72-12.38a16.17 16.17 0 0 0 7.16-7.17L416 371.59l12.34 24.73a16.17 16.17 0 0 0 7.16 7.17l24.72 12.38zM384 255.64a16.06 16.06 0 0 0-8.84-14.33L262.59 185 206.31 72.23c-5.43-10.86-23.19-10.86-28.62 0L121.41 185 8.84 241.31a16 16 0 0 0 0 28.67l112.57 56.36 56.28 112.71a16 16 0 0 0 28.62 0l56.28-112.71L375.16 270a16.09 16.09 0 0 0 8.84-14.36zm-140.5 44.43a16.1 16.1 0 0 0-7.16 7.16L192 396.06l-44.34-88.83a16.1 16.1 0 0 0-7.16-7.16l-88.72-44.43 88.72-44.42a16.2 16.2 0 0 0 7.16-7.16L192 115.23l44.34 88.83a16.2 16.2 0 0 0 7.16 7.16l88.72 44.42z" /></svg>
                <h1>Härligt!</h1>
                <p>
                    Resursen {booking.assetName} är nu bokad.<br />
                    {formatDateString(booking.startTime)} - {formatDateString(booking.endTime)}<br />
                    Kostnad: {booking.price === 0 ? 'Gratis' : `${booking.price} kr`}
                </p>
                {booking.isIntermediateBooking && (
                    <p>
                        Bokningen gäller: {booking.userName}<br />
                        Fakturaadress: {booking.intermediateInfo.invoiceAddress}, {booking.intermediateInfo.invoiceZipCode} {booking.intermediateInfo.invoiceCity}
                    </p>
                )}
                <Link to="/bookings/" className="btn btn-lg btn-primary btn-shadow">Visa bokade resurser</Link><br />
                <Link to="/bookings/new/" className="btn btn-lg btn-secondary btn-shadow mt-3">Boka ytterligare resurs</Link>
            </div>
        </NewBookingLayout>
    );
}

export default BookingConfirmation;