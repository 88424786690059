/* eslint-disable react/display-name */
import { useContext, useState } from 'react';
import { AuthContext } from '../authentication/AuthContext';
import RedirectLoader from '../authentication/RedirectLoader';
import Unauthorized from '../authentication/Unauthorized';
import { PropertyAssociationContext } from './PropertyAssociationContext';
import SelectPropertyAssociation from './SelectPropertyAssociation';
import NoPropertyAssociation from './components/NoPropertyAssociation';
import SelectPropertyAssociationArea from './SelectPropertyAssociationArea';

// NOTE: Use this for any top level component that requires a current property association
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const withPropertyAssociation = (Component: any, allowGuests = true) => (props: any) => {
	const { isAuthenticated, signinRedirect } = useContext(AuthContext);
    const { allPropertyAssociations, currentArea, currentPropertyAssociation, isAdmin, loadPropertyAssociations, requireAreaSelection } = useContext(PropertyAssociationContext);
    const [ propertyAssociationsInitialized, setPropertyAssociationsInitialized ] = useState(false);

    if(!isAuthenticated) {
        signinRedirect();
        return <RedirectLoader />;
    }

    if(!currentPropertyAssociation && !allPropertyAssociations) {
        if(!propertyAssociationsInitialized) {
            loadPropertyAssociations();
            setPropertyAssociationsInitialized(true);
        }
        return null;
    }
 
    if(!currentPropertyAssociation && allPropertyAssociations?.length === 0 && !isAdmin) {
        return <NoPropertyAssociation />
    }

    if(!currentPropertyAssociation && allPropertyAssociations) {
        return <SelectPropertyAssociation />;
    }

    if(!isAdmin && !allowGuests && currentPropertyAssociation?.isGuest) {
        return <Unauthorized />;
    }

    if(requireAreaSelection && !currentArea) {
        return <SelectPropertyAssociationArea />;
    }

	return <Component {...props} />;
}
