
export function addEventListeners(selector: string, eventName: string, handler: (event: any) => void): void {
    const elements = find(selector);
    
    Array.prototype.map.call(elements, element => {
        element.addEventListener(eventName, handler);
    });
}

export function find(selector: string): NodeListOf<HTMLElement> {
    return document.querySelectorAll(selector);
}

export function findFrom(root: HTMLElement, selector: string): NodeListOf<HTMLElement> | null {
    if(!root) {
        return null;
    }

    return root.querySelectorAll(selector);
}

export function findAsArray(selector: string): [HTMLElement] {
    const htmlElements = find(selector);
    return [...htmlElements] as [HTMLElement];
}

export function first(selector: string): HTMLElement | null {
    return document.querySelector(selector);
}

export function firstFrom(root: HTMLElement, selector: string): HTMLElement | null {
    if(!root) {
        return null;
    }

    return root.querySelector(selector);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function mapElements(elements: any, func: any): any[] {
    if(!elements) {
        return [];
    }

    return Array.prototype.map.call(elements, func).filter((x) => x);
}

export function onDocumentReady(func: () => void): void {
    if ((document as any).attachEvent ? document.readyState === 'complete' : document.readyState !== 'loading') {
        func();
    } else {
        document.addEventListener('DOMContentLoaded', func);
    }
}

export function stringToElements(html: string): [HTMLElement] {
    const container = document.createElement('div');
    container.innerHTML = html;
    return [...container.children] as [HTMLElement];
}

export function remove(element: HTMLElement | null): void {
    if (element === null || element.parentNode === null) {
        return;
    }

    element.parentNode.removeChild(element);
}

export function setFocus(selector: string): void {
    const element = first(selector);
    if(element) {
        element.focus();
    }
}

export function toggleClass(selector: string, className: string): void {
    const element = first(selector);
    if(element) {
        element.classList.toggle(className);
    }
}

export function addClass(element: HTMLElement, className: string): void {
    if(!element) {
        return;
    }

    element.classList.add(className);
}

export function getOffset(element: HTMLElement): { top: number, left: number } {
    const rect = element.getBoundingClientRect();
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

export function scrollToElement(id: string): void {
    const element = document.getElementById(id);
    if(!element || !window.scrollTo) {
        return;
    }

    const offset = getOffset(element);
    window.scrollTo({
        top: offset.top,
        behavior: 'smooth'
    });
}

export function scrollElementTo(id: string, y: number): void {
    const element = document.getElementById(id);
    if(!element || !window.scrollTo) {
        return;
    }

    element.scrollTo({
        top: y,
        behavior: 'smooth'
    });
}