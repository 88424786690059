import React, { FunctionComponent } from 'react'
import { Field } from 'formik';

type Props = {
    label: string;
    name: string;
    readonly?: boolean;
    value: any;
}

const RadioButton: FunctionComponent<Props> = ({ label, name, readonly = false, value }) => {
	const id = `${name}_${value}`;

	return (
        <label className="form-check mb-2">
            <Field
                className="form-check-input form-check-input--checkmark"
                name={name}
                id={id}
                disabled={readonly}
                type="radio"
                value={value}
            />
            <span className="form-check-label">{label}</span>
        </label>
	)
}

export default RadioButton
