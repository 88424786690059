import axios from 'axios';
import { Log, User, UserManager, UserManagerEvents, UserManagerSettings, WebStorageStateStore } from 'oidc-client';
import { clearAllBut } from '../../common/utils/localStorageUtils';
import { toUrlWithQueryStrings } from '../../common/utils/searchParams';
import { Configuration } from '../configuration/Configuration';

export interface IAuthService {
    addUserSignedOutEvent: (callback: UserManagerEvents.UserSignedOutCallback) => any,
    getUser: () => any;
    isAuthenticated: (user: User | null) => boolean,
    removeUser: () => void,
    setAccessToken: (user: User | null) => void,
    signinRedirect: () => void,
    signinRedirectCallback: () => void,
    signinSilentCallback: () => void,
    signout: () => void,
    signoutRedirectCallback: () => void,
}

export default class AuthService implements IAuthService {
    private userManager: UserManager;

    constructor(config: Configuration) {
        const settings = buildSettings(config);

        this.userManager = new UserManager(settings);

        //TODO: Lägg till logger vid behov, t ex Log.logger = appInsightsLogger;
        Log.level = Log.WARN;

        this.userManager.events.addUserLoaded(() => {
            this.getUser().then(this.setAccessToken);

            this.handleRedirectsOnSignin();
        });

        this.userManager.events.addUserSignedOut(() => {
            this.removeUser();
            delete axios.defaults.headers.common['Authorization'];
        });
    }

    addUserSignedOutEvent = (callback: UserManagerEvents.UserSignedOutCallback): void => {
        this.userManager.events.addUserSignedOut(callback);
    }

    getUser = (): Promise<User | null> => {
        return this.userManager.getUser();
    }

    handleRedirectsOnSignin = (): void => {
        if (window.location.href.indexOf('signin-oidc') === -1) {
            return;
        }

        const redirectUri = localStorage.getItem('redirectUri');
        if(redirectUri) {
            window.location.replace(redirectUri);
        }
    }

    isAuthenticated = (user: User | null): boolean => (!((!user || user.expired)));

    removeUser = (): void => {
        this.userManager.removeUser();
    }

    setAccessToken = (user: User | null): void => {
        if (!user) {
            delete axios.defaults.headers.common['Authorization'];
            return;
        }
        
        axios.defaults.headers.common['Authorization'] = `Bearer ${user.access_token}`;
    }

    signinRedirect = (): void => {
        const url = toUrlWithQueryStrings(window.location)
        localStorage.setItem('redirectUri', url);
        this.userManager.signinRedirect();
    }

    signinRedirectCallback = (): void => {
        this.userManager.signinRedirectCallback();
    }

    signinSilentCallback = (): void => {
        this.userManager.signinSilentCallback();
    }

    signout = (): void => {
        this.userManager.signoutRedirect();
    }
    
    signoutRedirectCallback = (): void => {
        this.userManager.signoutRedirectCallback().then(() => {
            clearAllBut(['propertyAssociationId']);
            window.location.replace('/');
        });
        this.userManager.clearStaleState();
    }
}

function buildSettings(config: Configuration): UserManagerSettings {
    const { host, protocol } = window.location;
    const currentHost = `${protocol}//${host}`;

    return {
        // NOTE: Documentation https://github.com/IdentityModel/oidc-client-js/wiki
        accessTokenExpiringNotificationTime: 300,
        authority: config.authUrl,
        automaticSilentRenew: true,
        client_id: 'manager-hemshare-web',
        filterProtocolClaims: true,
        includeIdTokenInSilentRenew: false,
        loadUserInfo: false,
        metadata: {
            issuer: `${config.authUrl}`,
            authorization_endpoint: `${config.authUrl}/connect/authorize`,
            end_session_endpoint: `${config.authUrl}/connect/endsession`,
            jwks_uri: `${config.authUrl}/.well-known/openid-configuration/jwks`,
            revocation_endpoint: `${config.authUrl}/connect/revocation`,
            token_endpoint: `${config.authUrl}/connect/token`,
            userinfo_endpoint: `${config.authUrl}/connect/userinfo`,
        },
        post_logout_redirect_uri: `${currentHost}/signout-callback-oidc`,
        redirect_uri: `${currentHost}/signin-oidc`,
        response_type: 'code',
        revokeAccessTokenOnSignout : true,
        scope: 'openid profile offline_access hemshare-core',
        silent_redirect_uri: `${currentHost}/silentrenew`,
        silentRequestTimeout: 60000,
        userStore: new WebStorageStateStore({ store: window.sessionStorage }),
    }
}