import { useFormikContext } from 'formik';
import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { ReactTags, ReactTagsAPI, TagSuggestion } from 'react-tag-autocomplete';
import Label from './Label';

type Props = {
    initialValues?: TagSuggestion[];
    label?: string;
    name: string;
    placeholder: string;
    suggestions: TagSuggestion[];
}

const TagsField: FunctionComponent<Props> = ({initialValues, label, name, placeholder, suggestions}) => {
    const api = useRef<ReactTagsAPI>(null)
    const { setFieldValue } = useFormikContext();
    const [selected, setSelected] = useState<TagSuggestion[]>([])

    useEffect(() => {
        if(!initialValues) {
            return;
        }

        setSelected(initialValues);
    }, [initialValues])

    useEffect(() => {
        setFieldValue(name, selected.map(x => (x.value)));
    }, [selected])

    const suggestionsTransform = (value, suggestions) => {
        // Remove selected from suggestions
        suggestions = suggestions.filter(x => !selected.some(y => y.value === x.value));
        return suggestions.filter(x => x.label.toLowerCase().includes(value.toLowerCase()))
    }

    const onAdd = useCallback(
        (newTag) => {
            setSelected([...selected, newTag]);
            api.current?.listBox.collapse();
        },
        [selected]
    )

    const onDelete = useCallback(
        (tagIndex) => {
            setSelected(selected.filter((_, i) => i !== tagIndex));
        },
        [selected]
    )

    return (
        <div>
            {label && <Label name={`tags_${name}`} text={label} />}
            <ReactTags
                ref={api}
                id={`tags_${name}`}
                selected={selected}
                suggestions={suggestions}
                suggestionsTransform={suggestionsTransform}
                onAdd={onAdd}
                onDelete={onDelete}
                activateFirstOption={true}
                placeholderText={placeholder}
                noOptionsText="Ingen träff för '%value%'"
                // onShouldExpand={(value) => value.length > 2}
            />
        </div>
    );
}

export default TagsField