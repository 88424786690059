import { FunctionComponent, useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { objectAsArray } from '../../common/utils/objectUtils';
import { ConfigContext } from '../configuration/ConfigContext';
import { PropertyAssociationContext } from '../propertyAssociation/PropertyAssociationContext';
import { withPropertyAssociation } from '../propertyAssociation/withPropertyAssociation';
import AssetType from './components/AssetType';
import { createAsset, refreshAssets } from './assetService';
import { AssetTypeDefinition, DefaultsLookup } from './assetTypes/assetTypes';

const CreateAsset: FunctionComponent = () => {
    const history = useHistory();
    const { apiBaseUrl } = useContext(ConfigContext);
    const { currentPropertyAssociationId, currentArea } = useContext(PropertyAssociationContext);
    const [hasError, setHasError] = useState(false);

    const areaId = currentArea?.propertyAssociationAreaId;

    const onCreateAsset = ({ name, assetTypeTag, timeSelectionType }: AssetTypeDefinition) => {
        setHasError(false);

        createAsset(
            apiBaseUrl,
            currentPropertyAssociationId,
            {
                name,
                assetTypeTag,
                timeSelectionType,
                propertyAssociationAreaId: areaId,
            },
            (assetId: string) => {
                refreshAssets(apiBaseUrl, currentPropertyAssociationId, areaId);
                history.push(`/assets/create/${assetId}`);

            },
            () => setHasError(true)
        );
    }

    return (
        <div className="app__inner app__inner--center">
            <div className="text-center">
                <h1 className="large-heading">Vilken typ av bokningsbar resurs vill du skapa?</h1>
                <p className="preamble">Börja med att välja en resurstyp nedan. I nästa steg kan du fylla i namn och detaljerad information för resursen.</p>
            </div>
            <div>
                {hasError && (
                    <div className="alert my-3 text-center">Kunde inte skapa den nya resursen. Vänligen försök igen.</div>
                )}
                <div className="boxlist">
                    {objectAsArray(DefaultsLookup).map((x: AssetTypeDefinition) => <AssetType key={x.name} defaults={x} onClick={onCreateAsset} />)}
                </div>
            </div>
        </div>
    );
}

export default withPropertyAssociation(CreateAsset)