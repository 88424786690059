import { Fragment, FunctionComponent, useContext, useState } from 'react';
import * as Yup from 'yup';
import { BOOKING_CANCEL_FAIL } from '../../bookings/bookingConstants';
import { cancelBooking } from '../../bookings/bookingService';
import { BookingDetails } from '../../bookings/models/BookingDetails';
import { ConfigContext } from '../../configuration/ConfigContext';
import BooleanRadioButton from '../../forms/BooleanRadioButton';
import TextArea from '../../forms/TextArea';
import { requiredValidator } from '../../forms/validators';
import { postConversationMessage } from '../../messages/conversationService';
import FormModal from '../../modal/FormModal';
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext';
import { ToastContext } from '../../toaster/ToastContext';

type Props = {
    booking: BookingDetails;
    onClose: () => void;
    onRefresh: () => void;
}

type FormValues = {
    text: string;
    applyCancellationFee: boolean;
};

const validationSchema = Yup.object().shape({
    text: requiredValidator,
});

const INITIAL_VALUES: FormValues = {
    applyCancellationFee: true,
    text: '',
}

const CancellationView: FunctionComponent<Props> = ({ booking, onClose, onRefresh }) => {
    const { currentPropertyAssociationId } = useContext(PropertyAssociationContext);
    const { apiBaseUrl } = useContext(ConfigContext);
    const { addToast } = useContext(ToastContext);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState<string | undefined>();

    if(!booking) {
        return null;
    }

    const closeModal = () => {
        setIsSubmitting(false);
        onRefresh();
        onClose();
    }

    const onFail = () => {
        setIsSubmitting(false);
        setError(BOOKING_CANCEL_FAIL);
    }

    const onCancelSuccess = (text: string) => {
        if(!booking?.conversationId) {
            closeModal();
            return;
        }

        postConversationMessage(apiBaseUrl, currentPropertyAssociationId, booking.conversationId, text, undefined, true,
            () => {
                addToast('Meddelandet har skickats.');
                closeModal();
            },
            onFail
        );
    }

    const onSubmit = (values: FormValues) => {
        const { applyCancellationFee, text } = values;
        setIsSubmitting(true);
        cancelBooking(
            apiBaseUrl,
            currentPropertyAssociationId,
            booking.bookingId,
            applyCancellationFee,
            () => onCancelSuccess(text),
            onFail
        );
    };

    return (
        <FormModal
            submitText='Avboka och lämna kommentar'
            initialValues={INITIAL_VALUES}
            title='Lämna kommentar'
            validationSchema={validationSchema}
            visible={true}
            isSubmitting={isSubmitting}
            onClose={onClose}
            onSubmit={onSubmit}
        >
            {({ values, errors, touched }) => {
                const formProps = { values, errors, touched };
                return (
                    <Fragment>
                        <p>Beskriv varför bokningen tas bort och därmed inte kommer kunna genomföras.</p>
                        <TextArea placeholder='Hej resursen är inte tillgänglig under din bokade tid...' name='text' {...formProps} />
                        {!booking.hasFreeCancellation && (
                            <div>
                                <BooleanRadioButton name="applyCancellationFee" value={true} label="Debitera för sen avbokning" {...formProps} />
                                <BooleanRadioButton name="applyCancellationFee" value={false} label="Debitera inte för sen avbokning" {...formProps} />
                            </div>
                        )}
                        {error && <div className='alert alert-danger'>{error}</div>}
                    </Fragment>
                );
            }}
        </FormModal>
    );
}

export default CancellationView